export interface ListBrokerForm {
  search?: string;
  demand?: string;
  realEstateType?: string;
  province?: string;
}

export const listBrokerDefaultValues: ListBrokerForm = {
  search: '',
  demand: '',
  realEstateType: '',
  province: '',
};
