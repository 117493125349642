import { gql } from '@apollo/client';
import {
  Boolean,
  DirectionEnumType,
  EquipmentsAndServicesEnumType,
  FarmLandUseEnumType,
  FixedUtilitiesEnumType,
  Float,
  Int,
  LocalUtilitiesEnumType,
  ObjectID,
  RealEstate,
  String,
  UtilitiesEnumType,
  WarehouseEnumType,
} from '../queries';
import { WalletSourceType } from './calculate-valuation';
import { CoordinateDataInput, ViewEnumType } from './create-real-estate';
import { Date } from './update-user-v2';

export interface RealEstateUpdateInput {
  alleyTurnCount?: Int;
  area?: Float;
  bedroomCount?: Int;
  block?: String;
  buildingType?: String;
  clearanceArea?: Float;
  constructionArea?: Float;
  contactEmail?: String;
  contactName?: String;
  contactPhones?: String[];
  country?: String;
  direction?: DirectionEnumType;
  district?: String;
  endShowTime?: Date;
  equipmentsAndServices?: EquipmentsAndServicesEnumType[];
  fixedUtilities?: FixedUtilitiesEnumType[];
  floorArea?: Float;
  floorCount?: Int;
  frontHomeAlleyWidth?: Float;
  hasAlley?: Boolean;
  hasMezzanine?: Boolean;
  hotelStar?: Int;
  houseNumber?: String;
  industryTrade?: String;
  investor?: String;
  isForSell?: Boolean;
  isNewConstruction?: Boolean;
  legals?: String[];
  length?: Float;
  localUtilities?: LocalUtilitiesEnumType[];
  location?: CoordinateDataInput;
  mediaUrls?: ObjectID[];
  panoramicImages?: ObjectID[];
  onFloor?: Int;
  postContent?: String;
  postTitle?: String;
  postType?: Int;
  price?: Float;
  privateNote?: String;
  project?: String;
  province?: String;
  rear?: Float;
  source?: WalletSourceType;
  startShowTime?: Date;
  street?: String;
  toiletCount?: Int;
  type?: String;
  utilities?: UtilitiesEnumType[];
  villaView?: ViewEnumType[];
  vr360Urls?: String[];
  ward?: String;
  warehouseType?: WarehouseEnumType;
  width?: Float;
  farmLandUse?: FarmLandUseEnumType;
}
export interface UpdateRealEstateData {
  updateRealEstate?: RealEstate;
}

export interface UpdateRealEstateVariables {
  realEstateId: ObjectID;
  editOnly?: Boolean;
  data: RealEstateUpdateInput;
  captchaId: String;
  captchaCode: String;
}

export const UPDATE_REAL_ESTATE = gql`
  mutation updateRealEstate(
    $realEstateId: ObjectID!
    $editOnly: Boolean
    $data: RealEstateUpdateInput!
    $captchaId: String!
    $captchaCode: String!
  ) @api(name: realEstate) {
    updateRealEstate(
      realEstateId: $realEstateId
      editOnly: $editOnly
      data: $data
      captchaId: $captchaId
      captchaCode: $captchaCode
    ) {
      id
      fullSlug
    }
  }
`;
