import { SVGProps } from 'react';

const IconBuildingWarehouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M2 14.8206V6.15397L8 3.4873L14 6.15397V14.8206'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.6665 9.4873H11.3332V14.8206H4.6665V10.8206H8.6665'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.66667 14.821V8.82096C8.66667 8.64415 8.59643 8.47458 8.4714 8.34956C8.34638 8.22453 8.17681 8.1543 8 8.1543H6.66667C6.48986 8.1543 6.32029 8.22453 6.19526 8.34956C6.07024 8.47458 6 8.64415 6 8.82096V10.821'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconBuildingWarehouse };
