import { IconClose, IconDirectionDown } from 'components/icons';
import { useOutsideClick } from 'hooks';
import { filter, find, includes, isEmpty, isEqual, map, toString } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { removeVietnameseAccents } from 'utils';

interface Props {
  name: string;
  disabled?: boolean;
  required?: boolean;
  placeHolder?: boolean;
  label?: string;
  options?: Option[];
  error?: { message?: string };
  emptyOptionsText?: string;
  wrapperClassName?: string;
  popoverClassName?: string;
  labelClassName?: string;
  optionClassName?: string;
  onChange?: (value?: string) => void;
}
export interface Option {
  value?: string;
  label?: string;
}

const FormSelect = ({
  name,
  disabled,
  required,
  placeHolder,
  label,
  options,
  error,
  emptyOptionsText,
  wrapperClassName,
  popoverClassName,
  labelClassName,
  optionClassName,
  onChange,
}: Props) => {
  const form = useFormContext<any>();
  const valueWatch = useWatch({ control: form.control, name });
  const [privateOptions, setPrivateOptions] = useState(options);
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const clickOutside = () => {
    if (!disabled) {
      setOpen(!isOpen);
    }
  };
  const changeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setPrivateOptions(
      isEmpty(event.target.value)
        ? options
        : filter(options, (option) =>
            includes(
              removeVietnameseAccents(toString(option.label)),
              removeVietnameseAccents(event.target.value),
            ),
          ),
    );
  };
  const selectOption = (option: Option) => {
    const newValue = option.value;
    form.setValue(name, newValue, { shouldValidate: true });
    onChange?.(newValue);
  };
  const clearSelect = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setInputValue('');
    const newValue = undefined;
    form.setValue(name, newValue, { shouldValidate: true });
    onChange?.(newValue);
  };

  useOutsideClick(containerRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setPrivateOptions(options);
    }
  }, [isOpen]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setPrivateOptions(options);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [valueWatch]);

  return (
    <div
      ref={containerRef}
      className={`flex select-none flex-col space-y-[3px] ${disabled ? '' : 'cursor-text'}`}
      onClick={clickOutside}
    >
      <div className={disabled ? 'pointer-events-none' : ''}>
        <fieldset
          className={`group relative flex items-center rounded-[8px] border ${
            error ? 'border-alert' : isOpen ? 'border-primary' : 'border-stroke'
          } ${wrapperClassName ?? ''} ${disabled ? 'bg-disabled' : ''}`}
        >
          {isOpen || valueWatch ? (
            !placeHolder && (
              <legend className='pointer-events-none mx-[2px] px-[4px] leading-[2px]'>
                <label className='text-[12px]'>
                  <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
                  {required && <span className='text-alert'>*</span>}
                </label>
              </legend>
            )
          ) : (
            <label className='pointer-events-none absolute top-1/2 -translate-y-1/2'>
              <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
              {required && <span className='text-alert'>*</span>}
            </label>
          )}
          <div className='flex w-full items-center justify-between space-x-[8px]'>
            <div className='flex w-full items-center'>
              {isOpen ? (
                <input
                  type='text'
                  ref={inputRef}
                  autoComplete='off'
                  value={inputValue}
                  defaultValue={find(options, (option) => isEqual(option.value, valueWatch))?.label}
                  className='w-full bg-transparent'
                  onChange={changeInput}
                />
              ) : (
                <span className='pointer-events-none w-full line-clamp-1'>
                  {find(options, (option) => isEqual(option.value, valueWatch))?.label}
                </span>
              )}
              {!isOpen && valueWatch && (
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={clearSelect}
                >
                  <IconClose className='h-[20px] w-[20px] text-text2' />
                </button>
              )}
            </div>
            <div className='broder-stroke h-full border-l pl-[8px]'>
              <IconDirectionDown
                className={`min-h-[24px] min-w-[24px] text-text2 transition duration-[200ms] ease-in-out ${
                  isOpen ? 'rotate-180' : ''
                }`}
              />
            </div>
          </div>
          <div
            className={`${
              isOpen
                ? `visible mt-[12px] opacity-100 ${
                    isEmpty(privateOptions) ? 'pointer-events-none' : 'pointer-events-auto'
                  }`
                : 'pointer-events-none invisible opacity-0'
            } absolute top-full left-0 right-0 z-[2] max-h-[200px] space-y-[2px] overflow-y-auto rounded-[8px] bg-paper shadow-13 ${
              popoverClassName ?? ''
            }`}
          >
            {isEmpty(privateOptions) ? (
              <div className={`flex cursor-default items-center ${optionClassName ?? ''}`}>
                <span className='italic text-text2 line-clamp-1'>{emptyOptionsText}</span>
              </div>
            ) : (
              map(privateOptions, (option, optionIndex) => (
                <div
                  key={`${option.value}-${optionIndex}`}
                  className={`flex cursor-pointer items-center hover:bg-secondary ${
                    isEqual(valueWatch, option.value) ? 'bg-secondary' : ''
                  } ${optionClassName ?? ''}`}
                  onClick={() => {
                    selectOption(option);
                  }}
                >
                  <span className='line-clamp-1' data-value={option.value}>
                    {option.label}
                  </span>
                </div>
              ))
            )}
          </div>
        </fieldset>
      </div>
      {error && (
        <div className='flex w-full py-[3px] px-[12px] '>
          <span className='text-[12px] leading-[15px] text-alert'>{error?.message}</span>
        </div>
      )}
    </div>
  );
};

export default FormSelect;
