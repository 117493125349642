import { gql } from '@apollo/client';
import { Conversation } from '../../main/queries/conversations';

export interface ConversationUpdatedData {
  conversationUpdated?: Conversation;
}

export const CONVERSATION_UPDATED = gql`
  subscription conversationUpdated {
    conversationUpdated {
      id
      name
      participants {
        id
        nickname
        fullName
        avatar {
          originalUrl
          fileId
        }
        userId
        pinnedAt
      }
      createdAt
      updatedAt
      lastMessage {
        id
        from
        to
        text
        type
        createdAt
        callPayload
        attachments {
          type
          attachmentId
          url
        }
        seenBy
        hiddenBy
        unreadBy
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
