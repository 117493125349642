import { floor, gte, isEmpty, join } from 'lodash';

export const convertNumberToVietnameseCurrencyLongString = (number: number) => {
  const parts = [];

  if (gte(number, 1e9)) {
    parts.push(`${floor(number / 1e9).toLocaleString()} tỷ`);
    number = number % 1e9;
  }
  if (gte(number, 1e6)) {
    parts.push(`${floor(number / 1e6)} triệu`);
    number = number % 1e6;
  }
  if (gte(number, 1e3)) {
    parts.push(`${floor(number / 1e3)} nghìn`);
    number = number % 1e3;
  }
  if (gte(number, 1)) {
    parts.push(`${number} đồng`);
  }

  return isEmpty(parts) ? '0 đồng' : join(parts, ' ');
};
