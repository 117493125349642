import { SVGProps } from 'react';

const IconList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M5.23914 0H0.477229C0.214228 0 0.0010376 0.21319 0.0010376 0.476191V5.2381C0.0010376 5.5011 0.214228 5.71429 0.477229 5.71429H5.23914C5.50214 5.71429 5.71533 5.5011 5.71533 5.2381V0.476191C5.71533 0.21319 5.50214 0 5.23914 0Z'
      fill='currentColor'
    />
    <path
      d='M5.23914 7.14282H0.477229C0.214228 7.14282 0.0010376 7.35601 0.0010376 7.61901V12.3809C0.0010376 12.6439 0.214228 12.8571 0.477229 12.8571H5.23914C5.50214 12.8571 5.71533 12.6439 5.71533 12.3809V7.61901C5.71533 7.35601 5.50214 7.14282 5.23914 7.14282Z'
      fill='currentColor'
    />
    <path
      d='M5.23914 14.2856H0.477229C0.214228 14.2856 0.0010376 14.4988 0.0010376 14.7618V19.5237C0.0010376 19.7867 0.214228 19.9999 0.477229 19.9999H5.23914C5.50214 19.9999 5.71533 19.7867 5.71533 19.5237V14.7618C5.71533 14.4988 5.50214 14.2856 5.23914 14.2856Z'
      fill='currentColor'
    />
    <path
      d='M18.9289 0H8.21465C7.6229 0 7.14322 0.21319 7.14322 0.476191V5.2381C7.14322 5.5011 7.6229 5.71429 8.21465 5.71429H18.9289C19.5207 5.71429 20.0004 5.5011 20.0004 5.2381V0.476191C20.0004 0.21319 19.5207 0 18.9289 0Z'
      fill='currentColor'
    />
    <path
      d='M18.9289 7.14282H8.21465C7.6229 7.14282 7.14322 7.35601 7.14322 7.61901V12.3809C7.14322 12.6439 7.6229 12.8571 8.21465 12.8571H18.9289C19.5207 12.8571 20.0004 12.6439 20.0004 12.3809V7.61901C20.0004 7.35601 19.5207 7.14282 18.9289 7.14282Z'
      fill='currentColor'
    />
    <path
      d='M18.9289 14.2856H8.21465C7.6229 14.2856 7.14322 14.4988 7.14322 14.7618V19.5237C7.14322 19.7867 7.6229 19.9999 8.21465 19.9999H18.9289C19.5207 19.9999 20.0004 19.7867 20.0004 19.5237V14.7618C20.0004 14.4988 19.5207 14.2856 18.9289 14.2856Z'
      fill='currentColor'
    />
  </svg>
);
export { IconList };
