import { SVGProps } from 'react';

const IconTextAa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8.61873 9.2625L5.44373 3.26875C5.40154 3.18749 5.33784 3.11938 5.25959 3.07183C5.18134 3.02429 5.09154 2.99915 4.99998 2.99915C4.90842 2.99915 4.81862 3.02429 4.74037 3.07183C4.66211 3.11938 4.59842 3.18749 4.55623 3.26875L1.38123 9.2625L0.0562285 11.7687C-0.00353482 11.8867 -0.014761 12.0234 0.0249513 12.1495C0.0646635 12.2757 0.152161 12.3813 0.268728 12.4437C0.386703 12.5035 0.523379 12.5147 0.649524 12.475C0.775669 12.4353 0.881266 12.3478 0.943729 12.2312L2.12498 10H7.87498L9.05623 12.2312C9.09798 12.3128 9.16159 12.3811 9.23996 12.4286C9.31833 12.4761 9.40836 12.5008 9.49998 12.5C9.58597 12.5001 9.67053 12.478 9.74548 12.4358C9.82044 12.3937 9.88326 12.3329 9.92787 12.2594C9.97248 12.1859 9.99738 12.1021 10.0002 12.0162C10.0029 11.9302 9.9835 11.845 9.94373 11.7687L8.61873 9.2625ZM2.65623 9L4.99998 4.56875L7.34373 9H2.65623ZM13 5.93125C12.3104 5.92452 11.6458 6.18945 11.15 6.66875C11.0994 6.7138 11.0586 6.76864 11.0299 6.82996C11.0012 6.89127 10.9852 6.95777 10.983 7.02543C10.9807 7.0931 10.9923 7.16051 11.0169 7.22358C11.0415 7.28666 11.0786 7.34408 11.126 7.39237C11.1735 7.44067 11.2302 7.47882 11.2929 7.50452C11.3555 7.53023 11.4227 7.54294 11.4904 7.5419C11.5581 7.54086 11.6249 7.52609 11.6867 7.49847C11.7485 7.47086 11.804 7.43098 11.85 7.38125C12.1592 7.08576 12.5724 6.92407 13 6.93125C13.825 6.93125 14.5 7.49375 14.5 8.18125V8.63125C14.0557 8.33567 13.5336 8.17901 13 8.18125C11.6187 8.18125 10.5 9.1875 10.5 10.4312C10.5 11.675 11.6187 12.6812 13 12.6812C13.5518 12.6835 14.0909 12.5155 14.5437 12.2C14.5818 12.2893 14.6455 12.3654 14.7266 12.4188C14.8078 12.4722 14.9029 12.5004 15 12.5C15.1326 12.5 15.2598 12.4473 15.3535 12.3536C15.4473 12.2598 15.5 12.1326 15.5 12V8.18125C15.5 6.9375 14.3812 5.93125 13 5.93125ZM13 11.6812C12.175 11.6812 11.5 11.1187 11.5 10.4312C11.5 9.74375 12.175 9.18125 13 9.18125C13.825 9.18125 14.5 9.74375 14.5 10.4312C14.5 11.1187 13.825 11.6812 13 11.6812Z'
      fill='currentColor'
    />
  </svg>
);
export { IconTextAa };
