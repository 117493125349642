import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconOrderCancelledModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;
  return (
    <svg
      width='46'
      height='45'
      viewBox='0 0 46 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g clipPath='url(#clip0_369_18457)'>
        <rect x='0.5' width='45' height='45' rx='8' fill='white' />
        <rect x='0.5' width='45' height='45' rx='8' fill='url(#paint0_linear_369_18457)' />
        <rect x='0.5' width='45' height='45' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.6'
          cx='35.1111'
          cy='38.5'
          r={r ?? '30'}
          fill='url(#paint1_radial_369_18457)'
          fillOpacity='0.8'
        />
        <path
          d='M23.5 12.5H20.5C19.9477 12.5 19.5 12.9477 19.5 13.5V14.5C19.5 15.0523 19.9477 15.5 20.5 15.5H23.5C24.0523 15.5 24.5 15.0523 24.5 14.5V13.5C24.5 12.9477 24.0523 12.5 23.5 12.5Z'
          fill={fillColor2 ?? '#80888F'}
        />
        <path
          d='M28 13.5H25.5V14.5C25.5 15.0304 25.2893 15.5391 24.9142 15.9142C24.5391 16.2893 24.0304 16.5 23.5 16.5H20.5C19.9696 16.5 19.4609 16.2893 19.0858 15.9142C18.7107 15.5391 18.5 15.0304 18.5 14.5V13.5H16C15.6022 13.5 15.2206 13.658 14.9393 13.9393C14.658 14.2206 14.5 14.6022 14.5 15V30C14.5 30.3978 14.658 30.7794 14.9393 31.0607C15.2206 31.342 15.6022 31.5 16 31.5H23.5C22.8388 30.6167 22.4884 29.5395 22.5033 28.4363C22.5182 27.333 22.8976 26.2657 23.5824 25.4006C24.2672 24.5355 25.2189 23.9211 26.2893 23.6533C27.3596 23.3855 28.4885 23.4793 29.5 23.92V15C29.5 14.6022 29.342 14.2206 29.0607 13.9393C28.7794 13.658 28.3978 13.5 28 13.5ZM21.5 27.5H17.5C17.3674 27.5 17.2402 27.4473 17.1464 27.3536C17.0527 27.2598 17 27.1326 17 27C17 26.8674 17.0527 26.7402 17.1464 26.6464C17.2402 26.5527 17.3674 26.5 17.5 26.5H21.5C21.6326 26.5 21.7598 26.5527 21.8536 26.6464C21.9473 26.7402 22 26.8674 22 27C22 27.1326 21.9473 27.2598 21.8536 27.3536C21.7598 27.4473 21.6326 27.5 21.5 27.5ZM26.5 23.5H17.5C17.3674 23.5 17.2402 23.4473 17.1464 23.3536C17.0527 23.2598 17 23.1326 17 23C17 22.8674 17.0527 22.7402 17.1464 22.6464C17.2402 22.5527 17.3674 22.5 17.5 22.5H26.5C26.6326 22.5 26.7598 22.5527 26.8536 22.6464C26.9473 22.7402 27 22.8674 27 23C27 23.1326 26.9473 23.2598 26.8536 23.3536C26.7598 23.4473 26.6326 23.5 26.5 23.5ZM26.5 19.5H17.5C17.3674 19.5 17.2402 19.4473 17.1464 19.3536C17.0527 19.2598 17 19.1326 17 19C17 18.8674 17.0527 18.7402 17.1464 18.6464C17.2402 18.5527 17.3674 18.5 17.5 18.5H26.5C26.6326 18.5 26.7598 18.5527 26.8536 18.6464C26.9473 18.7402 27 18.8674 27 19C27 19.1326 26.9473 19.2598 26.8536 19.3536C26.7598 19.4473 26.6326 19.5 26.5 19.5Z'
          fill={fillColor2 ?? '#80888F'}
        />
        <path
          d='M27.5 24.5C26.7089 24.5 25.9355 24.7346 25.2777 25.1741C24.6199 25.6136 24.1072 26.2384 23.8045 26.9693C23.5017 27.7002 23.4225 28.5044 23.5769 29.2804C23.7312 30.0563 24.1122 30.769 24.6716 31.3284C25.231 31.8878 25.9437 32.2688 26.7196 32.4231C27.4956 32.5775 28.2998 32.4983 29.0307 32.1955C29.7616 31.8928 30.3864 31.3801 30.8259 30.7223C31.2654 30.0645 31.5 29.2911 31.5 28.5C31.5 27.4391 31.0786 26.4217 30.3284 25.6716C29.5783 24.9214 28.5609 24.5 27.5 24.5ZM28.915 29.205C28.9619 29.2515 28.9991 29.3068 29.0244 29.3677C29.0498 29.4286 29.0629 29.494 29.0629 29.56C29.0629 29.626 29.0498 29.6914 29.0244 29.7523C28.9991 29.8132 28.9619 29.8685 28.915 29.915C28.8683 29.9613 28.8129 29.998 28.752 30.0229C28.691 30.0478 28.6258 30.0604 28.56 30.06C28.4942 30.0604 28.429 30.0478 28.368 30.0229C28.3071 29.998 28.2517 29.9613 28.205 29.915L27.5 29.205L26.795 29.915C26.7483 29.9613 26.6929 29.998 26.632 30.0229C26.571 30.0478 26.5058 30.0604 26.44 30.06C26.3742 30.0604 26.309 30.0478 26.248 30.0229C26.1871 29.998 26.1317 29.9613 26.085 29.915C26.0381 29.8685 26.0009 29.8132 25.9756 29.7523C25.9502 29.6914 25.9371 29.626 25.9371 29.56C25.9371 29.494 25.9502 29.4286 25.9756 29.3677C26.0009 29.3068 26.0381 29.2515 26.085 29.205L26.795 28.5L26.085 27.795C25.9909 27.7008 25.938 27.5732 25.938 27.44C25.938 27.3068 25.9909 27.1792 26.085 27.085C26.1792 26.9908 26.3069 26.938 26.44 26.938C26.5732 26.938 26.7009 26.9908 26.795 27.085L27.5 27.795L28.205 27.085C28.2992 26.9908 28.4269 26.938 28.56 26.938C28.6932 26.938 28.8209 26.9908 28.915 27.085C29.0092 27.1792 29.062 27.3068 29.062 27.44C29.062 27.5732 29.0092 27.7008 28.915 27.795L28.205 28.5L28.915 29.205Z'
          fill={fillColor2 ?? '#80888F'}
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_369_18457'
          x1='41.5'
          y1='42.5'
          x2='4.5'
          y2='3.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9DBFE8' />
          <stop offset='1' stopColor='#97A9B0' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_369_18457'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(35.1111 38.5) rotate(90) scale(30)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_369_18457'>
          <rect x='0.5' width='45' height='45' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconOrderCancelledModule };
