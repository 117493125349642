import { SVGProps } from 'react';

const IconHomeVerify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1955_55812)'>
      <path
        d='M16.5417 10.5834C13.2564 10.5834 10.5834 13.2564 10.5834 16.5417C10.5834 19.8271 13.2564 22.5001 16.5417 22.5001C19.8271 22.5001 22.5001 19.8271 22.5001 16.5417C22.5001 13.2564 19.8271 10.5834 16.5417 10.5834ZM19.5227 15.0833L16.3144 18.75C15.9651 19.1487 15.351 19.1689 14.977 18.794L13.1436 16.9606C12.7852 16.6022 12.7852 16.0229 13.1436 15.6645C13.5021 15.3061 14.0814 15.3061 14.4398 15.6645L15.5801 16.8048L18.1432 13.8751C18.4768 13.4956 19.0562 13.4553 19.4366 13.789C19.817 14.1226 19.8564 14.702 19.5227 15.0833Z'
        fill='currentColor'
      />
      <path
        d='M16.0833 5.9614V2.33323C16.0833 1.82723 15.6727 1.41657 15.1667 1.41657C14.6607 1.41657 14.25 1.82723 14.25 2.33323V4.1684L10.7657 0.76115C10.4092 0.412817 9.83992 0.413734 9.48425 0.76115L0.906083 9.14682C0.6485 9.4044 0.5 9.7619 0.5 10.1249C0.5 10.883 1.11692 11.4999 1.875 11.4999H3.25V16.5416C3.25 17.8047 4.2785 18.8332 5.54167 18.8332H7.83333C8.33933 18.8332 8.75 18.4226 8.75 17.9166C8.75 17.4106 8.33933 16.9999 7.83333 16.9999H5.54167C5.28867 16.9999 5.08333 16.7946 5.08333 16.5416V10.5832C5.08333 10.0772 4.67267 9.66656 4.16667 9.66656H2.997L10.125 2.69807L16.1301 8.57023C16.4931 8.92498 17.0724 8.91765 17.4262 8.55557C17.7801 8.19348 17.7737 7.61323 17.4116 7.2594L16.0833 5.9614Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1955_55812'>
        <rect width='22' height='22' fill='currentColor' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconHomeVerify };
