import { SVGProps } from 'react';

const IconAddFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_7808_11070)'>
      <path
        d='M17.935 10.3894V5.84747C17.935 5.71747 17.875 5.59735 17.79 5.50232L12.6978 0.15509C12.6028 0.0551147 12.4677 0 12.3327 0H4.25925C2.76877 0 1.57822 1.21545 1.57822 2.70612V17.9725C1.57822 19.4632 2.76877 20.6587 4.25925 20.6587H10.632C11.8374 22.6595 14.0285 24 16.5244 24C20.3159 24 23.4123 20.9187 23.4123 17.1222C23.4174 13.8058 21.0363 11.0347 17.935 10.3894ZM12.833 1.7558L16.2494 5.35217H14.0334C13.3731 5.35217 12.833 4.80707 12.833 4.14679V1.7558ZM4.25925 19.6582C3.32394 19.6582 2.5787 18.9078 2.5787 17.9725V2.70612C2.5787 1.76569 3.32394 1.00049 4.25925 1.00049H11.8325V4.14679C11.8325 5.36224 12.818 6.35266 14.0334 6.35266H16.9345V10.2592C16.7846 10.2543 16.6645 10.2393 16.5345 10.2393C14.7887 10.2393 13.1831 10.9096 11.9726 11.9601H5.6199C5.3447 11.9601 5.11966 12.1851 5.11966 12.4601C5.11966 12.7354 5.3447 12.9604 5.6199 12.9604H11.0422C10.687 13.4606 10.3918 13.9609 10.1618 14.5111H5.6199C5.3447 14.5111 5.11966 14.7361 5.11966 15.0114C5.11966 15.2864 5.3447 15.5116 5.6199 15.5116H9.83664C9.71158 16.0118 9.64657 16.567 9.64657 17.1222C9.64657 18.0225 9.82162 18.913 10.1367 19.6633H4.25925V19.6582ZM16.5295 23.0046C13.2882 23.0046 10.652 20.3685 10.652 17.1271C10.652 13.8858 13.283 11.2496 16.5295 11.2496C19.7758 11.2496 22.4068 13.8858 22.4068 17.1271C22.4068 20.3685 19.7708 23.0046 16.5295 23.0046Z'
        fill='currentColor'
      />
      <path
        d='M5.6199 10.4544H10.687C10.9622 10.4544 11.1872 10.2292 11.1872 9.95416C11.1872 9.67896 10.9622 9.45392 10.687 9.45392H5.6199C5.3447 9.45392 5.11966 9.67896 5.11966 9.95416C5.11966 10.2292 5.3447 10.4544 5.6199 10.4544Z'
        fill='currentColor'
      />
      <path d='M18.5653 16.6569H17.0347V15.1013C17.0347 14.8262 16.8095 14.601 16.5345 14.601C16.2592 14.601 16.0342 14.8262 16.0342 15.1013V16.6569H14.4785C14.2033 16.6569 13.9783 16.8821 13.9783 17.1572C13.9783 17.4324 14.2033 17.6574 14.4785 17.6574H16.0342V19.188C16.0342 19.4632 16.2592 19.6882 16.5345 19.6882C16.8095 19.6882 17.0347 19.4632 17.0347 19.188V17.6574H18.5653C18.8405 17.6574 19.0655 17.4324 19.0655 17.1572C19.0655 16.8821 18.8405 16.6569 18.5653 16.6569Z' />
    </g>
    <defs>
      <clipPath id='clip0_7808_11070'>
        <rect width='24' height='24' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconAddFile };
