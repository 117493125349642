import { SVGProps } from 'react';

const IconTagOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.59 11.58L11.59 2.58C11.965 2.20808 12.4719 1.99958 13 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V11C22.0002 11.2639 21.9482 11.5253 21.8469 11.769C21.7457 12.0127 21.5972 12.2339 21.41 12.42L12.41 21.42C12.035 21.7919 11.5281 22.0004 11 22C10.4704 21.9978 9.96335 21.7856 9.59 21.41L2.59 14.41C2.21441 14.0367 2.00223 13.5296 2 13C1.99979 12.7361 2.05182 12.4747 2.15308 12.231C2.25434 11.9873 2.40283 11.7661 2.59 11.58ZM11 20L20 11V4H13L4 13L11 20ZM17.5 5C17.2033 5 16.9133 5.08797 16.6666 5.2528C16.42 5.41762 16.2277 5.65189 16.1142 5.92598C16.0006 6.20006 15.9709 6.50166 16.0288 6.79264C16.0867 7.08361 16.2296 7.35088 16.4393 7.56066C16.6491 7.77044 16.9164 7.9133 17.2074 7.97118C17.4983 8.02906 17.7999 7.99935 18.074 7.88582C18.3481 7.77229 18.5824 7.58003 18.7472 7.33336C18.912 7.08668 19 6.79667 19 6.5C19 6.10218 18.842 5.72064 18.5607 5.43934C18.2794 5.15804 17.8978 5 17.5 5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconTagOutline };
