import { SVGProps } from 'react';

const IconDraw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1029 4C14.1029 4 11.7149 4.01107 9.27663 4.63706C8.0575 4.95005 6.81129 5.4097 5.79997 6.1733C4.78865 6.93689 4 8.10892 4 9.57731C4 10.9611 4.60846 11.8597 5.04875 12.4033C5.10263 12.4698 5.15297 12.5305 5.19984 12.5869C5.53589 12.9917 5.69312 13.1811 5.69312 13.7764C5.69312 14.0641 5.43596 14.5301 5.01259 15.146L4.9389 15.2527C4.52512 15.8507 4.00986 16.5954 4.00986 17.6178C4.00986 18.7448 4.76339 19.5321 5.38738 19.7894C6.01136 20.0467 6.58243 19.9959 6.58243 19.9959L6.48708 18.2219C6.48708 18.2219 6.21652 18.2231 5.99887 18.1334C5.96982 18.1214 5.94307 18.1117 5.91854 18.1028C5.75927 18.0453 5.69312 18.0213 5.69312 17.6178C5.69312 17.2684 5.95541 16.7976 6.37366 16.1892C6.40914 16.1376 6.44582 16.085 6.48323 16.0314C6.88687 15.4531 7.37639 14.7518 7.37639 13.7764C7.37639 12.5438 6.7336 11.7501 6.32764 11.2489L6.26924 11.1771C5.8989 10.7221 5.68326 10.4572 5.68326 9.57731C5.68326 8.73672 6.0534 8.16765 6.77968 7.61927C7.50596 7.07089 8.57676 6.64605 9.67443 6.36424C11.8698 5.80062 14.116 5.77752 14.116 5.77752L14.1029 4ZM17.7769 7.55505C17.5908 7.55505 17.4042 7.63051 17.2623 7.78071L16.2136 8.88819L18.7385 11.5545L19.7872 10.447C20.0709 10.1466 20.0709 9.65985 19.7872 9.36034L18.2914 7.78071C18.1491 7.63051 17.963 7.55505 17.7769 7.55505ZM8.21811 17.3314L15.0235 10.1449L17.5484 12.8112L10.743 19.9977H8.21811V17.3314Z'
      fill='currentColor'
    />
  </svg>
);
export { IconDraw };
