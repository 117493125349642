import { useReactiveVar } from '@apollo/client';
import { IconMessageCircle } from 'components/icons';
import { Fragment } from 'react';
import { showingChatBoxVar } from '../../../../apollo/store';
import ChatBox from './chat-box';

const Chat = () => {
  const hasMessage = false;
  const showingChatBox = useReactiveVar(showingChatBoxVar);

  const closeChatBox = () => {
    showingChatBoxVar(!showingChatBox);
  };

  return (
    <Fragment>
      <div
        className={`fixed right-[60px] bottom-0 z-[50] flex h-[48px] origin-bottom-right rounded-t-[8px] shadow-15 transition duration-[200ms] ease-in-out ${
          hasMessage
            ? 'bg-primary transition duration-[200ms] ease-in-out hover:bg-primary-light'
            : 'bg-paper'
        } ${showingChatBox ? 'scale-0' : 'scale-100'}`}
        onClick={closeChatBox}
      >
        <button
          type='button'
          className='relative flex h-full w-full items-center space-x-[8px] px-[16px]'
        >
          <IconMessageCircle
            className={`min-h-[28px] min-w-[28px] ${hasMessage ? 'text-paper' : 'text-[#1e88e5]'}`}
          />
          <span className={`font-[600] ${hasMessage ? 'text-paper' : 'text-primary'}`}>
            Tin nhắn
          </span>
        </button>
        {hasMessage && (
          <span className='absolute top-[-12px] right-[-4px] flex min-h-[20px] min-w-[20px] items-center justify-center rounded-full border border-paper bg-badge p-[2px] text-[12px] leading-[15px] text-paper'>
            5
          </span>
        )}
      </div>
      <ChatBox showing={showingChatBox} onClose={closeChatBox} />
    </Fragment>
  );
};

export default Chat;
