import {
  NotarizationDocumentForm,
  NotarizationDocumentFormCreate,
  maritalStatusEnum,
  typeNotarizationEnum,
} from 'forms/index';
import { isEmpty, isEqual, isObject, isUndefined, map, omitBy, toNumber } from 'lodash';
import moment from 'moment-timezone';

export const convertDataInNotarizationForm = (formData?: NotarizationDocumentForm) => {
  const checkMaritalOwner =
    isEqual(formData?.maritalStatusOfOwner, 'SINGLE') ||
    (isEqual(formData?.maritalStatusOfOwner, 'MARRIED') &&
      isEqual(formData?.assetStatusOfOwner, ['true']));
  const checkMaritalCustomer =
    isEqual(formData?.maritalStatusOfCustomer, 'SINGLE') ||
    (isEqual(formData?.maritalStatusOfCustomer, 'MARRIED') &&
      isEqual(formData?.assetStatusOfCustomer, ['true']));
  const data: NotarizationDocumentFormCreate = {
    createdBy: formData?.userId,
    typeNotarization: typeNotarizationEnum.NOTARIZATION,
    provinceOffice: {
      id: formData?.provinceOfNotaryOffice?.value,
      name: formData?.provinceOfNotaryOffice?.label,
    },
    districtOffice: {
      id: formData?.districtOfNotaryOffice?.value,
      name: formData?.districtOfNotaryOffice?.label,
    },
    idNotaryOffice: formData?.notaryOffice?.value,
    isForSale: isEqual(formData?.demand, 'true'),
    maritalStatusSale: formData?.maritalStatusOfOwner as maritalStatusEnum,
    privatePropertySale: isEqual(formData?.maritalStatusOfOwner, 'MARRIED')
      ? isEqual(formData?.assetStatusOfOwner, ['true'])
      : true,
    infoPersonSale: checkMaritalOwner
      ? [
          {
            name: formData?.fullNameOfOwner,
            idCard: formData?.taxIdOfOwner,
            phone: formData?.phoneNumberOfOwner,
            registeredResidence: !isEmpty(formData?.permanentAddressRegistrationOfficeOfOwner)
              ? formData?.permanentAddressRegistrationOfficeOfOwner
              : undefined,
          },
        ]
      : [
          {
            name: formData?.fullNameOwner1,
            idCard: formData?.taxIdOwner1,
            phone: formData?.phoneNumberOwner1,
            registeredResidence: !isEmpty(formData?.permanentAddressRegistrationOfficeOwner1)
              ? formData?.permanentAddressRegistrationOfficeOwner1
              : undefined,
          },
          {
            name: formData?.fullNameOwner2,
            idCard: formData?.taxIdOwner2,
            phone: formData?.phoneNumberOwner2,
            registeredResidence: !isEmpty(formData?.permanentAddressRegistrationOfficeOwner2)
              ? formData?.permanentAddressRegistrationOfficeOwner2
              : undefined,
          },
        ],
    maritalStatusBuy: formData?.maritalStatusOfCustomer as maritalStatusEnum,
    privatePropertyBuy: isEqual(formData?.maritalStatusOfCustomer, 'MARRIED')
      ? isEqual(formData?.assetStatusOfCustomer, ['true'])
      : true,
    infoPersonBuy: checkMaritalCustomer
      ? [
          {
            name: formData?.fullNameOfCustomer,
            idCard: formData?.taxIdOfCustomer,
            phone: formData?.phoneNumberOfCustomer,
            registeredResidence: !isEmpty(formData?.permanentAddressRegistrationOfficeOfCustomer)
              ? formData?.permanentAddressRegistrationOfficeOfCustomer
              : undefined,
          },
        ]
      : [
          {
            name: formData?.fullNameCustomer1,
            idCard: formData?.taxIdCustomer1,
            phone: formData?.phoneNumberCustomer1,
            registeredResidence: !isEmpty(formData?.permanentAddressRegistrationOfficeCustomer1)
              ? formData?.permanentAddressRegistrationOfficeCustomer1
              : undefined,
          },
          {
            name: formData?.fullNameCustomer2,
            idCard: formData?.taxIdCustomer2,
            phone: formData?.phoneNumberCustomer2,
            registeredResidence: !isEmpty(formData?.permanentAddressRegistrationOfficeCustomer2)
              ? formData?.permanentAddressRegistrationOfficeCustomer2
              : undefined,
          },
        ],
    priceContract: !isEmpty(formData?.price) ? toNumber(formData?.price) : undefined,
    province: {
      id: formData?.province?.value,
      name: formData?.province?.label,
    },
    district: {
      id: formData?.district?.value,
      name: formData?.district?.label,
    },
    ward: !isEmpty(formData?.ward)
      ? {
          id: formData?.ward?.value,
          name: formData?.ward?.label,
        }
      : undefined,
    street: {
      id: formData?.street?.value,
      name: formData?.street?.label,
    },
    apartmentNumber: !isEmpty(formData?.houseNumber) ? formData?.houseNumber : undefined,
    purposeUsingLand: isEqual(formData?.demand, 'true')
      ? !isEmpty(formData?.purposeUseLand)
        ? formData?.purposeUseLand?.value
        : undefined
      : undefined,
    area: toNumber(formData?.area),
    construcArea: formData?.constructionArea ? toNumber(formData?.constructionArea) : undefined,
    sheet: formData?.sheet,
    plot: formData?.plot,
    certificateNumber: !isEmpty(formData?.certificateNumber)
      ? formData?.certificateNumber
      : undefined,
    issueNumber: !isEmpty(formData?.issueNumber) ? formData?.issueNumber : undefined,
    issuanceDate: !isEmpty(formData?.issueDate)
      ? moment(formData?.issueDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : undefined,
    notarizationDate: !isEmpty(formData?.certificateIssueDatetime)
      ? moment(formData?.certificateIssueDatetime).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    description: !isEmpty(formData?.description) ? formData?.description : undefined,
    fullNameReferrer: !isEmpty(formData?.fullNameReferrer) ? formData?.fullNameReferrer : undefined,
    phoneReferrer: !isEmpty(formData?.phoneNumberReferrer)
      ? formData?.phoneNumberReferrer
      : undefined,
    emailReferrer: !isEmpty(formData?.emailReferrer) ? formData?.emailReferrer : undefined,
    attachmentSale: {
      images: map(formData?.imageUrlsOfOwner, 'id'),
      attachments: map(formData?.documentUrlsOfOwner, 'id'),
    },
    attachmentBuy: {
      images: map(formData?.imageUrlsOfCustomer, 'id'),
      attachments: map(formData?.documentUrlsOfCustomer, 'id'),
    },
    attachmentCertificate: {
      images: map(formData?.imageUrlsOfCertificate, 'id'),
      attachments: map(formData?.documentUrlsOfCertificate, 'id'),
    },
  };
  const result = omitBy(data, (value) => {
    if (isObject(value)) {
      return isEmpty(omitBy(value, isUndefined));
    }
    return isUndefined(value);
  });
  return result;
};
