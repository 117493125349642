import { SVGProps } from 'react';

const IconLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='41'
    viewBox='0 0 40 41'
    fill='currentColor'
    {...props}
  >
    <rect x='9.95117' y='10.4998' width='20.0977' height='11.7517' fill='currentColor' />
    <path
      d='M25.3832 29.1167L24.7232 30.6394H15.2768L14.6168 29.1167H2.5V29.9903C2.5 32.2865 4.47169 34.1546 6.89523 34.1546H33.1048C35.5283 34.1546 37.5 32.2865 37.5 29.9903V29.1167H25.3832Z'
      fill='currentColor'
    />
    <path
      text-rule='evenodd'
      clipRule='evenodd'
      d='M4.01123 27.0659V5.55632H35.9887V27.0659H24.0369L23.377 28.5886H16.6231L15.963 27.0659H4.01123ZM7.90039 24.3019H32.0996V8.44868H7.90039V24.3019Z'
      fill='currentColor'
    />
  </svg>
);
export { IconLaptop };
