import { gql } from '@apollo/client';
import { DateTime, String } from './get-real-estates';
import { B2CUser } from './get-representative-brokers';
import {
  HistoryUpgradeRequestEnumType,
  Rank,
  StatusUpgradeRequestEnumType,
  TransactionTypeUsers,
} from './list-request-upgrade-by-user';
import { B2BUserType } from './user-info';

export enum StatusNextUpgradeEnumType {
  WAIT = 'WAIT',
  PROCESSING = 'PROCESSING',
  UPGRADE = 'UPGRADE',
  MAX = 'MAX',
}

export interface NextUpgrade {
  expired: DateTime;
  nextRank: Rank;
  statusUpgrade: StatusNextUpgradeEnumType;
}
export interface UpgradeRequestOutputType {
  id?: String;
  requestId?: String;
  owner?: B2CUser;
  paymentInfo?: TransactionTypeUsers;
  fts?: String;
  status?: StatusUpgradeRequestEnumType;
  approvedAt?: DateTime;
  approvedBy?: B2BUserType;
  rejectedAt?: DateTime;
  rejectedBy?: B2BUserType;
  updatedAt?: DateTime;
  createdAt?: DateTime;
  reason?: String;
  historyType?: HistoryUpgradeRequestEnumType;
  editor?: B2BUserType;
  rank?: Rank;
  expired?: Boolean;
  nextUpgrade?: NextUpgrade;
}

export interface GetDetailsRequestUpgradeByTokenData {
  getDetailsRequestUpgradeByToken?: UpgradeRequestOutputType;
}

export const GET_DETAIL_REQUEST_UPGRADE_BY_TOKEN = gql`
  query getDetailsRequestUpgradeByToken @api(name: users) {
    getDetailsRequestUpgradeByToken {
      id
      createdAt
      requestId
      nextUpgrade {
        expired
        nextRank
        statusUpgrade
      }
      paymentInfo {
        paymentMethod
      }
    }
  }
`;
