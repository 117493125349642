import { SVGProps } from 'react';

const IconClockFive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM14 17L11 11.8V7H12.5V11.4L15.3 16.3L14 17Z'
      fill='currentColor'
    />
  </svg>
);
export { IconClockFive };
