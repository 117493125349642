import { SVGProps } from 'react';

const IconSmile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_807_5697)'>
      <path
        d='M14.6569 3.34313C13.1458 1.83216 11.1369 1 9 1C6.86312 1 4.85416 1.83216 3.34313 3.34313C1.83216 4.85416 1 6.86312 1 9C1 11.1369 1.83216 13.1458 3.34313 14.6569C4.85416 16.1678 6.86312 17 9 17C11.1369 17 13.1458 16.1678 14.6569 14.6569C16.1678 13.1458 17 11.1369 17 9C17 6.86312 16.1678 4.85416 14.6569 3.34313ZM13.9939 13.9939C12.66 15.3279 10.8865 16.0625 9 16.0625C7.11353 16.0625 5.34 15.3279 4.00606 13.9939C2.67212 12.66 1.9375 10.8865 1.9375 9C1.9375 7.11353 2.67212 5.34 4.00606 4.00606C5.34 2.67212 7.11353 1.9375 9 1.9375C10.8865 1.9375 12.66 2.67212 13.9939 4.00606C15.3279 5.34 16.0625 7.11353 16.0625 9C16.0625 10.8865 15.3279 12.66 13.9939 13.9939Z'
        fill='currentColor'
      />
      <path
        d='M4.14695 9.56281C4.14695 12.2388 6.32401 14.4158 8.99995 14.4158C11.6759 14.4158 13.8529 12.2388 13.8529 9.56281V8.53125H4.14695V9.56281ZM5.08445 9.46875H12.9154V9.56281C12.9154 11.7218 11.1589 13.4783 8.99995 13.4783C6.84095 13.4783 5.08445 11.7218 5.08445 9.56281V9.46875Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_807_5697'>
        <rect width='16' height='16' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconSmile };
