import { gql } from '@apollo/client';
import { ObjectID, String } from '../queries';
import { ForeclosurePayload } from './send-foreclosure-request';

export interface UpdateForeclosureRequestOnATMNhaData {
  updateForeclosureRequestOnATMNha?: ForeclosurePayload;
}
export interface UpdateForeclosureRequestOnATMNhaVariables {
  foreclosureId: String;
  fullName: String;
  phone: String;
  email?: String;
  note?: String;
  realEstateId?: ObjectID;
}

export const UPDATE_FORECLOSURE_REQUEST_ON_ATMNHA = gql`
  mutation updateForeclosureRequestOnATMNha(
    $foreclosureId: String!
    $fullName: String!
    $phone: String!
    $email: String
    $note: String
    $realEstateId: ObjectID
  ) @api(name: realEstate) {
    updateForeclosureRequestOnATMNha(
      foreclosureId: $foreclosureId
      fullName: $fullName
      phone: $phone
      email: $email
      note: $note
      realEstateId: $realEstateId
    ) {
      msg
      success
    }
  }
`;
