import { gql } from '@apollo/client';
import { Boolean, Int, ObjectID, String, UserTypeEnum, GenderEnumType } from './get-real-estates';
import { B2CUser } from './get-representative-brokers';

export enum RequestStatusEnumType {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  normal = 'normal',
}
export enum CompanyFieldsEnumType {
  other = 'other',
  project = 'project',
  townhouse = 'townhouse',
}
export interface FromToDate {
  from?: String;
  to?: String;
}
export interface ListB2CUser {
  hasNextPage?: Boolean;
  hasPrevPage?: Boolean;
  items?: B2CUser[];
  limit?: Int;
  nextPage?: Int;
  page?: Int;
  pagingCounter?: Int;
  prevPage?: Int;
  total?: Int;
  totalPages?: Int;
}

export interface GetB2CUsersData {
  getB2CUsers?: ListB2CUser;
}
export interface GetB2CUsersVariables {
  memberId?: String;
  statusProfile?: RequestStatusEnumType[];
  fullName?: String;
  type?: UserTypeEnum[];
  phone?: String;
  idNumberOrTaxId?: String;
  province?: ObjectID[];
  companyFields?: CompanyFieldsEnumType[];
  developer?: String;
  timeRequest?: FromToDate;
  gender?: GenderEnumType[];
  company?: String;
  email?: String;
  page?: Int;
  limit?: Int;
  sort?: String;
}

export const GET_B2C_USERS = gql`
  query getB2CUsers(
    $memberId: String
    $statusProfile: [RequestStatusEnumType]
    $fullName: String
    $type: [UserTypeEnum]
    $phone: String
    $idNumberOrTaxId: String
    $province: [ObjectID]
    $companyFields: [CompanyFieldsEnumType]
    $developer: String
    $timeRequest: FromToDate
    $gender: [GenderEnumType]
    $company: String
    $email: String
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: users) {
    getB2CUsers(
      memberId: $memberId
      statusProfile: $statusProfile
      fullName: $fullName
      type: $type
      phone: $phone
      idNumberOrTaxId: $idNumberOrTaxId
      province: $province
      companyFields: $companyFields
      developer: $developer
      timeRequest: $timeRequest
      gender: $gender
      company: $company
      email: $email
      page: $page
      limit: $limit
      sort: $sort
    ) {
      items {
        id
      }
    }
  }
`;
