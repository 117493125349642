import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { isEqual } from 'lodash';
import { blogClient, chatClient, mainClient, mapClient, uploadClient } from '../apollo/client';

export const useFlexQuery = <TData = any, TVariables = OperationVariables>(
  service: 'main' | 'upload' | 'map' | 'chat' | 'blog',
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> =>
  useQuery(query, {
    ...options,
    client: isEqual(service, 'upload')
      ? uploadClient
      : isEqual(service, 'map')
      ? mapClient
      : isEqual(service, 'chat')
      ? chatClient
      : isEqual(service, 'blog')
      ? blogClient
      : mainClient,
  });
