import { gql } from '@apollo/client';
import { ObjectID } from 'graphql/main/queries';
import { ProductReservation } from '../mutations';

export interface GetDetailReservationATMNhaData {
  getDetailReservationATMNha?: ProductReservation;
}
export interface GetDetailReservationATMNhaVariables {
  id: ObjectID;
}

export const GET_DETAIL_RESERVATION_ATMNHA = gql`
  query getDetailReservationATMNha($id: ObjectID!) @api(name: biha) {
    getDetailReservationATMNha(id: $id) {
      customerName
      customerPhone
      paymentType
      refundedAmount
      refundHistories {
        currentAmount
        issuedDate
        note
      }
    }
  }
`;
