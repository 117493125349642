import { SVGProps } from 'react';

const IconBuildCircleOutlineRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M6.09614 7.19375L4.28845 5.43125C4.18588 5.63125 4.10588 5.8375 4.04845 6.05C3.9905 6.2625 3.96152 6.475 3.96152 6.6875C3.96152 7.4 4.22127 8.0095 4.74076 8.516C5.25973 9.022 5.8846 9.275 6.61537 9.275C6.79486 9.275 6.95511 9.2625 7.09614 9.2375C7.23717 9.2125 7.37819 9.175 7.51922 9.125L9.8846 11.4125C10.0384 11.5625 10.2179 11.6375 10.4231 11.6375C10.6282 11.6375 10.8077 11.5625 10.9615 11.4125L11.5 10.8875C11.6538 10.7375 11.7308 10.5625 11.7308 10.3625C11.7308 10.1625 11.6538 9.9875 11.5 9.8375L9.15383 7.53125C9.20511 7.39375 9.24358 7.26575 9.26922 7.14725C9.29486 7.02825 9.30768 6.8625 9.30768 6.65C9.30768 5.9375 9.04819 5.328 8.52922 4.8215C8.00973 4.3155 7.3846 4.0625 6.65383 4.0625C6.43588 4.0625 6.21793 4.0905 5.99999 4.1465C5.78204 4.203 5.5705 4.28125 5.36537 4.38125L7.17306 6.14375L6.09614 7.19375ZM7.99999 15.5C6.93588 15.5 5.93588 15.303 4.99999 14.909C4.06409 14.5155 3.24999 13.9813 2.55768 13.3063C1.86537 12.6313 1.31742 11.8375 0.913832 10.925C0.50973 10.0125 0.307678 9.0375 0.307678 8C0.307678 6.9625 0.50973 5.9875 0.913832 5.075C1.31742 4.1625 1.86537 3.36875 2.55768 2.69375C3.24999 2.01875 4.06409 1.48425 4.99999 1.09025C5.93588 0.69675 6.93588 0.5 7.99999 0.5C9.06409 0.5 10.0641 0.69675 11 1.09025C11.9359 1.48425 12.75 2.01875 13.4423 2.69375C14.1346 3.36875 14.6826 4.1625 15.0861 5.075C15.4902 5.9875 15.6923 6.9625 15.6923 8C15.6923 9.0375 15.4902 10.0125 15.0861 10.925C14.6826 11.8375 14.1346 12.6313 13.4423 13.3063C12.75 13.9813 11.9359 14.5155 11 14.909C10.0641 15.303 9.06409 15.5 7.99999 15.5ZM7.99999 14C9.70511 14 11.1572 13.4158 12.3561 12.2472C13.5546 11.0782 14.1538 9.6625 14.1538 8C14.1538 6.3375 13.5546 4.92175 12.3561 3.75275C11.1572 2.58425 9.70511 2 7.99999 2C6.29486 2 4.84306 2.58425 3.6446 3.75275C2.44563 4.92175 1.84614 6.3375 1.84614 8C1.84614 9.6625 2.44563 11.0782 3.6446 12.2472C4.84306 13.4158 6.29486 14 7.99999 14Z'
      fill='currentColor'
    />
  </svg>
);
export { IconBuildCircleOutlineRounded };
