import { gql } from '@apollo/client';
import { ObjectID } from '../queries';
import { MutationOfConversation } from './create-conversation';

export interface PinConversationData {
  pinConversation?: MutationOfConversation;
}
export interface PinConversationVariables {
  conversationId: ObjectID;
}

export const PIN_CONVERSATION = gql`
  mutation pinConversation($conversationId: ObjectID!) @api(name: chat) {
    pinConversation(conversationId: $conversationId) {
      success
      msg
    }
  }
`;
