import { useReactiveVar } from '@apollo/client';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { IconPhone, IconVideo } from 'components/icons';
import { find, gt, isEqual, toString } from 'lodash';
import Image from 'next/image';
import { getGroupNameFromParticipants } from 'utils';
import { selectedConversationVar } from '../../../../../../../apollo/store';
import ConversationAction from '../../section-right/conversation-list/conversation/conversation-action';

const MessageToolbar = () => {
  const { currentUser } = useAuthenticationContext();
  const selectedConversation = useReactiveVar(selectedConversationVar);

  return (
    <div
      id='message-toolbar'
      className='flex h-[56px] items-center justify-between space-x-[16px] border-b border-stroke bg-paper p-[12px]'
    >
      {selectedConversation?.isGroup ? (
        <button type='button' className='flex h-full items-center space-x-[12px]'>
          <div className='relative h-[32px] w-[32px]'>
            <div className='absolute top-0 right-0 min-h-[24px] min-w-[24px] overflow-hidden rounded-full'>
              <Image
                src={
                  selectedConversation.participants?.[0].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                alt={
                  selectedConversation.participants?.[0].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                layout='fill'
                objectFit='cover'
                placeholder='blur'
                blurDataURL='/images/image-loader.svg'
              />
            </div>
            <div className='absolute bottom-0 box-content min-h-[24px] min-w-[24px] overflow-hidden rounded-full border-[2px] border-paper'>
              <Image
                src={
                  selectedConversation.participants?.[1].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                alt={
                  selectedConversation.participants?.[1].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                layout='fill'
                objectFit='cover'
                placeholder='blur'
                blurDataURL='/images/image-loader.svg'
              />
            </div>
          </div>
          <div className='flex h-full max-w-[240px] flex-col justify-center space-y-[4px]'>
            <span className='truncate text-[13px] font-[600] leading-[16px]'>
              {selectedConversation.name}
            </span>
          </div>
        </button>
      ) : (
        <button type='button' className='flex h-full items-center space-x-[12px]'>
          <div className='relative h-[32px] w-[32px] overflow-hidden rounded-full'>
            <Image
              src={
                find(
                  selectedConversation?.participants,
                  (participant) => !isEqual(participant.userId, currentUser?.id),
                )?.avatar?.originalUrl ?? '/images/default-avatar.svg'
              }
              alt={
                find(
                  selectedConversation?.participants,
                  (participant) => !isEqual(participant.userId, currentUser?.id),
                )?.avatar?.originalUrl ?? '/images/default-avatar.svg'
              }
              layout='fill'
              objectFit='cover'
              placeholder='blur'
              blurDataURL='/images/image-loader.svg'
            />
          </div>
          <div className='flex h-full max-w-[240px] flex-col justify-center space-y-[4px]'>
            <span className='truncate text-[13px] font-[600] leading-[16px]'>
              {getGroupNameFromParticipants(
                toString(currentUser?.id),
                selectedConversation?.participants,
              )}
            </span>
          </div>
        </button>
      )}
      <div className='flex items-center space-x-[12px]'>
        <button
          type='button'
          className='flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#3b82f6]'
        >
          <IconPhone className='min-h-[12px] min-w-[12px] text-paper' />
        </button>
        <button
          type='button'
          className='flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#3b82f6]'
        >
          <IconVideo className='min-h-[12px] min-w-[12px] text-paper' />
        </button>
        <ConversationAction isGroup={gt(selectedConversation?.participants?.length, 2)} />
      </div>
    </div>
  );
};

export default MessageToolbar;
