import { gql } from '@apollo/client';
import {
  GroupedStagesType,
  RefundHistory,
  RefundStatus,
  ReservationDepartment,
  StageOutput,
  TradingStatusEnumType,
} from '../mutations';
import { Float, RealEstateProjectRealEstate, String } from './get-real-estates';
import { CompanyUsers } from './list-companies-by-type';
import { AreaOutput, Block, Floor, ProductInProjectBiha, UserBiha } from './list-projects-atmnha';

export enum BookingStatusTypeEnum {
  pending = 'pending',
  success = 'success',
  rejected = 'rejected',
  locked = 'locked',
  approved = 'approved',
}

export interface ProjectBooking {
  amount?: Float;
  approvalReason?: String;
  approvedAt?: String;
  approvedBy?: UserBiha;
  attachments?: String[];
  block?: Block;
  company?: CompanyUsers;
  companyId?: String;
  createdAt?: String;
  creator?: UserBiha;
  customerName?: String;
  customerPhone?: String;
  deletedAt?: String;
  deletedBy?: UserBiha;
  departments?: ReservationDepartment[];
  displayReservationStatus?: String;
  editable?: boolean;
  editor?: UserBiha;
  fInfo?: CompanyUsers[];
  floor?: Floor;
  groupedStages?: GroupedStagesType[];
  id?: String;
  idNumber?: String;
  investorId?: String;
  isInvestor?: boolean;
  isShared?: boolean;
  newBooking?: String;
  newBookingCode?: String;
  newProduct?: ProductInProjectBiha;
  note?: String;
  oldBooking?: String;
  oldBookingCode?: String;
  oldProduct?: ProductInProjectBiha;
  order?: Float;
  paymentType?: String;
  presenterName?: String;
  presenterPhone?: String;
  price?: Float;
  product?: ProductInProjectBiha;
  productArea?: AreaOutput;
  productId?: String;
  project?: RealEstateProjectRealEstate;
  projectId?: String;
  refundHistories?: RefundHistory[];
  refundNote?: String;
  refundStatus?: RefundStatus;
  refundedAmount?: Float;
  remainRefundAmount?: Float;
  reservationCode?: String;
  reservationStatus?: String;
  reservationTime?: String;
  roomNumber?: String;
  sellerName?: String;
  sellerPhone?: String;
  stages?: StageOutput[];
  totalStagePrice?: Float;
  tradingStatus?: TradingStatusEnumType;
  transferedProduct?: String;
  updatedAt?: String;
}
export interface GetReservationDetailData {
  getReservationDetails?: ProjectBooking;
}
export interface GetReservationDetailVariables {
  id?: String;
}
export const GET_RESERVATION_DETAIL = gql`
  query getReservationDetails($id: ObjectID!) @api(name: biha) {
    getReservationDetails(id: $id) {
      reservationCode
      project {
        id
        projectName
        fullAddress
        projectArea {
          area
        }
      }
      customerName
      customerPhone
      roomNumber
      price
      approvalReason
      productArea {
        area
      }
      floor {
        name
        block {
          name
        }
      }
      reservationStatus
      reservationTime
      sellerName
      sellerPhone
      stages {
        attachments {
          ... on YoutubeFile {
            fileId
            youtubeMetadata {
              description
              thumbnailUrl
              title
              videoId
            }
            id
            type
          }
          ... on FileWithFullUrls {
            urls {
              url
            }
            updatedAt
            type
            originalUrl
            id
            fileId
          }
        }
        id
        paymentAmount
        period
        stageName
      }
      productId
      projectId
      paymentType
      totalStagePrice
      createdAt
      product {
        roomNumber
        area
        block {
          name
        }
        floor {
          name
        }
        price
      }
      tradingStatus
      transferedProduct
    }
  }
`;
