import { gql } from '@apollo/client';
import { ObjectID, String } from '../queries';
import { MutationOfRegisterInProject } from './create-register-get-price-list';

export interface CreateRegisterAdvise {
  publishedProject: ObjectID;
  fullName: String;
  phone: String;
  email?: String;
  note?: String;
}

export interface CreateRegisterAdviseData {
  createRegisterAdvise: MutationOfRegisterInProject;
}
export interface CreateRegisterAdviseVariables {
  input?: CreateRegisterAdvise;
}

export const CREATE_REGISTER_ADVISE = gql`
  mutation createRegisterAdvise($input: CreateRegisterAdvise) @api(name: biha) {
    createRegisterAdvise(input: $input) {
      success
      msg
    }
  }
`;
