import { SVGProps } from 'react';

const IconImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1558_131351)'>
      <path
        d='M1.33334 3.99967C1.33334 3.29243 1.6143 2.61415 2.11439 2.11406C2.61449 1.61396 3.29277 1.33301 4.00001 1.33301H12C12.7073 1.33301 13.3855 1.61396 13.8856 2.11406C14.3857 2.61415 14.6667 3.29243 14.6667 3.99967V11.9997C14.6667 12.7069 14.3857 13.3852 13.8856 13.8853C13.3855 14.3854 12.7073 14.6663 12 14.6663H4.00001C3.29277 14.6663 2.61449 14.3854 2.11439 13.8853C1.6143 13.3852 1.33334 12.7069 1.33334 11.9997V3.99967Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.66667 7.33333C6.58714 7.33333 7.33333 6.58714 7.33333 5.66667C7.33333 4.74619 6.58714 4 5.66667 4C4.74619 4 4 4.74619 4 5.66667C4 6.58714 4.74619 7.33333 5.66667 7.33333Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.684 8.41387L4 14.6665H12.0887C12.7724 14.6665 13.4281 14.3949 13.9116 13.9115C14.3951 13.428 14.6667 12.7723 14.6667 12.0885V11.9999C14.6667 11.6892 14.55 11.5699 14.34 11.3399L11.6533 8.40987C11.5281 8.27328 11.3758 8.16429 11.2061 8.08985C11.0364 8.01542 10.8531 7.97717 10.6678 7.97754C10.4825 7.97792 10.2993 8.01691 10.1299 8.09204C9.96053 8.16717 9.80866 8.27677 9.684 8.41387V8.41387Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1558_131351'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconImage };
