import { gql } from '@apollo/client';
import { Foreclosure } from './get-list-foreclosure-request-on-ATMNha';
import { String, FileWithFullUrls } from './get-real-estates';

export interface GetForeclosureDetailData {
  getForeclosureDetail?: Foreclosure;
}
export interface GetForeclosureDetailVariables {
  foreclosureId?: String;
}

export const GET_FORECLOSURE_DETAIL = gql`
  query getForeclosureDetail($foreclosureId: String) @api(name: realEstate) {
    getForeclosureDetail(foreclosureId: $foreclosureId) {
      id
      fullName
      requestCode
      phone
      email
      note
      status
      rejectedReason
      realEstate {
        id
        isForSell
        type
        mediaUrls {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
        area
        width
        length
        floorCount
        fullAddress
        postTitle
        postType
        isAuthorizedRe
        isForeclosure
        realEstateType
        hasAlley
        province {
          id
          name
        }
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        houseNumber
        price
        pageNo
        parcel
        fullSlug
      }
    }
  }
`;
