import { gql } from '@apollo/client';
import { Boolean, Int, ObjectID, String } from './get-real-estates';
import { KindRealEstateEnumType } from './near-real-estates';

export interface StatisticRealEstateByProvince {
  slug?: String;
  total?: Int;
  provinceId?: String;
  provinceName?: String;
}

export interface NearRealEstatesByProvinceData {
  nearRealEstatesByProvince?: StatisticRealEstateByProvince[];
}
export interface NearRealEstatesByProvinceVariables {
  isForSell?: Boolean;
  realEstateType?: String[];
  kindOfRealEstate?: KindRealEstateEnumType;
  province?: ObjectID;
}

export const NEAR_REAL_ESTATES_BY_PROVINCE = gql`
  query nearRealEstatesByProvince(
    $isForSell: Boolean
    $realEstateType: [String]
    $kindOfRealEstate: KindRealEstateEnumType
    $province: ObjectID
  ) @api(name: realEstate) {
    nearRealEstatesByProvince(
      isForSell: $isForSell
      realEstateType: $realEstateType
      kindOfRealEstate: $kindOfRealEstate
      province: $province
    ) {
      slug
      total
      provinceId
      provinceName
    }
  }
`;
