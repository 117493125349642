import { ApolloClient, ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client';
import { AtmnhaAuthenticationModel } from 'components/providers/authentication-provider';
import { getCookie } from 'cookies-next';
import { isEqual, toString } from 'lodash';
import {
  baseLink,
  blogLink,
  chatLink,
  createAuthenticationLink,
  errorLink,
  mainLink,
  mapLink,
  uploadLink,
} from './link';

const isBrowser = !isEqual(typeof window, 'undefined');

export const baseClientOption = {
  ssrMode: isBrowser,
  connectToDevTools: isBrowser,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  } as DefaultOptions,
};

export const mainClient = new ApolloClient({
  ...baseClientOption,
  link: ApolloLink.from([errorLink, baseLink, mainLink]),
});
export const uploadClient = new ApolloClient({
  ...baseClientOption,
  link: ApolloLink.from([errorLink, baseLink, uploadLink]),
});
export const mapClient = new ApolloClient({
  ...baseClientOption,
  link: ApolloLink.from([errorLink, baseLink, mapLink]),
});
export const chatClient = new ApolloClient({
  ...baseClientOption,
  link: chatLink
    ? ApolloLink.from([errorLink, baseLink, chatLink])
    : ApolloLink.from([errorLink, baseLink]),
});
export const blogClient = new ApolloClient({
  ...baseClientOption,
  link: ApolloLink.from([errorLink, baseLink, blogLink]),
});

export const setupApollo = () => {
  const atmAuthenticationString = toString(getCookie('atmnha_authentication_cookie'));
  if (atmAuthenticationString) {
    const atmnhaAuthentication = JSON.parse(atmAuthenticationString) as AtmnhaAuthenticationModel;
    const { accessToken } = atmnhaAuthentication;
    const authLink = createAuthenticationLink(accessToken);
    mainClient.setLink(ApolloLink.from([errorLink, authLink, mainLink]));
    uploadClient.setLink(ApolloLink.from([errorLink, authLink, uploadLink]));
    mapClient.setLink(ApolloLink.from([errorLink, authLink, mapLink]));
    blogClient.setLink(ApolloLink.from([errorLink, authLink, blogLink]));
  }
};
