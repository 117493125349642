import { SVGProps } from 'react';

const IconCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M14.8307 6.79822H7.28338C6.5752 6.79822 6 7.36508 6 8.06301V18.7351C6 19.4331 6.5752 19.9999 7.28338 19.9999H14.8307C15.5389 19.9999 16.1141 19.4331 16.1141 18.7351V8.06301C16.1108 7.36508 15.5356 6.79822 14.8307 6.79822ZM15.2131 18.7319C15.2131 18.9416 15.0402 19.112 14.8274 19.112H7.28006C7.06727 19.112 6.89438 18.9416 6.89438 18.7319V8.06301C6.89438 7.85331 7.06727 7.68292 7.28006 7.68292H14.8274C15.0402 7.68292 15.2131 7.85331 15.2131 8.06301V18.7319Z'
      fill='currentColor'
    />
    <path
      d='M17.7165 4H10.1691C9.46094 4 8.88574 4.56686 8.88574 5.2648C8.88574 5.51055 9.08523 5.70715 9.33459 5.70715C9.58396 5.70715 9.78345 5.51055 9.78345 5.2648C9.78345 5.05509 9.95634 4.8847 10.1691 4.8847H17.7165C17.9293 4.8847 18.1022 5.05509 18.1022 5.2648V15.9369C18.1022 16.1466 17.9293 16.317 17.7165 16.317C17.4671 16.317 17.2676 16.5136 17.2676 16.7594C17.2676 17.0051 17.4671 17.2017 17.7165 17.2017C18.4247 17.2017 18.9999 16.6349 18.9999 15.9369V5.2648C18.9999 4.56686 18.4247 4 17.7165 4Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCopy };
