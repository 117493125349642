import { SVGProps } from 'react';

const IconCash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.5 5H17.5V15H2.5V5ZM10 7.5C10.663 7.5 11.2989 7.76339 11.7678 8.23223C12.2366 8.70107 12.5 9.33696 12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5C9.33696 12.5 8.70107 12.2366 8.23223 11.7678C7.76339 11.2989 7.5 10.663 7.5 10C7.5 9.33696 7.76339 8.70107 8.23223 8.23223C8.70107 7.76339 9.33696 7.5 10 7.5ZM5.83333 6.66667C5.83333 7.10869 5.65774 7.53262 5.34518 7.84518C5.03262 8.15774 4.60869 8.33333 4.16667 8.33333V11.6667C4.60869 11.6667 5.03262 11.8423 5.34518 12.1548C5.65774 12.4674 5.83333 12.8913 5.83333 13.3333H14.1667C14.1667 12.8913 14.3423 12.4674 14.6548 12.1548C14.9674 11.8423 15.3913 11.6667 15.8333 11.6667V8.33333C15.3913 8.33333 14.9674 8.15774 14.6548 7.84518C14.3423 7.53262 14.1667 7.10869 14.1667 6.66667H5.83333Z'
      fill='currentColor'
    />
  </svg>
);

export { IconCash };
