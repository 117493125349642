import { SVGProps } from 'react';

const IconSuitcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M19.5 6.5H16.5V5.5C16.5 4.96957 16.2893 4.46086 15.9142 4.08579C15.5391 3.71071 15.0304 3.5 14.5 3.5H10.5C9.96957 3.5 9.46086 3.71071 9.08579 4.08579C8.71071 4.46086 8.5 4.96957 8.5 5.5V6.5H5.5C4.70435 6.5 3.94129 6.81607 3.37868 7.37868C2.81607 7.94129 2.5 8.70435 2.5 9.5V18.5C2.5 19.2956 2.81607 20.0587 3.37868 20.6213C3.94129 21.1839 4.70435 21.5 5.5 21.5H19.5C20.2956 21.5 21.0587 21.1839 21.6213 20.6213C22.1839 20.0587 22.5 19.2956 22.5 18.5V9.5C22.5 8.70435 22.1839 7.94129 21.6213 7.37868C21.0587 6.81607 20.2956 6.5 19.5 6.5ZM10.5 5.5H14.5V6.5H10.5V5.5ZM20.5 18.5C20.5 18.7652 20.3946 19.0196 20.2071 19.2071C20.0196 19.3946 19.7652 19.5 19.5 19.5H5.5C5.23478 19.5 4.98043 19.3946 4.79289 19.2071C4.60536 19.0196 4.5 18.7652 4.5 18.5V13.5H8.5V14.5C8.5 14.7652 8.60536 15.0196 8.79289 15.2071C8.98043 15.3946 9.23478 15.5 9.5 15.5C9.76522 15.5 10.0196 15.3946 10.2071 15.2071C10.3946 15.0196 10.5 14.7652 10.5 14.5V13.5H14.5V14.5C14.5 14.7652 14.6054 15.0196 14.7929 15.2071C14.9804 15.3946 15.2348 15.5 15.5 15.5C15.7652 15.5 16.0196 15.3946 16.2071 15.2071C16.3946 15.0196 16.5 14.7652 16.5 14.5V13.5H20.5V18.5ZM20.5 11.5H4.5V9.5C4.5 9.23478 4.60536 8.98043 4.79289 8.79289C4.98043 8.60536 5.23478 8.5 5.5 8.5H19.5C19.7652 8.5 20.0196 8.60536 20.2071 8.79289C20.3946 8.98043 20.5 9.23478 20.5 9.5V11.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconSuitcase };
