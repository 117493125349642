import { gql } from '@apollo/client';
import { UpgradeRequestOutputType } from './get-detail-request-by-token';
import { DateTime, Int, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export enum Rank {
  basic = 'basic',
  gold = 'gold',
  diamond = 'diamond',
}
export enum StatusUpgradeRequestEnumType {
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum StatusTransactionEnum {
  PAID = 'PAID',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export interface PaymentErrorType {
  code?: String;
  errorMessage?: String;
}
export enum PaymentTypeEnum {
  NOW = 'NOW',
  LATER = 'LATER',
}
export enum PaymentMethodEnum {
  VNPAY = 'VNPAY',
  TRANSFER = 'TRANSFER',
  CASH = 'CASH',
}
export interface PaymentPayloadType {
  transactionId?: String;
  paymentUrl?: String;
  redirectUrl?: String;
}
export interface TransactionTypeUsers {
  orderId?: String;
  amount?: Int;
  body?: String;
  bankCode?: String;
  cardType?: String;
  bankAccountNumber?: String;
  status?: StatusTransactionEnum;
  paymentError?: PaymentErrorType;
  paymentType?: PaymentTypeEnum;
  paymentMethod?: PaymentMethodEnum;
  paymentPayload?: PaymentPayloadType;
  bankOwner?: String;
  ipAddress?: String;
  invoiceDate?: DateTime;
  transactionId?: String;
}
export enum HistoryUpgradeRequestEnumType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface listUpgradeRequestPaginationType extends PaginationOf {
  items?: UpgradeRequestOutputType[];
}

export interface ListRequestUpgradeByUserData {
  listRequestUpgradeByUser?: listUpgradeRequestPaginationType;
}
export interface ListRequestUpgradeByUserVariables {
  requestId?: String;
  rank?: Rank[];
  statusRequest?: StatusUpgradeRequestEnumType[];
  statusTransaction?: StatusTransactionEnum[];
  amount?: Rank[];
  page?: Int;
  limit?: Int;
  sort?: String;
}

export const LIST_REQUEST_UPGRADE_BY_USER = gql`
  query listRequestUpgradeByUser(
    $requestId: String
    $rank: [Rank]
    $statusRequest: [StatusUpgradeRequestEnumType]
    $statusTransaction: [StatusTransactionEnum]
    $amount: [Rank]
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: users) {
    listRequestUpgradeByUser(
      requestId: $requestId
      rank: $rank
      statusRequest: $statusRequest
      statusTransaction: $statusTransaction
      amount: $amount
      page: $page
      limit: $limit
      sort: $sort
    ) {
      hasNextPage
      limit
      page
      prevPage
      total
      hasPrevPage
      nextPage
      pagingCounter
      totalPages
      items {
        id
        requestId
        rank
        paymentInfo {
          amount
          status
        }
        status
      }
    }
  }
`;
