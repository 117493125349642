import { gql } from '@apollo/client';
import { Int, String } from './get-real-estates';
import { ListSavedItem } from './get-user-saved-real-estate';

export interface GetSavedProductInProjectData {
  getSavedProductInProject?: ListSavedItem;
}
export interface GetSavedProductInProjectVariables {
  isForSell?: Boolean;
  collection?: String[];
  searchString?: String;
  page?: Int;
  limit?: Int;
  sort?: String;
}

export const GET_SAVED_PRODUCT_IN_PROJECT = gql`
  query getSavedProductInProject(
    $isForSell: Boolean
    $collection: [String]
    $searchString: String
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: realEstate) {
    getSavedProductInProject(
      isForSell: $isForSell
      collection: $collection
      searchString: $searchString
      page: $page
      limit: $limit
      sort: $sort
    ) {
      hasPrevPage
      hasNextPage
      items {
        belongsToCollection {
          id
          name
          owner
          totalItems
        }
        createdAt
        createdItemDate
        id
        note
        type
        updatedAt
        item {
          ... on ProductInProject {
            id
            projectId
            projectName
            fullAddress
            isForSell
            blockName
            floorName
            roomNumber
            mediaUrls {
              ... on YoutubeFile {
                fileId
                type
              }
              ... on FileWithFullUrls {
                type
                fileId
                originalUrl
              }
            }
            vr360Urls
            savedItem {
              id
              belongsToCollection {
                id
                name
              }
            }
            createdAt
            updatedAt
          }
        }
      }
      limit
      nextPage
      prevPage
      total
      totalPages
      page
      pagingCounter
    }
  }
`;
