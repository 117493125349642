import { gql } from '@apollo/client';
import { ObjectID, String } from '../../main/queries/get-real-estates';

export enum TypeOfDemand {
  ForSale = 'ForSale',
  ForRent = 'ForRent',
  ToBuy = 'ToBuy',
  ToRent = 'ToRent',
}
export enum TypeOfDemandEnumType {
  ForSale = 'ForSale',
  ForRent = 'ForRent',
  ToBuy = 'ToBuy',
  ToRent = 'ToRent',
}
export interface Suggestion {
  district?: ObjectID;
  fullText?: String;
  province?: ObjectID;
  realEstateType?: String;
  street?: ObjectID;
  typeOfDemand?: TypeOfDemand;
  ward?: ObjectID;
}

export interface GetSuggestionsData {
  getSuggestions?: Suggestion[];
}
export interface GetSuggestionsVariables {
  search: String;
  typeOfDemand: TypeOfDemand;
}

export const GET_SUGGESTIONS = gql`
  query getSuggestions($search: String!, $typeOfDemand: TypeOfDemand!) {
    getSuggestions(search: $search, typeOfDemand: $typeOfDemand) {
      realEstateType
      fullText
      typeOfDemand
    }
  }
`;
