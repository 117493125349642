import { SVGProps } from 'react';

const IconSatellite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M14.3212 10.3125L16.0669 8.56687C16.184 8.44967 16.2499 8.29073 16.2499 8.125C16.2499 7.95927 16.184 7.80033 16.0669 7.68313L14.6337 6.25L15.9375 4.94625L17.8663 6.875L18.75 5.99125L14.0087 1.25L13.125 2.13375L15.0538 4.0625L13.75 5.36625L12.3169 3.93313C12.1997 3.81596 12.0407 3.75013 11.875 3.75013C11.7093 3.75013 11.5503 3.81596 11.4331 3.93313L9.6875 5.67875L5.44187 1.43312C5.32467 1.31596 5.16573 1.25013 5 1.25013C4.83427 1.25013 4.67533 1.31596 4.55813 1.43312L1.43312 4.55813C1.31596 4.67533 1.25013 4.83427 1.25013 5C1.25013 5.16573 1.31596 5.32467 1.43312 5.44187L5.67875 9.6875L3.93313 11.4331C3.81596 11.5503 3.75013 11.7093 3.75013 11.875C3.75013 12.0407 3.81596 12.1997 3.93313 12.3169L5.36625 13.75L4.0625 15.0538L2.13375 13.125L1.25 14.0087L5.99125 18.75L6.875 17.8663L4.94625 15.9375L6.25 14.6337L7.68313 16.0669C7.80033 16.184 7.95927 16.2499 8.125 16.2499C8.29073 16.2499 8.44967 16.184 8.56687 16.0669L10.3125 14.3212L14.5581 18.5669C14.6753 18.684 14.8343 18.7499 15 18.7499C15.1657 18.7499 15.3247 18.684 15.4419 18.5669L18.5669 15.4419C18.684 15.3247 18.7499 15.1657 18.7499 15C18.7499 14.8343 18.684 14.6753 18.5669 14.5581L14.3212 10.3125ZM2.75875 5L5 2.75875L6.30375 4.0625L4.0625 6.30375L2.75875 5ZM4.94625 7.1875L7.1875 4.94625L8.80375 6.5625L6.5625 8.80375L4.94625 7.1875ZM8.125 14.7413L5.25875 11.875L11.875 5.25875L14.7413 8.125L8.125 14.7413ZM11.1962 13.4375L13.4375 11.1962L15.0538 12.8125L12.8125 15.0538L11.1962 13.4375ZM15 17.2413L13.6962 15.9375L15.9375 13.6962L17.2413 15L15 17.2413Z'
      fill='currentColor'
    />
  </svg>
);
export { IconSatellite };
