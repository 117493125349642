import ConversationList from './conversation-list';
import ConversationToolbar from './conversation-toolbar';

const SectionRight = () => (
  <div id='section-right' className='flex h-full min-w-[272px] flex-col border-l border-stroke'>
    <ConversationToolbar />
    <ConversationList />
  </div>
);

export default SectionRight;
