import FormRadio from 'components/shared/form-radio';

export const DemandOption = [
  { value: 'true', label: 'Bán' },
  { value: 'false', label: 'Cho thuê' },
];

const Demand = () => {
  return (
    <div id='demand' className='flex flex-col space-y-[24px]'>
      <span className='text-[18px] font-semibold leading-[23px] text-primary'>2. Nhu cầu</span>
      <div className='flex flex-row space-x-[24px]'>
        <span className='font-bold min-w-[150px]'>Nhu cầu</span>
        <div className='w-full'>
          <FormRadio
            name='demand'
            options={DemandOption}
            groupClassName='flex items-center space-x-[28px]'
          />
        </div>
      </div>
    </div>
  );
};

export default Demand;
