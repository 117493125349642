import { SVGProps } from 'react';

const IconNotification = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 24V25C20 26.0609 19.5786 27.0783 18.8284 27.8284C18.0783 28.5786 17.0609 29 16 29C14.9391 29 13.9217 28.5786 13.1716 27.8284C12.4214 27.0783 12 26.0609 12 25V24M26.73 21.9644C25.125 20 23.9919 19 23.9919 13.5844C23.9919 8.625 21.4594 6.85812 19.375 6C19.0981 5.88625 18.8375 5.625 18.7531 5.34062C18.3875 4.09625 17.3625 3 16 3C14.6375 3 13.6119 4.09688 13.25 5.34188C13.1656 5.62938 12.905 5.88625 12.6281 6C10.5413 6.85938 8.01125 8.62 8.01125 13.5844C8.00813 19 6.875 20 5.27 21.9644C4.605 22.7781 5.1875 24 6.35063 24H25.6556C26.8125 24 27.3913 22.7744 26.73 21.9644Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export { IconNotification };
