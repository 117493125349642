import { SVGProps } from 'react';

const IconAlertTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    {...props}
  >
    <path
      d='M22.5629 18.5L14.5629 4.50001C14.3885 4.19222 14.1355 3.9362 13.8299 3.75808C13.5242 3.57996 13.1767 3.48611 12.8229 3.48611C12.4691 3.48611 12.1217 3.57996 11.816 3.75808C11.5103 3.9362 11.2574 4.19222 11.0829 4.50001L3.08294 18.5C2.90662 18.8054 2.81417 19.1519 2.81495 19.5045C2.81573 19.8571 2.90972 20.2033 3.08738 20.5078C3.26505 20.8124 3.52009 21.0646 3.82663 21.2388C4.13318 21.4131 4.48034 21.5032 4.83294 21.5H20.8329C21.1838 21.4997 21.5285 21.407 21.8322 21.2313C22.136 21.0556 22.3882 20.8031 22.5634 20.4991C22.7387 20.1951 22.831 19.8504 22.8309 19.4995C22.8308 19.1486 22.7384 18.8039 22.5629 18.5Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.833 9.5V13.5'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.833 17.5H12.843'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconAlertTriangle };
