import { gql } from '@apollo/client';
import { Boolean, ID, String } from '../queries';
import { address as _address } from './temp/create-post';
import { FriendRequestMutation } from './un-friend';

export enum STATUS {
  REQUESTED = 'REQUESTED',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
}

export interface urls {
  type?: String;
  url?: String;
}
export interface File {
  fileId?: String;
  type?: String;
  originalKey?: String;
  urls?: urls[];
  originalUrl?: String;
}
export interface address {
  fileId?: String;
  type?: String;
  originalKey?: String;
  urls?: urls[];
  originalUrl?: String;
}
export interface FriendRequest {
  _id: ID;
  requester?: ID;
  recipient?: ID;
  status?: STATUS;
  friend?: Friend;
  isBlockRequester?: Boolean;
}
export interface Friend {
  id?: ID;
  fullName?: String;
  avatar?: File;
  gender?: String;
  province?: _address;
  type?: String;
}

export interface CancelFriendRequestData {
  cancelFriendRequest?: FriendRequestMutation;
}

export interface CancelFriendRequestVariables {
  requestId?: string;
}
export const CANCEL_FRIEND_REQUEST = gql`
  mutation cancelFriendRequest($requestId: ID!) @api(name: social) {
    cancelFriendRequest(requestId: $requestId) {
      success
      msg
      data {
        _id
      }
    }
  }
`;
