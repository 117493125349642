import { Boolean, Int, String } from './get-real-estates';
import { gql } from '@apollo/client';
import { PaginationOf } from './get-user-saved-real-estate';

export interface ProjectProvince {
  province_code?: String;
  province_name?: String;
  count?: String;
}
export interface ListProjectProvince extends PaginationOf {
  items?: ProjectProvince[];
}

export interface ListProjectProvincesData {
  listProjectProvinces?: ListProjectProvince;
}
export interface ListProjectProvincesVariables {
  search?: String;
  limit?: Int;
  page?: Int;
}

export const LIST_PROJECT_PROVINCES = gql`
  query listProjectProvinces($search: String, $limit: Int, $page: Int) @api(name: realEstate) {
    listProjectProvinces(search: $search, limit: $limit, page: $page) {
      total
      items {
        province_code
        province_name
        count
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
