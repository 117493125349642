import { SVGProps } from 'react';

const IconHouseRear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <g id='bi:house'>
      <g id='Group'>
        <path
          id='Vector'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.60059 16.1112V9.17847H4.66716V16.1112C4.66716 16.2527 4.72335 16.3883 4.82336 16.4883C4.92337 16.5883 5.05901 16.6445 5.20045 16.6445H14.7996C14.9411 16.6445 15.0767 16.5883 15.1767 16.4883C15.2767 16.3883 15.3329 16.2527 15.3329 16.1112V9.17847H16.3995V16.1112C16.3995 16.5355 16.231 16.9425 15.9309 17.2425C15.6309 17.5425 15.224 17.7111 14.7996 17.7111H5.20045C4.77614 17.7111 4.36921 17.5425 4.06918 17.2425C3.76914 16.9425 3.60059 16.5355 3.60059 16.1112ZM15.3329 4.37887V8.11189L13.1998 5.97873V4.37887C13.1998 4.23743 13.256 4.10179 13.356 4.00178C13.456 3.90177 13.5916 3.84558 13.7331 3.84558H14.7996C14.9411 3.84558 15.0767 3.90177 15.1767 4.00178C15.2767 4.10179 15.3329 4.23743 15.3329 4.37887Z'
          fill='currentColor'
        />
        <path
          id='Vector_2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.24593 3.31228C9.44594 3.11233 9.71718 3 10 3C10.2828 3 10.5541 3.11233 10.7541 3.31228L17.8436 10.4007C17.9437 10.5009 18 10.6367 18 10.7783C18 10.9199 17.9437 11.0557 17.8436 11.1559C17.7435 11.256 17.6077 11.3123 17.466 11.3123C17.3244 11.3123 17.1886 11.256 17.0885 11.1559L10 4.06635L2.91153 11.1559C2.81139 11.256 2.67558 11.3123 2.53396 11.3123C2.39235 11.3123 2.25653 11.256 2.15639 11.1559C2.05626 11.0557 2 10.9199 2 10.7783C2 10.6367 2.05626 10.5009 2.15639 10.4007L9.24593 3.31228Z'
          fill='currentColor'
        />
        <line
          id='Line 26'
          x1='5.81158'
          y1='17.2006'
          x2='15.5995'
          y2='13.7382'
          stroke='currentColor'
          strokeWidth='0.8'
          strokeLinecap='round'
        />
        <line
          id='Line 27'
          x1='4.52554'
          y1='13.5544'
          x2='15.6016'
          y2='9.73406'
          stroke='currentColor'
          strokeWidth='0.8'
          strokeLinecap='round'
        />
        <line
          id='Line 28'
          x1='3.98296'
          y1='10.0387'
          x2='13.7709'
          y2='6.57631'
          stroke='currentColor'
          strokeWidth='0.8'
          strokeLinecap='round'
        />
      </g>
    </g>
  </svg>
);
export { IconHouseRear };
