import { gql } from '@apollo/client';
import { CommonAddress, Float, ID, Int, ObjectID, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export interface Information {
  chudautu?: String;
  dientich?: String;
  dientichxaydung?: String;
  donviphanphoi?: String;
  donviphattrien?: String;
  donviquanly?: String;
  donvithicong?: String;
  donvithietke?: String;
  gia?: String;
  kieubietthu?: String;
  matdoxaydung?: String;
  nhathau?: String;
  phaply?: String;
  quymo?: String;
  socanho?: String;
  sotoa?: String;
}
export interface Meta {
  og_title?: String;
  og_description?: String;
  og_image?: String;
  description?: String;
  keywords?: String;
}
export interface Breadcrumbs {
  name?: String;
  position?: Int;
  item?: String;
}
export interface Project {
  _id?: ID;
  title?: String;
  href?: String;
  summary?: String;
  id?: Int;
  address?: String;
  desc?: String;
  statusLable?: String;
  facilities?: String[];
  gallery?: String[];
  information?: Information;
  designOverview?: String;
  meta?: Meta;
  province?: CommonAddress;
  district?: CommonAddress;
  ward?: CommonAddress;
  street?: CommonAddress;
  lat?: Float;
  lng?: Float;
  breadscrumbs?: Breadcrumbs[];
}
export interface ProjectFilterRealEstate {
  province?: ObjectID;
  district?: ObjectID;
  ward?: ObjectID;
  street?: ObjectID;
  address?: String;
  title?: String;
}
export interface ProjectPaginator extends PaginationOf {
  items: Project[];
}

export interface ProjectsListVariables {
  filters?: ProjectFilterRealEstate;
  page?: Int;
  limit?: Int;
}
export interface ProjectsListData {
  projectsList?: ProjectPaginator;
}

export const PROJECTS_LIST = gql`
  query projectsList($filters: ProjectFilter, $page: Int, $limit: Int) @api(name: realEstate) {
    projectsList(filters: $filters, page: $page, limit: $limit) {
      items {
        _id
        title
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
      }
      hasNextPage
      limit
      page
      prevPage
      total
      hasPrevPage
      nextPage
      pagingCounter
      totalPages
    }
  }
`;
