import { gql } from '@apollo/client';
import { DateTime, ObjectID, String } from '../queries';
import { MutationOfRegisterInProject } from './create-register-get-price-list';

export enum DemandType {
  seeHouseLive = 'seeHouseLive',
  seeLegalInformation = 'seeLegalInformation',
  seeLiveStream = 'seeLiveStream',
  getDocumentProject = 'getDocumentProject',
}
export interface CreateRegisterSeeHouseData {
  createRegisterSeeHouse?: MutationOfRegisterInProject;
}
export interface CreateRegisterSeeHouseVariables {
  input?: {
    product: ObjectID;
    fullName: String;
    phone: String;
    email?: String;
    appointmentTime: DateTime;
    note?: String;
    demand: DemandType;
  };
}

export const CREATE_REGISTER_SEE_HOUSE = gql`
  mutation createRegisterSeeHouse($input: RegisterSeeHouse) @api(name: biha) {
    createRegisterSeeHouse(input: $input) {
      success
      msg
    }
  }
`;
