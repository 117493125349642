import { gql } from '@apollo/client';
import { String } from './get-real-estates';

export interface BuildingFullAddress {
  payload?: String;
}

export interface GetBuildingFullAddressData {
  getBuildingFullAddress?: BuildingFullAddress;
}
export interface GetBuildingFullAddressVariables {
  buildingId: String;
}

export const GET_BUILDING_FULL_ADDRESS = gql`
  query getBuildingFullAddress($buildingId: String!) @api(name: realEstate) {
    getBuildingFullAddress(buildingId: $buildingId) {
      payload
    }
  }
`;
