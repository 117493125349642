import { SVGProps } from 'react';

const IconWarehouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='currentColor'
    {...props}
  >
    <path
      d='M14.4 6.15376C14.3187 6.15409 14.2385 6.13585 14.1653 6.10042L7.99998 2.95376L1.83465 6.07909C1.7031 6.14133 1.55222 6.14876 1.41519 6.09975C1.27816 6.05074 1.16622 5.94931 1.10398 5.81776C1.04174 5.68621 1.03431 5.53533 1.08332 5.3983C1.13233 5.26127 1.23377 5.14933 1.36531 5.08709L7.99998 1.80176L14.6346 5.08709C14.7745 5.11976 14.8959 5.20621 14.9724 5.32771C15.049 5.44921 15.0746 5.59598 15.0438 5.73625C15.0129 5.87651 14.9281 5.99897 14.8076 6.07712C14.6871 6.15526 14.5406 6.1828 14.4 6.15376ZM5.33331 11.4871H2.13331V14.6871H5.33331V11.4871ZM9.59998 11.4871H6.39998V14.6871H9.59998V11.4871ZM13.8666 11.4871H10.6666V14.6871H13.8666V11.4871ZM11.7333 7.22042H8.53331V10.4204H11.7333V7.22042ZM7.46665 7.22042H4.26665V10.4204H7.46665V7.22042Z'
      fill='currentColor'
    />
  </svg>
);
export { IconWarehouse };
