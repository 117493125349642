import { forEach, split, trim } from 'lodash';

export const parseCookiesStringToObject = (cookiesString?: string) => {
  const keyValuePairs = split(cookiesString, '; ');
  const object: { [key: string]: string } = {};
  forEach(keyValuePairs, (pair) => {
    const [key, value] = split(pair, '=');
    object[trim(key)] = decodeURIComponent(trim(value));
  });

  return object;
};
