import { SVGProps } from 'react';

const IconContactChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.64499 20H4.35741L5.26789 19.0895C5.7588 18.5986 6.06536 17.9601 6.14778 17.2674C4.86675 16.4268 3.93 15.3207 3.42856 14.0523C2.92747 12.7849 2.86451 11.3897 3.24648 10.0174C3.70479 8.37088 4.77974 6.87012 6.27325 5.79154C7.89622 4.61952 9.91664 4 12.1161 4C14.8868 4 17.2223 4.79605 18.8698 6.30204C20.354 7.65881 21.1715 9.48889 21.1715 11.4552C21.1715 12.4105 20.9769 13.3401 20.5933 14.218C20.1964 15.1265 19.6151 15.9354 18.8656 16.6222C17.2158 18.1342 14.8819 18.9333 12.1161 18.9333C11.0893 18.9333 10.0179 18.7963 9.06138 18.5447C8.15596 19.4736 6.92904 20 5.64499 20ZM12.1161 5.06667C7.70583 5.06667 4.99965 7.69682 4.27407 10.3035C3.58842 12.7668 4.59916 15.0943 6.97782 16.5298L7.2436 16.6902L7.23542 17.0005C7.21921 17.6146 7.07279 18.2062 6.81071 18.741C7.4489 18.525 8.02799 18.1336 8.48147 17.5978L8.70696 17.3314L9.04136 17.4314C9.97978 17.7121 11.0717 17.8667 12.1161 17.8667C17.6049 17.8667 20.1048 14.5432 20.1048 11.4552C20.1048 9.79207 19.4106 8.24156 18.1501 7.08935C16.7025 5.76612 14.616 5.06667 12.1161 5.06667Z'
      fill='currentColor'
    />
  </svg>
);
export { IconContactChat };
