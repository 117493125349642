import { SVGProps } from 'react';

const IconPanHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='currentColor'
    {...props}
  >
    <path
      d='M13.5 5.625L12.7046 6.42037L14.7218 8.4375H3.27825L5.29537 6.42037L4.5 5.625L1.125 9L4.5 12.375L5.29537 11.5796L3.27825 9.5625H14.7218L12.7046 11.5796L13.5 12.375L16.875 9L13.5 5.625Z'
      fill='currentColor'
    />
  </svg>
);
export { IconPanHorizontal };
