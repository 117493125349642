import { SVGProps } from 'react';

const IconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='28'
    height='29'
    viewBox='0 0 28 29'
    fill='currentColor'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 14.5C7 14.2679 7.09219 14.0454 7.25628 13.8813C7.42038 13.7172 7.64294 13.625 7.875 13.625H18.0128L14.2555 9.86951C14.0912 9.70521 13.9989 9.48236 13.9989 9.25001C13.9989 9.01765 14.0912 8.79481 14.2555 8.63051C14.4198 8.46621 14.6426 8.3739 14.875 8.3739C15.1074 8.3739 15.3302 8.46621 15.4945 8.63051L20.7445 13.8805C20.826 13.9618 20.8906 14.0583 20.9347 14.1646C20.9789 14.271 21.0016 14.3849 21.0016 14.5C21.0016 14.6151 20.9789 14.7291 20.9347 14.8354C20.8906 14.9417 20.826 15.0382 20.7445 15.1195L15.4945 20.3695C15.3302 20.5338 15.1074 20.6261 14.875 20.6261C14.6426 20.6261 14.4198 20.5338 14.2555 20.3695C14.0912 20.2052 13.9989 19.9824 13.9989 19.75C13.9989 19.5176 14.0912 19.2948 14.2555 19.1305L18.0128 15.375H7.875C7.64294 15.375 7.42038 15.2828 7.25628 15.1187C7.09219 14.9546 7 14.7321 7 14.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconArrowRight };
