import { gql } from '@apollo/client';
import { DateTime, JSONObject, ObjectID, String } from './get-real-estates';

export interface BreadScrumb {
  name?: String;
  slug?: String;
  tooltip?: String;
}

export interface Slug {
  breadScrumbs?: BreadScrumb[];
  createdAt?: DateTime;
  description?: String;
  filters?: JSONObject;
  id?: ObjectID;
  seoContent?: String;
  seoMetaTitle?: String;
  seoMetaDescription?: String;
  seoMetaImage?: String;
  slug?: String;
  title?: String;
  updatedAt?: DateTime;
}

export interface SlugifyData {
  slugify?: Slug;
}
export interface SlugifyVariables {
  slug: String;
}

export const SLUGIFY = gql`
  query slugify($slug: String!) @api(name: realEstate) {
    slugify(slug: $slug) {
      id
      slug
      title
      filters
      seoContent
      seoMetaTitle
      seoMetaDescription
      seoMetaImage
      breadScrumbs {
        tooltip
        name
        slug
      }
    }
  }
`;
