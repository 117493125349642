import { SVGProps } from 'react';

const IconEdit1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_157_27309)'>
      <path
        d='M9.16675 3.33417H5.66675C4.26662 3.33417 3.56655 3.33417 3.03177 3.60666C2.56137 3.84634 2.17892 4.22879 1.93923 4.6992C1.66675 5.23398 1.66675 5.93404 1.66675 7.33417V14.3342C1.66675 15.7343 1.66675 16.4344 1.93923 16.9691C2.17892 17.4396 2.56137 17.822 3.03177 18.0617C3.56655 18.3342 4.26662 18.3342 5.66675 18.3342H12.6667C14.0669 18.3342 14.7669 18.3342 15.3017 18.0617C15.7721 17.822 16.1546 17.4396 16.3943 16.9691C16.6667 16.4344 16.6667 15.7343 16.6667 14.3342V10.8342M6.66673 13.3342H8.06218C8.46983 13.3342 8.67366 13.3342 8.86547 13.2881C9.03553 13.2473 9.1981 13.18 9.34722 13.0886C9.51542 12.9855 9.65954 12.8414 9.9478 12.5531L17.9167 4.58417C18.6071 3.89382 18.6071 2.77453 17.9167 2.08417C17.2264 1.39382 16.1071 1.39382 15.4167 2.08417L7.44778 10.0531C7.15952 10.3414 7.0154 10.4855 6.91233 10.6537C6.82094 10.8028 6.7536 10.9654 6.71278 11.1355C6.66673 11.3273 6.66673 11.5311 6.66673 11.9387V13.3342Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_157_27309'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconEdit1 };
