import { gt } from 'lodash';
import moment from 'moment-timezone';

export function timeSince(date: any) {
  const seconds = Math.floor((+new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (gt(interval, 1)) {
    return moment(date).format('HH:mm DD/MM/YYYY');
  }
  interval = seconds / 2592000;
  if (gt(interval, 1)) {
    return moment(date).format('HH:mm DD/MM/YYYY');
  }
  interval = seconds / 86400;
  if (gt(interval, 1)) {
    return moment(date).format('HH:mm DD/MM/YYYY');
  }
  interval = seconds / 3600;
  if (gt(interval, 1)) {
    return Math.floor(interval) + ' giờ trước';
  }
  interval = seconds / 60;
  if (gt(interval, 1)) {
    return Math.floor(interval) + ' phút trước';
  }
  return 'vài giây trước';
}
