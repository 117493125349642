import { gql } from '@apollo/client';
import { ObjectID } from 'graphql/main/queries';
import { ProductInProjectBiha } from './list-projects-atmnha';

export interface GetPublicProductInProjectATMNhaDetailData {
  getPublicProductInProjectATMNhaDetail?: ProductInProjectBiha;
}
export interface GetPublicProductInProjectATMNhaDetailVariables {
  productId: ObjectID;
}

export const GET_PUBLIC_PRODUCT_IN_PROJECT_ATMNHA_DETAIL = gql`
  query getPublicProductInProjectATMNhaDetail($productId: ObjectID!) @api(name: biha) {
    getPublicProductInProjectATMNhaDetail(productId: $productId) {
      id
      createdAt
      updatedAt
      productCode
      projectId
      projectName
      type
      formatName
      fullAddress
      status
      price
      area
      rear
      clearanceArea
      floorCount
      width
      length
      block {
        id
        name
      }
      floor {
        id
        name
      }
      blockName
      floorName
      roomNumber
      isForSell
      isNewConstruction
      commission
      bedroomCount
      toiletCount
      direction
      buildingType
      roomCount
      hotelStar
      hasSeaView
      warehouseRoomCount
      bedRoomArea
      industryTrade
      mediaUrls {
        ... on FileWithFullUrls {
          fileId
          type
          originalUrl
        }
        ... on YoutubeFile {
          fileId
          type
          youtubeMetadata {
            videoId
          }
        }
      }
      vr360Urls
      project {
        id
        projectName
        fullAddress
        totalBlock
        blocks {
          id
        }
        projectArea {
          area
        }
        apartmentCount
        mediaUrls {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
      }
      supporterATMNha {
        id
        fullName
        employeeCode
        phones
        avatar {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
      }
      fixedUtilities
      utilities
      localUtilities
      views
      equipmentsAndServices
      legals
      savedItem {
        id
        belongsToCollection {
          id
          name
        }
      }
    }
  }
`;
