import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconForeclosureModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;
  return (
    <svg
      width='56'
      height='55'
      viewBox='0 0 56 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g>
        <rect x='0.5' width='55' height='55' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.6'
          cx='45.1113'
          cy='48.5'
          r={r ?? '36'}
          fill='url(#paint0_radial_225_43599)'
          fillOpacity='0.8'
        />
        <path
          d='M33.9582 40.5C35.8263 40.5 37.6179 39.7579 38.9389 38.4369C40.2598 37.116 41.0019 35.3244 41.0019 33.4563C41.0019 31.5882 40.2598 29.7966 38.9389 28.4756C37.6179 27.1547 35.8263 26.4126 33.9582 26.4126C32.0901 26.4126 30.2985 27.1547 28.9776 28.4756C27.6567 29.7966 26.9146 31.5882 26.9146 33.4563C26.9146 35.3244 27.6567 37.116 28.9776 38.4369C30.2985 39.7579 32.0901 40.5 33.9582 40.5ZM34.6707 28.7189L37.6894 31.7376C37.8783 31.9266 37.9845 32.1828 37.9845 32.45C37.9845 32.7173 37.8783 32.9735 37.6894 33.1625C37.5004 33.3514 37.2442 33.4576 36.977 33.4576C36.7098 33.4576 36.4535 33.3514 36.2645 33.1625L34.9645 31.8604V37.4813C34.9645 37.7481 34.8585 38.0041 34.6698 38.1928C34.4811 38.3815 34.2251 38.4875 33.9582 38.4875C33.6914 38.4875 33.4354 38.3815 33.2467 38.1928C33.058 38.0041 32.952 37.7481 32.952 37.4813V31.8604L31.6519 33.1625C31.4633 33.3514 31.2072 33.4577 30.9402 33.4579C30.6732 33.458 30.417 33.3521 30.2281 33.1635C30.0392 32.9748 29.9329 32.7188 29.9327 32.4518C29.9325 32.1847 30.0384 31.9286 30.2271 31.7396L33.2458 28.7209C33.3393 28.6272 33.4503 28.5529 33.5726 28.5021C33.6948 28.4514 33.8259 28.4253 33.9582 28.4253C34.0906 28.4253 34.2217 28.4514 34.3439 28.5021C34.4661 28.5529 34.5772 28.6272 34.6707 28.7209V28.7189Z'
          fill={fillColor2 ?? '#80888F'}
        />
        <path
          d='M33.4149 20.9541V16.6663C33.4149 16.0683 32.9295 15.5829 32.3315 15.5829C31.7335 15.5829 31.2482 16.0683 31.2482 16.6663V18.8351L27.1304 14.8083C26.709 14.3967 26.0362 14.3978 25.6159 14.8083L15.478 24.7188C15.1735 25.0232 14.998 25.4457 14.998 25.8747C14.998 26.7706 15.7271 27.4997 16.6231 27.4997H18.2481V33.4581C18.2481 34.9509 19.4636 36.1664 20.9564 36.1664H23.6648C24.2628 36.1664 24.7481 35.6811 24.7481 35.0831C24.7481 34.4851 24.2628 33.9997 23.6648 33.9997H20.9564C20.6574 33.9997 20.4148 33.7571 20.4148 33.4581V26.4163C20.4148 25.8183 19.9294 25.333 19.3314 25.333H17.9491L26.3731 17.0974L33.4701 24.0373C33.8991 24.4566 34.5838 24.4479 35.0019 24.02C35.4201 23.5921 35.4125 22.9063 34.9846 22.4882L33.4149 20.9541Z'
          fill={fillColor2 ?? '#80888F'}
        />
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_225_43599'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(45.1113 48.5) rotate(90) scale(30)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_225_43599'>
          <rect x='0.5' width='55' height='55' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconForeclosureModule };
