import { SVGProps } from 'react';

const IconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M13.8809 6.67416L5.07744 15.4875C5.03315 15.5319 5.00117 15.5881 4.98594 15.6484L4.01016 19.5691C3.98098 19.6871 4.01562 19.8126 4.10166 19.8988C4.16677 19.9639 4.25547 20 4.34621 20C4.374 20 4.40243 19.9966 4.43009 19.9896L8.34665 19.0127C8.40769 18.9974 8.46315 18.9655 8.50744 18.9212L17.3116 10.1085L13.8809 6.67416Z'
      fill='currentColor'
    />
    <path
      d='M19.4926 5.47249L18.5126 4.4915C17.8577 3.83585 16.7162 3.83649 16.062 4.4915L14.8616 5.69315L18.2922 9.12739L19.4926 7.92573C19.8198 7.59835 20 7.16261 20 6.69917C20 6.23574 19.8198 5.79999 19.4926 5.47249Z'
      fill='currentColor'
    />
  </svg>
);
export { IconEdit };
