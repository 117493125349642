import * as yup from 'yup';

export interface ChangePasswordForm {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export const changePasswordFormDefaultValues: ChangePasswordForm = {
  oldPassword: undefined,
  newPassword: undefined,
  confirmNewPassword: undefined,
};

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Vui lòng nhập mật khẩu cũ')
    .min(8, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự')
    .max(32, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự'),
  newPassword: yup
    .string()
    .required('Vui lòng nhập mật khẩu mới')
    .min(8, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự')
    .max(32, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự')
    .notOneOf([yup.ref('oldPassword')], 'Mật khẩu mới không được giống mật khẩu cũ'),
  confirmNewPassword: yup
    .string()
    .required('Vui lòng nhập lại mật khẩu mới')
    .oneOf([yup.ref('newPassword')], 'Nhập lại mật khẩu mới không trùng khớp'),
});
