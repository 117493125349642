import { SVGProps } from 'react';

const IconWidget = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M3 2.99945H11V7.33945L16.66 1.68945L22.31 7.33945L16.66 12.9995H21V20.9995H13V12.9995H16.66L11 7.33945V10.9995H3V2.99945ZM3 12.9995H11V20.9995H3V12.9995Z'
      fill='currentColor'
    />
  </svg>
);
export { IconWidget };
