import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { Boolean, ObjectID } from '../queries';
import { TransactionForUser } from './create-deposit-transaction';

export interface MutationOfRepaymentTransaction extends MutationOf {
  payload?: TransactionForUser;
}

export interface RepaymentTransactionData {
  repaymentTransaction?: MutationOfRepaymentTransaction;
}
export interface RepaymentTransactionVariables {
  id: ObjectID;
  isMB?: Boolean;
}

export const REPAYMENT_TRANSACTION = gql`
  mutation repaymentTransaction($id: ObjectID!, $isMB: Boolean) @api(name: ewallet) {
    repaymentTransaction(id: $id, isMB: $isMB) {
      success
      msg
      payload {
        paymentPayload {
          paymentUrl
          transactionId
        }
      }
    }
  }
`;
