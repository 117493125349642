import { SVGProps } from 'react';

const IconStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.47568 2.29422L10.0201 5.39779C10.0958 5.55378 10.2449 5.66282 10.4183 5.68912L13.8752 6.18855C14.0155 6.20768 14.1422 6.28124 14.2271 6.3929C14.3121 6.50455 14.3483 6.64503 14.3276 6.7831C14.3103 6.8966 14.2551 7.00118 14.1708 7.08038L11.6671 9.50616C11.5406 9.62571 11.4838 9.80015 11.5163 9.96991L12.1196 13.3886C12.1632 13.6703 11.9701 13.9346 11.6852 13.9832C11.5677 14.0013 11.4474 13.9826 11.3413 13.9296L8.25849 12.3184C8.10449 12.2348 7.91779 12.2348 7.76379 12.3184L4.66285 13.9415C4.40269 14.0731 4.08353 13.9753 3.94493 13.7216C3.89089 13.6192 3.87185 13.5024 3.89063 13.3886L4.49393 9.96991C4.52311 9.80036 4.46686 9.62741 4.34311 9.50616L1.82133 7.08038C1.6151 6.87434 1.6151 6.54323 1.82133 6.33719C1.9055 6.26039 2.01013 6.20883 2.12298 6.18855L5.57986 5.68912C5.75246 5.66091 5.90069 5.55245 5.97804 5.39779L7.52247 2.29422C7.5835 2.16838 7.6935 2.07216 7.82754 2.02738C7.96157 1.9826 8.10827 1.99307 8.23436 2.0564C8.3384 2.10839 8.42292 2.19169 8.47568 2.29422Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconStar };
