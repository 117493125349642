import { gql } from '@apollo/client';
import { Boolean, String } from '../queries';

export interface MessageType {
  message?: String;
  msg?: String;
  payload?: String;
  success?: Boolean;
}

export interface ForgotPasswordData {
  forgotPassword?: MessageType;
}
export interface ForgotPasswordVariables {
  authString: String;
  identifier: String;
}

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($authString: String!, $identifier: String!) @api(name: users) {
    forgotPassword(authString: $authString, identifier: $identifier) {
      message
    }
  }
`;
