import { SVGProps } from 'react';

const IconOfficeBuildingMarkerOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12.5 7.50016H14.1667V9.16683H12.5V7.50016ZM14.1667 4.16683H12.5V5.8335H14.1667V4.16683ZM9.16667 12.5002H10.8333V10.8335H9.16667V12.5002ZM10.8333 4.16683H9.16667V5.8335H10.8333V4.16683ZM9.16667 9.16683H10.8333V7.50016H9.16667V9.16683ZM7.5 4.16683H5.83333V5.8335H7.5V4.16683ZM7.5 7.50016H5.83333V9.16683H7.5V7.50016ZM12.125 17.5002H10.8333V14.5835H9.16667V17.5002H4.16667V2.50016H15.8333V9.19183C16.425 9.24183 16.9917 9.42516 17.5 9.69183V0.833496H2.5V19.1668H13.2583C12.9167 18.7002 12.5 18.1335 12.125 17.5002ZM5.83333 15.8335H7.5V14.1668H5.83333V15.8335ZM7.5 10.8335H5.83333V12.5002H7.5V10.8335ZM18.3333 13.7502C18.3333 15.9168 15.4167 19.1668 15.4167 19.1668C15.4167 19.1668 12.5 15.9168 12.5 13.7502C12.5 12.1668 13.8333 10.8335 15.4167 10.8335C17 10.8335 18.3333 12.1668 18.3333 13.7502ZM16.4167 13.8335C16.4167 13.3335 15.9167 12.8335 15.4167 12.8335C14.9167 12.8335 14.4167 13.2502 14.4167 13.8335C14.4167 14.3335 14.8333 14.8335 15.4167 14.8335C16 14.8335 16.5 14.3335 16.4167 13.8335Z'
      fill='currentColor'
    />
  </svg>
);
export { IconOfficeBuildingMarkerOutline };
