import { SVGProps } from 'react';

const IconCompass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656ZM13.875 5.7793C13.7985 5.74941 13.7169 5.73488 13.6348 5.73656C13.5527 5.73824 13.4717 5.75608 13.3965 5.78906L8.10352 8.10547L5.78906 13.3984C5.71875 13.5586 5.71875 13.7402 5.78906 13.9004C5.85547 14.0527 5.97852 14.1719 6.13477 14.2324C6.20898 14.2617 6.28516 14.2754 6.36328 14.2754C6.44922 14.2754 6.5332 14.2578 6.61328 14.2227L11.9062 11.9062L14.2207 6.61328C14.2545 6.53604 14.2723 6.45277 14.2731 6.36847C14.2739 6.28416 14.2577 6.20057 14.2254 6.12269C14.1931 6.04481 14.1454 5.97425 14.0852 5.91525C14.025 5.85624 13.9535 5.81 13.875 5.7793ZM11.2656 10.4375L11.7773 10.9492L10.9492 11.7773L10.4375 11.2656L7.42188 12.5859L8.74023 9.57031L8.24219 9.07031L9.07031 8.24219L9.56836 8.74023L12.5859 7.42188L11.2656 10.4375ZM9.07031 8.24219L8.24219 9.07031L8.74023 9.57031L10.4375 11.2656L10.9492 11.7773L11.7773 10.9492L11.2656 10.4375L9.57031 8.74023L9.07031 8.24219Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCompass };
