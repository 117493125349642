import { PHONE_NUMBER_REGEX } from 'globalConstants';
import * as yup from 'yup';

export interface RegisterGetPriceListForm {
  fullName?: string;
  phone?: string;
  email?: string;
  priceList?: string[];
}

export const registerGetPriceListFormDefaultValues: RegisterGetPriceListForm = {
  fullName: undefined,
  phone: undefined,
  email: undefined,
  priceList: undefined,
};

export const registerGetPriceListFormSchema = yup.object().shape({
  fullName: yup.string().required('Vui lòng nhập Họ và tên'),
  phone: yup
    .string()
    .required('Vui lòng nhập Số điện thoại')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  email: yup.string().nullable().email('Vui lòng nhập đúng định dạng email@xxx.com'),
});
