import { SVGProps } from 'react';

const IconMapMarkerOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M10.3333 5.41669C10.8859 5.41669 11.4158 5.63618 11.8065 6.02688C12.1972 6.41758 12.4167 6.94749 12.4167 7.50002C12.4167 7.77361 12.3628 8.04452 12.2581 8.29728C12.1534 8.55004 11.9999 8.7797 11.8065 8.97316C11.613 9.16662 11.3834 9.32007 11.1306 9.42477C10.8778 9.52947 10.6069 9.58335 10.3333 9.58335C9.7808 9.58335 9.2509 9.36386 8.86019 8.97316C8.46949 8.58246 8.25 8.05256 8.25 7.50002C8.25 6.94749 8.46949 6.41758 8.86019 6.02688C9.2509 5.63618 9.7808 5.41669 10.3333 5.41669ZM10.3333 1.66669C11.8804 1.66669 13.3642 2.28127 14.4581 3.37523C15.5521 4.46919 16.1667 5.95292 16.1667 7.50002C16.1667 11.875 10.3333 18.3334 10.3333 18.3334C10.3333 18.3334 4.5 11.875 4.5 7.50002C4.5 5.95292 5.11458 4.46919 6.20854 3.37523C7.30251 2.28127 8.78624 1.66669 10.3333 1.66669ZM10.3333 3.33335C9.22826 3.33335 8.16846 3.77234 7.38705 4.55374C6.60565 5.33514 6.16667 6.39495 6.16667 7.50002C6.16667 8.33335 6.16667 10 10.3333 15.5917C14.5 10 14.5 8.33335 14.5 7.50002C14.5 6.39495 14.061 5.33514 13.2796 4.55374C12.4982 3.77234 11.4384 3.33335 10.3333 3.33335Z'
      fill='currentColor'
    />
  </svg>
);
export { IconMapMarkerOutline };
