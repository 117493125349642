import { SVGProps } from 'react';

const IconFullscreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='currentColor'
    {...props}
  >
    <path
      d='M5.29045 4.27896H6.8077C7.00889 4.27896 7.20185 4.19903 7.34412 4.05677C7.48639 3.9145 7.56632 3.72154 7.56632 3.52034C7.56632 3.31914 7.48639 3.12618 7.34412 2.98391C7.20185 2.84164 7.00889 2.76172 6.8077 2.76172H3.52034C3.31914 2.76172 3.12618 2.84164 2.98391 2.98391C2.84164 3.12618 2.76172 3.31914 2.76172 3.52034V6.8077C2.76172 7.00889 2.84164 7.20185 2.98391 7.34412C3.12618 7.48639 3.31914 7.56632 3.52034 7.56632C3.72154 7.56632 3.9145 7.48639 4.05677 7.34412C4.19903 7.20185 4.27896 7.00889 4.27896 6.8077V5.29045L7.94563 8.95712C8.01204 9.02354 8.09089 9.07622 8.17767 9.11217C8.26444 9.14811 8.35745 9.16662 8.45137 9.16662C8.5453 9.16662 8.63831 9.14811 8.72508 9.11217C8.81186 9.07622 8.89071 9.02354 8.95712 8.95712C9.02354 8.89071 9.07622 8.81186 9.11217 8.72508C9.14811 8.63831 9.16662 8.5453 9.16662 8.45137C9.16662 8.35745 9.14811 8.26444 9.11217 8.17767C9.07622 8.09089 9.02354 8.01204 8.95712 7.94563L5.29045 4.27896Z'
      fill='currentColor'
    />
    <path
      d='M8.95712 13.0427C8.89071 12.9763 8.81186 12.9236 8.72508 12.8877C8.63831 12.8518 8.5453 12.8333 8.45137 12.8333C8.35745 12.8333 8.26444 12.8518 8.17767 12.8877C8.09089 12.9236 8.01204 12.9763 7.94563 13.0427L4.27896 16.7094V15.1922C4.27896 14.991 4.19903 14.798 4.05677 14.6557C3.9145 14.5135 3.72154 14.4335 3.52034 14.4335C3.31914 14.4335 3.12618 14.5135 2.98391 14.6557C2.84164 14.798 2.76172 14.991 2.76172 15.1922V18.4795C2.76172 18.6807 2.84164 18.8737 2.98391 19.016C3.12618 19.1582 3.31914 19.2381 3.52034 19.2381H6.8077C7.00889 19.2381 7.20185 19.1582 7.34412 19.016C7.48639 18.8737 7.56632 18.6807 7.56632 18.4795C7.56632 18.2783 7.48639 18.0854 7.34412 17.9431C7.20185 17.8008 7.00889 17.7209 6.8077 17.7209H5.29045L8.95712 14.0542C9.02354 13.9878 9.07622 13.909 9.11217 13.8222C9.14811 13.7354 9.16662 13.6424 9.16662 13.5485C9.16662 13.4546 9.14811 13.3616 9.11217 13.2748C9.07622 13.188 9.02354 13.1092 8.95712 13.0427Z'
      fill='currentColor'
    />
    <path
      d='M18.4796 2.76172H15.1922C14.991 2.76172 14.7981 2.84164 14.6558 2.98391C14.5135 3.12618 14.4336 3.31914 14.4336 3.52034C14.4336 3.72154 14.5135 3.9145 14.6558 4.05677C14.7981 4.19903 14.991 4.27896 15.1922 4.27896H16.7095L13.0428 7.94563C12.9764 8.01204 12.9237 8.09089 12.8878 8.17767C12.8518 8.26444 12.8333 8.35745 12.8333 8.45137C12.8333 8.5453 12.8518 8.63831 12.8878 8.72508C12.9237 8.81186 12.9764 8.89071 13.0428 8.95712C13.1092 9.02354 13.1881 9.07622 13.2748 9.11217C13.3616 9.14811 13.4546 9.16662 13.5486 9.16662C13.6425 9.16662 13.7355 9.14811 13.8223 9.11217C13.909 9.07622 13.9879 9.02354 14.0543 8.95712L17.721 5.29045V6.8077C17.721 7.00889 17.8009 7.20185 17.9432 7.34412C18.0854 7.48639 18.2784 7.56632 18.4796 7.56632C18.6808 7.56632 18.8737 7.48639 19.016 7.34412C19.1583 7.20185 19.2382 7.00889 19.2382 6.8077V3.52034C19.2382 3.31914 19.1583 3.12618 19.016 2.98391C18.8737 2.84164 18.6808 2.76172 18.4796 2.76172Z'
      fill='currentColor'
    />
    <path
      d='M18.4796 14.4335C18.2784 14.4335 18.0854 14.5135 17.9432 14.6557C17.8009 14.798 17.721 14.991 17.721 15.1922V16.7094L14.0543 13.0427C13.9879 12.9763 13.909 12.9236 13.8223 12.8877C13.7355 12.8518 13.6425 12.8333 13.5486 12.8333C13.4546 12.8333 13.3616 12.8518 13.2748 12.8877C13.1881 12.9236 13.1092 12.9763 13.0428 13.0427C12.9764 13.1092 12.9237 13.188 12.8878 13.2748C12.8518 13.3616 12.8333 13.4546 12.8333 13.5485C12.8333 13.6424 12.8518 13.7354 12.8878 13.8222C12.9237 13.909 12.9764 13.9878 13.0428 14.0542L16.7095 17.7209H15.1922C14.991 17.7209 14.7981 17.8008 14.6558 17.9431C14.5135 18.0854 14.4336 18.2783 14.4336 18.4795C14.4336 18.6807 14.5135 18.8737 14.6558 19.016C14.7981 19.1582 14.991 19.2381 15.1922 19.2381H18.4796C18.6808 19.2381 18.8737 19.1582 19.016 19.016C19.1583 18.8737 19.2382 18.6807 19.2382 18.4795V15.1922C19.2382 14.991 19.1583 14.798 19.016 14.6557C18.8737 14.5135 18.6808 14.4335 18.4796 14.4335Z'
      fill='currentColor'
    />
  </svg>
);
export { IconFullscreen };
