import { SVGProps } from 'react';

const IconBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2V2ZM12 3.66667C10.9057 3.66667 9.82202 3.88221 8.81097 4.301C7.79992 4.71979 6.88126 5.33362 6.10744 6.10744C4.54464 7.67025 3.66667 9.78986 3.66667 12C3.66667 14 4.375 15.8333 5.55 17.275L17.275 5.55C15.8333 4.375 14 3.66667 12 3.66667ZM12 20.3333C14.2101 20.3333 16.3298 19.4554 17.8926 17.8926C19.4554 16.3298 20.3333 14.2101 20.3333 12C20.3333 10 19.625 8.16667 18.45 6.725L6.725 18.45C8.16667 19.625 10 20.3333 12 20.3333Z'
      fill='currentColor'
    />
  </svg>
);
export { IconBlock };
