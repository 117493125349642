import { SVGProps } from 'react';

const IconSocialMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='22'
    viewBox='0 0 21 22'
    fill='currentColor'
    {...props}
  >
    <g id='Group 432'>
      <path
        id='Vector'
        d='M18.4 8.83646C18.0623 8.98841 17.7054 9.09494 17.3388 9.15318C17.9251 10.7938 17.927 12.5815 17.3442 14.2234C16.7614 15.8652 15.6284 17.2637 14.1311 18.1897C12.6337 19.1157 10.8607 19.5143 9.1027 19.3201C7.34468 19.1259 5.70592 18.3505 4.45499 17.1209C3.20406 15.8913 2.41517 14.2805 2.21762 12.5525C2.02006 10.8245 2.42556 9.08179 3.36765 7.60998C4.30974 6.13816 5.73254 5.02456 7.40287 4.4517C9.07319 3.87883 10.8919 3.88068 12.5611 4.45694C12.6203 4.09661 12.7286 3.74577 12.8833 3.41393C10.9757 2.76558 8.90076 2.77217 6.99744 3.43263C5.09413 4.09309 3.47488 5.36838 2.40417 7.05022C1.33345 8.73206 0.874545 10.7211 1.10239 12.6924C1.33024 14.6637 2.23137 16.5008 3.65862 17.9037C5.08587 19.3066 6.95489 20.1924 8.96043 20.4163C10.966 20.6403 12.9895 20.1892 14.7005 19.1367C16.4116 18.0843 17.709 16.4927 18.3809 14.6219C19.0528 12.751 19.0596 10.7115 18.4 8.83646Z'
        fill='currentColor'
      />
      <path
        id='Vector_2'
        d='M11.25 4.99206C12.2269 6.02373 12.9642 7.25155 13.4111 8.5907H10.4167V4.89923H9.58334V8.59616H6.58889C7.03671 7.25459 7.77602 6.0248 8.75556 4.99206C8.27155 5.07818 7.79842 5.2155 7.34445 5.40162C6.62859 6.3707 6.08221 7.45036 5.72778 8.59616H3.85556C3.7081 8.88358 3.58185 9.18105 3.47778 9.48626H5.49445C5.33794 10.2042 5.25788 10.9363 5.25556 11.6706C5.2571 12.4737 5.35027 13.2742 5.53334 14.0569H3.56667C3.68161 14.363 3.819 14.6605 3.97778 14.947H5.77223C6.11234 15.9932 6.61657 16.9807 7.26667 17.874C7.73221 18.0702 8.21854 18.2149 8.71667 18.3054C7.80996 17.3332 7.11265 16.1909 6.66667 14.947H9.5889V18.4091H10.4222V14.947H13.3333C12.8858 16.1914 12.1866 17.3338 11.2778 18.3054C11.7782 18.2118 12.2665 18.0634 12.7333 17.8631C13.3824 16.9732 13.8866 15.9894 14.2278 14.947H16.0056C16.1636 14.6658 16.301 14.3738 16.4167 14.0733H14.4445C14.6275 13.2906 14.7207 12.4901 14.7222 11.6869C14.7199 10.9527 14.6398 10.2206 14.4833 9.50264H16.4833C16.45 9.40981 16.4167 9.32244 16.3778 9.23507C15.6941 9.19295 15.0317 8.98468 14.45 8.62892H14.25C14.25 8.56886 14.2056 8.51425 14.1889 8.45418C13.6922 8.09808 13.2821 7.63811 12.9882 7.10742C12.6942 6.57672 12.5237 5.98853 12.4889 5.38524C12.0895 5.21606 11.6747 5.08442 11.25 4.99206ZM9.58334 14.0514H6.39445C6.00332 12.5486 5.98617 10.9752 6.34445 9.46442H9.58334V14.0514ZM13.8889 11.6706C13.8861 12.4753 13.7835 13.2766 13.5833 14.0569H10.4167V9.48626H13.6556C13.8152 10.2037 13.8934 10.9362 13.8889 11.6706Z'
        fill='currentColor'
      />
      <path
        id='Vector_3'
        d='M15.9436 11.7024L15.387 12.2051L15.9436 12.8213L16.5002 12.2051L15.9436 11.7024ZM15.9436 11.7024C16.5002 12.2051 16.5002 12.205 16.5004 12.2049L16.5006 12.2046L16.5015 12.2037L16.504 12.2008L16.5129 12.1909L16.545 12.1547C16.5726 12.1235 16.6122 12.0783 16.6622 12.0203C16.7621 11.9043 16.9036 11.737 17.0728 11.5285C17.4106 11.1123 17.8622 10.5278 18.3153 9.85624C18.767 9.18682 19.2294 8.4173 19.5812 7.63207C19.9285 6.85668 20.1936 6.01039 20.1936 5.20239C20.1936 4.07522 19.7458 2.99422 18.9488 2.19719C18.1517 1.40016 17.0707 0.952393 15.9436 0.952393C14.8164 0.952393 13.7354 1.40016 12.9384 2.19719C12.1413 2.99422 11.6936 4.07522 11.6936 5.20239C11.6936 6.01039 11.9586 6.85668 12.306 7.63207C12.6578 8.4173 13.1202 9.18682 13.5718 9.85624C14.025 10.5278 14.4766 11.1123 14.8144 11.5285C14.9836 11.737 15.1251 11.9043 15.225 12.0203C15.275 12.0783 15.3146 12.1235 15.3421 12.1547L15.3742 12.1909L15.3831 12.2008L15.3857 12.2037L15.3865 12.2046L15.3868 12.2049C15.3869 12.205 15.387 12.2051 15.9436 11.7024ZM15.9436 5.70239C15.811 5.70239 15.6838 5.64971 15.59 5.55595C15.4963 5.46218 15.4436 5.335 15.4436 5.20239C15.4436 5.06978 15.4963 4.94261 15.59 4.84884C15.6838 4.75507 15.811 4.70239 15.9436 4.70239C16.0762 4.70239 16.2034 4.75507 16.2971 4.84884C16.3909 4.94261 16.4436 5.06978 16.4436 5.20239C16.4436 5.26805 16.4306 5.33307 16.4055 5.39373C16.3804 5.4544 16.3436 5.50952 16.2971 5.55595C16.2507 5.60238 16.1956 5.63921 16.1349 5.66433C16.0743 5.68946 16.0092 5.70239 15.9436 5.70239Z'
        fill='currentColor'
        stroke='white'
        strokeWidth='1.5'
      />
    </g>
  </svg>
);
export { IconSocialMedia };
