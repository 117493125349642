import { SVGProps } from 'react';

const IconBankOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.41666 8.33331H3.74999V14.1666H5.41666V8.33331ZM10.4167 8.33331H8.74999V14.1666H10.4167V8.33331ZM17.5 15.8333H1.66666V17.5H17.5V15.8333ZM15.4167 8.33331H13.75V14.1666H15.4167V8.33331ZM9.58332 2.71665L13.925 4.99998H5.24166L9.58332 2.71665ZM9.58332 0.833313L1.66666 4.99998V6.66665H17.5V4.99998L9.58332 0.833313Z'
      fill='currentColor'
    />
  </svg>
);

export { IconBankOutline };
