import { gql } from '@apollo/client';
import { Int, String } from './get-real-estates';

export interface ListBuildingByProjectData {
  listBuildingByProject?: [
    {
      rawId?: String;
      name?: String;
    },
  ];
}

export interface ListBuildingByProjectVariables {
  search?: String;
  projectId: String;
  limit?: Int;
  page?: Int;
}

export const LIST_BUILDING_BY_PROJECT = gql`
  query listBuildingByProject($search: String, $projectId: String!, $limit: Int, $page: Int)
  @api(name: realEstate) {
    listBuildingByProject(search: $search, projectId: $projectId, limit: $limit, page: $page) {
      rawId
      name
    }
  }
`;
