import { SVGProps } from 'react';

const IconInvoiceOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M13.8846 9.28475C13.8846 9.10113 13.8117 8.92504 13.6819 8.79521C13.552 8.66538 13.3759 8.59244 13.1923 8.59244H7.65386C7.47024 8.59244 7.29415 8.66538 7.16432 8.79521C7.03449 8.92504 6.96155 9.10113 6.96155 9.28475C6.96155 9.46836 7.03449 9.64445 7.16432 9.77428C7.29415 9.90411 7.47024 9.97705 7.65386 9.97705H13.1923C13.3759 9.97705 13.552 9.90411 13.6819 9.77428C13.8117 9.64445 13.8846 9.46836 13.8846 9.28475ZM12.9615 12.054C12.9615 11.8704 12.8886 11.6943 12.7588 11.5644C12.6289 11.4346 12.4529 11.3617 12.2692 11.3617H7.65386C7.47024 11.3617 7.29415 11.4346 7.16432 11.5644C7.03449 11.6943 6.96155 11.8704 6.96155 12.054C6.96155 12.2376 7.03449 12.4137 7.16432 12.5435C7.29415 12.6733 7.47024 12.7463 7.65386 12.7463H12.2692C12.4529 12.7463 12.6289 12.6733 12.7588 12.5435C12.8886 12.4137 12.9615 12.2376 12.9615 12.054ZM13.1923 14.1309C13.3759 14.1309 13.552 14.2038 13.6819 14.3337C13.8117 14.4635 13.8846 14.6396 13.8846 14.8232C13.8846 15.0068 13.8117 15.1829 13.6819 15.3127C13.552 15.4426 13.3759 15.5155 13.1923 15.5155H7.65386C7.47024 15.5155 7.29415 15.4426 7.16432 15.3127C7.03449 15.1829 6.96155 15.0068 6.96155 14.8232C6.96155 14.6396 7.03449 14.4635 7.16432 14.3337C7.29415 14.2038 7.47024 14.1309 7.65386 14.1309H13.1923Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.96154 20.3617H18.9615C19.6348 20.3617 20.2804 20.0942 20.7565 19.6182C21.2326 19.1421 21.5 18.4964 21.5 17.8232V12.7463C21.5 12.5627 21.4271 12.3866 21.2972 12.2567C21.1674 12.1269 20.9913 12.054 20.8077 12.054H17.8077V4.84751C17.8077 3.53397 16.3225 2.76966 15.2535 3.53305L15.092 3.64843C14.7383 3.89953 14.3151 4.03412 13.8813 4.03346C13.4475 4.0328 13.0247 3.89692 12.6717 3.64474C12.0824 3.22536 11.3771 3 10.6538 3C9.93057 3 9.22527 3.22536 8.636 3.64474C8.28303 3.89692 7.86023 4.0328 7.42644 4.03346C6.99264 4.03412 6.56943 3.89953 6.21569 3.64843L6.05415 3.53305C4.98523 2.76966 3.5 3.53305 3.5 4.84751V16.9001C3.5 17.8182 3.8647 18.6986 4.51386 19.3478C5.16303 19.997 6.04348 20.3617 6.96154 20.3617ZM9.44092 4.77089C9.79534 4.51931 10.2192 4.38415 10.6538 4.38415C11.0885 4.38415 11.5124 4.51931 11.8668 4.77089C12.4545 5.191 13.1587 5.41725 13.8811 5.41807C14.6035 5.4189 15.3082 5.19427 15.8969 4.77551L16.0585 4.66012C16.0929 4.63558 16.1335 4.62099 16.1757 4.61794C16.2179 4.6149 16.2602 4.62352 16.2978 4.64286C16.3354 4.66221 16.367 4.69153 16.3891 4.72762C16.4112 4.76372 16.423 4.80519 16.4231 4.84751V17.8232C16.4231 18.2386 16.5228 18.6309 16.7 18.977H6.96154C6.4107 18.977 5.88243 18.7582 5.49293 18.3687C5.10343 17.9792 4.88462 17.451 4.88462 16.9001V4.84751C4.88473 4.80519 4.89647 4.76372 4.91857 4.72762C4.94066 4.69153 4.97226 4.66221 5.0099 4.64286C5.04754 4.62352 5.08978 4.6149 5.13199 4.61794C5.1742 4.62099 5.21476 4.63558 5.24923 4.66012L5.41077 4.77551C5.99946 5.19427 6.70415 5.4189 7.42658 5.41807C8.14902 5.41725 8.8532 5.191 9.44092 4.77089ZM17.8077 17.8232V13.4386H20.1154V17.8232C20.1154 18.1292 19.9938 18.4227 19.7774 18.6391C19.561 18.8555 19.2676 18.977 18.9615 18.977C18.6555 18.977 18.362 18.8555 18.1456 18.6391C17.9293 18.4227 17.8077 18.1292 17.8077 17.8232Z'
      fill='currentColor'
    />
  </svg>
);
export { IconInvoiceOutline };
