import { gql } from '@apollo/client';
import { Int, ObjectID, String } from './get-real-estates';
import { DistributedProjectPagination } from './list-projects-atmnha';

export interface GetRelatedPublishedProjectsATMNhaData {
  getRelatedPublishedProjectsATMNha?: DistributedProjectPagination;
}
export interface GetRelatedPublishedProjectsATMNhaVariables {
  id: ObjectID;
  page?: Int;
  limit?: Int;
  sort?: String;
}

export const GET_RELATED_PUBLISHED_PROJECTS_ATMNHA = gql`
  query getRelatedPublishedProjectsATMNha($id: ObjectID!, $page: Int, $limit: Int, $sort: String)
  @api(name: biha) {
    getRelatedPublishedProjectsATMNha(id: $id, page: $page, limit: $limit, sort: $sort) {
      items {
        id
        expectedPrice {
          from
          to
        }
        project {
          id
          projectStatus
          projectType
          projectName
          fullAddress
          projectArea {
            area
          }
          mediaUrls {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          totalBlock
          blocks {
            id
            name
          }
          apartmentCount
          projectOverview
          vr360Urls
          createdAt
        }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
