import { Fragment } from 'react';
import Chat from './chat';
import { isEqual } from 'lodash';
import { detectDeviceByUserAgent } from 'utils';

const Modules = () => {
  if (isEqual(typeof window, 'undefined')) {
    return null;
  } else {
    const userAgent = window.navigator.userAgent;
    const device = detectDeviceByUserAgent(userAgent);

    return isEqual(device, 'mobile') ? null : isEqual(device, 'desktop') ? (
      <Fragment>
        <Chat />
      </Fragment>
    ) : null;
  }
};

export default Modules;
