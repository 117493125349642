import * as yup from 'yup';

export interface SaveProjectOrProductForm {
  search?: string;
  collection?: string;
  newCollection?: string;
}

export const saveProjectOrProductFormSchema = yup.object().shape({
  search: yup.string(),
  collection: yup.string(),
  newCollection: yup.string().required('Vui lòng nhập tên bộ sưu tập'),
});
