import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { Boolean, DateTime, ID, ObjectID, String } from '../queries';

export enum RealEstateModelType {
  realEstate = 'realEstate',
  neededRealEstate = 'neededRealEstate',
  realEstateProject = 'realEstateProject',
}
export interface MakeAppointmentInput {
  contactName: String;
  phone: String;
  meetTime: DateTime;
  address: String;
  recipient: ObjectID;
  note?: String;
  relatedRealEstate: ObjectID;
  type: RealEstateModelType;
}
export interface Appointment {
  id?: ID;
  contactName?: String;
  phone?: String;
  meetTime?: DateTime;
  note?: String;
  address?: String;
  owner?: ObjectID;
  recipient?: ObjectID;
  relatedRealEstate?: ObjectID;
  deletedNote?: String;
  deletedAt?: DateTime;
  isRead?: Boolean;
  deletedBy?: ObjectID;
  createdAt?: DateTime;
  updatedAt?: DateTime;
}
export interface MutationOfAppointment extends MutationOf {
  success?: Boolean;
  msg?: String;
}

export interface MakeAppointmentToSeeHouseData {
  makeAppointmentToSeeHouse?: MutationOfAppointment;
}
export interface MakeAppointmentToSeeHouseVariables {
  appointment: MakeAppointmentInput;
}

export const MAKE_APPOINTMENT_TO_SEE_HOUSE = gql`
  mutation makeAppointmentToSeeHouse($appointment: MakeAppointmentInput!) @api(name: common) {
    makeAppointmentToSeeHouse(appointment: $appointment) {
      success
      msg
    }
  }
`;
