import { BankBorrowingForm, BankBorrowingFormCreate, typeNotarizationEnum } from 'forms';
import { isEmpty, isEqual, isObject, isUndefined, map, omitBy } from 'lodash';
import moment from 'moment';

export const convertDataInBankBorrowingForm = (formData?: BankBorrowingForm) => {
  const data: BankBorrowingFormCreate = {
    createdBy: formData?.userId,
    typeNotarization: typeNotarizationEnum.BANK_LOANS,
    bank: {
      id: formData?.bankName?.value,
      name: formData?.bankName?.label,
    },
    provinceOffice: {
      id: formData?.provinceRegisterBank?.value,
      name: formData?.provinceRegisterBank?.label,
    },
    districtOffice: !isEmpty(formData?.districtRegisterBank)
      ? {
          id: formData?.districtRegisterBank?.value,
          name: formData?.districtRegisterBank?.label,
        }
      : undefined,
    idNotaryOffice: formData?.branch?.value,
    namePersonLoan: formData?.fullNameBorrower,
    birthPersonLoan: !isEmpty(formData?.dateOfBirth)
      ? moment(formData?.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : undefined,
    genderPersonLoan: formData?.gender,
    nationalityPersonLoan: { name: 'Việt Nam' },
    idCardPersonLoan: formData?.taxIBorrower,
    province: {
      id: formData?.province?.value,
      name: formData?.province?.label,
    },
    district: {
      id: formData?.district?.value,
      name: formData?.district?.label,
    },
    ward: !isEmpty(formData?.ward)
      ? {
          id: formData?.ward?.value,
          name: formData?.ward?.label,
        }
      : undefined,
    street: {
      id: formData?.street?.value,
      name: formData?.street?.label,
    },
    apartmentNumber: !isEmpty(formData?.houseNumber) ? formData?.houseNumber : undefined,
    phonePersonLoan: formData?.phoneNumberBorrower,
    emailPersonLoan: !isEmpty(formData?.emailBorrower) ? formData?.emailBorrower : undefined,
    packageLoan: formData?.borrowedAmount?.value,
    purposeLoan: formData?.purposeUseLoan?.value,
    maritalStatusPersonLoan: formData?.maritalStatus,
    infoWifeHusband: isEqual(formData?.maritalStatus, 'MARRIED')
      ? formData?.fullNameAndInformationHusbandOrWife
      : undefined,
    carrierPersonLoan: !isEmpty(formData?.profession) ? formData?.profession : undefined,
    incomePersonLoan: !isEmpty(formData?.incomePerMonth) ? formData?.incomePerMonth : undefined,
    otherIncomePersonLoan: !isEmpty(formData?.otherIncome) ? formData?.otherIncome : undefined,
    debtPersonLoan: !isEmpty(formData?.debtInformation) ? formData?.debtInformation : undefined,
    infoMorePersonLoan: !isEmpty(formData?.moreInformation) ? formData?.moreInformation : undefined,
    fullNameReferrer: !isEmpty(formData?.fullNameReferrer) ? formData?.fullNameReferrer : undefined,
    phoneReferrer: !isEmpty(formData?.phoneNumberReferrer)
      ? formData?.phoneNumberReferrer
      : undefined,
    emailReferrer: !isEmpty(formData?.emailReferrer) ? formData?.emailReferrer : undefined,
    attachmentBorrower: {
      images: map(formData?.imageBorrower, 'id'),
      attachments: map(formData?.documentBorrower, 'id'),
    },
    attachmentCertificate: {
      images: map(formData?.imageAsset, 'id'),
      attachments: map(formData?.documentAsset, 'id'),
    },
  };
  const result = omitBy(data, (value) => {
    if (isObject(value)) {
      return isEmpty(omitBy(value, isUndefined));
    }
    return isUndefined(value);
  });
  return result;
};
