import { gql } from '@apollo/client';
import { MutationOfString } from './verify-otp';

export interface VerifyForgotPasswordData {
  verifyForgotPassword?: MutationOfString;
}
export interface VerifyForgotPasswordVariables {
  authString: String;
  OTP: String;
}

export const VERIFY_FORGOT_PASSWORD = gql`
  mutation verifyForgotPassword($authString: String!, $OTP: String!) @api(name: users) {
    verifyForgotPassword(authString: $authString, OTP: $OTP) {
      msg
      success
      payload
    }
  }
`;
