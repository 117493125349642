import { SVGProps } from 'react';

const IconUnblock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.63864 15.8614C7.27704 16.4998 8.03494 17.0062 8.86905 17.3517C9.70317 17.6972 10.5972 17.875 11.5 17.875C12.4028 17.875 13.2968 17.6972 14.1309 17.3517C14.9651 17.0062 15.723 16.4998 16.3614 15.8614C16.9998 15.223 17.5062 14.4651 17.8517 13.6309C18.1972 12.7968 18.375 11.9028 18.375 11C18.375 10.0972 18.1972 9.20317 17.8517 8.36905C17.5062 7.53494 16.9998 6.77704 16.3614 6.13864C15.072 4.84933 13.3234 4.125 11.5 4.125C9.67664 4.125 7.92795 4.84933 6.63864 6.13864C5.34933 7.42795 4.625 9.17664 4.625 11C4.625 12.8234 5.34933 14.572 6.63864 15.8614ZM8.29703 15.2831C9.32694 16.0539 10.6002 16.4277 11.8833 16.3359C13.1664 16.244 14.3735 15.6927 15.2831 14.7831C16.1927 13.8735 16.744 12.6664 16.8359 11.3833C16.9277 10.1002 16.5539 8.82694 15.7831 7.79703L13.6603 9.91987C13.5162 10.059 13.3232 10.136 13.1229 10.1343C12.9227 10.1325 12.7311 10.0522 12.5894 9.91056C12.4478 9.76893 12.3675 9.57734 12.3657 9.37705C12.364 9.17676 12.441 8.98381 12.5801 8.83974L14.7022 6.7169C13.6721 5.94814 12.3995 5.57605 11.1175 5.66873C9.83551 5.76142 8.62974 6.31267 7.72089 7.22161C6.81205 8.13054 6.26092 9.33637 6.16837 10.6184C6.07582 11.9004 6.44804 13.1729 7.2169 14.203L9.33974 12.0801C9.48381 11.941 9.67676 11.864 9.87705 11.8657C10.0773 11.8675 10.2689 11.9478 10.4106 12.0894C10.5522 12.2311 10.6325 12.4227 10.6343 12.6229C10.636 12.8232 10.559 13.0162 10.4199 13.1603L8.29703 15.2831Z'
      fill='currentColor'
    />
  </svg>
);
export { IconUnblock };
