import { SVGProps } from 'react';

const IconCertificateLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M17.7778 3.33301H2.22223C1.92754 3.33301 1.64493 3.45007 1.43655 3.65844C1.22818 3.86682 1.11111 4.14943 1.11111 4.44412V15.5552C1.11111 15.8499 1.22818 16.1325 1.43655 16.3409C1.64493 16.5493 1.92754 16.6663 2.22223 16.6663H10.5556L10.8722 16.2775L11.3889 15.6441L11.3389 15.5552H2.22223V4.44412H17.7778V9.19967C18.1998 9.47289 18.575 9.81245 18.8889 10.2052V4.44412C18.8889 4.14943 18.7718 3.86682 18.5635 3.65844C18.3551 3.45007 18.0725 3.33301 17.7778 3.33301Z'
      fill='currentColor'
    />
    <path d='M3.88889 6.66699H13.3333V7.55588H3.88889V6.66699Z' fill='currentColor' />
    <path d='M3.88889 8.88867H10V9.77756H3.88889V8.88867Z' fill='currentColor' />
    <path d='M3.88889 12.7773H9.44444V13.6662H3.88889V12.7773Z' fill='currentColor' />
    <path
      d='M15.2556 9.57238C14.5446 9.56673 13.8486 9.77568 13.2583 10.1719C12.668 10.5682 12.2109 11.1333 11.9469 11.7934C11.6829 12.4535 11.6241 13.1779 11.7783 13.8719C11.9325 14.5659 12.2924 15.1973 12.8111 15.6835L11.7334 17.0002L12.2334 19.0057L14.2667 16.5279C14.9103 16.7182 15.5953 16.7182 16.2389 16.5279L18.2722 19.0057L18.7722 17.0002L17.6945 15.6835C18.2132 15.1973 18.5731 14.5659 18.7273 13.8719C18.8815 13.1779 18.8227 12.4535 18.5587 11.7934C18.2947 11.1333 17.8376 10.5682 17.2473 10.1719C16.657 9.77568 15.9609 9.56673 15.25 9.57238H15.2556ZM15.2556 15.5057C14.7835 15.4991 14.3239 15.3531 13.9345 15.0861C13.5451 14.819 13.2434 14.4428 13.0673 14.0047C12.8912 13.5667 12.8485 13.0863 12.9446 12.624C13.0407 12.1618 13.2714 11.7383 13.6075 11.4067C13.9437 11.0752 14.3704 10.8504 14.8339 10.7607C15.2974 10.671 15.7772 10.7203 16.2128 10.9025C16.6483 11.0846 17.0204 11.3915 17.282 11.7845C17.5437 12.1775 17.6833 12.6391 17.6834 13.1113C17.6775 13.7503 17.4188 14.361 16.9638 14.8097C16.5088 15.2584 15.8946 15.5087 15.2556 15.5057Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCertificateLine };
