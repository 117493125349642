import { SVGProps } from 'react';

const IconTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_108_25795)'>
      <path
        d='M6 7.27539V8.62539H7.5V7.27539H6ZM16.5 7.27539V8.62539H18V7.27539H16.5ZM6 9.97539V11.3254H7.5V9.97539H6ZM16.5 9.97539V11.3254H18V9.97539H16.5ZM6 12.6754V14.0254H7.5V12.6754H6ZM16.5 12.6754V14.0254H18V12.6754H16.5ZM6 15.3754V16.7254H7.5V15.3754H6ZM16.5 15.3754V16.7254H18V15.3754H16.5Z'
        fill='currentColor'
      />
      <path
        d='M2.25 4.5C1.65326 4.5 1.08097 4.73705 0.65901 5.15901C0.237053 5.58097 0 6.15326 0 6.75L0 9C0 9.19891 0.0790176 9.38968 0.21967 9.53033C0.360322 9.67098 0.551088 9.75 0.75 9.75C1.34674 9.75 1.91903 9.98705 2.34099 10.409C2.76295 10.831 3 11.4033 3 12C3 12.5967 2.76295 13.169 2.34099 13.591C1.91903 14.0129 1.34674 14.25 0.75 14.25C0.551088 14.25 0.360322 14.329 0.21967 14.4697C0.0790176 14.6103 0 14.8011 0 15L0 17.25C0 17.8467 0.237053 18.419 0.65901 18.841C1.08097 19.2629 1.65326 19.5 2.25 19.5H21.75C22.3467 19.5 22.919 19.2629 23.341 18.841C23.7629 18.419 24 17.8467 24 17.25V15C24 14.8011 23.921 14.6103 23.7803 14.4697C23.6397 14.329 23.4489 14.25 23.25 14.25C22.6533 14.25 22.081 14.0129 21.659 13.591C21.2371 13.169 21 12.5967 21 12C21 11.4033 21.2371 10.831 21.659 10.409C22.081 9.98705 22.6533 9.75 23.25 9.75C23.4489 9.75 23.6397 9.67098 23.7803 9.53033C23.921 9.38968 24 9.19891 24 9V6.75C24 6.15326 23.7629 5.58097 23.341 5.15901C22.919 4.73705 22.3467 4.5 21.75 4.5H2.25ZM1.5 6.75C1.5 6.55109 1.57902 6.36032 1.71967 6.21967C1.86032 6.07902 2.05109 6 2.25 6H21.75C21.9489 6 22.1397 6.07902 22.2803 6.21967C22.421 6.36032 22.5 6.55109 22.5 6.75V8.325C21.6523 8.49714 20.8901 8.95705 20.3427 9.62681C19.7953 10.2966 19.4962 11.135 19.4962 12C19.4962 12.865 19.7953 13.7034 20.3427 14.3732C20.8901 15.043 21.6523 15.5029 22.5 15.675V17.25C22.5 17.4489 22.421 17.6397 22.2803 17.7803C22.1397 17.921 21.9489 18 21.75 18H2.25C2.05109 18 1.86032 17.921 1.71967 17.7803C1.57902 17.6397 1.5 17.4489 1.5 17.25V15.675C2.34772 15.5029 3.10986 15.043 3.65728 14.3732C4.20471 13.7034 4.50376 12.865 4.50376 12C4.50376 11.135 4.20471 10.2966 3.65728 9.62681C3.10986 8.95705 2.34772 8.49714 1.5 8.325V6.75Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_108_25795'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconTicket };
