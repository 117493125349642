import { gql } from '@apollo/client';
import { Direction, FromTo, Int, JSONObject, String } from './get-real-estates';
import { Slug } from './slugify';

export enum RealEstateTypeEnum {
  townhouse = 'townhouse',
  villa = 'villa',
  building = 'building',
  hotel = 'hotel',
  warehouse = 'warehouse',
  land = 'land',
  apartment = 'apartment',
  shophouse = 'shophouse',
  penthouse = 'penthouse',
  motel = 'motel',
  complex = 'complex',
  landProject = 'landProject',
  industrialArea = 'industrialArea',
  buildingProject = 'buildingProject',
  shoppingMall = 'shoppingMall',
  allProject = 'allProject',
  condotel = 'condotel',
  house = 'house',
  farm = 'farm',
  other = 'other',
}
export enum RealEstateTypeEnumType {
  condotel = 'condotel',
  townhouse = 'townhouse',
  villa = 'villa',
  building = 'building',
  hotel = 'hotel',
  warehouse = 'warehouse',
  land = 'land',
  apartment = 'apartment',
  shophouse = 'shophouse',
  penthouse = 'penthouse',
  motel = 'motel',
  house = 'house',
  farm = 'farm',
  other = 'other',
}
export enum BuildingType {
  typeA = 'typeA',
  typeB = 'typeB',
  typeC = 'typeC',
}
export enum FarmLandUseEnumType {
  industrialLand = 'industrialLand',
  agricultureLand = 'agricultureLand',
  productionLand = 'productionLand',
  commercialLand = 'commercialLand',
}
export interface SlugAddressInput {
  district?: JSONObject;
  project?: JSONObject;
  province?: JSONObject;
  street?: JSONObject;
  ward?: JSONObject;
}

export interface SlugeryData {
  slugery?: Slug;
}
export interface SlugeryVariables {
  search?: String;
  demandType?: String;
  type?: RealEstateTypeEnum[];
  address?: SlugAddressInput;
  area?: FromTo;
  price?: FromTo;
  direction?: Direction;
  width?: FromTo;
  length?: FromTo;
  hasAlley?: Boolean;
  bedroomCount?: FromTo;
  toiletCount?: FromTo;
  floorCount?: FromTo;
  postType?: Int;
  isAuthorizedRe?: Boolean;
  isForeclosure?: Boolean;
  pageNo?: String;
  parcel?: String;
}

export const SLUGERY = gql`
  query slugery(
    $search: String
    $demandType: String
    $type: [RealEstateTypeEnum]
    $address: SlugAddressInput
    $area: FromTo
    $price: FromTo
    $direction: Direction
    $width: FromTo
    $length: FromTo
    $hasAlley: Boolean
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $floorCount: FromTo
    $postType: Int
    $isAuthorizedRe: Boolean
    $isForeclosure: Boolean
    $pageNo: String
    $parcel: String
  ) @api(name: realEstate) {
    slugery(
      search: $search
      demandType: $demandType
      type: $type
      address: $address
      area: $area
      price: $price
      direction: $direction
      width: $width
      length: $length
      hasAlley: $hasAlley
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      floorCount: $floorCount
      postType: $postType
      isAuthorizedRe: $isAuthorizedRe
      isForeclosure: $isForeclosure
      pageNo: $pageNo
      parcel: $parcel
    ) {
      id
      slug
    }
  }
`;
