import { ApolloLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { chatClient } from 'apollo/client';
import { createAuthenticationLink, errorLink } from 'apollo/link';
import { CHAT_SOCKET_API_ENDPOINT } from 'globalConstants';
import { createClient } from 'graphql-ws';
import { isEqual } from 'lodash';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useAuthenticationContext } from '../authentication-provider';

interface Props {}

const isBrowser = !isEqual(typeof window, 'undefined');

const ChatContext = createContext<Props>({});

export const useChatContext = () => useContext(ChatContext);

const ChatProvider = ({ children }: PropsWithChildren) => {
  const { atmnhaAuthentication } = useAuthenticationContext();
  const { accessToken } = atmnhaAuthentication ?? {};

  useEffect(() => {
    if (accessToken && isBrowser) {
      const chatLink = new GraphQLWsLink(
        createClient({
          url: `${CHAT_SOCKET_API_ENDPOINT}/subscriptions`,
          connectionParams: {
            authToken: accessToken,
          },
        }),
      );
      const authLink = createAuthenticationLink(accessToken);
      chatClient.setLink(ApolloLink.from([errorLink, authLink, chatLink]));
    }
  }, [atmnhaAuthentication]);

  return <ChatContext.Provider value={{}}>{children}</ChatContext.Provider>;
};

export default ChatProvider;
