import {
  BuildingUtility,
  ConstructionQuality,
  DemandType,
  DepositMethodEnum,
  LandUsageEnum,
  MinusPoint,
  PaymentTypeEnum,
  PlusPoint,
  Utility,
} from 'graphql/main/mutations';
import { DEMAND, LEGAL, PROJECT_TYPE } from 'graphql/main/mutations/temp/create-post';
import {
  ApprovalStatusEnumType,
  BookingStatusTypeEnum,
  BuildingType,
  ConstructionFormEnumType,
  EquipmentsAndServicesEnumType,
  FarmLandUseEnumType,
  FixedUtilitiesEnumType,
  ForeclosureStatusEnum,
  LocalUtilitiesEnumType,
  PaymentMethodEnum,
  PaymentStatusEnum,
  ProjectEnumType,
  ProjectStatusEnum,
  Rank,
  RequestStatusEnumType,
  SourceEnum,
  StatusTransactionEnum,
  StatusUpgradeRequestEnumType,
  TradingStatus,
  TypeOfStansaction,
  UserTypeEnum,
  UtilitiesEnumType,
  VillaViewType,
  WarehouseEnumType,
  statusRegisterInProject,
} from 'graphql/main/queries';
import { TypeOfDemand } from 'graphql/map/queries';
import { values, zipObject } from 'lodash';
import {
  GenderPersonLoanEnum,
  MaritalStatusEnum,
  PackageLoanEnum,
  PurposeLoanEnum,
  PurposeUsingLandEnum,
  StatusEnum,
} from 'restful/notarization';

const vi = {
  desktop: {
    home: {
      banner: {
        slogan: {
          title: 'Kênh thông tin về bất động sản',
          subtile: 'Giúp bạn nhanh chóng tìm được ngôi nhà mà bạn luôn mong ước',
        },
        searchTool: {
          forBuy: 'Đang bán',
          forRent: 'Cho thuê',
        },
      },
    },
    detail: {
      type: 'Loại',
      fullAddress: 'Địa chỉ',
      hasAlley: 'Vị trí',
      alleyTurnCount: 'Số lần quẹo hẻm',
      frontHomeAlleyWidth: 'Độ rộng hẻm',
      width: 'Ngang',
      length: 'Dài',
      rear: 'Mặt hậu',
      area: 'Diện tích',
      price: 'Giá',
      floorCount: 'Số tầng',
      constructionArea: 'Diện tích sàn',
      bedroomCount: 'Số phòng ngủ',
      toiletCount: 'Số nhà vệ sinh',
      direction: 'Hướng',
      isNewConstruction: 'Hiện trạng',
      industryTrade: 'Ngành hàng',
      project: 'Dự án',
      hotelStar: 'Số sao',
      buildingType: 'Loại cao ốc',
      warehouseType: 'Chức năng nhà xưởng',
      block: 'Thuộc block/khu nào',
      onFloor: 'Vị trí tầng',
      clearanceArea: 'Diện tích thông thuỷ',
      investor: 'Chủ đầu tư',
      hasMezzanine: 'Có gác hay không',
      commission: 'Phí hoa hồng',
      roomCount: 'Số lượng phòng',
      hasSeaView: 'Có view biển hay không',
      warehouseRoomCount: 'Số phòng kho',
      bedRoomArea: 'Diện tích phòng ngủ',
      farmLandUse: 'Chức năng đất',
      pageNo: 'Số tờ',
      parcel: 'Số thửa',
    },
    detailProject: {
      projectType: 'Loại hình',
      expectedPrice: 'Giá bán dự kiến',
      country: 'Quốc gia',
      fullAddress: 'Địa chỉ',
      coordinates: 'Tọa độ',
      projectName: 'Tên dự án',
      projectArea: 'Tổng diện tích dự án',
      constructionProjectArea: 'Tổng diện tích xây dựng',
      projectScale: 'Quy mô dự án',
      complexProjectType: 'Loại dự án',
      totalBlock: 'Số lượng tòa nhà',
      floorCountPerBlockRange: 'Số tầng các block',
      localProjectUtilities: 'Tiện tích nội khu dự án',
      ownershipTime: 'Thời gian sở hữu',
      startYear: 'Năm khởi công',
      expectedCompletionYear: 'Thời gian hoàn thành (Dự kiến)',
      investorName: 'Chủ đầu tư',
      constructionGeneralContractor: 'Tổng thầu xây dựng',
      projectOverview: 'Tổng quan dự án',
      investment: 'Vốn đầu tư',
      standardStar: 'Chuẩn sao',
      typeAreas: 'Loại diện tích',
      totalZone: 'Tổng số khu',
      totalBackground: 'Tổng số nền',
      floorCanBuild: 'Số tầng có thể xây',
      directions: 'Hướng',
      constructionForm: 'Hình thức xây dựng',
      totalWarehouse: 'Tổng số kho bãi',
      totalWorkshops: 'Tổng số nhà xưởng toàn dự án',
      floorRange: 'Số lượng tầng',
      totalBasements: 'Số lượng hầm',
      totalAreaBuild: 'Tổng diện tích sàn xây dựng toàn dự án',
      legalLicense: 'Giấy phép pháp lý',
      legals: 'Pháp lý',
      projectLocation: 'Vị trí dự án',
      planAndModelApartment: 'Mặt bằng & căn hộ mẫu',
      paymentRoute: 'Lộ trình thanh toán',
      apartmentCount: 'Số lượng căn hộ',
      townhouseCount: 'Số lượng Nhà phố liền kề',
      independentVillaCount: 'Số lượng Biệt thự đơn lập',
      doubleVillaCount: 'Số lượng Biệt thự song lập',
      shopHouseCount: 'Số lượng Shophouse',
      condotelCount: 'Số lượng Condotel',
      offictelCount: 'Số lượng Officetel',
      penthouseCount: 'Số lượng Penthouse',
      commercialAreaCount: 'Số lượng khu thương mại',
      loanEstimate: 'Ước tính khoảng vay',
      projectUtilities: 'Tiện ích quanh dự án',
      fixedUtilities: 'Tiện ích cố định',
      utilities: 'Tiện ích',
      localUtilities: 'Tiện ích trong khu vực',
      projectViews: 'View',
      equipmentsAndServices: 'Thiết bị, dịch vụ',
      realEstateLocation: 'Vị trí bất động sản',
      realEstatesInProject: 'Các loại bất động sản trong dự án',
      projectInformation: 'Thông tin dự án',
      areaBlockInformation: 'Thông tin Block & khu',
      realEstate: {
        apartment: 'Căn hộ',
        independentVilla: 'Biệt thự đơn lập',
        townhouse: 'Nhà phố liền kề',
        doubleVilla: 'Biệt thự song lập',
        shophouse: 'Shophouse',
        condotel: 'Condotel',
        offictel: 'Offictel',
        penthouse: 'Penthouse',
        commercialArea: 'Khu thương mại',
        kiosk: 'Kiosk',
        detail: 'Thông tin chi tiết',
        bedroomCount: 'Số lượng phòng ngủ mỗi',
        bedroomArea: 'Diện tích mỗi phòng ngủ',
        toiletCount: 'Số lượng nhà vệ sinh mỗi',
        toiletArea: 'Diện tích mỗi nhà vệ sinh',
        internalRoadArea: 'Diện tích sân vườn, đường nội bộ',
        floorCount: 'Số tầng của',
        kioskCount: 'Số kiosk',
      },
      product: {
        type: 'Loại',
        fullAddress: 'Địa chỉ',
        houseNumber: 'Mã căn',
        hasAlley: 'Vị trí',
        alleyTurnCount: 'Số lần quẹo hẻm',
        frontHomeAlleyWidth: 'Độ rộng hẻm',
        width: 'Ngang',
        length: 'Dài',
        rear: 'Mặt hậu',
        area: 'Diện tích',
        price: 'Giá',
        floorCount: 'Số tầng',
        constructionArea: 'Diện tích sàn',
        bedroomCount: 'Số phòng ngủ',
        toiletCount: 'Số nhà vệ sinh',
        direction: 'Hướng',
        isNewConstruction: 'Hiện trạng',
        industryTrade: 'Ngành hàng',
        project: 'Dự án',
        hotelStar: 'Số sao',
        buildingType: 'Loại cao ốc',
        warehouseType: 'Chức năng nhà xưởng',
        block: 'Thuộc block/khu nào',
        onFloor: 'Vị trí tầng',
        clearanceArea: 'Diện tích thông thuỷ',
        investor: 'Chủ đầu tư',
        hasMezzanine: 'Có gác hay không',
        commission: 'Phí hoa hồng',
        roomCount: 'Số lượng phòng',
        hasSeaView: 'Có view biển hay không',
        warehouseRoomCount: 'Số phòng kho',
        bedRoomArea: 'Diện tích phòng ngủ',
      },
    },
    detailProduct: {
      type: 'Loại',
      fullAddress: 'Địa chỉ',
      houseNumber: 'Mã căn',
      hasAlley: 'Vị trí',
      alleyTurnCount: 'Số lần quẹo hẻm',
      frontHomeAlleyWidth: 'Độ rộng hẻm',
      width: 'Ngang',
      length: 'Dài',
      rear: 'Mặt hậu',
      area: 'Diện tích',
      price: 'Giá',
      floorCount: 'Số tầng',
      constructionArea: 'Diện tích sàn',
      bedroomCount: 'Số phòng ngủ',
      toiletCount: 'Số nhà vệ sinh',
      direction: 'Hướng',
      isNewConstruction: 'Hiện trạng',
      industryTrade: 'Ngành hàng',
      project: 'Dự án',
      hotelStar: 'Số sao',
      buildingType: 'Loại cao ốc',
      warehouseType: 'Chức năng nhà xưởng',
      block: 'Thuộc block/khu nào',
      onFloor: 'Vị trí tầng',
      clearanceArea: 'Diện tích thông thuỷ',
      investor: 'Chủ đầu tư',
      hasMezzanine: 'Có gác hay không',
      commission: 'Phí hoa hồng',
      roomCount: 'Số lượng phòng',
      hasSeaView: 'Có view biển hay không',
      warehouseRoomCount: 'Số phòng kho',
      bedRoomArea: 'Diện tích phòng ngủ',
    },
  },
  mobile: {
    home: {
      banner: {
        slogan: {
          title: 'Kênh thông tin về bất động sản',
          subtile: 'Giúp bạn nhanh chóng tìm được ngôi nhà mà bạn luôn mong ước',
        },
        searchTool: {
          searchModal: {
            forBuy: 'Đang bán',
            forRent: 'Cho thuê',
          },
        },
      },
    },
  },
  major: {
    statusProfile: zipObject(values(RequestStatusEnumType), [
      'Đã xác thực hồ sơ',
      'Đang xác thực hồ sơ',
      'Bị từ chối',
      'Chưa xác thực hồ sơ',
    ]),
    approvalStatus: zipObject(values(ApprovalStatusEnumType), [
      'Đã duyệt',
      'Đang chờ duyệt',
      'Bị từ chối',
    ]),
    tradingStatus: zipObject(values(TradingStatus), [
      'Đang giao dịch',
      'Ngưng giao dịch',
      'Tất cả',
    ]),
    b2cUserTypes: zipObject(values(UserTypeEnum), [
      'CV Môi giới',
      'Doanh nghiệp',
      'Chủ nhà',
      'Công nhân',
      'Sinh viên',
    ]),
    typeOfDemand: zipObject(values(TypeOfDemand), ['Bán', 'Cho thuê', 'Cần mua', 'Cần thuê']),
    width: {
      lessThanThree: '3 m trở xuống',
      threeToFive: '3 - 5 m',
      fiveToTen: '5 - 10 m',
      tenToTwenty: '10 - 20 m',
      twentyToForty: '20 - 40 m',
      fortyToSixty: '40 - 60 m',
      sixtyToHundred: '60 - 100 m',
      hundredToMax: '100 m trở lên',
    },
    length: {
      lessThanTen: '10 m trở xuống',
      tenToTwenty: '10 - 20 m',
      twentyToForty: '20 - 40 m',
      fortyToEighty: '40 - 80 m',
      eightyToOneHundredSixty: '80 - 160 m',
      oneHundredSixtyToThreeHundreds: '160 - 300 m',
      threeHundredsToFiveHundreds: '300 - 500 m',
      fiveHundredsToMax: '500 m trở lên',
    },
    area: {
      lessThanTen: '10 m\u00B2 trở xuống',
      tenToTwenty: '10 - 20 m\u00B2',
      twentyToForty: '20 - 40 m\u00B2',
      fortyToEighty: '40 - 80 m\u00B2',
      eightyToOneHundredSixty: '80 - 160 m\u00B2',
      oneHundredSixtyToThreeHundreds: '160 - 300 m\u00B2',
      threeHundredsToFiveHundreds: '300 - 500 m\u00B2',
      fiveHunderedsToOneThousand: '500 - 1,000 m\u00B2',
      oneThoundsanTo2Thousand: '1,000 - 2,000 m\u00B2',
      twoThousandTo3Thousand: '2,000 - 3,000 m\u00B2',
      threeThousandTo4Thousand: '3,000 - 4,000 m\u00B2',
      fourThousandTo5Thousand: '4,000 - 5,000 m\u00B2',
      fiveThousandTo20Thousand: '5,000 - 20,000 m\u00B2',
      over20Thousand: '20,000 m\u00B2 trở lên',
    },
    priceForRent: {
      lessThan1M: '1 triệu trở xuống',
      oneMTo3M: '1 triệu - 3 triệu',
      threeMTo5M: '3 triệu - 5 triệu',
      fiveMTo10M: '5 triệu - 10 triệu',
      tenMTo40M: '10 triệu - 40 triệu',
      fortyMTo70M: '40 triệu - 70 triệu',
      seventyMTo100M: '70 triệu - 100 triệu',
      oneHundredMTo200M: '100 triệu - 200 triệu',
      twoHundredsMToMax: '200 triệu trở lên',
    },
    priceForSell: {
      lessThan500M: '500 triệu trở xuống',
      fiveHundredsMTo800Mil: '500 triệu - 800 triệu',
      eightHundredsMTo1B: '800 triệu - 1 tỷ',
      oneBTo2B: '1 tỷ - 2 tỷ',
      twoBTo3B: '2 tỷ - 3 tỷ',
      threeBTo5B: '3 tỷ - 5 tỷ',
      fiveBTo7B: '5 tỷ - 7 tỷ',
      sevenBTo10B: '7 tỷ - 10 tỷ',
      tenBTo20B: '10 tỷ - 20 tỷ',
      twentyBToThirtyB: '20 tỷ - 30 tỷ',
      thirtyBToFiftyB: '30 tỷ - 50 tỷ',
      fiftyBToEightyB: '50 tỷ - 80 tỷ',
      eightyBToHundredB: '80 tỷ - 100 tỷ',
      hundredBToMax: '100 tỷ trở lên',
    },
    bedroomCount: {
      lessThanThreeFloors: '3 phòng trở xuống',
      threeToFiveFloors: '3 - 5 phòng',
      fiveToTenFloors: '5 - 10 phòng',
      tenToTwentyFloors: '10 - 20 phòng',
      twentyToFortyFloors: '20 - 40 phòng',
      fortyToSixtyFloors: '40 - 60 phòng',
      sixtyToEightyFloors: '60 - 80 phòng',
      eightyToHundredFloors: '80 - 100 phòng',
      hundredToHundredTwentyFloors: '100 - 120 phòng',
      hundredTwentyToHundredFortyFloors: '120 - 140 phòng',
      hundredFortyToHundredSixtyFloors: '140 - 160 phòng',
      hundredSixtyToHundredSixtyEightFloors: '160 - 168+ phòng',
    },
    toiletCount: {
      lessThanThreeFloors: '3 phòng trở xuống',
      threeToFiveFloors: '3 - 5 phòng',
      fiveToTenFloors: '5 - 10 phòng',
      tenToTwentyFloors: '10 - 20 phòng',
      twentyToFortyFloors: '20 - 40 phòng',
      fortyToSixtyFloors: '40 - 60 phòng',
      sixtyToEightyFloors: '60 - 80 phòng',
      eightyToHundredFloors: '80 - 100 phòng',
      hundredToHundredTwentyFloors: '100 - 120 phòng',
      hundredTwentyToHundredFortyFloors: '120 - 140 phòng',
      hundredFortyToHundredSixtyFloors: '140 - 160 phòng',
      hundredSixtyToHundredSixtyEightFloors: '160 - 168+ phòng',
    },
    floorCount: {
      lessThanThreeFloors: '3 tầng trở xuống',
      threeToFiveFloors: '3 - 5 tầng',
      fiveToTenFloors: '5 - 10 tầng',
      tenToTwentyFloors: '10 - 20 tầng',
      twentyToFortyFloors: '20 - 40 tầng',
      fortyToSixtyFloors: '40 - 60 tầng',
      sixtyToEightyFloors: '60 - 80 tầng',
      eightyToHundredFloors: '80 - 100 tầng',
      hundredToHundredTwentyFloors: '100 - 120 tầng',
      hundredTwentyToHundredFortyFloors: '120 - 140 tầng',
      hundredFortyToHundredSixtyFloors: '140 - 160 tầng',
      hundredSixtyToHundredSixtyEightFloors: '160 - 168+ tầng',
    },
    postTypes: {
      3: 'Tin thường',
      2: 'Tin VIP',
      1: 'Tin VIP đặc biệt',
    },
    isForSell: {
      true: 'Bán',
      false: 'Cho thuê',
    },
    demand: {
      sell: 'Bán',
      rent: 'Cho thuê',
    },
    hasAlley: {
      true: 'Hẻm',
      false: 'Mặt tiền',
    },
    direction: {
      Dong: 'Đông',
      DongBac: 'Đông Bắc',
      DongNam: 'Đông Nam',
      DongTuTrach: 'Đông Tứ Trạch',
      Nam: 'Nam',
      Tay: 'Tây',
      Bac: 'Bắc',
      TayBac: 'Tây Bắc',
      TayNam: 'Tây Nam',
      TayTuTrach: 'Tây Tứ Trạch',
    },
    forSellRealEstateType: {
      townhouse: 'Nhà mặt phố',
      house: 'Nhà riêng',
      villa: 'Biệt thự, nhà liền kề',
      building: 'Cao ốc, văn phòng',
      hotel: 'Khách sạn',
      warehouse: 'Nhà xưởng, kho, kiot',
      land: 'Đất nền',
      apartment: 'Căn hộ chung cư',
      shophouse: 'Shophouse',
      penthouse: 'Penthouse',
      condotel: 'Condotel',
      farm: 'Trang trại, khu nghỉ dưỡng',
      other: 'Nhà đất khác',
    },
    forRentRealEstateType: {
      townhouse: 'Nhà mặt phố',
      house: 'Nhà riêng',
      villa: 'Biệt thự, nhà liền kề',
      building: 'Cao ốc, văn phòng',
      hotel: 'Khách sạn',
      warehouse: 'Nhà xưởng, kho, kiot',
      land: 'Đất nền',
      apartment: 'Căn hộ chung cư',
      shophouse: 'Shophouse',
      penthouse: 'Penthouse',
      motel: 'Phòng trọ',
      condotel: 'Condotel',
      farm: 'Trang trại, khu nghỉ dưỡng',
      other: 'Nhà đất khác',
    },
    warehouseType: zipObject(values(WarehouseEnumType), [
      'Xưởng công nghiệp',
      'Xưởng nông nghiệp',
      'Xưởng thực phẩm',
      'Xưởng gia công',
      'Xưởng sản xuất',
      'Xưởng kho',
    ]),
    buildingType: zipObject(values(BuildingType), ['Loại A', 'Loại B', 'Loại C']),
    isNewConstruction: {
      true: 'Xây mới',
      false: 'Xây cũ',
    },
    fixedUtilities: zipObject(values(FixedUtilitiesEnumType), [
      'Phòng cháy chữa cháy',
      'Tổng đài',
      'Bệnh viện',
      'Taxi',
      'Công an phường',
      'Trung tâm thể thao',
      'Điện lực',
    ]),
    utilities: zipObject(values(UtilitiesEnumType), [
      'Hồ bơi',
      'Nhà kho',
      'Sân vườn',
      'Thang máy',
      'Thẻ ra vào tòa nhà',
    ]),
    localUtilities: zipObject(values(LocalUtilitiesEnumType), [
      'Bãi đỗ xe',
      'Chợ',
      'Bệnh viện',
      'Công viên',
      'Hiệu thuốc',
      'Ngân hàng',
      'Nhà hàng',
      'Siêu thị',
      'Phòng gym',
      'Cà phê',
      'Trung tâm mua sắm',
    ]),
    villaViewTypes: zipObject(values(VillaViewType), [
      'View Biển',
      'View Phố',
      'View Núi',
      'View nội khu',
      'View Đồi',
    ]),
    equipmentsAndServices: zipObject(values(EquipmentsAndServicesEnumType), [
      'Hệ thống liên lạc tòa nhà',
      'Máy nước nóng',
      'Thiết bị nhà bếp',
      'Tủ lạnh',
      'Máy giặt',
      'Thiết bị âm thanh',
      'Tivi',
      'Máy lạnh',
    ]),
    legals: {
      fj6CRrbDaN: 'Sổ đỏ',
      '5RFyDzsR4F': 'Sổ hồng',
      '2CuWnD9Qdx': 'Bằng khoán',
      NHYzxq89wW: 'Đang thế chấp',
      '4qeqABnYbz': 'Ngân hàng chấp thuận cho vay',
      Z8SRwDegQv: 'Đang chờ bàn giao sổ',
      sq8BYh834D: 'Có hợp đồng thuê',
      RkGsFSqcS2: 'Có đặt cọc',
      zagg65MEwh: 'Có hợp đồng giữ xe',
      YZpgh54N8A: 'Có đăng ký tạm trú, tạm vắng',
    },
    hasMezzanine: {
      true: 'Có',
      false: 'Không',
    },
    constructQualities: zipObject(values(ConstructionQuality), [
      'Xây thô',
      'Đơn giản',
      'Tiện nghi',
      'Sang trọng',
      'Cực kỳ cao cấp',
    ]),
    constructionUtilities: zipObject(values(Utility), [
      'Có hồ bơi',
      'Có lửng',
      'Có thang máy',
      'Có tầng hầm',
    ]),
    plusPoints: zipObject(values(PlusPoint), [
      'Có hẻm sau nhà',
      'Căn góc, có hẻm bên hông',
      'Gần công viên, trung tâm giải trí (<500m)',
      'Hẻm thông ra đường khác đẹp hơn',
      'Tiện làm quán cafe, nhà hàng, khách sạn',
      'Vị trí đẹp nhất trong đoạn đường',
      'Có 2 mặt đường chính',
      'Khu dân cư cao cấp có cổng bảo vệ',
      'Gần chợ, siêu thị (<500m)',
    ]),
    minusPoints: zipObject(values(MinusPoint), [
      'Đường, hẻm đâm thẳng vào nhà',
      'Có cống trước nhà',
      'Có cây lớn trước nhà',
      'Bị quy hoạch treo',
      'Dưới chân cầu hoặc dưới đường dây điện cao thế',
      'Đối diện hoặc gần sát chùa, nhà thờ',
      'Có trụ điện trước nhà',
      'Đối diện hoặc gần sát nhà tang lễ, nhà xác',
    ]),
    landUsage: zipObject(values(LandUsageEnum), [
      'Đất ở',
      'Đất rừng sản xuất',
      'Đất vườn',
      'Đất nuôi trồng thủy sản',
      'Đất nông nghiệp',
      'Đất thương mại dịch vụ',
      'Đất trồng cây lâu năm',
      'Đất giáo dục',
    ]),
    buildingUtility: zipObject(values(BuildingUtility), ['Có hồ bơi', 'Có thang máy']),
    farmLandUse: zipObject(values(FarmLandUseEnumType), [
      'Đất công nghiệp',
      'Đất nông nghiệp',
      'Đất sản xuất kinh doanh',
      'Đất thương mại dịch vụ',
    ]),
    projectStatus: zipObject(values(ProjectStatusEnum), [
      'Đang mở bán',
      'Đang khởi công móng',
      'Đã hoàn thành 50%',
      'Đã cất nóc',
    ]),
    saleStatus: {
      onSale: 'Đang bán',
      reserved: 'Giữ chỗ',
      confirmed: 'Đã đặt chỗ',
      sold: 'Đã bán',
      transferred: 'Đã bàn giao',
      notOnSale: 'Chưa mở bán',
      stopSelling: 'Đã ngưng bán',
    },
    rentStatus: {
      renting: 'Đang cho thuê',
      rented: 'Đã cho thuê',
    },
    projectTotalArea: {
      under1000m2: '1,000 m\u00B2 trở xuống',
      '1000m2to2000m2': '1,000 - 2,000 m\u00B2',
      '2000m2to3000m2': '2,000 - 3,000 m\u00B2',
      '3000m2to4000m2': '3,000 - 4,000 m\u00B2',
      '4000m2to4000m2': '4,000 - 5,000 m\u00B2',
      over5000m2: '5,000 m\u00B2 trở lên',
    },
    projectLegals: {
      ownershipCertificate: 'Sổ đỏ',
      bankSupport: 'Có ngân hàng hỗ trợ vay',
      followInvestorContract: 'Mua bán theo hợp đồng chủ đầu tư',
      followContributionContract: 'Mua bán theo hợp đồng góp vốn',
      other: 'Khác',
    },
    projectType: zipObject(values(ProjectEnumType), [
      'Dự án phức hợp',
      'Dự án đất nền',
      'Dự án khu công nghiệp',
      'Dự án cao ốc văn phòng',
      'Dự án trung tâm thương mại',
      'Dự án nhà ở xã hội',
    ]),
    priceRange: {
      under500: '500 nghìn/m\u00B2 trở xuống',
      '500to1000': '500 nghìn - 1 triệu/m\u00B2',
      '1000to2000': '1 - 2 triệu/m\u00B2',
      '2000to3000': '2 - 3 triệu/m\u00B2',
      '3000to4000': '4 - 5 triệu/m\u00B2',
      '5000to10000': '5 - 10 triệu/m\u00B2',
      '10000to20000': '10 - 20 triệu/m\u00B2',
      '20000to30000': '20 - 30 triệu/m\u00B2',
      '30000to50000': '30 - 50 triệu/m\u00B2',
      '50000to70000': '50 - 70 triệu/m\u00B2',
      '70000to100000': '70 - 100 triệu/m\u00B2',
      '100000to200000': '100 - 200 triệu/m\u00B2',
      '200000to300000': '200 - 300 triệu/m\u00B2',
      '300000to500000': '300 - 500 triệu/m\u00B2',
      over500000: '500 triệu/m\u00B2 trở lên',
    },
    complexProjectType: {
      complex: 'Dự án phức hợp',
      socialHouse: 'Dự án nhà ở xã hội',
      newUrban: 'Dự án khu đô thị mới',
      resort: 'Dự án nghỉ dưỡng',
    },
    constructionForm: zipObject(values(ConstructionFormEnumType), [
      'Xây cất tự do',
      'Xây cất theo quy định của chủ đầu tư',
    ]),
    projectViews: {
      seaView: 'View biển',
      cityView: 'View phố',
      mountainView: 'View đồi',
      localView: 'View nội khu',
    },
    paymentType: zipObject(values(PaymentTypeEnum), [
      'Thanh toán đặt chỗ',
      'Thanh toán đặt cọc',
      'Thanh toán theo giai đoạn',
      'Tất toán 98% hợp đồng',
      'Bàn giao sổ và nhận 2% còn lại ',
    ]),
    bookingStatus: zipObject(values(BookingStatusTypeEnum), [
      'Đang chờ duyệt',
      'Đang chờ duyệt',
      'Bị từ chối',
      'Đã khóa',
      'Đã duyệt',
    ]),
    seeHouseDemand: zipObject(values(DemandType), [
      'Xem nhà trực tiếp',
      'Xem thông tin pháp lý',
      'Xem nhà livestream',
      'Nhận tài liệu dự án',
    ]),
    statusRegisterInProject: zipObject(values(statusRegisterInProject), [
      'Đã xác nhận',
      'Đang chờ xử lí',
      'Đã xem nhà',
      'Đã hủy hẹn',
      'Bỏ xem nhà',
    ]),
    rank: zipObject(values(Rank), ['Cơ bản', 'Vàng', 'Kim cương']),
    statusTransaction: zipObject(values(StatusTransactionEnum), [
      'Đã nhận thanh toán',
      'Lỗi thanh toán - thất bại',
      'Đang chờ thanh toán',
      'Đã từ chối',
    ]),
    statusUpgradeRequest: zipObject(values(StatusUpgradeRequestEnumType), [
      'Đang xử lý',
      'Đã duyệt',
      'Đã từ chối',
    ]),
    paymentMethod: zipObject(values(PaymentMethodEnum), [
      'Cổng thanh toán',
      'Chuyển khoản ngân hàng',
      'Offline',
    ]),
    paymentAmount: zipObject(values(Rank), [0, 500000, 5000000]),
    statusEnum: zipObject(values(StatusEnum), [
      'Chờ xử lý',
      'Đang chờ duyệt',
      'Đã duyệt',
      'Đã từ chối',
    ]),
    foreClosureStatus: zipObject(values(ForeclosureStatusEnum), [
      'Chờ xử lý',
      'Đang chờ duyệt',
      'Đã duyệt',
      'Đã từ chối',
    ]),
    maritalStatus: zipObject(values(MaritalStatusEnum), ['Độc thân', 'Đã kết hôn']),
    genderPersonLoan: zipObject(values(GenderPersonLoanEnum), ['Nam', 'Nữ']),
    packageLoan: zipObject(values(PackageLoanEnum), [
      'Vay tín chấp',
      'Vay thế chấp',
      'Vay thấu chi',
      'Vay trả góp',
    ]),
    purposeLoan: zipObject(values(PurposeLoanEnum), [
      'Vay mua bất động sản',
      'Vay mua ô tô',
      'Vay tiêu dùng có tài sản đảm bảo',
      'Vay tiêu dùng không có tài sản đảm bảo',
      'Vay vốn hộ kinh doanh',
      'Vay xây sửa nhà',
      'Vay du học',
      'Sản phẩm khác',
    ]),
    typeOfWithdrawal: {
      CreateNewSellRE: 'Đăng tin',
      RenewSellRE: 'Gia hạn tin',
    },
    purposeUsingLand: zipObject(values(PurposeUsingLandEnum), [
      'Đất nông nghiệp',
      'Đất lâm nghiệp',
      'Đất chuyên dùng',
      'Đất xây dựng cơ sở khoa học và công nghệ',
      'Đất có mục đích công cộng',
      'Đất công trình công cộng khác',
      'Đất có mặt nước ven biển (quan sát)',
      'Đất sản xuất nông nghiệp',
      'Đất rừng sản xuất',
      'Đất xây dựng trụ sở cơ quan',
      'Đất xây dựng cơ sở ngoại giao',
      'Đất giao thông',
      'Đất cơ sở tôn giáo',
      'Đất mặt nước ven biển nuôi trồng thủy sản',
      'Đất trồng cây hàng năm',
      'Đất rừng phòng hộ',
      'Đất quốc phòng',
      'Đất xây dựng công trình sự nghiệp khác',
      'Đất thủy lợi',
      'Đất cơ sở tín ngưỡng',
      'Đất mặt nước ven biển có rừng',
      'Đất trồng lúa',
      'Đất rừng đặc dụng',
      'Đất an ninh',
      'Đất sản xuất, kinh doanh phi nông nghiệp',
      'Đất có di tích lịch sử - văn hóa',
      'Đất nghĩa trang, nghĩa địa, nhà tang lễ, nhà hỏa táng',
      'Đất mặt nước ven biển có mục đích khác',
      'Đất chuyên trồng lúa nước',
      'Đất nuôi trồng thuỷ sản',
      'Đất xây dựng công trình sự nghiệp',
      'Đất khu công nghiệp',
      'Đất danh lam thắng cảnh',
      'Đất sông, ngòi, kênh, rạch, suối',
      'Đất phát triển hạ tầng',
      'Đất trồng lúa nước còn lại',
      'Đất làm muối',
      'Đất xây dựng trụ sở tổ chức sự nghiệp',
      'Đất cụm công nghiệp',
      'Đất sinh hoạt cộng đồng',
      'Đất có mặt nước chuyên dùng',
      'Đất hỗn hợp',
      'Đất trồng lúa nương',
      'Đất nông nghiệp khác',
      'Đất xây dựng cơ sở văn hóa',
      'Đất khu chế xuất',
      'Đất khu vui chơi, giải trí công cộng',
      'Đất phi nông nghiệp khác',
      'Đất cây xanh đô thị',
      'Đất trồng cây hàng năm khác',
      'Đất phi nông nghiệp',
      'Đất xây dựng cơ sở dịch vụ xã hội',
      'Đất thương mại, dịch vụ',
      'Đất công trình năng lượng',
      'Đất chưa sử dụng',
      'Đất cây xanh đơn vị ở',
      'Đất bằng trồng cây hàng năm khác',
      'Đất ở',
      'Đất xây dựng cơ sở y tế',
      'Đất cơ sở sản xuất phi nông nghiệp',
      'Đất công trình bưu chính, viễn thông',
      'Đất bằng chưa sử dụng',
      'Đất cây xanh chuyên đề',
      'Đất nương rẫy trồng cây hàng năm khác',
      'Đất ở tại nông thôn',
      'Đất xây dựng cơ sở giáo dục và đào tạo',
      'Đất sử dụng cho hoạt động khoáng sản',
      'Đất chợ',
      'Đất đồi núi chưa sử dụng',
      'Đất cây xanh cách ly',
      'Đất trồng cây lâu năm',
      'Đất ở tại đô thị',
      'Đất xây dựng cơ sở thể dục thể thao',
      'Đất sản xuất vật liệu xây dựng, làm đồ gốm',
      'Đất bãi thải, xử lý chất thải',
      'Núi đá không có rừng cây',
    ]),
    typeOfTransaction: zipObject(values(TypeOfStansaction), [
      'Nạp tiền',
      'Đăng tin',
      'Gia hạn tin đăng bán',
    ]),
    financePaymentStatus: zipObject(values(PaymentStatusEnum), [
      'Đang chờ thanh toán',
      'Lỗi thanh toán - thất bại',
      'Đã nhận thanh toán',
      'Đã từ chối',
    ]),
    financeSource: zipObject(values(SourceEnum), ['Ví chính', 'Ví thưởng', 'Ví hoa hồng']),
    financePaymentMethod: zipObject(values(DepositMethodEnum), [
      'Cổng thanh toán',
      'Thanh toán ngân hàng',
    ]),
  },
  social: {
    forSellRealEstateType: {
      townhouse: 'Nhà mặt phố',
      house: 'Nhà riêng',
      villa: 'Biệt thự, nhà liền kề',
      building: 'Cao ốc, văn phòng',
      hotel: 'Khách sạn',
      warehouse: 'Nhà xưởng, kho, kiot',
      land: 'Đất nền',
      apartment: 'Căn hộ chung cư',
      shophouse: 'Shophouse',
      penthouse: 'Penthouse',
      condotel: 'Condotel',
      farm: 'Trang trại, khu nghỉ dưỡng',
      other: 'Nhà đất khác',
    },
    forRentRealEstateType: {
      townhouse: 'Nhà mặt phố',
      house: 'Nhà riêng',
      villa: 'Biệt thự, nhà liền kề',
      building: 'Cao ốc, văn phòng',
      hotel: 'Khách sạn',
      warehouse: 'Nhà xưởng, kho, kiot',
      land: 'Đất nền',
      apartment: 'Căn hộ chung cư',
      shophouse: 'Shophouse',
      penthouse: 'Penthouse',
      motel: 'Phòng trọ',
      condotel: 'Condotel',
      farm: 'Trang trại, khu nghỉ dưỡng',
      other: 'Nhà đất khác',
    },
    projectType: zipObject(values(PROJECT_TYPE), [
      'Dự án phức hợp',
      'Dự án đất nền',
      'Dự án cao ốc văn phòng',
      'Dự án khu công nghiệp',
      'Dự án trung tâm thương mại',
    ]),
    legal: zipObject(values(LEGAL), [
      'Mua bán theo hợp đồng chủ đầu tư',
      'Sổ hồng',
      'Có ngân hàng hỗ trợ vay',
      'Mua bán theo hợp đồng góp vốn',
    ]),
    demand: zipObject(values(DEMAND), ['Bán', 'Cho thuê', 'Cần thuê', 'Cần mua']),
    userSocialTypes: {
      Broker: 'CV Môi giới',
      Company: 'Doanh nghiệp',
      HomeOwner: 'Chủ nhà',
      Employee: 'Công nhân',
      Student: 'Sinh viên',
    },
    hasAlley: {
      true: 'Hẻm',
      false: 'Mặt tiền',
    },
    direction: {
      Dong: 'Đông',
      DongBac: 'Đông Bắc',
      DongNam: 'Đông Nam',
      DongTuTrach: 'Đông Tứ Trạch',
      Nam: 'Nam',
      Tay: 'Tây',
      Bac: 'Bắc',
      TayBac: 'Tây Bắc',
      TayNam: 'Tây Nam',
      TayTuTrach: 'Tây Tứ Trạch',
    },
    gender: { MALE: 'Nam', FEMALE: 'Nữ' },
    location: {
      all: 'Tất cả',
      noAlley: 'Mặt tiền',
      hasAlley: 'Hẻm',
    },
  },
};

export default vi;
