import { SVGProps } from 'react';

const IconBookmarkOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M14.1667 15L10.0001 13.1833L5.83341 15V4.16667H14.1667V15ZM14.1667 2.5H5.83341C5.39139 2.5 4.96746 2.67559 4.6549 2.98816C4.34234 3.30072 4.16675 3.72464 4.16675 4.16667V17.5L10.0001 15L15.8334 17.5V4.16667C15.8334 3.24167 15.0834 2.5 14.1667 2.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconBookmarkOutline };
