interface LabelValue {
  value?: string;
  label?: string;
}
export interface NotarizationConfigData {
  purposeLoan?: LabelValue[];
  packageLoan?: LabelValue[];
  gender?: LabelValue[];
  typeNotarization?: LabelValue[];
  status?: LabelValue[];
  statusMarital?: LabelValue[];
  source?: LabelValue[];
  purposeUsingLand?: LabelValue[];
}
export interface NotarizationConfigResponse {
  data?: NotarizationConfigData;
}
export type FluctuationData = NotarizationData;
export type FLuctuationsResponse = NotarizationsResponse;
export enum PackageLoanEnum {
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  COLLATERAL_LOAN = 'COLLATERAL_LOAN',
  CASH_ADVANCE = 'CASH_ADVANCE',
  INSTALLMENT_LOAN = 'INSTALLMENT_LOAN',
}
export enum PurposeLoanEnum {
  REAL_ESTATE_PURCHASE = 'REAL_ESTATE_PURCHASE',
  CAR_LOAN = 'CAR_LOAN',
  SECURED_CONSUMER_LOAN = 'SECURED_CONSUMER_LOAN',
  UNSECURED_CONSUMER_LOAN = 'UNSECURED_CONSUMER_LOAN',
  BUSINESS_CAPITAL_LOAN = 'BUSINESS_CAPITAL_LOAN',
  HOME_CONSTRUCTION_RENOVATION_LOAN = 'HOME_CONSTRUCTION_RENOVATION_LOAN',
  EDUCATION_LOAN = 'EDUCATION_LOAN',
  OTHER_PRODUCTS = 'OTHER_PRODUCTS',
}
export enum MaritalStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
}
export enum GenderPersonLoanEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
export interface Attachments {
  images?: string[];
  attachments?: string[];
}
export enum StatusEnum {
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum SourceEnum {
  ATMNHA = 'ATMNHA',
  INTERNAL = 'INTERNAL',
}
export enum PurposeUsingLandEnum {
  AGRICULTURAL_LAND = 'AGRICULTURAL_LAND',
  FORESTRY_LAND = 'FORESTRY_LAND',
  SPECIAL_USE_LAND = 'SPECIAL_USE_LAND',
  SCIENTIFIC_AND_TECHNOLOGICAL_CONSTRUCTION_LAND = 'SCIENTIFIC_AND_TECHNOLOGICAL_CONSTRUCTION_LAND',
  PUBLIC_LAND = 'PUBLIC_LAND',
  OTHER_PUBLIC_CONSTRUCTION_LAND = 'OTHER_PUBLIC_CONSTRUCTION_LAND',
  COASTAL_LAND_OBSERVATION = 'COASTAL_LAND_OBSERVATION',
  AGRICULTURAL_PRODUCTION_LAND = 'AGRICULTURAL_PRODUCTION_LAND',
  FOREST_PRODUCTION_LAND = 'FOREST_PRODUCTION_LAND',
  HEADQUARTERS_LAND = 'HEADQUARTERS_LAND',
  FOREIGN_RELATIONS_LAND = 'FOREIGN_RELATIONS_LAND',
  TRANSPORTATION_LAND = 'TRANSPORTATION_LAND',
  RELIGIOUS_LAND = 'RELIGIOUS_LAND',
  COASTAL_WATER_LAND_AQUACULTURE = 'COASTAL_WATER_LAND_AQUACULTURE',
  ANNUAL_CROP_LAND = 'ANNUAL_CROP_LAND',
  PROTECTIVE_FOREST_LAND = 'PROTECTIVE_FOREST_LAND',
  DEFENSE_LAND = 'DEFENSE_LAND',
  OTHER_PROJECT_CONSTRUCTION_LAND = 'OTHER_PROJECT_CONSTRUCTION_LAND',
  IRRIGATION_LAND = 'IRRIGATION_LAND',
  RELIGIOUS_FOUNDATION_LAND = 'RELIGIOUS_FOUNDATION_LAND',
  COASTAL_FOREST_LAND = 'COASTAL_FOREST_LAND',
  PADDY_LAND = 'PADDY_LAND',
  DESIGNATED_FOREST_LAND = 'DESIGNATED_FOREST_LAND',
  SECURITY_LAND = 'SECURITY_LAND',
  NON_AGRICULTURAL_PRODUCTION_BUSINESS_LAND = 'NON_AGRICULTURAL_PRODUCTION_BUSINESS_LAND',
  HISTORICAL_CULTURAL_LAND = 'HISTORICAL_CULTURAL_LAND',
  CEMETERY_LAND = 'CEMETERY_LAND',
  OTHER_COASTAL_WATER_LAND = 'OTHER_COASTAL_WATER_LAND',
  RICE_PADDY_LAND = 'RICE_PADDY_LAND',
  AQUACULTURE_LAND = 'AQUACULTURE_LAND',
  PROJECT_CONSTRUCTION_LAND = 'PROJECT_CONSTRUCTION_LAND',
  INDUSTRIAL_ZONE_LAND = 'INDUSTRIAL_ZONE_LAND',
  SCENIC_LAND = 'SCENIC_LAND',
  RIVER_STREAM_CHANNEL_LAND = 'RIVER_STREAM_CHANNEL_LAND',
  INFRASTRUCTURE_DEVELOPMENT_LAND = 'INFRASTRUCTURE_DEVELOPMENT_LAND',
  REMAINING_PADDY_LAND = 'REMAINING_PADDY_LAND',
  SALT_MAKING_LAND = 'SALT_MAKING_LAND',
  ORGANIZATION_HEADQUARTERS_LAND = 'ORGANIZATION_HEADQUARTERS_LAND',
  INDUSTRIAL_CLUSTER_LAND = 'INDUSTRIAL_CLUSTER_LAND',
  COMMUNITY_LAND = 'COMMUNITY_LAND',
  SPECIFIC_WATER_LAND = 'SPECIFIC_WATER_LAND',
  MIXED_LAND = 'MIXED_LAND',
  WET_RICE_LAND = 'WET_RICE_LAND',
  AGRICULTURAL_LAND_OTHER = 'AGRICULTURAL_LAND_OTHER',
  CULTURAL_FACILITY_LAND = 'CULTURAL_FACILITY_LAND',
  EXPORT_PROCESSING_ZONE_LAND = 'EXPORT_PROCESSING_ZONE_LAND',
  PUBLIC_ENTERTAINMENT_LAND = 'PUBLIC_ENTERTAINMENT_LAND',
  NON_AGRICULTURAL_LAND_OTHER = 'NON_AGRICULTURAL_LAND_OTHER',
  URBAN_GREEN_SPACE_LAND = 'URBAN_GREEN_SPACE_LAND',
  OTHER_PERENNIAL_CROP_LAND = 'OTHER_PERENNIAL_CROP_LAND',
  NON_AGRICULTURAL_LAND = 'NON_AGRICULTURAL_LAND',
  SOCIAL_SERVICE_FACILITY_LAND = 'SOCIAL_SERVICE_FACILITY_LAND',
  COMMERCIAL_SERVICE_LAND = 'COMMERCIAL_SERVICE_LAND',
  ENERGY_PROJECT_LAND = 'ENERGY_PROJECT_LAND',
  UNUSED_LAND = 'UNUSED_LAND',
  ANNUAL_CROP_OTHER = 'ANNUAL_CROP_OTHER',
  OTHER_ANNUAL_CROP_CULTIVATION_LAND = 'OTHER_ANNUAL_CROP_CULTIVATION_LAND',
  RESIDENTIAL_LAND = 'RESIDENTIAL_LAND',
  HEALTHCARE_FACILITY_LAND = 'HEALTHCARE_FACILITY_LAND',
  NON_AGRICULTURAL_PRODUCTION_FACILITY_LAND = 'NON_AGRICULTURAL_PRODUCTION_FACILITY_LAND',
  POST_TELECOMMUNICATION_LAND = 'POST_TELECOMMUNICATION_LAND',
  UNUSED_PLAIN_LAND = 'UNUSED_PLAIN_LAND',
  URBAN_TREE_LAND_SPECIALIZED = 'URBAN_TREE_LAND_SPECIALIZED',
  OTHER_ANNUAL_CROP_LAND = 'OTHER_ANNUAL_CROP_LAND',
  RURAL_RESIDENTIAL_LAND = 'RURAL_RESIDENTIAL_LAND',
  EDUCATION_TRAINING_LAND = 'EDUCATION_TRAINING_LAND',
  MINERAL_ACTIVITY_LAND = 'MINERAL_ACTIVITY_LAND',
  MARKET_LAND = 'MARKET_LAND',
  UNUSED_HILL_LAND = 'UNUSED_HILL_LAND',
  ISOLATED_GREEN_SPACE_LAND = 'ISOLATED_GREEN_SPACE_LAND',
  PERENNIAL_CROP_LAND = 'PERENNIAL_CROP_LAND',
  URBAN_RESIDENTIAL_LAND = 'URBAN_RESIDENTIAL_LAND',
  SPORTS_FACILITY_LAND = 'SPORTS_FACILITY_LAND',
  CONSTRUCTION_MATERIAL_PRODUCTION_LAND = 'CONSTRUCTION_MATERIAL_PRODUCTION_LAND',
  WASTE_DISPOSAL_LAND = 'WASTE_DISPOSAL_LAND',
  ROCKY_MOUNTAIN_LAND_WITHOUT_FOREST = 'ROCKY_MOUNTAIN_LAND_WITHOUT_FOREST',
}
export type ResponseUserSerialization =
  | {
      _id: string;
      fullName?: string;
      email?: string;
      phone?: string;
      avatar?: string;
    }
  | undefined
  | null;

export type AddressSerialization =
  | {
      id: string;
      name?: string;
    }
  | undefined
  | null;
export type ITypeUrls =
  | {
      type?: string;
      url?: string;
    }
  | undefined
  | null;
export type ResponseImageSerialization =
  | {
      fileId: string;
      type?: string;
      originalUrl?: string;
      urls?: ITypeUrls[];
      createdAt?: string;
      creator?: ResponseUserSerialization;
      mimetype?: string;
      size?: number;
      name?: string;
    }
  | undefined
  | null;
export type ResponseAttachmentSerialization =
  | {
      fileId: string;
      type?: string;
      originalUrl?: string;
      urls?: ITypeUrls[];
      createdAt?: string;
      creator?: ResponseUserSerialization;
      mimetype?: string;
      size?: number;
      name?: string;
    }
  | undefined
  | null;
export interface NotarizationLoansSerialization {
  province?: AddressSerialization;
  district?: AddressSerialization;
  ward?: AddressSerialization;
  street?: AddressSerialization;
  fullNameReferrer?: string;
  phoneReferrer?: string;
  emailReferrer?: string;
  provinceOffice?: AddressSerialization;
  districtOffice?: AddressSerialization;
  source?: SourceEnum;
  apartmentNumber?: string;
  _id?: string;
  code?: string;
  typeNotarization?: TypeNotarizationEnum;
  status?: StatusEnum;
  idNotaryOffice?: string;
  bank?: {
    id?: string;
    name?: string;
  };
  notaryOffice?: {
    id?: string;
    name?: string;
  };
  namePersonLoan?: string;
  birthPersonLoan?: string;
  genderPersonLoan?: GenderPersonLoanEnum;
  nationalityPersonLoan?: {
    name?: string;
  };
  idCardPersonLoan?: string;
  phonePersonLoan?: string;
  emailPersonLoan?: string;
  packageLoan?: PackageLoanEnum;
  purposeLoan?: PurposeLoanEnum;
  maritalStatusPersonLoan?: MaritalStatusEnum;
  infoWifeHusband?: string;
  carrierPersonLoan?: string;
  incomePersonLoan?: string;
  otherIncomePersonLoan?: string;
  debtPersonLoan?: string;
  infoMorePersonLoan?: string;
  attachmentBorrower?: {
    images?: ResponseImageSerialization[];
    attachments?: ResponseAttachmentSerialization[];
  };
  attachmentCertificate?: {
    images?: ResponseImageSerialization[];
    attachments?: ResponseAttachmentSerialization[];
  };
  createdBy?: ResponseUserSerialization;
  updatedBy?: ResponseUserSerialization;
  approvedBy?: ResponseUserSerialization;
  createdAt?: string;
  updatedAt?: string;
  approvedAt?: string;
  reason?: string;
}
export interface BankLoanData {
  data?: NotarizationLoansSerialization[];
  currentPage?: number;
  perPage?: number;
  totalPage?: number;
  totalData?: number;
}
export type BankLoansResponse = BankLoanData;
export interface PersonalInfoSerialization {
  name?: string;
  idCard?: string;
  phone?: string;
  registeredResidence?: string;
}
export enum TypeNotarizationEnum {
  FLUCTUATION = 'FLUCTUATION',
  BANK_LOANS = 'BANK_LOANS',
  NOTARIZATION = 'NOTARIZATION',
}
export type ResponseTagSerialization =
  | {
      _id: string;
      name?: string;
      description?: string;
    }
  | undefined
  | null;
export interface NotarizationData {
  data?: NotarizationRecordsSerialization[];
  currentPage?: number;
  perPage?: number;
  totalPage?: number;
  totalData?: number;
}
export interface NotarizationRecordsSerialization {
  bank?: {
    name?: string;
  };
  province?: AddressSerialization;
  district?: AddressSerialization;
  ward?: AddressSerialization;
  street?: AddressSerialization;
  apartmentNumber?: string;
  fullNameReferrer?: string;
  phoneReferrer?: string;
  emailReferrer?: string;
  provinceOffice?: AddressSerialization;
  districtOffice?: AddressSerialization;
  _id?: string;
  typeNotarization?: TypeNotarizationEnum;
  notaryOffice?: {
    id?: string;
    name?: string;
  };
  status?: StatusEnum;
  code?: string;
  idNotaryOffice?: string;
  isForSale?: boolean;
  maritalStatusBuy?: MaritalStatusEnum;
  privatePropertyBuy?: boolean;
  maritalStatusSale?: MaritalStatusEnum;
  privatePropertySale?: boolean;
  infoPersonSale?: PersonalInfoSerialization[];
  infoPersonBuy?: PersonalInfoSerialization[];
  priceContract?: number;
  purposeUsingLand?: string;
  area?: number;
  construcArea?: number;
  sheet?: number;
  plot?: number;
  certificateNumber?: string;
  issueNumber?: string;
  issuanceDate?: string;
  notarizationDate?: string;
  description?: string;
  attachmentSale?: {
    images?: ResponseImageSerialization[];
    attachments?: ResponseAttachmentSerialization[];
  };
  attachmentBuy?: {
    images?: ResponseImageSerialization[];
    attachments?: ResponseAttachmentSerialization[];
  };
  attachmentCertificate?: {
    images?: ResponseImageSerialization[];
    attachments?: ResponseAttachmentSerialization[];
  };
  createdBy?: ResponseUserSerialization;
  updatedBy?: ResponseUserSerialization;
  approvedBy?: ResponseUserSerialization;
  createdAt?: string;
  updatedAt?: string;
  approvedAt?: string;
  reason?: string;
  source?: string;
}
export interface NotarizationsResponse {
  data?: NotarizationData;
}
export interface NotarizationsParams {
  page: number;
  perPage: number;
  typeNotarization: TypeNotarizationEnum;
  maritalStatusBuy?: boolean;
  privatePropertyBuy?: boolean;
  maritalStatusSale?: boolean;
  privatePropertySale?: boolean;
  priceContract?: string;
  province?: string;
  district?: string;
  ward?: string;
  street?: string;
  apartmentNumber?: string;
  purposeUsingLand?: string;
  sheet?: string;
  plot?: string;
  code?: string;
  createdAt?: string;
  updatedAt?: string;
  isForSale?: boolean;
  provinceOffice?: string;
  districtOffice?: string;
  'infoPersonSale.name'?: string;
  'infoPersonSale.idCard'?: string;
  'infoPersonSale.phone'?: string;
  'infoPersonBuy.name'?: string;
  'infoPersonBuy.idCard'?: string;
  'infoPersonBuy.phone'?: string;
  idNotaryOffice?: string;
  status?: string;
  approvedAt?: string;
  approvedBy?: string;
  until?: string;
  availableSort?: string;
  optionSort?: string;
}
export interface ResponseAttachments {
  images?: ResponseImageSerialization[];
  attachments?: ResponseAttachmentSerialization[];
}
