import { SVGProps } from 'react';

const IconMap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M9.5 11.813C9.17165 11.8138 8.84636 11.7497 8.54282 11.6245C8.23928 11.4992 7.96346 11.3153 7.73118 11.0832C7.4989 10.8511 7.31474 10.5754 7.18927 10.272C7.06379 9.96855 6.99948 9.64332 7 9.31496C7.00008 8.90387 7.10153 8.49914 7.29537 8.13662C7.48921 7.77409 7.76946 7.46496 8.1113 7.23661C8.45314 7.00825 8.84601 6.86772 9.25513 6.82745C9.66424 6.78719 10.077 6.84843 10.4568 7.00576C10.8366 7.16309 11.1717 7.41165 11.4325 7.72942C11.6933 8.0472 11.8717 8.42439 11.9519 8.82758C12.0322 9.23077 12.0117 9.64752 11.8924 10.0409C11.7731 10.4343 11.5587 10.7922 11.268 11.083C11.0358 11.315 10.7601 11.4989 10.4567 11.6241C10.1534 11.7494 9.82823 11.8136 9.5 11.813ZM9.5 7.81596C9.116 7.81596 8.731 7.96196 8.439 8.25396C8.156 8.53796 8 8.91396 8 9.31496C8 9.71596 8.156 10.092 8.439 10.376C8.72284 10.6452 9.10061 10.7931 9.49183 10.7879C9.88305 10.7828 10.2568 10.6251 10.5335 10.3484C10.8101 10.0718 10.9678 9.69801 10.973 9.30679C10.9781 8.91557 10.8303 8.5378 10.561 8.25396C10.4217 8.11474 10.2562 8.00439 10.0742 7.92923C9.89208 7.85407 9.69697 7.81558 9.5 7.81596ZM8.086 7.90096H8.096H8.086Z'
      fill='currentColor'
    />
    <path
      d='M9.5 11.813C9.17165 11.8138 8.84636 11.7497 8.54282 11.6245C8.23928 11.4992 7.96346 11.3153 7.73118 11.0832C7.4989 10.8511 7.31474 10.5754 7.18927 10.272C7.06379 9.96855 6.99948 9.64332 7 9.31496C7.00008 8.90387 7.10153 8.49914 7.29537 8.13662C7.48921 7.77409 7.76946 7.46496 8.1113 7.23661C8.45314 7.00825 8.84601 6.86772 9.25513 6.82745C9.66424 6.78719 10.077 6.84843 10.4568 7.00576C10.8366 7.16309 11.1717 7.41165 11.4325 7.72942C11.6933 8.0472 11.8717 8.42439 11.9519 8.82758C12.0322 9.23077 12.0117 9.64752 11.8924 10.0409C11.7731 10.4343 11.5587 10.7922 11.268 11.083C11.0358 11.315 10.7601 11.4989 10.4567 11.6241C10.1534 11.7494 9.82823 11.8136 9.5 11.813ZM9.5 7.81596C9.116 7.81596 8.731 7.96196 8.439 8.25396C8.156 8.53796 8 8.91396 8 9.31496C8 9.71596 8.156 10.092 8.439 10.376C8.72284 10.6452 9.10061 10.7931 9.49183 10.7879C9.88305 10.7828 10.2568 10.6251 10.5335 10.3484C10.8101 10.0718 10.9678 9.69801 10.973 9.30679C10.9781 8.91557 10.8303 8.5378 10.561 8.25396C10.4217 8.11474 10.2562 8.00439 10.0742 7.92923C9.89208 7.85407 9.69697 7.81558 9.5 7.81596ZM8.086 7.90096H8.096H8.086Z'
      fill='currentColor'
    />
    <path
      d='M9.50001 15.2497C9.3674 15.2497 9.24023 15.1971 9.14646 15.1033C9.05269 15.0095 9.00001 14.8824 9.00001 14.7497V11.3147C9.00001 11.1821 9.05269 11.055 9.14646 10.9612C9.24023 10.8674 9.3674 10.8147 9.50001 10.8147C9.63262 10.8147 9.7598 10.8674 9.85357 10.9612C9.94734 11.055 10 11.1821 10 11.3147V14.7497C10 14.8824 9.94734 15.0095 9.85357 15.1033C9.7598 15.1971 9.63262 15.2497 9.50001 15.2497ZM5.50001 12.7497C5.42228 12.7495 5.34564 12.7313 5.27601 12.6967L1.82901 10.9727C1.57918 10.8492 1.36903 10.658 1.22247 10.421C1.07592 10.1839 0.998842 9.91046 1.00001 9.63175V2.86775C1.00001 2.34375 1.26601 1.86675 1.71101 1.59175C2.15701 1.31875 2.70201 1.29175 3.17101 1.52675L5.72401 2.80275C5.80696 2.84431 5.87671 2.90814 5.92545 2.98708C5.97419 3.06602 6.00001 3.15697 6.00001 3.24975V12.2497C6.00001 12.3824 5.94733 12.5095 5.85357 12.6033C5.7598 12.6971 5.63262 12.7497 5.50001 12.7497ZM2.50101 2.36675C2.4077 2.36735 2.31636 2.39365 2.23701 2.44275C2.16405 2.48696 2.10384 2.54939 2.06229 2.6239C2.02074 2.69841 1.99928 2.78244 2.00001 2.86775V9.63175C2.00001 9.82275 2.10501 9.99375 2.27601 10.0787L5.00001 11.4407V3.55875L2.72401 2.42075C2.65495 2.3855 2.57855 2.367 2.50101 2.36675Z'
      fill='currentColor'
    />
    <path
      d='M5.5 12.7499C5.4343 12.75 5.36923 12.7372 5.3085 12.7121C5.24778 12.687 5.19261 12.6502 5.14615 12.6037C5.0997 12.5573 5.06287 12.5021 5.03779 12.4414C5.01271 12.3807 4.99987 12.3156 5 12.2499V3.24988C5 3.04488 5.124 2.86188 5.314 2.78588L10.314 0.785884C10.3899 0.755481 10.472 0.744138 10.5533 0.752847C10.6345 0.761557 10.7124 0.790053 10.7801 0.835842C10.8478 0.881631 10.9033 0.943316 10.9416 1.0155C10.9799 1.08768 11 1.16816 11 1.24988V7.59188C11.0001 7.6798 10.977 7.76618 10.9331 7.84233C10.8891 7.91848 10.8259 7.9817 10.7497 8.02561C10.6736 8.06952 10.5872 8.09258 10.4993 8.09245C10.4114 8.09232 10.325 8.06902 10.249 8.02488C9.96408 7.85856 9.63223 7.79094 9.30494 7.83253C8.97766 7.87412 8.67326 8.02258 8.439 8.25488C8.156 8.53788 8 8.91388 8 9.31488C8 9.71588 8.156 10.0919 8.439 10.3759H8.44C8.521 10.4579 8.623 10.5319 8.76 10.6109C8.84183 10.6574 8.90886 10.7262 8.95336 10.8091C8.99786 10.8921 9.01804 10.9859 9.01156 11.0799C9.00508 11.1738 8.97222 11.264 8.91675 11.3401C8.86127 11.4161 8.78545 11.475 8.698 11.5099L5.685 12.7149C5.62604 12.7379 5.56331 12.7498 5.5 12.7499ZM6 3.58788V11.5109L7.558 10.8879C7.19526 10.4441 6.99801 9.88805 7 9.31488C6.99965 8.94433 7.08195 8.57836 7.2409 8.24363C7.39984 7.9089 7.63144 7.61383 7.91882 7.3799C8.2062 7.14597 8.54213 6.97908 8.90215 6.89137C9.26218 6.80366 9.63723 6.79735 10 6.87288V1.98788L6 3.58788Z'
      fill='currentColor'
    />
    <path
      d='M13.501 12.1335C13.272 12.1335 13.043 12.0805 12.829 11.9735L10.849 10.9835C10.7832 10.9502 10.7255 10.9028 10.6801 10.8447C10.6346 10.7866 10.6026 10.7191 10.5863 10.6472C10.57 10.5753 10.5697 10.5006 10.5856 10.4286C10.6014 10.3565 10.633 10.2889 10.678 10.2305C10.9017 9.94319 11.0131 9.58435 10.9916 9.22092C10.9701 8.85748 10.817 8.51431 10.561 8.25546C10.4676 8.16534 10.3627 8.088 10.249 8.02546C10.1733 7.9813 10.1105 7.91809 10.0668 7.84213C10.0231 7.76616 10.0001 7.68009 10 7.59246V1.25046C10.0001 1.16533 10.0219 1.08164 10.0634 1.0073C10.1049 0.93297 10.1647 0.870459 10.2371 0.825694C10.3095 0.780928 10.3921 0.75539 10.4771 0.751499C10.5622 0.747608 10.6468 0.765493 10.723 0.80346L14.17 2.52746C14.683 2.78246 15 3.29646 15 3.86846V10.6325C15.001 10.8883 14.9361 11.1401 14.8116 11.3636C14.6871 11.5871 14.5071 11.7747 14.289 11.9085C14.046 12.0585 13.774 12.1335 13.501 12.1335ZM11.775 10.3295L13.276 11.0795C13.3519 11.1184 13.4367 11.1369 13.5219 11.133C13.6072 11.1292 13.6899 11.1031 13.762 11.0575C13.8353 11.0135 13.8958 10.9512 13.9376 10.8766C13.9793 10.8021 14.0008 10.7179 14 10.6325V3.86846C14.0006 3.77556 13.975 3.68437 13.9262 3.60532C13.8774 3.52628 13.8073 3.46256 13.724 3.42146L11 2.05946V7.32046C11.099 7.39246 11.187 7.46646 11.268 7.54846C12.009 8.28946 12.189 9.40346 11.775 10.3295Z'
      fill='currentColor'
    />
  </svg>
);
export { IconMap };
