import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { isEqual } from 'lodash';
import { blogClient, chatClient, mainClient, mapClient, uploadClient } from '../apollo/client';

export const useFlexMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  service: 'main' | 'upload' | 'map' | 'chat' | 'blog',
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> =>
  useMutation(mutation, {
    ...options,
    client: isEqual(service, 'upload')
      ? uploadClient
      : isEqual(service, 'map')
      ? mapClient
      : isEqual(service, 'chat')
      ? chatClient
      : isEqual(service, 'blog')
      ? blogClient
      : mainClient,
  });
