import { gql } from '@apollo/client';
import {
  Boolean,
  Direction,
  Float,
  FromTo,
  FromToDateInput,
  Int,
  JSONObject,
  PostStatusType,
  SavedItem,
  String,
  TradingStatus,
} from './get-real-estates';

export enum EnumDemand {
  ForSale = 'ForSale',
  ForRent = 'ForRent',
  ToBuy = 'ToBuy',
  ToRent = 'ToRent',
}
export interface PaginationOf {
  hasNextPage?: Boolean;
  hasPrevPage?: Boolean;
  limit?: Int;
  nextPage?: Int;
  page?: Int;
  pagingCounter?: Int;
  prevPage?: Int;
  total?: Int;
  totalPages?: Int;
}
export type Paginator = PaginationOf;
export interface ListSavedItem extends PaginationOf {
  items?: SavedItem[];
}

export interface GetUserSavedRealEstateVariables {
  searchString?: String;
  realEstateType?: String[];
  province?: String;
  district?: String;
  ward?: String;
  street?: String;
  commission?: String;
  price?: FromTo;
  priceUnit?: String;
  area?: FromTo;
  length?: FromTo;
  width?: FromTo;
  rear?: FromTo;
  alleyWidth?: FromTo;
  bedRoomArea?: FromTo;
  floorCount?: FromTo;
  floorPosition?: FromTo;
  roomCount?: FromTo;
  bedroomCount?: FromTo;
  toiletCount?: FromTo;
  blockCount?: FromTo;
  projectName?: String;
  hasSeaView?: Boolean;
  villaView?: String[];
  hotelStar?: Float;
  investor?: String;
  buildingType?: String;
  direction?: Direction[];
  note?: String;
  fixedUtilities?: String[];
  utilities?: String[];
  localUtilities?: String[];
  equipmentsAndServices?: String[];
  postStatus?: PostStatusType;
  postType?: Int[];
  tradingStatus?: TradingStatus;
  sort?: JSONObject;
  isAuthorizedRe?: Boolean;
  isSaleOffRE?: Boolean;
  phone?: String;
  mediaTypes?: String[];
  company?: String;
  projectIds?: String[];
  houseNumber?: String;
  realEstateId?: String;
  contactName?: String;
  creator?: String;
  isProduct?: Boolean;
  hasAlley?: Boolean;
  createdAt?: FromToDateInput;
  updatedAt?: FromToDateInput;
  collection?: String[];
  mediaType?: String;
  demandType?: EnumDemand;
  type?: String;
  page?: Int;
  limit?: Int;
}
export interface GetUserSavedRealEstateData {
  getUserSavedRealEstate?: ListSavedItem;
}

export const GET_USER_SAVED_REAL_ESTATE = gql`
  query getUserSavedRealEstate(
    $searchString: String
    $realEstateType: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $commission: String
    $price: FromTo
    $priceUnit: String
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $alleyWidth: FromTo
    $bedRoomArea: FromTo
    $floorCount: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $blockCount: FromTo
    $projectName: String
    $hasSeaView: Boolean
    $villaView: [String]
    $hotelStar: Float
    $investor: String
    $buildingType: String
    $direction: [Direction]
    $note: String
    $fixedUtilities: [String]
    $utilities: [String]
    $localUtilities: [String]
    $equipmentsAndServices: [String]
    $postStatus: PostStatusType
    $postType: [Int]
    $tradingStatus: TradingStatus
    $sort: JSONObject
    $isAuthorizedRe: Boolean
    $isSaleOffRE: Boolean
    $phone: String
    $mediaTypes: [String]
    $company: String
    $projectIds: [String]
    $houseNumber: String
    $realEstateId: String
    $contactName: String
    $creator: String
    $isProduct: Boolean
    $hasAlley: Boolean
    $createdAt: FromToDateInput
    $updatedAt: FromToDateInput
    $collection: [String]
    $mediaType: String
    $demandType: EnumDemand
    $type: String
    $page: Int
    $limit: Int
  ) @api(name: realEstate) {
    getUserSavedRealEstate(
      searchString: $searchString
      realEstateType: $realEstateType
      province: $province
      district: $district
      ward: $ward
      street: $street
      commission: $commission
      price: $price
      priceUnit: $priceUnit
      area: $area
      length: $length
      width: $width
      rear: $rear
      alleyWidth: $alleyWidth
      bedRoomArea: $bedRoomArea
      floorCount: $floorCount
      floorPosition: $floorPosition
      roomCount: $roomCount
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      blockCount: $blockCount
      projectName: $projectName
      hasSeaView: $hasSeaView
      villaView: $villaView
      hotelStar: $hotelStar
      investor: $investor
      buildingType: $buildingType
      direction: $direction
      note: $note
      fixedUtilities: $fixedUtilities
      utilities: $utilities
      localUtilities: $localUtilities
      equipmentsAndServices: $equipmentsAndServices
      postStatus: $postStatus
      postType: $postType
      tradingStatus: $tradingStatus
      sort: $sort
      isAuthorizedRe: $isAuthorizedRe
      isSaleOffRE: $isSaleOffRE
      phone: $phone
      mediaTypes: $mediaTypes
      company: $company
      projectIds: $projectIds
      houseNumber: $houseNumber
      realEstateId: $realEstateId
      contactName: $contactName
      creator: $creator
      isProduct: $isProduct
      hasAlley: $hasAlley
      createdAt: $createdAt
      updatedAt: $updatedAt
      collection: $collection
      mediaType: $mediaType
      demandType: $demandType
      type: $type
      page: $page
      limit: $limit
    ) {
      items {
        id
        item {
          ... on RealEstate {
            id
            postTitle
            postType
            isForSell
            realEstateType
            isSaved
            area
            toiletCount
            bedroomCount
            price
            createdAt
            fullSlug
            shortAddress
            privateNote
            mediaUrls {
              ... on FileWithFullUrls {
                fileId
                type
                originalUrl
              }
              ... on YoutubeFile {
                fileId
                type
                youtubeMetadata {
                  videoId
                }
              }
            }
            saveItem {
              id
              belongsToCollection {
                id
                type
                name
                owner
                totalItems
              }
            }
            creator {
              id
            }
          }
        }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
