import { SVGProps } from 'react';

const IconAttachment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    {...props}
  >
    <path
      d='M4.56491 8.83574L9.25241 16.9547C10.5024 19.1198 12.7512 19.2648 14.375 18.3273C15.9988 17.3898 16.9976 15.3698 15.7476 13.2047L10.1226 3.46194C9.1851 1.83814 7.79007 1.92188 6.70754 2.54688C5.62501 3.17188 4.85497 4.33814 5.79247 5.96194L11.105 15.1635C11.4175 15.7047 12.2712 15.9335 12.8125 15.621C13.3538 15.3085 13.5825 14.4547 13.27 13.9135L8.89504 6.33574'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconAttachment };
