import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconNotarizationModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;
  return (
    <svg
      width='56'
      height='55'
      viewBox='0 0 56 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g>
        <rect x='0.5' width='55' height='55' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.6'
          cx='45.1113'
          cy='48.5'
          r={r ?? '36'}
          fill='url(#paint0_radial_14_8816)'
          fillOpacity='0.8'
        />
        <g>
          <path
            d='M29.0027 31.8317C29.632 31.8317 30.1422 31.3216 30.1422 30.6923C30.1422 30.063 29.632 29.5529 29.0027 29.5529C28.3734 29.5529 27.8633 30.063 27.8633 30.6923C27.8633 31.3216 28.3734 31.8317 29.0027 31.8317Z'
            fill={fillColor2 ?? '#80888F'}
          />
          <path
            d='M33.2612 28.2373C32.7763 27.408 31.6092 27.4669 31.0539 27.1453C30.5281 26.8386 29.9939 25.7827 29.0005 25.7827C28.0173 25.7827 27.4728 26.8414 26.9519 27.1453C26.3903 27.4703 25.2315 27.4046 24.7446 28.2373C24.2798 29.0401 24.8986 30.0357 24.8986 30.692C24.8986 31.3584 24.2792 32.3426 24.7446 33.1466C25.2295 33.9759 26.3953 33.9165 26.9519 34.2386C27.4762 34.5444 28.0143 35.6013 29.0005 35.6013C29.9926 35.6013 30.5076 34.5573 31.0539 34.2386C31.6144 33.9141 32.773 33.9816 33.2612 33.1466C33.7266 32.3426 33.1071 31.3611 33.1071 30.692C33.1071 30.0326 33.7264 29.0408 33.2612 28.2373ZM29.0028 32.7648C27.8584 32.7648 26.9278 31.8352 26.9278 30.6922C26.9278 29.5495 27.8584 28.6198 29.0028 28.6198C30.1471 28.6198 31.0781 29.5495 31.0781 30.6922C31.0781 31.8352 30.1471 32.7648 29.0028 32.7648Z'
            fill={fillColor2 ?? '#80888F'}
          />
          <path
            d='M38.8422 15.8333H19.163C18.4116 15.8333 17.8003 16.4399 17.8003 17.1913V32.0032C17.8003 32.7545 18.4116 33.3658 19.163 33.3658H23.8157C23.3446 32.2537 23.965 31.0926 23.965 30.6919C23.965 30.247 23.2506 28.9525 23.937 27.7705C24.6377 26.5692 26.0789 26.5532 26.485 26.3379C26.7066 26.2018 27.3746 25.2216 28.3919 24.9379H21.1002C20.8423 24.9379 20.6335 24.7289 20.6335 24.4712C20.6335 24.2135 20.8423 24.0045 21.1002 24.0045H36.455C36.7129 24.0045 36.9217 24.2135 36.9217 24.4712C36.9217 24.7289 36.7129 24.9379 36.455 24.9379H29.6145C30.6626 25.2282 31.2533 26.1831 31.5249 26.3379C31.8509 26.5337 33.3559 26.5574 34.0682 27.7659C34.743 28.934 34.0402 30.2804 34.0402 30.6919C34.0402 31.0922 34.6607 32.2533 34.1896 33.3658H38.8422C39.5936 33.3658 40.2001 32.7545 40.2001 32.0032V17.1913C40.2001 16.4399 39.5936 15.8333 38.8422 15.8333ZM23.9325 19.1953H33.6222C33.8801 19.1953 34.0889 19.4042 34.0889 19.6619C34.0889 19.9196 33.8801 20.1286 33.6222 20.1286H23.9325C23.6746 20.1286 23.4659 19.9196 23.4659 19.6619C23.4659 19.4042 23.6746 19.1953 23.9325 19.1953ZM36.455 22.5332H21.1002C20.8423 22.5332 20.6335 22.3243 20.6335 22.0666C20.6335 21.8088 20.8423 21.5999 21.1002 21.5999H36.455C36.7129 21.5999 36.9217 21.8088 36.9217 22.0666C36.9217 22.3243 36.7129 22.5332 36.455 22.5332Z'
            fill={fillColor2 ?? '#80888F'}
          />
          <path
            d='M32.052 38.8586C31.8183 39.186 31.4152 39.2463 31.1234 39.0685C29.6955 38.2146 28.2955 38.2099 26.8862 39.0545C26.5781 39.2366 26.1722 39.1526 25.9528 38.8492C25.7848 38.6206 25.7427 38.3219 25.8315 38.0419L26.6715 35.2186C26.7228 35.2653 26.7695 35.3166 26.8114 35.3586C27.2594 35.8299 27.9361 36.5346 29 36.5346C30.064 36.5346 30.7315 35.8346 31.1794 35.3726C31.2215 35.3259 31.2727 35.2746 31.3288 35.2186L32.178 38.0606C32.2667 38.3266 32.2201 38.6299 32.052 38.8586Z'
            fill={fillColor2 ?? '#80888F'}
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_14_8816'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(45.1113 48.5) rotate(90) scale(30)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_14_8816'>
          <rect x='0.5' width='55' height='55' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconNotarizationModule };
