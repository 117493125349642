import { SVGProps } from 'react';

const IconNoteSearch = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19 19L16 16M10 20H7.197C6.079 20 5.519 20 5.092 19.782C4.71569 19.5903 4.40974 19.2843 4.218 18.908C4 18.48 4 17.92 4 16.8V7.2C4 6.08 4 5.52 4.218 5.092C4.41 4.715 4.715 4.41 5.092 4.218C5.52 4 6.08 4 7.2 4H16.8C17.92 4 18.48 4 18.907 4.218C19.284 4.41 19.59 4.715 19.782 5.092C20 5.519 20 6.079 20 7.197V10M13.5 17C12.5717 17 11.6815 16.6313 11.0251 15.9749C10.3687 15.3185 10 14.4283 10 13.5C10 12.5717 10.3687 11.6815 11.0251 11.0251C11.6815 10.3687 12.5717 10 13.5 10C14.4283 10 15.3185 10.3687 15.9749 11.0251C16.6313 11.6815 17 12.5717 17 13.5C17 14.4283 16.6313 15.3185 15.9749 15.9749C15.3185 16.6313 14.4283 17 13.5 17Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export { IconNoteSearch };
