import { gql } from '@apollo/client';
import { Int, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export interface InsightBuilding {
  rawId?: String;
  name?: String;
}
export interface ProjectBuilding {
  id?: String;
  name?: String;
  isHousing?: Boolean;
  buildings?: InsightBuilding[];
}
export interface ListProjectBuilding extends PaginationOf {
  items?: ProjectBuilding[];
}

export interface ListProjectByProvinceData {
  listProjectByProvince?: ListProjectBuilding;
}
export interface ListProjectByProvinceVariables {
  search?: String;
  province?: String;
  limit?: Int;
  page?: Int;
}

export const LIST_PROJECT_BY_PROVINCE = gql`
  query listProjectByProvince($search: String, $province: String, $limit: Int, $page: Int)
  @api(name: realEstate) {
    listProjectByProvince(search: $search, province: $province, limit: $limit, page: $page) {
      total
      items {
        id
        name
        isOneHousing
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
