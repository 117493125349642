import { useReactiveVar } from '@apollo/client';
import { IconLoading } from 'components/icons';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import {
  MESSAGE_CREATED,
  MessageCreatedData,
  MessageCreatedVariables,
} from 'graphql/chat/subscriptions';
import {
  MESSAGES,
  Message as MessageModel,
  MessagesData,
  MessagesVariables,
} from 'graphql/main/queries';
import { useFlexQuery, useFlexSubscription } from 'hooks';
import { filter, gt, isEqual, isString, map, size, upperFirst } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { selectedConversationVar } from '../../../../../../../apollo/store';
import Message from './message';

const DEFAULT_MESSAGES_LIMIT = 10;
const DEFAULT_MESSAGES_OFFSET = 0;

const MessageList = () => {
  const { currentUser } = useAuthenticationContext();
  const [hasMore, setHasMore] = useState(true);
  const selectedConversation = useReactiveVar(selectedConversationVar);
  const {
    data: messagesData,
    loading: loadingMessages,
    fetchMore: fetchMoreMessages,
  } = useFlexQuery<MessagesData, MessagesVariables>('main', MESSAGES, {
    variables: {
      conversationId:
        selectedConversation && isString(selectedConversation?.id) ? selectedConversation.id : '',
      limit: DEFAULT_MESSAGES_LIMIT,
      offset: DEFAULT_MESSAGES_OFFSET,
    },
    skip: !isString(selectedConversation?.id),
    fetchPolicy: 'cache-and-network',
  });
  const [messages, setMessages] = useState<MessageModel[]>();
  const messagesSize = size(messages);
  const { data: messageCreatedData } = useFlexSubscription<
    MessageCreatedData,
    MessageCreatedVariables
  >('chat', MESSAGE_CREATED, {
    variables: {
      conversationId: selectedConversation?.id,
    },
  });

  const isShowMessageDateTime = (message: MessageModel, nextMessage: MessageModel) => {
    if (!nextMessage) {
      return true;
    }
    if (gt(moment(message.createdAt).diff(moment(nextMessage.createdAt), 'minutes'), 10)) {
      return true;
    }

    return false;
  };
  const loadMore = () => {
    fetchMoreMessages({
      variables: {
        limit: DEFAULT_MESSAGES_LIMIT,
        offset: messagesSize,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (isEqual(size(fetchMoreResult.messages?.items), 0)) {
          setHasMore(false);
        }

        return !fetchMoreResult || isEqual(size(fetchMoreResult.messages?.items), 0)
          ? {
              ...previousResult,
              messages: {
                ...previousResult.messages,
                items: [...(messages ?? [])],
              },
            }
          : {
              ...fetchMoreResult,
              messages: {
                ...fetchMoreResult.messages,
                items: [...(messages ?? []), ...(fetchMoreResult.messages?.items ?? [])],
              },
            };
      },
    });
  };

  useEffect(() => {
    setHasMore(true);
  }, [selectedConversation]);
  useEffect(() => {
    setMessages(messagesData?.messages?.items);
  }, [messagesData]);
  useEffect(() => {
    if (messageCreatedData?.messageCreated) {
      setMessages([
        messageCreatedData.messageCreated,
        ...filter(
          messages,
          (message) => !isEqual(message.id, messageCreatedData.messageCreated?.id),
        ),
      ]);
    }
  }, [messageCreatedData]);

  return loadingMessages ? (
    <div className='flex h-[430px] items-center justify-center'>
      <IconLoading className='min-h-[24px] min-w-[24px] animate-spin text-primary' />
    </div>
  ) : (
    <div
      id='message-list'
      className='chat flex max-h-[430px] min-h-[430px] flex-col-reverse overflow-y-auto overflow-x-hidden p-[10px]'
    >
      <InfiniteScroll
        inverse
        dataLength={messagesSize}
        hasMore={hasMore}
        scrollableTarget='message-list'
        loader={
          <div key={0} className='flex h-[69px] items-center justify-center'>
            <IconLoading className='min-h-[24px] min-w-[24px] animate-spin text-primary' />
          </div>
        }
        className='flex flex-col-reverse'
        next={loadMore}
      >
        {map(messages, (message, messageIndex, messageList) => (
          <div key={messageIndex}>
            {message.createdAt && isShowMessageDateTime(message, messageList[messageIndex + 1]) && (
              <div className='flex items-center space-x-[16px] py-[8px]'>
                <div className='h-[1px] w-full bg-stroke' />
                <span className='whitespace-nowrap text-[12px] leading-[15px] text-placeholder'>
                  {upperFirst(moment(message.createdAt).calendar())}
                </span>
                <div className='h-[1px] w-full bg-stroke' />
              </div>
            )}
            <Message message={message} isOwner={isEqual(message.from, currentUser?.id)} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default MessageList;
