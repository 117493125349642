import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconBankLoanModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;
  return (
    <svg
      width='56'
      height='55'
      viewBox='0 0 56 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g>
        <rect x='0.5' width='55' height='55' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.55'
          cx='48.7812'
          cy='50.2928'
          r={r ?? '38'}
          fill='url(#paint0_radial_14_8805)'
          fillOpacity='0.57'
        />
        <path
          d='M21.5835 25.1667H19.2502V33.3334H21.5835V25.1667ZM28.5835 25.1667H26.2502V33.3334H28.5835V25.1667ZM38.5002 35.6667H16.3335V38H38.5002V35.6667ZM35.5835 25.1667H33.2502V33.3334H35.5835V25.1667ZM27.4168 17.3034L33.4952 20.5H21.3385L27.4168 17.3034ZM27.4168 14.6667L16.3335 20.5V22.8334H38.5002V20.5L27.4168 14.6667Z'
          fill={fillColor2 ?? '#80888F'}
        />
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_14_8805'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(48.7812 50.2928) rotate(90) scale(26.5634)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_14_8805'>
          <rect x='0.5' width='55' height='55' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconBankLoanModule };
