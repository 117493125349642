import { SVGProps } from 'react';

const IconTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='currentColor'
    {...props}
  >
    <path
      d='M11.2706 3.2445C11.182 3.19868 11.0958 3.14845 11.0122 3.094C10.7693 2.93342 10.5466 2.74423 10.3489 2.5305C9.85369 1.96408 9.66877 1.3895 9.60111 0.987583H9.60344C9.54685 0.653333 9.57019 0.4375 9.57427 0.4375L7.31969 0.4375L7.31969 9.15425C7.31969 9.27092 7.31969 9.387 7.31502 9.50133C7.31502 9.51533 7.31327 9.52817 7.31269 9.54392C7.31269 9.54975 7.31269 9.55675 7.31094 9.56317V9.56842C7.28726 9.88109 7.1871 10.1832 7.01926 10.448C6.85143 10.7129 6.62106 10.9325 6.34844 11.0874C6.06402 11.2492 5.74233 11.3341 5.41511 11.3336C4.36511 11.3336 3.51344 10.4773 3.51344 9.41967C3.51344 8.3615 4.36511 7.50517 5.41511 7.50517C5.61402 7.50517 5.81177 7.53667 6.00077 7.5985L6.00369 5.3025C5.4297 5.22845 4.84659 5.27419 4.29116 5.43684C3.73574 5.59949 3.22006 5.87552 2.77669 6.2475C2.3881 6.58498 2.0614 6.98769 1.81127 7.4375C1.71619 7.60142 1.35686 8.26117 1.31369 9.331C1.28627 9.93767 1.46886 10.5677 1.55577 10.8273V10.8331C1.61002 10.9859 1.82236 11.5086 2.16769 11.949C2.44617 12.3025 2.7752 12.6131 3.14419 12.8707V12.8648L3.14944 12.8707C4.24027 13.6115 5.45069 13.5631 5.45069 13.5631C5.66011 13.5543 6.36186 13.5631 7.15869 13.1857C8.04244 12.7668 8.54527 12.1432 8.54527 12.1432C8.86675 11.7706 9.12241 11.3459 9.30127 10.8873C9.50544 10.3507 9.5731 9.70783 9.5731 9.45117L9.5731 4.82592C9.60052 4.84225 9.96511 5.08317 9.96511 5.08317C9.96511 5.08317 10.4901 5.41975 11.3085 5.6385C11.8954 5.79425 12.6869 5.8275 12.6869 5.8275V3.58925C12.4099 3.61958 11.8469 3.53208 11.27 3.24508L11.2706 3.2445Z'
      fill='currentColor'
    />
  </svg>
);
export { IconTiktok };
