import { gql } from '@apollo/client';
import { Int, String } from './get-real-estates';
import { ListCollection } from './get-saved-project';

export enum CollectionEnum {
  estate = 'estate',
  need_estate = 'need_estate',
  lead_estate = 'lead_estate',
  premium = 'premium',
  project = 'project',
  news = 'news',
  search_filter = 'search_filter',
  construction = 'construction',
  law = 'law',
  supplier = 'supplier',
  product = 'product',
}

export interface GetUserCollectionsByTypeData {
  getUserCollectionsByType?: ListCollection;
}
export interface GetUserCollectionsByTypeVariables {
  type: CollectionEnum;
  realEstateId?: String;
  name?: String;
  limit?: Int;
  page?: Int;
}

export const GET_USER_COLLECTIONS_BY_TYPE = gql`
  query getUserCollectionsByType(
    $type: CollectionEnum!
    $realEstateId: String
    $name: String
    $limit: Int
    $page: Int
  ) @api(name: realEstate) {
    getUserCollectionsByType(
      type: $type
      realEstateId: $realEstateId
      name: $name
      limit: $limit
      page: $page
    ) {
      total
      items {
        id
        type
        name
        owner
        totalItems
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
