import { Option } from 'components/desktop/shared/form-select';
import { Media } from 'components/mobile/service-bank-loan-registration/main-content/media';
import { NAME_REGEX, PHONE_NUMBER_REGEX, TAX_REGEX } from 'globalConstants';
import { Address } from 'graphql/main/queries/list-projects-atmnha';
import { gte, size } from 'lodash';
import * as yup from 'yup';
import { Attachments, typeNotarizationEnum } from './notarization-document';

export const nationalityDefaultValue = {
  value: 'vietnam',
  label: 'Việt Nam',
};
export const genderOptions = [
  {
    value: 'MALE',
    label: 'Nam',
  },
  {
    value: 'FEMALE',
    label: 'Nữ',
  },
];
export const statusMaritalOptions = [
  {
    value: 'SINGLE',
    label: 'Độc thân',
  },
  {
    value: 'MARRIED',
    label: 'Đã kết hôn',
  },
];
export const packageLoanOptions = [
  {
    value: 'PERSONAL_LOAN',
    label: 'Vay tín chấp',
  },
  {
    value: 'COLLATERAL_LOAN',
    label: 'Vay thế chấp',
  },
  {
    value: 'CASH_ADVANCE',
    label: 'Vay thấu chi',
  },
  {
    value: 'INSTALLMENT_LOAN',
    label: 'Vay trả góp',
  },
];
export const purposeLoanOptions = [
  {
    value: 'REAL_ESTATE_PURCHASE',
    label: 'Vay mua bất động sản',
  },
  {
    value: 'CAR_LOAN',
    label: 'Vay mua ô tô',
  },
  {
    value: 'SECURED_CONSUMER_LOAN',
    label: 'Vay tiêu dùng có tài sản đảm bảo',
  },
  {
    value: 'UNSECURED_CONSUMER_LOAN',
    label: 'Vay tiêu dùng không có tài sản đảm bảo',
  },
  {
    value: 'BUSINESS_CAPITAL_LOAN',
    label: 'Vay vốn hộ kinh doanh',
  },
  {
    value: 'HOME_CONSTRUCTION_RENOVATION_LOAN',
    label: 'Vay xây sửa nhà',
  },
  {
    value: 'EDUCATION_LOAN',
    label: 'Vay du học',
  },
  {
    value: 'OTHER_PRODUCTS',
    label: 'Sản phẩm khác',
  },
];
export interface NationalityPersonLoan {
  name?: string;
}

export interface BankBorrowingFormCreate {
  createdBy?: string;
  province?: Address;
  district?: Address;
  ward?: Address;
  street?: Address;
  apartmentNumber?: string;
  fullNameReferrer?: string;
  phoneReferrer?: string;
  emailReferrer?: string;
  provinceOffice?: Address;
  districtOffice?: Address;
  typeNotarization?: typeNotarizationEnum;
  idNotaryOffice?: string;
  bank?: Address;
  namePersonLoan?: string;
  birthPersonLoan?: string;
  genderPersonLoan?: string;
  nationalityPersonLoan?: NationalityPersonLoan;
  idCardPersonLoan?: string;
  phonePersonLoan?: string;
  emailPersonLoan?: string;
  packageLoan?: string;
  purposeLoan?: string;
  maritalStatusPersonLoan?: string;
  infoWifeHusband?: string;
  carrierPersonLoan?: string;
  incomePersonLoan?: string;
  otherIncomePersonLoan?: string;
  debtPersonLoan?: string;
  infoMorePersonLoan?: string;
  attachmentBorrower?: Attachments;
  attachmentCertificate?: Attachments;
}
export interface BankBorrowingForm {
  userId?: string;
  bankName?: Option;
  provinceRegisterBank?: Option;
  districtRegisterBank?: Option;
  branch?: Option;
  bankId?: string;
  provinceRegisterBankId?: string;
  districtRegisterBankId?: string;
  branchId?: string;
  fullNameBorrower?: string;
  dateOfBirth?: string;
  gender?: string;
  nationality?: Option;
  nationalityId?: string;
  taxIBorrower?: string;
  province?: Option;
  district?: Option;
  ward?: Option;
  street?: Option;
  provinceId?: string;
  districtId?: string;
  wardId?: string;
  streetId?: string;
  houseNumber?: string;
  phoneNumberBorrower?: string;
  emailBorrower?: string;
  borrowedAmount?: Option;
  purposeUseLoan?: Option;
  borrowedAmountId?: string;
  purposeUseLoanId?: string;
  maritalStatus?: string;
  fullNameAndInformationHusbandOrWife?: string;
  profession?: string;
  incomePerMonth?: string;
  otherIncome?: string;
  debtInformation?: string;
  moreInformation?: string;
  fullNameReferrer?: string;
  phoneNumberReferrer?: string;
  emailReferrer?: string;
  imageBorrower?: Media[];
  documentBorrower?: Media[];
  borrowerAttachments?: Media[];
  imageAsset?: Media[];
  documentAsset?: Media[];
  assetAttachments?: Media[];
}

export const bankBorrowingFormDefaultValues = {
  bankName: undefined,
  provinceRegisterBank: undefined,
  districtRegisterBank: undefined,
  bankId: undefined,
  provinceRegisterBankId: undefined,
  districtRegisterBankId: undefined,
  branch: undefined,
  fullNameBorrower: '',
  dateOfBirth: undefined,
  gender: genderOptions[0].value,
  nationality: nationalityDefaultValue,
  nationalityId: nationalityDefaultValue.value,
  taxIBorrower: '',
  provinceId: undefined,
  districtId: undefined,
  wardId: undefined,
  streetId: undefined,
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  houseNumber: undefined,
  phoneNumberBorrower: '',
  emailBorrower: '',
  borrowedAmountId: packageLoanOptions[1].value,
  purposeUseLoanId: purposeLoanOptions[0].value,
  borrowedAmount: packageLoanOptions[1],
  purposeUseLoan: purposeLoanOptions[0],
  maritalStatus: statusMaritalOptions[0].value,
  fullNameAndInformationHusbandOrWife: '',
  profession: '',
  incomePerMonth: '',
  otherIncome: '',
  debtInformation: '',
  moreInformation: '',
  fullNameReferrer: '',
  phoneNumberReferrer: '',
  emailReferrer: '',
  imageBorrower: undefined,
  documentBorrower: undefined,
  borrowerAttachments: undefined,
  imageAsset: undefined,
  documentAsset: undefined,
  assetAttachments: undefined,
};

export const bankBorrowingFormSchema = yup.object().shape({
  bankId: yup.string().required('Vui lòng chọn tên ngân hàng'),
  provinceRegisterBankId: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  branchId: yup.string().required('Vui lòng chọn chi nhánh'),
  fullNameBorrower: yup
    .string()
    .required('Vui lòng nhập họ và tên người vay')
    .matches(NAME_REGEX, 'Vui lòng nhập họ và tên hợp lệ'),
  taxIBorrower: yup
    .string()
    .required('Vui lòng nhập số CMND/CCCD người vay')
    .matches(TAX_REGEX, 'Vui lòng nhập số CMND/CCCD hợp lệ'),
  provinceId: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  districtId: yup.string().required('Vui lòng chọn quận/ huyện'),
  // ward: yup.string().required('Vui lòng chọn phường/ xã'),
  streetId: yup.string().required('Vui lòng chọn đường'),
  // houseNumber: yup.string().required('Vui lòng nhập số nhà'),
  phoneNumberBorrower: yup
    .string()
    .required('Vui lòng nhập số số điện thoại người vay')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại hợp lệ'),
  emailBorrower: yup.string().email('Vui lòng nhập đúng định dạng email').nullable(),
  borrowedAmount: yup.object().nullable().required('Vui lòng chọn gói vay'),
  purposeUseLoan: yup.object().nullable().required('Vui lòng chọn mục đích sử dụng khoản vay'),
  maritalStatus: yup.string(),
  gender: yup.string(),
  fullNameAndInformationHusbandOrWife: yup.string().when('maritalStatus', {
    is: statusMaritalOptions?.[0]?.value,
    then: yup.string().notRequired(),
    otherwise: yup.string().when('gender', {
      is: genderOptions[0].value,
      then: yup.string().required('Vui lòng nhập tên và thông tin vợ'),
      otherwise: yup.string().required('Vui lòng nhập tên và thông tin chồng'),
    }),
  }),
  fullNameReferrer: yup
    .string()
    .test('is-valid-name', 'Vui lòng nhập họ và tên hợp lệ', function (value) {
      if (!value) {
        return true;
      }
      return NAME_REGEX.test(value);
    })
    .notRequired(),
  phoneNumberReferrer: yup
    .string()
    .test('is-valid-phone-number', 'Vui lòng nhập số điện thoại hợp lệ', function (value) {
      if (!value) {
        return true;
      }
      return PHONE_NUMBER_REGEX.test(value);
    })
    .notRequired(),
  emailReferrer: yup.string().email('Vui lòng nhập đúng định dạng email').nullable(),
  imageBorrower: yup.array().notRequired(),
  documentBorrower: yup.array().notRequired(),
  borrowerAttachments: yup
    .array()
    .test(
      'media-validate',
      'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin người vay',
      function () {
        const { imageBorrower, documentBorrower } = this.parent;
        const sizeMedia = size(imageBorrower) + size(documentBorrower);
        return gte(sizeMedia, 1);
      },
    ),
  imageAsset: yup.array().notRequired(),
  documentAsset: yup.array().notRequired(),
  assetAttachments: yup
    .array()
    .test(
      'media-validate',
      'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin tài sản vay',
      function () {
        const { imageAsset, documentAsset } = this.parent;
        const sizeMedia = size(imageAsset) + size(documentAsset);
        return gte(sizeMedia, 1);
      },
    ),
});
