import { gql } from '@apollo/client';
import {
  CircleInput,
  Float,
  FromTo,
  ID,
  Int,
  Map,
  MapDataInput,
  PostStatusType,
  RealEstate,
  RealEstateProjectRealEstate,
  String,
  TradingStatus,
} from './get-real-estates';
import { BuildingType } from './slugery';

export enum FixedUtilitiesEnumType {
  fireProtection = 'fireProtection',
  callCenter = 'callCenter',
  hospital = 'hospital',
  police = 'police',
  taxi = 'taxi',
  sportCenter = 'sportCenter',
  electricity = 'electricity',
}
export enum UtilitiesEnumType {
  pool = 'pool',
  storehouse = 'storehouse',
  garden = 'garden',
  lift = 'lift',
  smartKey = 'smartKey',
}
export enum LocalUtilitiesEnumType {
  parking = 'parking',
  market = 'market',
  hospital = 'hospital',
  park = 'park',
  pharmacy = 'pharmacy',
  bank = 'bank',
  restaurant = 'restaurant',
  supermarket = 'supermarket',
  gym = 'gym',
  cafe = 'cafe',
  mall = 'mall',
}
export enum EquipmentsAndServicesEnumType {
  internalContactSystem = 'internalContactSystem',
  waterHeater = 'waterHeater',
  kitchenEquipments = 'kitchenEquipments',
  fridge = 'fridge',
  washingMachine = 'washingMachine',
  audioDevices = 'audioDevices',
  airConditioner = 'airConditioner',
  tv = 'tv',
}
export enum WarehouseEnumType {
  XCN = 'XCN',
  XNN = 'XNN',
  XTP = 'XTP',
  XGC = 'XGC',
  XSX = 'XSX',
  XKH = 'XKH',
}
export enum VillaViewType {
  all = 'all',
  city = 'city',
  inside = 'inside',
  mountain = 'mountain',
  sea = 'sea',
}
export enum VillaViewEnumType {
  city = 'city',
  inside = 'inside',
  mountain = 'mountain',
  sea = 'sea',
}
export interface RealEstateFilter {
  bottomLeft?: MapDataInput;
  upperRight?: MapDataInput;
  circle?: CircleInput;
  polygonPoints?: MapDataInput[];
  alleyWidth?: FromTo;
  area?: FromTo;
  bedRoomArea?: FromTo;
  bedroomCount?: FromTo;
  blockCount?: FromTo;
  buildingType?: BuildingType;
  direction?: String[];
  district?: String;
  equipmentsAndServices?: String[];
  fixedUtilities?: String[];
  floorCount?: FromTo;
  floorPosition?: FromTo;
  hasLift?: Boolean;
  hasSeaView?: Int;
  hasSmartKey?: Boolean;
  hotelStar?: Int;
  isAuthorizedRe?: Boolean;
  isSaleOffRE?: Boolean;
  length?: FromTo;
  limit?: Int;
  localUtilities?: String[];
  mediaTypes?: String[];
  page?: Int;
  phone?: String;
  postStatus?: PostStatusType;
  price?: FromTo;
  province?: String;
  realEstateType?: String[];
  rear?: FromTo;
  roomCount?: FromTo;
  searchString?: String;
  sort?: String;
  street?: String;
  toiletCount?: FromTo;
  tradingStatus?: TradingStatus;
  typeOfDemand?: String;
  typeOfRealEstate?: String[];
  utilities?: String[];
  villaView?: VillaViewEnumType;
  ward?: String;
  width?: FromTo;
  hasAlley?: Boolean;
}

export type RealEstateUnion = RealEstate | RealEstateProjectRealEstate;

export interface RealEstateGroupOnMap {
  id?: ID;
  realEstateIds?: String[];
  count?: Int;
  lat?: Float;
  long?: Float;
  firstItem?: RealEstateUnion;
  bottomLeft?: Map;
  upperRight?: Map;
}

export interface RealEstateOnMapV3Data {
  realEstateOnMapV3?: RealEstateGroupOnMap[];
}

export interface RealEstateOnMapV3Variables {
  filter: RealEstateFilter;
}

export const REAL_ESTATE_ON_MAP_V3 = gql`
  query realEstateOnMapV3($filter: RealEstateFilter) @api(name: realEstate) {
    realEstateOnMapV3(filter: $filter) {
      id
      realEstateIds
      count
      lat
      long
    }
  }
`;
