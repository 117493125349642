import { gql } from '@apollo/client';
import { ObjectID } from '../../main/queries/get-real-estates';
import { Message } from '../../main/queries/messages';

export interface MessageCreatedData {
  messageCreated?: Message;
}
export interface MessageCreatedVariables {
  conversationId?: ObjectID;
}

export const MESSAGE_CREATED = gql`
  subscription messageCreated($conversationId: ObjectID) {
    messageCreated(conversationId: $conversationId) {
      id
      from
      to
      text
      type
      callPayload
      attachments {
        type
        attachmentId
        url
      }
      seenBy
      hiddenBy
      unreadBy
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
