import { SVGProps } from 'react';

const IconStarFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.66701 14.1333L2.80155 10.9334C2.07198 10.35 2.39197 9.17668 3.3178 9.04442L8.80026 8.80016L11.1415 2.93051C11.3047 2.60518 11.6364 2.40039 12.0001 2.40039C12.3639 2.40039 12.6956 2.60625 12.8588 2.93051L15.2 8.80016L20.6825 9.04442C21.6083 9.17668 21.9283 10.35 21.1987 10.9334L17.3333 14.1333L18.3882 20.3806C18.5098 21.2328 17.6245 21.8706 16.8554 21.4856L12.0001 18.3998L7.14486 21.4845C6.37475 21.8696 5.49052 21.2317 5.61211 20.3795L6.66701 14.1333Z'
      fill='currentColor'
    />
  </svg>
);
export { IconStarFill };
