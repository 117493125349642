import { SVGProps } from 'react';

const IconDeliveryService = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_419_39020)'>
      <path
        d='M20.41 14.4915L15.636 15.9875C15.68 15.8775 15.702 15.7675 15.702 15.6355C15.724 15.3275 15.614 15.0195 15.394 14.7775C15.152 14.5135 14.822 14.3815 14.47 14.3815H11.412L8.772 13.4135C8.134 13.1715 7.452 13.0615 6.748 13.0615H5.494V19.0895H6C6.198 19.0895 6.396 19.1335 6.594 19.1995L12.248 21.2015C12.424 21.2675 12.6 21.2895 12.776 21.2895C13.04 21.2895 13.304 21.2235 13.546 21.0915L21.378 16.6255C21.642 16.4935 21.84 16.2735 21.972 16.0095C21.994 15.9875 21.994 15.9655 21.994 15.9655C22.104 15.6795 22.082 15.3495 21.95 15.0635C21.686 14.4695 20.982 14.2275 20.41 14.4915ZM4.152 11.3235H1.82C1.336 11.3235 0.940002 11.7195 0.940002 12.2035V19.8375C0.940002 20.3215 1.336 20.7175 1.82 20.7175H4.152C4.636 20.7175 5.032 20.3215 5.032 19.8375V12.2255C5.054 11.7195 4.658 11.3235 4.152 11.3235ZM14.976 4.4595L15.966 5.3615V1.6875H13.678V5.3395L14.668 4.4375C14.712 4.3935 14.756 4.3715 14.822 4.3715C14.888 4.3935 14.932 4.4155 14.976 4.4595Z'
        fill='currentColor'
      />
      <path
        d='M10.378 1.6875C9.65201 1.6875 9.03601 2.2815 9.03601 3.0295V11.9395C9.03601 12.6655 9.63001 13.2815 10.378 13.2815H19.42C20.102 13.2155 20.63 12.6435 20.63 11.9615V3.0295C20.63 2.3035 20.036 1.6875 19.288 1.6875H16.428V5.8455C16.428 5.9335 16.384 6.0215 16.296 6.0435C16.274 6.0655 16.23 6.0655 16.208 6.0655C16.164 6.0655 16.098 6.0435 16.054 5.9995L14.844 4.8995L13.634 5.9995C13.568 6.0655 13.48 6.0655 13.392 6.0435C13.304 5.9995 13.26 5.9335 13.26 5.8455V1.6875H10.378ZM13.942 10.8175H10.84C10.708 10.8175 10.62 10.7075 10.62 10.5975C10.62 10.4875 10.708 10.3775 10.84 10.3775H13.942C14.074 10.3775 14.162 10.4875 14.162 10.5975C14.162 10.7075 14.074 10.8175 13.942 10.8175ZM13.942 8.5955C14.074 8.5955 14.162 8.6835 14.162 8.8155C14.162 8.9475 14.074 9.0355 13.942 9.0355H10.84C10.708 9.0355 10.62 8.9475 10.62 8.8155C10.62 8.6835 10.708 8.5955 10.84 8.5955H13.942Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_419_39020'>
        <rect width='22' height='22' fill='white' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export { IconDeliveryService };
