import { Popover } from '@headlessui/react';
import { IconBsxShare, IconDotsVertical } from 'components/icons';
import { map } from 'lodash';
import MessageReaction from './message-reaction';

interface Props {
  id?: string;
}

const MessageAction = ({ id }: Props) => {
  const makeAppointment = () => {};
  const copyMessage = () => {};
  const forwardMessage = () => {};
  const deleteMessage = () => {};
  const recallMessage = () => {};
  const replyMessage = () => {};

  const messageActions = [
    {
      label: 'Tạo lịch hẹn',
      onClick: () => makeAppointment(),
    },
    {
      label: 'Copy tin nhắn',
      onClick: () => copyMessage(),
    },
    {
      label: 'Chuyển tiếp tin nhắn',
      onClick: () => forwardMessage(),
    },
    {
      label: 'Xóa ở phía bạn',
      onClick: () => deleteMessage(),
    },
    {
      label: 'Thu hồi tin nhắn',
      onClick: () => recallMessage(),
    },
  ];

  return (
    <div
      id='message-action'
      className='hidden items-center space-x-[8px] pr-[10px] group-hover:flex'
    >
      <Popover className='relative'>
        <Popover.Button type='button'>
          <IconDotsVertical className='min-h-[24px] min-w-[24px] text-text2' />
        </Popover.Button>
        <Popover.Panel className='absolute bottom-[40px] left-1/2 flex min-w-[138px] -translate-x-1/2 flex-col space-y-[12px] rounded-[8px] bg-paper py-[12px] shadow-4'>
          {map(messageActions, (messageAction, messageActionIndex) => (
            <button
              key={messageActionIndex}
              type='button'
              className='flex h-[22px] w-full items-center px-[12px] transition duration-[200ms] ease-in-out hover:bg-secondary'
              onClick={messageAction.onClick}
            >
              <span className='whitespace-nowrap text-[12px] leading-[15px]'>
                {messageAction.label}
              </span>
            </button>
          ))}
        </Popover.Panel>
        <div className='absolute top-[-15px] left-1/2 -translate-x-1/2 border-x-[10px] border-t-[8px] border-x-transparent border-t-paper' />
      </Popover>
      <MessageReaction />
      <button type='button' onClick={replyMessage}>
        <IconBsxShare className='min-h-[24px] min-w-[24px] scale-x-[-1] text-text2' />
      </button>
    </div>
  );
};

export default MessageAction;
