import { gql } from '@apollo/client';

export interface GetListBankData {
  getListBank?: any[];
}

export const GET_LIST_BANK = gql`
  query getListBank @api(name: users) {
    getListBank
  }
`;
