import { Area } from 'react-easy-crop';

export const getCroppedImage = async (croppedAreaPixels: Area, source: string): Promise<Blob> => {
  const image = new Image();
  image.src = source;

  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  const context = canvas.getContext('2d');
  context?.drawImage(
    image,
    croppedAreaPixels.x * scaleX,
    croppedAreaPixels.y * scaleY,
    croppedAreaPixels.width * scaleX,
    croppedAreaPixels.height * scaleY,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Failed to convert cropped image to blob'));
        return;
      }
      resolve(blob);
    }, 'image/jpeg');
  });
};
