import { SVGProps } from 'react';

const IconUserAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='currentColor'
    {...props}
  >
    <path
      d='M13.9464 8.64286C16.8755 8.64286 19.25 11.0173 19.25 13.9464C19.25 16.8755 16.8755 19.25 13.9464 19.25C11.0173 19.25 8.64286 16.8755 8.64286 13.9464C8.64286 11.0173 11.0173 8.64286 13.9464 8.64286ZM13.9464 11C13.621 11 13.3571 11.2638 13.3571 11.5893V13.3571H11.5893C11.2638 13.3571 11 13.621 11 13.9464C11 14.2718 11.2638 14.5357 11.5893 14.5357H13.3571V16.3036C13.3571 16.629 13.621 16.8929 13.9464 16.8929C14.2718 16.8929 14.5357 16.629 14.5357 16.3036V14.5357H16.3036C16.629 14.5357 16.8929 14.2718 16.8929 13.9464C16.8929 13.621 16.629 13.3571 16.3036 13.3571H14.5357V11.5893C14.5357 11.2638 14.2718 11 13.9464 11ZM8.94594 9.82143C8.02036 10.9422 7.46429 12.3794 7.46429 13.9464C7.46429 14.5593 7.54932 15.1522 7.70824 15.7141L7.53522 15.7152C4.55637 15.7152 2.86511 14.7198 2.75567 12.7134L2.75 12.5022V11.5893C2.75 10.6704 3.45112 9.91518 4.3476 9.82952L4.51786 9.82143H8.94594ZM7.46429 2.75C9.09182 2.75 10.4112 4.06938 10.4112 5.6969C10.4112 7.32444 9.09182 8.64381 7.46429 8.64381C5.83675 8.64381 4.51737 7.32444 4.51737 5.6969C4.51737 4.06938 5.83675 2.75 7.46429 2.75ZM13.3571 3.92857C14.659 3.92857 15.7143 4.9839 15.7143 6.28571C15.7143 6.77567 15.5648 7.23072 15.309 7.60773C14.8696 7.51375 14.4139 7.46429 13.9464 7.46429C13.1304 7.46429 12.3497 7.61506 11.6304 7.89028C11.2393 7.46951 11 6.90555 11 6.28571C11 4.9839 12.0553 3.92857 13.3571 3.92857Z'
      fill='currentColor'
    />
  </svg>
);
export { IconUserAdd };
