import { SVGProps } from 'react';

const IconFillHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M21.4608 11.199C21.4603 11.1986 21.4599 11.1981 21.4594 11.1977L13.301 3.03955C12.9532 2.69165 12.4909 2.5 11.9991 2.5C11.5073 2.5 11.045 2.6915 10.6971 3.0394L2.54293 11.1934C2.54018 11.1961 2.53743 11.199 2.53469 11.2018C1.82058 11.92 1.8218 13.0853 2.5382 13.8017C2.8655 14.1292 3.29778 14.3188 3.75997 14.3387C3.77874 14.3405 3.79766 14.3414 3.81673 14.3414H4.1419V20.3453C4.1419 21.5334 5.10854 22.5 6.2969 22.5H9.48873C9.81221 22.5 10.0747 22.2377 10.0747 21.9141V17.207C10.0747 16.6649 10.5156 16.2239 11.0578 16.2239H12.9404C13.4826 16.2239 13.9235 16.6649 13.9235 17.207V21.9141C13.9235 22.2377 14.1858 22.5 14.5095 22.5H17.7013C18.8897 22.5 19.8563 21.5334 19.8563 20.3453V14.3414H20.1578C20.6495 14.3414 21.1118 14.1499 21.4599 13.802C22.177 13.0844 22.1773 11.9171 21.4608 11.199Z'
      fill='currentColor'
    />
  </svg>
);
export { IconFillHome };
