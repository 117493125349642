import { PHONE_NUMBER_REGEX } from 'globalConstants';
import { DemandType } from 'graphql/main/mutations';
import * as yup from 'yup';

export interface RegisterSeeHouseForm {
  fullName?: string;
  phone?: string;
  email?: string;
  appointmentTime?: string;
  note?: string;
  demand: DemandType;
}

export const registerSeeHouseFormDefaultValues: RegisterSeeHouseForm = {
  fullName: undefined,
  phone: undefined,
  email: undefined,
  appointmentTime: undefined,
  note: undefined,
  demand: DemandType.seeHouseLive,
};

export const registerSeeHouseFormSchema = yup.object().shape({
  fullName: yup.string().required('Vui lòng nhập Họ và tên'),
  phone: yup
    .string()
    .required('Vui lòng nhập Số điện thoại')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  email: yup.string().nullable().email('Vui lòng nhập đúng định dạng email@xxx.com'),
  appointmentTime: yup.string().required('Vui lòng chọn thời gian hẹn'),
  demand: yup.string().required('Vui lòng chọn Nhu cầu cụ thể'),
});
