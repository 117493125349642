import { SVGProps } from 'react';

const IconBsxShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M11 6.91394V2.58594L6.293 7.29294L2.519 11.0669L6.36 14.2679L11 18.1349V13.8999C19.146 13.2859 22 17.9999 22 17.9999C22 15.0629 21.758 12.0149 19.449 9.70694C16.765 7.02194 12.878 6.83194 11 6.91394Z'
      fill='currentColor'
    />
  </svg>
);
export { IconBsxShare };
