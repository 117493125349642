import { SVGProps } from 'react';

const IconWrap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M21 5H3V7H21V5ZM3 19H10V17H3V19ZM3 13H18C19 13 20 13.43 20 15C20 16.57 19 17 18 17H16V15L12 18L16 21V19H18C20.95 19 22 17.73 22 15C22 12.28 21 11 18 11H3V13Z'
      fill='currentColor'
    />
  </svg>
);
export { IconWrap };
