import { gql } from '@apollo/client';
import { Int, String } from './get-real-estates';

export interface ListFloorByBuildingData {
  listFloorByBuilding?: [
    {
      rawId?: String;
      name?: String;
    },
  ];
}

export interface ListFloorByBuildingVariables {
  search?: String;
  buildingId: String;
  limit?: Int;
  page?: Int;
}
export const LIST_FLOOR_BY_BUILDING = gql`
  query listFloorByBuilding($search: String, $buildingId: String!, $limit: Int, $page: Int)
  @api(name: realEstate) {
    listFloorByBuilding(search: $search, buildingId: $buildingId, limit: $limit, page: $page) {
      rawId
      name
    }
  }
`;
