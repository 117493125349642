import { SVGProps } from 'react';

const IconFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M4.72301 4H20.2512C20.3913 4 20.5283 4.04068 20.6457 4.1171C20.7631 4.19353 20.8557 4.30239 20.9124 4.43048C20.969 4.55856 20.9873 4.70035 20.9649 4.8386C20.9425 4.97686 20.8804 5.10563 20.7862 5.20926L14.844 11.7457C14.723 11.8787 14.656 12.0521 14.656 12.232V17.3492C14.656 17.4682 14.6266 17.5854 14.5704 17.6903C14.5143 17.7952 14.4331 17.8847 14.334 17.9507L11.4422 19.8786C11.3334 19.9512 11.2068 19.9928 11.0761 19.9992C10.9454 20.0055 10.8155 19.9762 10.7001 19.9145C10.5847 19.8527 10.4883 19.7608 10.421 19.6486C10.3538 19.5363 10.3183 19.4079 10.3183 19.277V12.232C10.3183 12.0521 10.2512 11.8787 10.1303 11.7457L4.18807 5.20926C4.09386 5.10563 4.03178 4.97686 4.00937 4.8386C3.98697 4.70035 4.0052 4.55856 4.06186 4.43048C4.11852 4.30239 4.21117 4.19353 4.32854 4.1171C4.44591 4.04068 4.58296 4 4.72301 4V4Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconFilter };
