import { gql } from '@apollo/client';
import { ObjectID } from './get-real-estates';
import { RegisterInProject } from './list-register-see-house-list-in-project-user';

export interface GetDetailsRegisterSeeHouseUserData {
  getDetailsRegisterSeeHouseUser?: RegisterInProject;
}
export interface GetDetailsRegisterSeeHouseUserVariables {
  id?: ObjectID;
}

export const GET_DETAILS_REGISTER_SEE_HOUSE_USER = gql`
  query getDetailsRegisterSeeHouseUser($id: ObjectID) @api(name: biha) {
    getDetailsRegisterSeeHouseUser(id: $id) {
      id
      bookingCode
      fullName
      phone
      email
      priceList
      product {
        id
        projectName
        block {
          id
          name
        }
        fullAddress
        floor {
          id
          name
        }
        roomNumber
        area
        price
        tnccPrice
      }
      project {
        id
      }
      reasonCancel
      note
      appointmentTime
      registerType
      status
      source
      demand
      createdAt
      updatedAt
    }
  }
`;
