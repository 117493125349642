import { eCommerceClient } from 'axios/client';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AddProductParams,
  AddProductResponse,
  CartParams,
  CartResponse,
  CartSerialization,
  CartType,
  CheckoutParams,
  CheckoutResponse,
  DeleteCartProductParams,
  DeleteCartProductResponse,
  MessageType,
  UpdateCartProductParams,
  UpdateCartProductResponse,
  UpdateCartProductSerialization,
} from 'restful/e-commerce';
import { useAuthenticationContext } from '../authentication-provider';

interface ECommerceCartProps {
  cart?: CartSerialization;
  loading?: boolean;
  setCart?: Dispatch<SetStateAction<CartSerialization | undefined>>;
  setLoading?: (loading: boolean) => void;
  refetch?: () => void;
  addProduct?: (params: AddProductParams) => Promise<CartSerialization | MessageType | undefined>;
  updateProduct?: (
    params: UpdateCartProductParams,
  ) => Promise<UpdateCartProductSerialization | undefined>;
  deleteProduct?: (params: DeleteCartProductParams) => Promise<CartSerialization | undefined>;
  checkout?: (params: CheckoutParams) => Promise<CartSerialization | undefined>;
}
const ECommerceCart = createContext<ECommerceCartProps>({});

export const useCart = () => useContext(ECommerceCart);

const ECommerceCartProvider = ({ children }: PropsWithChildren) => {
  const { currentUser } = useAuthenticationContext();
  const { ecommerceData } = currentUser ?? {};
  const { userId } = ecommerceData ?? {};
  const [cart, setCart] = useState<CartSerialization>();
  const [loading, setLoading] = useState<boolean>();

  const fetchCart = async () => {
    const cartResponse = await eCommerceClient.get<CartParams, CartResponse>(`/v1/carts/get-cart`, {
      params: {
        type: CartType.cart,
      },
    });
    return cartResponse?.data?.data;
  };
  const refetch = async () => {
    setLoading(true);
    const response = await fetchCart();
    setCart(response);
    setLoading(false);
  };
  const addProduct = async (params: AddProductParams) => {
    const response = await eCommerceClient.post<AddProductParams, AddProductResponse>(
      '/v1/carts/add-product',
      params,
    );
    return response.data?.data;
  };
  const updateProduct = async (params: UpdateCartProductParams) => {
    const response = await eCommerceClient.post<UpdateCartProductParams, UpdateCartProductResponse>(
      '/v1/carts/update-product',
      params,
    );
    return response.data?.data;
  };
  const deleteProduct = async (data: DeleteCartProductParams) => {
    const response = await eCommerceClient.delete<
      DeleteCartProductParams,
      DeleteCartProductResponse
    >('/v1/carts', {
      data,
    });
    return response.data?.data;
  };
  const checkout = async (param: CheckoutParams) => {
    const response = await eCommerceClient.post<CheckoutParams, CheckoutResponse>(
      '/v1/carts/checkout',
      param,
    );
    return response.data?.data;
  };

  useEffect(() => {
    (async () => {
      if (userId) {
        setLoading(true);
        const response = await fetchCart();
        setCart(response);
        setLoading(false);
      }
    })();
  }, [userId]);

  return (
    <ECommerceCart.Provider
      value={{
        cart,
        loading,
        setCart,
        refetch,
        addProduct,
        updateProduct,
        deleteProduct,
        checkout,
      }}
    >
      {children}
    </ECommerceCart.Provider>
  );
};

export default ECommerceCartProvider;
