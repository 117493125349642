import { SVGProps } from 'react';

const IconCancelledOrder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.5 2.5H9.5C8.94772 2.5 8.5 2.94772 8.5 3.5V4.5C8.5 5.05228 8.94772 5.5 9.5 5.5H12.5C13.0523 5.5 13.5 5.05228 13.5 4.5V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5Z'
      fill='currentColor'
    />
    <path
      d='M17 3.5H14.5V4.5C14.5 5.03043 14.2893 5.53914 13.9142 5.91421C13.5391 6.28929 13.0304 6.5 12.5 6.5H9.5C8.96957 6.5 8.46086 6.28929 8.08579 5.91421C7.71071 5.53914 7.5 5.03043 7.5 4.5V3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H12.5C11.8388 20.6167 11.4884 19.5395 11.5033 18.4363C11.5182 17.333 11.8976 16.2657 12.5824 15.4006C13.2672 14.5355 14.2189 13.9211 15.2893 13.6533C16.3596 13.3855 17.4885 13.4793 18.5 13.92V5C18.5 4.60218 18.342 4.22064 18.0607 3.93934C17.7794 3.65804 17.3978 3.5 17 3.5ZM10.5 17.5H6.5C6.36739 17.5 6.24021 17.4473 6.14645 17.3536C6.05268 17.2598 6 17.1326 6 17C6 16.8674 6.05268 16.7402 6.14645 16.6464C6.24021 16.5527 6.36739 16.5 6.5 16.5H10.5C10.6326 16.5 10.7598 16.5527 10.8536 16.6464C10.9473 16.7402 11 16.8674 11 17C11 17.1326 10.9473 17.2598 10.8536 17.3536C10.7598 17.4473 10.6326 17.5 10.5 17.5ZM15.5 13.5H6.5C6.36739 13.5 6.24021 13.4473 6.14645 13.3536C6.05268 13.2598 6 13.1326 6 13C6 12.8674 6.05268 12.7402 6.14645 12.6464C6.24021 12.5527 6.36739 12.5 6.5 12.5H15.5C15.6326 12.5 15.7598 12.5527 15.8536 12.6464C15.9473 12.7402 16 12.8674 16 13C16 13.1326 15.9473 13.2598 15.8536 13.3536C15.7598 13.4473 15.6326 13.5 15.5 13.5ZM15.5 9.5H6.5C6.36739 9.5 6.24021 9.44732 6.14645 9.35355C6.05268 9.25979 6 9.13261 6 9C6 8.86739 6.05268 8.74021 6.14645 8.64645C6.24021 8.55268 6.36739 8.5 6.5 8.5H15.5C15.6326 8.5 15.7598 8.55268 15.8536 8.64645C15.9473 8.74021 16 8.86739 16 9C16 9.13261 15.9473 9.25979 15.8536 9.35355C15.7598 9.44732 15.6326 9.5 15.5 9.5Z'
      fill='currentColor'
    />
    <path
      d='M16.5 14.5C15.7089 14.5 14.9355 14.7346 14.2777 15.1741C13.6199 15.6136 13.1072 16.2384 12.8045 16.9693C12.5017 17.7002 12.4225 18.5044 12.5769 19.2804C12.7312 20.0563 13.1122 20.769 13.6716 21.3284C14.231 21.8878 14.9437 22.2688 15.7196 22.4231C16.4956 22.5775 17.2998 22.4983 18.0307 22.1955C18.7616 21.8928 19.3864 21.3801 19.8259 20.7223C20.2654 20.0645 20.5 19.2911 20.5 18.5C20.5 17.4391 20.0786 16.4217 19.3284 15.6716C18.5783 14.9214 17.5609 14.5 16.5 14.5ZM17.915 19.205C17.9619 19.2515 17.9991 19.3068 18.0244 19.3677C18.0498 19.4286 18.0629 19.494 18.0629 19.56C18.0629 19.626 18.0498 19.6914 18.0244 19.7523C17.9991 19.8132 17.9619 19.8685 17.915 19.915C17.8683 19.9613 17.8129 19.998 17.752 20.0229C17.691 20.0478 17.6258 20.0604 17.56 20.06C17.4942 20.0604 17.429 20.0478 17.368 20.0229C17.3071 19.998 17.2517 19.9613 17.205 19.915L16.5 19.205L15.795 19.915C15.7483 19.9613 15.6929 19.998 15.632 20.0229C15.571 20.0478 15.5058 20.0604 15.44 20.06C15.3742 20.0604 15.309 20.0478 15.248 20.0229C15.1871 19.998 15.1317 19.9613 15.085 19.915C15.0381 19.8685 15.0009 19.8132 14.9756 19.7523C14.9502 19.6914 14.9371 19.626 14.9371 19.56C14.9371 19.494 14.9502 19.4286 14.9756 19.3677C15.0009 19.3068 15.0381 19.2515 15.085 19.205L15.795 18.5L15.085 17.795C14.9909 17.7008 14.938 17.5732 14.938 17.44C14.938 17.3068 14.9909 17.1792 15.085 17.085C15.1792 16.9908 15.3069 16.938 15.44 16.938C15.5732 16.938 15.7009 16.9908 15.795 17.085L16.5 17.795L17.205 17.085C17.2992 16.9908 17.4269 16.938 17.56 16.938C17.6932 16.938 17.8209 16.9908 17.915 17.085C18.0092 17.1792 18.062 17.3068 18.062 17.44C18.062 17.5732 18.0092 17.7008 17.915 17.795L17.205 18.5L17.915 19.205Z'
      fill='currentColor'
    />
  </svg>
);

export { IconCancelledOrder };
