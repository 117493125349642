import { gql } from '@apollo/client';
import { Float, ID, Int, String } from './get-real-estates';

export interface Ewallet {
  id: ID;
  ownerId: String;
  balance?: Float;
  totalDeposit?: Float;
  totalWithdraw?: Float;
  totalCommission?: Float;
  totalReward?: Float;
  rewardBalance?: Float;
  commissionBalance?: Float;
  totalUsedReward?: Float;
  totalCommissionWithdraw?: Float;
  totalTransactionNetwork?: Float;
  amountBenefitNetwork?: Float;
  amountBenefitN1Network?: Float;
  amountBenefitN2Network?: Float;
  totalMemberNetwork?: Int;
  totalMemberN1Network?: Int;
  totalMemberN2Network?: Int;
  totalAmountNetwork?: Float;
}
export interface GetMyEwalletInfoData {
  getMyEwalletInfo?: Ewallet;
}

export const GET_MY_EWALLET_INFO = gql`
  query getMyEwalletInfo @api(name: ewallet) {
    getMyEwalletInfo {
      balance
      totalDeposit
      totalAmountNetwork
      commissionBalance
      totalCommission
      totalCommissionWithdraw
      amountBenefitN1Network
      amountBenefitN2Network
      amountBenefitNetwork
      totalTransactionNetwork
      totalMemberNetwork
      totalMemberN1Network
      totalMemberN2Network
      totalAmountNetwork
      totalWithdraw
    }
  }
`;
