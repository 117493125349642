import { SVGProps } from 'react';

const IconSearchFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='currentColor'
    {...props}
  >
    <path
      d='M21.6296 5.99156H10.4742C10.1676 5.08067 9.30598 4.42256 8.29299 4.42256C7.28 4.42256 6.41843 5.08067 6.11174 5.99156H4.37048C3.96599 5.99156 3.63806 6.31949 3.63806 6.72398C3.63806 7.12847 3.96599 7.4564 4.37048 7.4564H6.11179C6.41848 8.36729 7.28005 9.02539 8.29304 9.02539C9.30603 9.02539 10.1676 8.36729 10.4743 7.4564H21.6296C22.0341 7.4564 22.362 7.12847 22.362 6.72398C22.362 6.31949 22.0341 5.99156 21.6296 5.99156ZM8.29299 7.56055C7.83171 7.56055 7.45642 7.18526 7.45642 6.72398C7.45642 6.2627 7.83171 5.88741 8.29299 5.88741C8.75427 5.88741 9.12957 6.2627 9.12957 6.72398C9.12957 7.18526 8.75427 7.56055 8.29299 7.56055Z'
      fill='currentColor'
    />
    <path
      d='M21.6296 12.2676H19.8883C19.5816 11.3567 18.7199 10.6986 17.707 10.6986C16.6941 10.6986 15.8325 11.3567 15.5258 12.2676H4.37048C3.96599 12.2676 3.63806 12.5955 3.63806 13C3.63806 13.4045 3.96599 13.7324 4.37048 13.7324H15.5258C15.8325 14.6433 16.6941 15.3014 17.7071 15.3014C18.72 15.3014 19.5816 14.6433 19.8883 13.7324H21.6296C22.0341 13.7324 22.362 13.4045 22.362 13C22.362 12.5955 22.0341 12.2676 21.6296 12.2676ZM17.7071 13.8366C17.2458 13.8366 16.8705 13.4613 16.8705 13C16.8705 12.5387 17.2458 12.1634 17.7071 12.1634C18.1683 12.1634 18.5436 12.5387 18.5436 13C18.5436 13.4613 18.1683 13.8366 17.7071 13.8366Z'
      fill='currentColor'
    />
    <path
      d='M21.6296 18.5436H13.6123C13.3056 17.6327 12.444 16.9746 11.431 16.9746C10.418 16.9746 9.55647 17.6327 9.24978 18.5436H4.37048C3.96599 18.5436 3.63806 18.8715 3.63806 19.276C3.63806 19.6805 3.96599 20.0084 4.37048 20.0084H9.24978C9.55647 20.9193 10.418 21.5774 11.431 21.5774C12.444 21.5774 13.3056 20.9193 13.6123 20.0084H21.6296C22.0341 20.0084 22.362 19.6805 22.362 19.276C22.362 18.8715 22.0341 18.5436 21.6296 18.5436ZM11.431 20.1126C10.9698 20.1126 10.5945 19.7374 10.5945 19.2761C10.5945 18.8148 10.9698 18.4395 11.431 18.4395C11.8923 18.4395 12.2676 18.8147 12.2676 19.276C12.2676 19.7373 11.8923 20.1126 11.431 20.1126Z'
      fill='currentColor'
    />
  </svg>
);
export { IconSearchFilter };
