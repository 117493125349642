import { SVGProps } from 'react';

const IconHouseDoor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8.35399 1.14641C8.30755 1.09984 8.25237 1.0629 8.19163 1.03769C8.13088 1.01249 8.06576 0.999512 7.99999 0.999512C7.93423 0.999512 7.86911 1.01249 7.80836 1.03769C7.74762 1.0629 7.69244 1.09984 7.64599 1.14641L1.64599 7.14641C1.59959 7.19292 1.56281 7.24813 1.53776 7.30887C1.51271 7.36962 1.49988 7.4347 1.49999 7.50041V14.5004C1.49999 14.633 1.55267 14.7602 1.64644 14.854C1.74021 14.9477 1.86738 15.0004 1.99999 15.0004H6.49999C6.6326 15.0004 6.75978 14.9477 6.85355 14.854C6.94732 14.7602 6.99999 14.633 6.99999 14.5004V10.5004H8.99999V14.5004C8.99999 14.633 9.05267 14.7602 9.14644 14.854C9.24021 14.9477 9.36739 15.0004 9.49999 15.0004H14C14.1326 15.0004 14.2598 14.9477 14.3535 14.854C14.4473 14.7602 14.5 14.633 14.5 14.5004V7.50041C14.5001 7.4347 14.4873 7.36962 14.4622 7.30887C14.4372 7.24813 14.4004 7.19292 14.354 7.14641L13 5.79341V2.50041C13 2.3678 12.9473 2.24062 12.8535 2.14685C12.7598 2.05308 12.6326 2.00041 12.5 2.00041H11.5C11.3674 2.00041 11.2402 2.05308 11.1464 2.14685C11.0527 2.24062 11 2.3678 11 2.50041V3.79341L8.35399 1.14641ZM2.49999 14.0004V7.70741L7.99999 2.20741L13.5 7.70741V14.0004H9.99999V10.0004C9.99999 9.8678 9.94732 9.74062 9.85355 9.64685C9.75978 9.55308 9.6326 9.50041 9.49999 9.50041H6.49999C6.36739 9.50041 6.24021 9.55308 6.14644 9.64685C6.05267 9.74062 5.99999 9.8678 5.99999 10.0004V14.0004H2.49999Z'
      fill='currentColor'
    />
  </svg>
);
export { IconHouseDoor };
