import { SVGProps } from 'react';

const IconChartArea = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M10.238 4.0075L6.7235 2.1055C6.55529 2.02196 6.36702 1.98729 6.18008 2.00541C5.99314 2.02352 5.81504 2.09371 5.666 2.208L2 5.0005V1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H15V2.8675L10.238 4.0075ZM14 10.105L10.19 11.006L6.6755 9.564C6.5061 9.4999 6.32242 9.48325 6.14426 9.51586C5.9661 9.54846 5.80022 9.62907 5.6645 9.749L2 12.918V10.69L6.1875 5.99L9.697 8.8C9.84228 8.90792 10.0139 8.97472 10.194 8.99337C10.374 9.01202 10.5557 8.98184 10.72 8.906L14 7.301V10.105ZM6.262 2.9925L9.777 4.8945C9.98446 4.9975 10.2211 5.02576 10.447 4.9745L14 4.1325V6.189L10.3095 8.01L6.8 5.2C6.60044 5.04893 6.35127 4.97861 6.10218 5.00308C5.85308 5.02754 5.62235 5.14499 5.456 5.332L2 9.192V6.257L6.262 2.9925ZM2.275 14L6.3095 10.4945L9.824 11.9365C10.0073 12.0052 10.2067 12.0186 10.3975 11.975L14 11.13V14H2.275Z'
      fill='currentColor'
    />
  </svg>
);
export { IconChartArea };
