import { useState } from 'react';

export const useConfirmationModal = () => {
  const [showing, setShowing] = useState(false);
  const [data, setData] = useState<any>();

  const toggle = (confirmationData?: any) => {
    setShowing(!showing);
    setData(confirmationData);
  };

  return { showing, data, toggle };
};
