import { gql } from '@apollo/client';
import { CommissionData, TransactionPayload } from '../mutations';
import { UserUsers } from './get-list-broker';
import { AdditionalInfo } from './get-my-list-transactions';
import { DateTime, Float, ID, ObjectID, String, UserType } from './get-real-estates';
import { AddressType } from './list-companies-by-type';

export enum PaymentStatusType {
  Processing = 'Processing',
  Failed = 'Failed',
  Success = 'Success',
  Rejected = 'Rejected',
}

export interface GetTransactionDetailsData {
  getTransactionDetails: GeneralTransaction;
}
export interface GetTransactionDetailsVariables {
  transactionId: String;
}

export interface GeneralTransaction {
  id: ID;
  ownerId?: ObjectID;
  createdBy?: UserType;
  type?: String;
  status?: String;
  amount?: Float;
  basicAmount?: Float;
  vatAmount?: Float;
  paymentMethod?: String;
  typeOfWithdrawal?: String;
  content?: String;
  orderId?: String;
  address?: String;
  province?: AddressType;
  phone?: String;
  invoiceDate?: DateTime;
  invoiceImageUrls?: String[];
  members?: MemberAmount[];
  reasonOfFailure?: String;
  messageOfFailure?: String;
  paymentPayload?: TransactionPayload;
  metadata?: TransactionMetadata;
  commission?: CommissionData;
  createdAt?: String;
  updatedAt?: String;
  bankTransfer?: String;
  transactionInfo?: AdditionalInfo;
  bankCode?: String;
  updatedBy?: UserType;
  approvedBy?: UserType;
  approvedAt?: DateTime;
  typeOfTransaction?: String;
  user?: UserUsers;
  cointInOrOut?: Float;
  expired?: Boolean;
  memberInfo?: MemberInfo;
  developer?: UserType;
  source?: String;
  reasonReject?: String;
}
export interface MemberInfo {
  levelMember?: String;
  commissionReceived?: Float;
}
export interface MemberAmount {
  userId?: String;
  amount?: Float;
}
export interface TransactionMetadata {
  displayPostStartDate?: DateTime;
  displayPostEndDate?: DateTime;
  postId?: ObjectID;
  realEstateId?: ObjectID;
  realEstateType?: String;
}

export const GET_TRANSACTION_DETAILS = gql`
  query getTransactionDetails($transactionId: String!) @api(name: ewallet) {
    getTransactionDetails(transactionId: $transactionId) {
      status
    }
  }
`;
