import { useState } from 'react';

export const useModal = () => {
  const [showing, setShowing] = useState(false);

  const toggle = () => {
    setShowing(!showing);
  };

  return {
    showing,
    toggle,
  };
};
