import { SVGProps } from 'react';

const IconHandTap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='currentColor'
    {...props}
  >
    <path
      d='M9.61574 5.90745C9.61574 5.63732 9.72305 5.37826 9.91406 5.18725C10.1051 4.99624 10.3641 4.88893 10.6343 4.88893C10.9044 4.88893 11.1635 4.99624 11.3545 5.18725C11.5455 5.37826 11.6528 5.63732 11.6528 5.90745V10.4602L12.8852 10.5926L17.9167 12.8232C18.4565 13.0676 18.7824 13.6074 18.7824 14.1982V18.6389C18.7519 19.4741 18.0898 20.1362 17.2546 20.1667H10.6343C10.2472 20.1667 9.88056 20.0139 9.61574 19.7287L4.625 15.451L5.3787 14.6667C5.57222 14.4528 5.84722 14.3408 6.13241 14.3408H6.35648L9.61574 16.0926V5.90745ZM10.6343 1.83337C11.7148 1.83337 12.751 2.26261 13.5151 3.02664C14.2791 3.79068 14.7083 4.82694 14.7083 5.90745C14.7083 7.43523 13.8935 8.72874 12.6713 9.43152V8.18893C13.2926 7.62874 13.6898 6.81393 13.6898 5.90745C13.6898 5.09706 13.3679 4.31987 12.7949 3.74684C12.2218 3.17382 11.4446 2.85189 10.6343 2.85189C9.82388 2.85189 9.04668 3.17382 8.47365 3.74684C7.90063 4.31987 7.5787 5.09706 7.5787 5.90745C7.5787 6.81393 7.97593 7.62874 8.59722 8.18893V9.43152C7.375 8.72874 6.56019 7.43523 6.56019 5.90745C6.56019 4.82694 6.98942 3.79068 7.75345 3.02664C8.51749 2.26261 9.55375 1.83337 10.6343 1.83337Z'
      fill='currentColor'
    />
  </svg>
);
export { IconHandTap };
