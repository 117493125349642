import { LatLngTuple } from 'leaflet';
import { gt, padStart, round } from 'lodash';

export const convertDecimalDegreesToDegreesMinutesSeconds = ([
  latitude,
  longitude,
]: LatLngTuple) => {
  const absoluteLatitude = Math.abs(latitude);
  const absoluteLongitude = Math.abs(longitude);
  const degreesLatiude = Math.floor(absoluteLatitude);
  const minutesLatitude = Math.floor((absoluteLatitude - degreesLatiude) * 60);
  const secondsLatitude = padStart(
    round((absoluteLatitude - degreesLatiude - minutesLatitude / 60) * 3600, 2).toFixed(2),
    5,
    '0',
  );
  const degreesLongitude = Math.floor(absoluteLongitude);
  const minutesLongitude = Math.floor((absoluteLongitude - degreesLongitude) * 60);
  const secondsLongitude = padStart(
    round((absoluteLongitude - degreesLongitude - minutesLongitude / 60) * 3600, 2).toFixed(2),
    5,
    '0',
  );
  const directionLatitude = gt(latitude, 0) ? 'N' : 'S';
  const directionLongitude = gt(longitude, 0) ? 'E' : 'W';

  return `${degreesLatiude}° ${minutesLatitude}' ${secondsLatitude}" ${directionLatitude}, ${degreesLongitude}° ${minutesLongitude}' ${secondsLongitude}" ${directionLongitude}`;
};
