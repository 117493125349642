import { Rank, StatusTransactionEnum, StatusUpgradeRequestEnumType } from 'graphql/main/queries';

export interface HistoryUpdateAgentForm {
  requestId?: string;
  rank?: Rank[];
  statusTransaction?: StatusTransactionEnum[];
  statusRequest?: StatusUpgradeRequestEnumType[];
  amount?: Rank[];
}

export const historyUpdateAgentFormDefaultValues: HistoryUpdateAgentForm = {
  requestId: undefined,
  rank: undefined,
  statusTransaction: undefined,
  statusRequest: undefined,
  amount: undefined,
};
