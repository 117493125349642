import { gql } from '@apollo/client';
import { UserForeclosureType } from '../mutations';
import { FromToDate } from './get-b2c-users';
import { FromTo, Int, ObjectID, RealEstate, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export enum ForeclosureStatusEnum {
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum ForeclosureRejectedEnum {
  soldRequest = 'soldRequest',
  stopRequest = 'stopRequest',
  other = 'other',
}
export interface Foreclosure {
  approvedAt?: String;
  approvedBy?: UserForeclosureType;
  creator?: UserForeclosureType;
  editor?: UserForeclosureType;
  email?: String;
  fullName?: String;
  id?: String;
  note?: String;
  phone?: String;
  realEstate?: RealEstate;
  realEstateId?: String;
  requestCode?: String;
  status?: String;
  updatedAt?: String;
  createdAt?: String;
  rejectedStatus?: ForeclosureRejectedEnum;
  rejectedReason?: String;
}
export interface ListForeclosure extends PaginationOf {
  items?: Foreclosure[];
}

export interface GetListForeclosureRequestOnATMNhaData {
  getListForeclosureRequestOnATMNha?: ListForeclosure;
}
export interface GetListForeclosureRequestOnATMNhaVariables {
  requestCode?: String;
  name?: String;
  email?: String;
  phone?: String;
  province?: ObjectID;
  district?: ObjectID;
  ward?: ObjectID;
  street?: ObjectID;
  houseNumber?: String;
  price?: FromTo;
  pageNo?: String;
  parcel?: String;
  status?: ForeclosureStatusEnum[];
  createdAt?: FromToDate;
  updatedAt?: FromToDate;
  approvedAt?: FromToDate;
  limit?: Int;
  page?: Int;
}

export const GET_LIST_FORECLOSURE_REQUEST_ON_ATMNHA = gql`
  query getListForeclosureRequestOnATMNha(
    $requestCode: String
    $name: String
    $email: String
    $phone: String
    $province: ObjectID
    $district: ObjectID
    $ward: ObjectID
    $street: ObjectID
    $houseNumber: String
    $price: FromTo
    $pageNo: String
    $parcel: String
    $status: [ForeclosureStatusEnum]
    $createdAt: FromToDate
    $updatedAt: FromToDate
    $approvedAt: FromToDate
    $limit: Int
    $page: Int
  ) @api(name: realEstate) {
    getListForeclosureRequestOnATMNha(
      requestCode: $requestCode
      name: $name
      email: $email
      phone: $phone
      province: $province
      district: $district
      ward: $ward
      street: $street
      houseNumber: $houseNumber
      price: $price
      pageNo: $pageNo
      parcel: $parcel
      status: $status
      createdAt: $createdAt
      updatedAt: $updatedAt
      approvedAt: $approvedAt
      limit: $limit
      page: $page
    ) {
      total
      items {
        approvedAt
        createdAt
        status
        id
        note
        updatedAt
        fullName
        email
        phone
        requestCode
        realEstate {
          id
          isForSell
          type
          mediaUrls {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
          }
          area
          width
          length
          floorCount
          fullAddress
          postTitle
          postType
          isAuthorizedRe
          isForeclosure
          realEstateType
          hasAlley
          houseNumber
          street {
            name
            id
          }
          ward {
            id
            name
          }
          district {
            id
            name
          }
          province {
            id
            name
          }
          pageNo
          parcel
        }
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
