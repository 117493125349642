import { DemandOption } from 'components/desktop/service-notarization-document/demand';
import { Media } from 'components/desktop/service-notarization-document/media';
import { Option } from 'components/desktop/shared/form-select';
import { NAME_REGEX, PHONE_NUMBER_REGEX, TAX_REGEX } from 'globalConstants';
import { Address } from 'graphql/main/queries/list-projects-atmnha';
import { gte, isEqual, size } from 'lodash';
import * as yup from 'yup';

export enum typeNotarizationEnum {
  FLUCTUATION = 'FLUCTUATION',
  BANK_LOANS = 'BANK_LOANS',
  NOTARIZATION = 'NOTARIZATION',
}
export enum maritalStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED ',
}

export interface PersonalInfo {
  name?: string;
  idCard?: string;
  phone?: string;
  registeredResidence?: string;
}
export interface Attachments {
  images?: string[];
  attachments?: string[];
}
export interface NotarizationDocumentFormCreate {
  createdBy?: string;
  province?: Address;
  district?: Address;
  ward?: Address;
  street?: Address;
  apartmentNumber?: string;
  fullNameReferrer?: string;
  phoneReferrer?: string;
  emailReferrer?: string;
  provinceOffice?: Address;
  districtOffice?: Address;
  typeNotarization?: typeNotarizationEnum;
  idNotaryOffice?: string;
  isForSale?: boolean;
  maritalStatusBuy?: maritalStatusEnum;
  privatePropertyBuy?: boolean;
  maritalStatusSale?: maritalStatusEnum;
  privatePropertySale?: boolean;
  infoPersonSale?: PersonalInfo[];
  infoPersonBuy?: PersonalInfo[];
  priceContract?: number;
  purposeUsingLand?: string;
  area?: number;
  construcArea?: number;
  sheet?: string;
  plot?: string;
  certificateNumber?: string;
  issueNumber?: string;
  issuanceDate?: string;
  notarizationDate?: string;
  description?: string;
  attachmentSale?: Attachments;
  attachmentBuy?: Attachments;
  attachmentCertificate?: Attachments;
}

export interface NotarizationDocumentForm {
  userId?: string;
  provinceOfNotaryOffice?: Option;
  provinceOfNotaryOfficeId?: string;
  districtOfNotaryOffice?: Option;
  districtOfNotaryOfficeId?: string;
  notaryOffice?: Option;
  notaryOfficeId?: string;
  demand?: string;
  maritalStatusOfOwner?: string;
  fullNameOfOwner?: string;
  taxIdOfOwner?: string;
  phoneNumberOfOwner?: string;
  permanentAddressRegistrationOfficeOfOwner?: string;
  assetStatusOfOwner?: string;
  fullNameOwner1?: string;
  taxIdOwner1?: string;
  phoneNumberOwner1?: string;
  permanentAddressRegistrationOfficeOwner1?: string;
  fullNameOwner2?: string;
  taxIdOwner2?: string;
  phoneNumberOwner2?: string;
  permanentAddressRegistrationOfficeOwner2?: string;
  maritalStatusOfCustomer?: string;
  fullNameOfCustomer?: string;
  taxIdOfCustomer?: string;
  phoneNumberOfCustomer?: string;
  permanentAddressRegistrationOfficeOfCustomer?: string;
  assetStatusOfCustomer?: string;
  fullNameCustomer1?: string;
  taxIdCustomer1?: string;
  phoneNumberCustomer1?: string;
  permanentAddressRegistrationOfficeCustomer1?: string;
  fullNameCustomer2?: string;
  taxIdCustomer2?: string;
  phoneNumberCustomer2?: string;
  permanentAddressRegistrationOfficeCustomer2?: string;
  price?: string;
  provinceId?: string;
  province?: Option;
  districtId?: string;
  district?: Option;
  wardId?: string;
  ward?: Option;
  streetId?: string;
  street?: Option;
  houseNumber?: string;
  purposeUseLandId?: string;
  purposeUseLand?: Option;
  area?: string;
  constructionArea?: string;
  sheet?: string;
  plot?: string;
  certificateNumber?: string;
  issueNumber?: string;
  provinceOfIssuance?: string;
  districtOfIssuance?: string;
  certificateIssueDatetime?: string;
  issueDate?: string;
  description?: string;
  fullNameReferrer?: string;
  phoneNumberReferrer?: string;
  emailReferrer?: string;
  imageUrlsOfOwner?: Media[];
  documentUrlsOfOwner?: Media[];
  mediaUrlsOfOwner?: Media[];
  imageUrlsOfCustomer?: Media[];
  documentUrlsOfCustomer?: Media[];
  mediaUrlsOfCustomer?: Media[];
  imageUrlsOfCertificate?: Media[];
  documentUrlsOfCertificate?: Media[];
  mediaUrlsOfCertificate?: Media[];
}

export const notarizationDocumentFormDefaultValues: NotarizationDocumentForm = {
  provinceOfNotaryOffice: undefined,
  provinceOfNotaryOfficeId: undefined,
  districtOfNotaryOffice: undefined,
  districtOfNotaryOfficeId: undefined,
  notaryOffice: undefined,
  notaryOfficeId: undefined,
  demand: 'true',
  maritalStatusOfOwner: 'SINGLE',
  fullNameOfOwner: '',
  taxIdOfOwner: '',
  phoneNumberOfOwner: '',
  permanentAddressRegistrationOfficeOfOwner: undefined,
  assetStatusOfOwner: undefined,
  fullNameOwner1: '',
  taxIdOwner1: '',
  phoneNumberOwner1: '',
  permanentAddressRegistrationOfficeOwner1: undefined,
  fullNameOwner2: '',
  taxIdOwner2: '',
  phoneNumberOwner2: '',
  permanentAddressRegistrationOfficeOwner2: undefined,
  maritalStatusOfCustomer: 'SINGLE',
  fullNameOfCustomer: '',
  taxIdOfCustomer: '',
  phoneNumberOfCustomer: '',
  permanentAddressRegistrationOfficeOfCustomer: undefined,
  assetStatusOfCustomer: undefined,
  fullNameCustomer1: '',
  taxIdCustomer1: '',
  phoneNumberCustomer1: '',
  permanentAddressRegistrationOfficeCustomer1: undefined,
  fullNameCustomer2: '',
  taxIdCustomer2: '',
  phoneNumberCustomer2: '',
  permanentAddressRegistrationOfficeCustomer2: undefined,
  price: '',
  province: undefined,
  provinceId: undefined,
  district: undefined,
  districtId: undefined,
  ward: undefined,
  wardId: undefined,
  street: undefined,
  streetId: undefined,
  houseNumber: undefined,
  purposeUseLand: undefined,
  purposeUseLandId: undefined,
  area: '',
  constructionArea: '',
  sheet: '',
  plot: '',
  certificateNumber: '',
  issueNumber: '',
  certificateIssueDatetime: '',
  issueDate: '',
  provinceOfIssuance: '',
  districtOfIssuance: '',
  description: '',
  fullNameReferrer: '',
  phoneNumberReferrer: undefined,
  emailReferrer: '',
  imageUrlsOfOwner: undefined,
  documentUrlsOfOwner: undefined,
  mediaUrlsOfOwner: undefined,
  imageUrlsOfCustomer: undefined,
  documentUrlsOfCustomer: undefined,
  mediaUrlsOfCustomer: undefined,
  imageUrlsOfCertificate: undefined,
  documentUrlsOfCertificate: undefined,
  mediaUrlsOfCertificate: undefined,
};

export const notarizationDocumentFormSchema = yup.object().shape({
  provinceOfNotaryOfficeId: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  notaryOfficeId: yup.string().required('Vui lòng chọn tên phòng công chứng'),
  demand: yup.string().required('Vui lòng chọn loại đối tượng'),
  maritalStatusOfOwner: yup.string(),
  fullNameOfOwner: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('fullNameValidation', 'Vui lòng nhập họ và tên người bán', function (fullNameOfOwner) {
        const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
        if (
          isEqual(maritalStatusOfOwner, 'SINGLE') ||
          (isEqual(maritalStatusOfOwner, 'MARRIED') && isEqual(assetStatusOfOwner, ['true']))
        ) {
          if (!fullNameOfOwner) {
            return this.createError({ message: 'Vui lòng nhập họ và tên người bán' });
          }
          if (!fullNameOfOwner.match(NAME_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người cho thuê',
        function (fullNameOfOwner) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (
            isEqual(maritalStatusOfOwner, 'SINGLE') ||
            (isEqual(maritalStatusOfOwner, 'MARRIED') && isEqual(assetStatusOfOwner, ['true']))
          ) {
            if (!fullNameOfOwner) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người cho thuê' });
            }
            if (!fullNameOfOwner.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  taxIdOfOwner: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người bán', function (taxIdOfOwner) {
        const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
        if (
          isEqual(maritalStatusOfOwner, 'SINGLE') ||
          (isEqual(maritalStatusOfOwner, 'MARRIED') && isEqual(assetStatusOfOwner, ['true']))
        ) {
          if (!taxIdOfOwner) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người bán' });
          }
          if (!taxIdOfOwner.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test(
        'taxIdValidation',
        'Vui lòng nhập số CMND/CCCD người cho thuê',
        function (taxIdOfOwner) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (
            isEqual(maritalStatusOfOwner, 'SINGLE') ||
            (isEqual(maritalStatusOfOwner, 'MARRIED') && isEqual(assetStatusOfOwner, ['true']))
          ) {
            if (!taxIdOfOwner) {
              return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người cho thuê' });
            }
            if (!taxIdOfOwner.match(TAX_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  phoneNumberOfOwner: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Vui lòng nhập số số điện thoại người bán',
        function (phoneNumberOfOwner) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (
            isEqual(maritalStatusOfOwner, 'SINGLE') ||
            (isEqual(maritalStatusOfOwner, 'MARRIED') && isEqual(assetStatusOfOwner, ['true']))
          ) {
            if (!phoneNumberOfOwner) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người bán' });
            }
            if (!phoneNumberOfOwner.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Vui lòng nhập số số điện thoại người cho thuê',
        function (phoneNumberOfOwner) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (
            isEqual(maritalStatusOfOwner, 'SINGLE') ||
            (isEqual(maritalStatusOfOwner, 'MARRIED') && isEqual(assetStatusOfOwner, ['true']))
          ) {
            if (!phoneNumberOfOwner) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người cho thuê' });
            }
            if (!phoneNumberOfOwner.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  fullNameOfCustomer: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người mua',
        function (fullNameOfCustomer) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'SINGLE') ||
            (isEqual(maritalStatusOfCustomer, 'MARRIED') &&
              isEqual(assetStatusOfCustomer, ['true']))
          ) {
            if (!fullNameOfCustomer) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người mua' });
            }
            if (!fullNameOfCustomer.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người thuê',
        function (fullNameOfCustomer) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'SINGLE') ||
            (isEqual(maritalStatusOfCustomer, 'MARRIED') &&
              isEqual(assetStatusOfCustomer, ['true']))
          ) {
            if (!fullNameOfCustomer) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người thuê' });
            }
            if (!fullNameOfCustomer.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  taxIdOfCustomer: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người mua', function (taxIdOfCustomer) {
        const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
        if (
          isEqual(maritalStatusOfCustomer, 'SINGLE') ||
          (isEqual(maritalStatusOfCustomer, 'MARRIED') && isEqual(assetStatusOfCustomer, ['true']))
        ) {
          if (!taxIdOfCustomer) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người mua' });
          }
          if (!taxIdOfCustomer.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người thuê', function (taxIdOfCustomer) {
        const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
        if (
          isEqual(maritalStatusOfCustomer, 'SINGLE') ||
          (isEqual(maritalStatusOfCustomer, 'MARRIED') && isEqual(assetStatusOfCustomer, ['true']))
        ) {
          if (!taxIdOfCustomer) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người thuê' });
          }
          if (!taxIdOfCustomer.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
  }),
  phoneNumberOfCustomer: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Vui lòng nhập số số điện thoại người mua',
        function (phoneNumberOfCustomer) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'SINGLE') ||
            (isEqual(maritalStatusOfCustomer, 'MARRIED') &&
              isEqual(assetStatusOfCustomer, ['true']))
          ) {
            if (!phoneNumberOfCustomer) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người mua' });
            }
            if (!phoneNumberOfCustomer.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Vui lòng nhập số số điện thoại người thuê',
        function (phoneNumberOfCustomer) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'SINGLE') ||
            (isEqual(maritalStatusOfCustomer, 'MARRIED') &&
              isEqual(assetStatusOfCustomer, ['true']))
          ) {
            if (!phoneNumberOfCustomer) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người thuê' });
            }
            if (!phoneNumberOfCustomer.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  fullNameOwner1: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người bán 1',
        function (fullNameOfOwner1) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!fullNameOfOwner1) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người bán 1' });
            }
            if (!fullNameOfOwner1.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người cho thuê 1',
        function (fullNameOfOwner) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!fullNameOfOwner) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người cho thuê 1' });
            }
            if (!fullNameOfOwner.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  taxIdOwner1: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người bán 1', function (taxIdOwner1) {
        const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
        if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
          if (!taxIdOwner1) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người bán 1' });
          }
          if (!taxIdOwner1.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test('taxIdValidation', 'Vui lòng số CMND/CCCD người cho thuê 1', function (taxIdOwner1) {
        const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
        if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
          if (!taxIdOwner1) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD người cho thuê 1' });
          }
          if (!taxIdOwner1.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
  }),
  phoneNumberOwner1: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'phoneNumberOwner1Validation',
        'Vui lòng nhập số số điện thoại người bán 1',
        function (phoneNumberOwner1) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!phoneNumberOwner1) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người bán 1' });
            }
            if (!phoneNumberOwner1.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'taxIdValidation',
        'Vui lòng nhập số số điện thoại người cho thuê 1',
        function (phoneNumberOwner1) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!phoneNumberOwner1) {
              return this.createError({
                message: 'Vui lòng nhập số số điện thoại người cho thuê 1',
              });
            }
            if (!phoneNumberOwner1.match(TAX_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  fullNameOwner2: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'fullNameOwner2Validation',
        'Vui lòng nhập họ và tên người bán 1',
        function (fullNameOfOwner2) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!fullNameOfOwner2) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người bán 2' });
            }
            if (!fullNameOfOwner2.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người cho thuê 2',
        function (fullNameOfOwner) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!fullNameOfOwner) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người cho thuê 2' });
            }
            if (!fullNameOfOwner.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  taxIdOwner2: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người bán 2', function (taxIdOwner2) {
        const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
        if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
          if (!taxIdOwner2) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người bán 2' });
          }
          if (!taxIdOwner2.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test('taxIdValidation', 'Vui lòng số CMND/CCCD người cho thuê 2', function (taxIdOwner2) {
        const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
        if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
          if (!taxIdOwner2) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD người cho thuê 2' });
          }
          if (!taxIdOwner2.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
  }),
  phoneNumberOwner2: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'phoneNumberOwner2Validation',
        'Vui lòng nhập số số điện thoại người bán 2',
        function (phoneNumberOwner2) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!phoneNumberOwner2) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người bán 2' });
            }
            if (!phoneNumberOwner2.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'taxIdValidation',
        'Vui lòng nhập số số điện thoại người cho thuê 2',
        function (phoneNumberOwner2) {
          const { maritalStatusOfOwner, assetStatusOfOwner } = this.parent;
          if (isEqual(maritalStatusOfOwner, 'MARRIED') && !isEqual(assetStatusOfOwner, ['true'])) {
            if (!phoneNumberOwner2) {
              return this.createError({
                message: 'Vui lòng nhập số số điện thoại người cho thuê 2',
              });
            }
            if (!phoneNumberOwner2.match(TAX_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  fullNameCustomer1: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người mua 1',
        function (fullNameOfCustomer1) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!fullNameOfCustomer1) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người mua 1' });
            }
            if (!fullNameOfCustomer1.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người thuê 1',
        function (fullNameOfCustomer) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!fullNameOfCustomer) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người thuê 1' });
            }
            if (!fullNameOfCustomer.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  taxIdCustomer1: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người mua 1', function (taxIdCustomer1) {
        const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
        if (
          isEqual(maritalStatusOfCustomer, 'MARRIED') &&
          !isEqual(assetStatusOfCustomer, ['true'])
        ) {
          if (!taxIdCustomer1) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người mua 1' });
          }
          if (!taxIdCustomer1.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test('taxIdValidation', 'Vui lòng số CMND/CCCD người thuê 1', function (taxIdCustomer1) {
        const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
        if (
          isEqual(maritalStatusOfCustomer, 'MARRIED') &&
          !isEqual(assetStatusOfCustomer, ['true'])
        ) {
          if (!taxIdCustomer1) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD người thuê 1' });
          }
          if (!taxIdCustomer1.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
  }),
  phoneNumberCustomer1: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'phoneNumberCustomer1Validation',
        'Vui lòng nhập số số điện thoại người mua 1',
        function (phoneNumberCustomer1) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!phoneNumberCustomer1) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người mua 1' });
            }
            if (!phoneNumberCustomer1.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'taxIdValidation',
        'Vui lòng nhập số số điện thoại người thuê 1',
        function (phoneNumberCustomer1) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!phoneNumberCustomer1) {
              return this.createError({
                message: 'Vui lòng nhập số số điện thoại người thuê 1',
              });
            }
            if (!phoneNumberCustomer1.match(TAX_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  fullNameCustomer2: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người mua 2',
        function (fullNameOfCustomer1) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!fullNameOfCustomer1) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người mua 2' });
            }
            if (!fullNameOfCustomer1.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'fullNameValidation',
        'Vui lòng nhập họ và tên người thuê 2',
        function (fullNameOfCustomer) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!fullNameOfCustomer) {
              return this.createError({ message: 'Vui lòng nhập họ và tên người thuê 2' });
            }
            if (!fullNameOfCustomer.match(NAME_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập họ và tên hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  taxIdCustomer2: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test('taxIdValidation', 'Vui lòng nhập số CMND/CCCD người mua 2', function (taxIdCustomer2) {
        const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
        if (
          isEqual(maritalStatusOfCustomer, 'MARRIED') &&
          !isEqual(assetStatusOfCustomer, ['true'])
        ) {
          if (!taxIdCustomer2) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD người mua 2' });
          }
          if (!taxIdCustomer2.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng nhập số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
    otherwise: yup
      .string()
      .test('taxIdValidation', 'Vui lòng số CMND/CCCD người thuê 2', function (taxIdCustomer2) {
        const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
        if (
          isEqual(maritalStatusOfCustomer, 'MARRIED') &&
          !isEqual(assetStatusOfCustomer, ['true'])
        ) {
          if (!taxIdCustomer2) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD người thuê 2' });
          }
          if (!taxIdCustomer2.match(TAX_REGEX)) {
            return this.createError({ message: 'Vui lòng số CMND/CCCD hợp lệ' });
          }
        }
        return true;
      }),
  }),
  phoneNumberCustomer2: yup.string().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .string()
      .test(
        'phoneNumberCustomer2Validation',
        'Vui lòng nhập số số điện thoại người mua 2',
        function (phoneNumberCustomer2) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!phoneNumberCustomer2) {
              return this.createError({ message: 'Vui lòng nhập số số điện thoại người mua 2' });
            }
            if (!phoneNumberCustomer2.match(PHONE_NUMBER_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
    otherwise: yup
      .string()
      .test(
        'taxIdValidation',
        'Vui lòng nhập số số điện thoại người thuê 2',
        function (phoneNumberCustomer2) {
          const { maritalStatusOfCustomer, assetStatusOfCustomer } = this.parent;
          if (
            isEqual(maritalStatusOfCustomer, 'MARRIED') &&
            !isEqual(assetStatusOfCustomer, ['true'])
          ) {
            if (!phoneNumberCustomer2) {
              return this.createError({
                message: 'Vui lòng nhập số số điện thoại người thuê 2',
              });
            }
            if (!phoneNumberCustomer2.match(TAX_REGEX)) {
              return this.createError({ message: 'Vui lòng nhập số điện thoại hợp lệ' });
            }
          }
          return true;
        },
      ),
  }),
  provinceId: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  districtId: yup.string().required('Vui lòng chọn quận/ huyện'),
  streetId: yup.string().required('Vui lòng chọn đường'),
  area: yup.string().required('Vui lòng nhập diện tích đất'),
  sheet: yup.string().required('Vui lòng nhập số tờ'),
  plot: yup.string().required('Vui lòng nhập số thửa'),
  fullNameReferrer: yup
    .string()
    .test('is-valid-name', 'Vui lòng nhập họ và tên hợp lệ', function (value) {
      if (!value) {
        return true;
      }
      return NAME_REGEX.test(value);
    })
    .notRequired(),
  phoneNumberReferrer: yup
    .string()
    .test('is-valid-phone-number', 'Vui lòng nhập số điện thoại hợp lệ', function (value) {
      if (!value) {
        return true;
      }
      return PHONE_NUMBER_REGEX.test(value);
    })
    .notRequired(),
  emailReferrer: yup.string().email('Vui lòng nhập đúng định dạng email').nullable(),
  imageUrlsOfOwner: yup.array().notRequired(),
  documentUrlsOfOwner: yup.array().notRequired(),
  mediaUrlsOfOwner: yup.array().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .array()
      .test(
        'media-validate',
        'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin người bán',
        function () {
          const { imageUrlsOfOwner, documentUrlsOfOwner } = this.parent;
          const sizeMedia = size(imageUrlsOfOwner) + size(documentUrlsOfOwner);
          return gte(sizeMedia, 1);
        },
      ),
    otherwise: yup
      .array()
      .test(
        'media-validate',
        'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin người cho thuê',
        function () {
          const { imageUrlsOfOwner, documentUrlsOfOwner } = this.parent;
          const sizeMedia = size(imageUrlsOfOwner) + size(documentUrlsOfOwner);
          return gte(sizeMedia, 1);
        },
      ),
  }),
  imageUrlsOfCustomer: yup.array().notRequired(),
  documentUrlsOfCustomer: yup.array().notRequired(),
  mediaUrlsOfCustomer: yup.array().when('demand', {
    is: DemandOption?.[0]?.value,
    then: yup
      .array()
      .test(
        'media-validate',
        'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin người mua',
        function () {
          const { imageUrlsOfCustomer, documentUrlsOfCustomer } = this.parent;
          const sizeMedia = size(imageUrlsOfCustomer) + size(documentUrlsOfCustomer);
          return gte(sizeMedia, 1);
        },
      ),
    otherwise: yup
      .array()
      .test(
        'media-validate',
        'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin người thuê',
        function () {
          const { imageUrlsOfCustomer, documentUrlsOfCustomer } = this.parent;
          const sizeMedia = size(imageUrlsOfCustomer) + size(documentUrlsOfCustomer);
          return gte(sizeMedia, 1);
        },
      ),
  }),
  imageUrlsOfCertificate: yup.array().notRequired(),
  documentUrlsOfCertificate: yup.array().notRequired(),
  mediaUrlsOfCertificate: yup
    .array()
    .test(
      'media-validate',
      'Vui lòng up ít nhất 1 hình ảnh hoặc 1 tài liệu cho thông tin giấy chứng nhận và hợp đồng mua bán',
      function () {
        const { imageUrlsOfCertificate, documentUrlsOfCertificate } = this.parent;
        const sizeMedia = size(imageUrlsOfCertificate) + size(documentUrlsOfCertificate);
        return gte(sizeMedia, 1);
      },
    ),
});
