import { SVGProps } from 'react';

const IconMoneyGram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='41'
    height='40'
    viewBox='0 0 41 40'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_6123_7687)'>
      <path
        d='M40.5 20C40.5 31.045 31.545 40 20.5 40C9.455 40 0.5 31.045 0.5 20C0.5 16.9567 1.17833 14.075 2.395 11.4917C1.63097 13.3951 1.2329 15.4256 1.22167 17.4767C1.22167 26.5333 8.53333 27.8433 11.645 27.815C16.3733 27.7717 21.9017 25.455 26.5583 21.1633L25.7733 25.58C25.5967 26.5883 26.2567 27.4767 27.2667 27.4833H27.6833C28.7283 27.4833 29.4717 26.6317 29.66 25.5783L31.3367 16.1417C31.5367 15.095 30.8483 14.2417 29.8017 14.2417H20.3467C19.2967 14.2417 18.335 14.7983 18.1467 15.855L18.0733 16.28C17.9233 17.285 18.6233 18.0417 19.625 18.0417H24.5583C24.4786 18.1181 24.3997 18.1953 24.3217 18.2733C20.9217 21.49 16.7283 23.2533 13.255 23.2533C9.78833 23.2533 5.905 21.065 5.905 16.1383C5.905 5.925 15.4833 0 20.5 0C31.5233 0 40.5 8.955 40.5 20Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6123_7687'>
        <rect width='40' height='40' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconMoneyGram };
