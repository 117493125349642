import { gql } from '@apollo/client';
import { B2CUser } from '../queries';

export interface ChangePrimaryPhoneVariables {
  oldPhone: string;
  newPhone: string;
  phoneVerifyCode: string;
}
export interface ChangePrimaryPhoneData {
  msg?: string;
  payload?: B2CUser;
  success?: boolean;
}

export const CHANGE_PRIMARY_PHONE = gql`
  mutation changePrimaryPhone($oldPhone: String!, $newPhone: String!, $phoneVerifyCode: String!)
  @api(name: users) {
    changePrimaryPhone(
      oldPhone: $oldPhone
      newPhone: $newPhone
      phoneVerifyCode: $phoneVerifyCode
    ) {
      msg
      # payload
      success
    }
  }
`;
