import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfString } from './verify-otp';

export interface ChangePasswordVariables {
  oldPassword: String;
  newPassword: String;
  confirmNewPassword: String;
}
export interface ChangePasswordData {
  changePassword?: MutationOfString;
}

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) @api(name: users) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      success
      msg
      payload
    }
  }
`;
