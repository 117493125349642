import { SVGProps } from 'react';

const IconHeartOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.3291 3.7213C14.6607 3.71239 13.0747 4.44511 12 5.72128C10.932 4.43639 9.34156 3.70158 7.67085 3.7213C4.53892 3.7213 2 6.26022 2 9.39215C2 14.7593 11.3671 19.9997 11.7468 20.2023C11.9001 20.3047 12.0999 20.3047 12.2532 20.2023C12.6329 19.9997 22 14.8352 22 9.39215C22 6.26022 19.461 3.7213 16.3291 3.7213ZM12 19.1896C10.5316 18.3289 3.01266 13.7466 3.01266 9.39215C3.01266 6.81951 5.09821 4.73391 7.6709 4.73391C9.24634 4.71322 10.7207 5.50787 11.5696 6.83516C11.742 7.07284 12.0745 7.12581 12.3121 6.9534C12.3575 6.92045 12.3975 6.88057 12.4304 6.83516C13.8383 4.68197 16.7252 4.0778 18.8784 5.48573C20.1965 6.34763 20.9899 7.81719 20.9873 9.3921C20.9873 13.7972 13.4683 18.3542 12 19.1896Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.25'
    />
  </svg>
);
export { IconHeartOutline };
