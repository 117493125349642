import { NAME_REGEX, PHONE_NUMBER_REGEX } from 'globalConstants';
import * as yup from 'yup';

export interface ViewHouseCalendarForm {
  contactName: string;
  phone: string;
  address: string;
  note?: string;
  meetTime?: string;
}

export const viewHouseCalendarFormDefaultValues: ViewHouseCalendarForm = {
  contactName: '',
  phone: '',
  address: '',
  note: '',
  meetTime: undefined,
};

export const viewHouseCalendarFormSchema = yup
  .object()
  .shape({
    contactName: yup
      .string()
      .required('Vui lòng nhập họ và tên')
      .matches(NAME_REGEX, 'Vui lòng nhập họ và tên hợp lệ'),
    phone: yup
      .string()
      .required('Vui lòng nhập số điện thoại')
      .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
    address: yup.string().required('Vui lòng nhập địa chỉ'),
    note: yup.string(),
    meetTime: yup.string().required('Vui lòng chọn ngày hẹn'),
  })
  .required();
