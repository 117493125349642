import { SVGProps } from 'react';

const IconEyeOffOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.90909 4.9729L4.07273 3.81836L19.2727 19.0184L18.1182 20.182L15.3182 17.382C14.2727 17.7274 13.1636 17.9093 12 17.9093C7.45455 17.9093 3.57273 15.082 2 11.0911C2.62727 9.49109 3.62727 8.082 4.9 6.96381L2.90909 4.9729ZM12 8.36381C12.7233 8.36381 13.417 8.65115 13.9285 9.16261C14.4399 9.67408 14.7273 10.3678 14.7273 11.0911C14.7273 11.4093 14.6727 11.7184 14.5727 12.0002L11.0909 8.51836C11.3727 8.41836 11.6818 8.36381 12 8.36381ZM12 4.2729C16.5455 4.2729 20.4273 7.10018 22 11.0911C21.2545 12.982 19.9909 14.6184 18.3636 15.8093L17.0727 14.5093C18.3091 13.6547 19.3273 12.4911 20.0182 11.0911C18.5182 8.03654 15.4182 6.09109 12 6.09109C11.0091 6.09109 10.0364 6.25472 9.12727 6.54563L7.72727 5.15472C9.03636 4.59109 10.4818 4.2729 12 4.2729ZM3.98182 11.0911C5.48182 14.1456 8.58182 16.0911 12 16.0911C12.6273 16.0911 13.2455 16.0275 13.8182 15.9002L11.7455 13.8184C10.4455 13.682 9.40909 12.6456 9.27273 11.3456L6.18182 8.24563C5.28182 9.01836 4.52727 9.982 3.98182 11.0911Z'
      fill='currentColor'
    />
  </svg>
);
export { IconEyeOffOutline };
