import { gql } from '@apollo/client';
import { PaymentTypeEnum, ProductReservation } from '../mutations';
import { Boolean, Int, ObjectID, String } from './get-real-estates';
import { InternalFromToDateStringInputType, RangeValueInput } from './list-projects-atmnha';

export enum reservationStatusEnum {
  pending = 'pending',
  success = 'success',
  rejected = 'rejected',
  locked = 'locked',
  approved = 'approved',
}

export interface GetListReservationsATMNhaData {
  getListReservationsATMNha?: {
    items?: ProductReservation[];
    hasNextPage?: Boolean;
    hasPrevPage?: Boolean;
    limit?: Int;
    nextPage?: Int;
    page?: Int;
    pagingCounter?: Int;
    prevPage?: Int;
    total?: Int;
    totalPages?: Int;
  };
}

export interface GetListReservationsATMNhaVariables {
  reservationCode?: String;
  customerName?: String;
  customerPhone?: String;
  reservationStatus?: reservationStatusEnum[];
  paymentType?: PaymentTypeEnum[];
  totalStagePrice?: RangeValueInput;
  rangeAmount?: RangeValueInput;
  sellerName?: String;
  sellerPhone?: String;
  department?: String;
  bookerType?: String;
  code?: String;
  createdAt?: InternalFromToDateStringInputType;
  creatorName?: String;
  searchString?: String;
  projectId?: ObjectID[];
  projectName?: String;
  page?: Int;
  limit?: Int;
  sort?: String;
}
export const GET_LIST_RESERVATIONS_ATMNHA = gql`
  query getListReservationsATMNha(
    $reservationCode: String
    $customerName: String
    $customerPhone: String
    $reservationStatus: [reservationStatusEnum]
    $paymentType: [PaymentTypeEnum]
    $totalStagePrice: RangeValueInput
    $rangeAmount: RangeValueInput
    $sellerName: String
    $sellerPhone: String
    $department: String
    $bookerType: String
    $code: String
    $createdAt: InternalFromToDateStringInputType
    $creatorName: String
    $searchString: String
    $projectId: [ObjectID]
    $projectName: String
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: biha) {
    getListReservationsATMNha(
      reservationCode: $reservationCode
      customerName: $customerName
      customerPhone: $customerPhone
      reservationStatus: $reservationStatus
      paymentType: $paymentType
      totalStagePrice: $totalStagePrice
      rangeAmount: $rangeAmount
      sellerName: $sellerName
      sellerPhone: $sellerPhone
      department: $department
      bookerType: $bookerType
      code: $code
      createdAt: $createdAt
      creatorName: $creatorName
      searchString: $searchString
      projectId: $projectId
      projectName: $projectName
      page: $page
      limit: $limit
      sort: $sort
    ) {
      items {
        id
        block {
          name
          id
          otherName
        }
        createdAt
        customerName
        customerPhone
        editable
        floor {
          name
          id
        }
        isInvestor
        product {
          id
          isForSell
          shortAddress
          productCode
        }
        projectId
        project {
          mediaUrls {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          projectType
          projectName
          fullAddress
        }
        product {
          block {
            name
            id
          }
          floor {
            name
            id
          }
          isForSell
        }
        reservationStatus
        roomNumber
        totalStagePrice
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
