import { SVGProps } from 'react';

const IconHomeSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.7008 17.325C18.1042 16.6925 18.3333 15.9317 18.3333 15.125C18.3333 12.8333 16.5 11 14.2083 11C11.9167 11 10.0833 12.8333 10.0833 15.125C10.0833 17.4167 11.9167 19.25 14.2083 19.25C15.0058 19.25 15.7575 19.0208 16.39 18.6267L19.25 21.4408L20.5242 20.1667L17.7008 17.325ZM14.2083 17.4167C12.9433 17.4167 11.9167 16.39 11.9167 15.125C11.9167 13.86 12.9433 12.8333 14.2083 12.8333C15.4733 12.8333 16.5 13.86 16.5 15.125C16.5 16.39 15.4733 17.4167 14.2083 17.4167ZM4.58333 18.3333V11H1.83333L11 2.75L20.1667 11H18.4983C17.4167 9.8725 15.895 9.16667 14.2083 9.16667C10.9267 9.16667 8.24999 11.8433 8.24999 15.125C8.24999 16.3075 8.59833 17.4167 9.19416 18.3333H4.58333Z'
      fill='currentColor'
    />
  </svg>
);
export { IconHomeSearch };
