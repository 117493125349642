import { gql } from '@apollo/client';
import { DepositMethodEnum } from '../mutations';
import { Float, Int, String } from './get-real-estates';
import { GeneralTransaction } from './get-transaction-details';
import { PaginationOf } from './get-user-saved-real-estate';
import { AddressType } from './list-companies-by-type';
import { InternalFromToDateStringInputType, RangeValueInput } from './list-projects-atmnha';

export enum PaymentStatusEnum {
  Processing = 'Processing',
  Failed = 'Failed',
  Success = 'Success',
  Rejected = 'Rejected',
}
export enum WithdrawalMethodEnum {
  ViewOwnerPost = 'ViewOwnerPost',
  ViewOwnerSellPost = 'ViewOwnerSellPost',
  ViewOwnerRentPost = 'ViewOwnerRentPost',
  CreatePost = 'CreatePost',
  RenewPost = 'RenewPost',
  RenewSellRE = 'RenewSellRE',
  RenewRentRE = 'RenewRentRE',
  RenewBuyNRE = 'RenewBuyNRE',
  RenewRentNRE = 'RenewRentNRE',
  RenewPR = 'RenewPR',
  TransferMoney = 'TransferMoney',
  CreateNewSellRE = 'CreateNewSellRE',
  CreateNewRentRE = 'CreateNewRentRE',
  CreateNewBuyNRE = 'CreateNewBuyNRE',
  CreateNewRentNRE = 'CreateNewRentNRE',
  CreateNewPR = 'CreateNewPR',
  CreateNewARE = 'CreateNewARE',
  RenewARE = 'RenewARE',
  ViewPlaningArea = 'ViewPlaningArea',
  CalculateValuation = 'CalculateValuation',
}
export enum TransactionEnum {
  CashIn = 'CashIn',
  PayOut = 'PayOut',
  Reward = 'Reward',
}
export enum TypeOfStansaction {
  CashIn = 'CashIn',
  CreateNewSellRE = 'CreateNewSellRE',
  RenewSellRE = 'RenewSellRE',
}
export enum SourceEnum {
  main = 'main',
  reward = 'reward',
  commission = 'commission',
}
export interface TransactionPayload {
  paymentUrl?: String;
  transactionId?: String;
}
export interface CommissionData {
  balance?: Float;
  commission?: Float;
  developer?: String;
  grand?: String;
  grandCommission?: Float;
  isRead?: Boolean;
  totalDeposit?: Float;
}

export interface AdditionalInfo {
  bankAccountNumber?: String;
  bankOwnerName?: String;
  district?: AddressType;
  email?: String;
  houseNumber?: String;
  images?: String[];
  phone?: String;
  province?: AddressType;
  street?: AddressType;
  transactionId?: String;
  transactionNote?: String;
  ward?: AddressType;
}
export interface MyListTransactionsType extends PaginationOf {
  items?: GeneralTransaction[];
  totalAmountTransaction?: Float;
  totalPaymentLater?: Float;
  totalPrepayment?: Float;
  totalTransaction?: Int;
}

export interface GetMyListTransactionsVariables {
  search?: String;
  orderId?: String;
  amount?: RangeValueInput;
  paymentMethod?: DepositMethodEnum[];
  source?: SourceEnum[];
  status?: PaymentStatusEnum[];
  createdAt?: InternalFromToDateStringInputType;
  fullName?: String;
  phone?: String;
  memberId?: String;
  invoiceDate?: InternalFromToDateStringInputType;
  typeOfWithdrawal?: WithdrawalMethodEnum[];
  type?: TransactionEnum[];
  typeOfStansaction?: TypeOfStansaction[];
  fromDate?: Date;
  toDate?: Date;
  page?: Int;
  limit?: Int;
  sort?: String;
}
export interface GetMyListTransactionsData {
  getMyListTransactions: MyListTransactionsType;
}

export const GET_MY_LIST_TRANSACTIONS = gql`
  query getMyListTransactions(
    $search: String
    $orderId: String
    $amount: RangeValueInput
    $paymentMethod: [DepositMethodEnum]
    $source: [SourceEnum]
    $status: [PaymentStatusEnum]
    $createdAt: InternalFromToDateStringInputType
    $fullName: String
    $phone: String
    $memberId: String
    $invoiceDate: InternalFromToDateStringInputType
    $typeOfWithdrawal: [WithdrawalMethodEnum]
    $type: [TransactionEnum]
    $typeOfStansaction: [TypeOfStansaction]
    $fromDate: Date
    $toDate: Date
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: ewallet) {
    getMyListTransactions(
      search: $search
      orderId: $orderId
      amount: $amount
      paymentMethod: $paymentMethod
      source: $source
      status: $status
      createdAt: $createdAt
      fullName: $fullName
      phone: $phone
      memberId: $memberId
      invoiceDate: $invoiceDate
      typeOfWithdrawal: $typeOfWithdrawal
      type: $type
      typeOfStansaction: $typeOfStansaction
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      limit: $limit
      sort: $sort
    ) {
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
      totalPaymentLater
      totalPrepayment
      totalTransaction
      totalAmountTransaction
      items {
        id
        ownerId
        reasonReject
        createdBy {
          id
          fullName
          type
          province {
            id
            name
          }
          avatar {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
          }
        }
        type
        status
        amount
        basicAmount
        vatAmount
        paymentMethod
        typeOfWithdrawal
        content
        orderId
        address
        province {
          id
          name
        }
        phone
        invoiceDate
        invoiceImageUrls
        members {
          amount
          userId
        }
        reasonOfFailure
        messageOfFailure
        paymentPayload {
          paymentUrl
          transactionId
        }

        metadata {
          displayPostEndDate
          displayPostStartDate
          postId
          realEstateId
          realEstateType
        }

        commission {
          balance
          commission
          developer
          grand
          grandCommission
          isRead
          totalDeposit
        }
        createdAt
        updatedAt
        bankTransfer
        bankCode
        updatedBy {
          id
          fullName
          type
          province {
            id
            name
          }
          avatar {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
          }
        }
        approvedBy {
          id
          fullName
          type
          province {
            id
            name
          }
          avatar {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
          }
        }

        approvedAt
        typeOfTransaction
        user {
          id
          fullName
          type
          province {
            id
            name
          }
          avatar {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
          }
        }
        cointInOrOut
        expired
        source
      }
    }
  }
`;
