import { gql } from '@apollo/client';
import { ObjectID, String } from '../queries';
import { MutationOfAccessTokenType } from './sign-in';

export interface SignUpDataInputType {
  type?: String;
  signupType?: String;
  phone: String;
  phoneVerifyToken: String;
  password: String;
  fullName?: String;
  companyName?: String;
  taxId?: String;
  companyFields?: String[];
  developer?: ObjectID;
}

export interface SignUpData {
  signUp?: MutationOfAccessTokenType;
}
export interface SignUpVariables {
  inputData?: SignUpDataInputType;
}

export const SIGN_UP = gql`
  mutation signUp($inputData: SignUpDataInputType) @api(name: users) {
    signUp(inputData: $inputData) {
      success
      msg
      payload {
        accessToken
        refreshToken
      }
    }
  }
`;
