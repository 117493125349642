import { SVGProps } from 'react';

const IconCrown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M4.2308 18.3766C4.25418 18.4752 4.29726 18.568 4.35746 18.6495C4.41765 18.731 4.49371 18.7995 4.58107 18.8509C4.66842 18.9022 4.76525 18.9353 4.86574 18.9483C4.96624 18.9612 5.06831 18.9537 5.16582 18.9262C9.63668 17.6918 14.3584 17.6913 18.8295 18.9249C18.927 18.9524 19.029 18.9599 19.1295 18.947C19.2299 18.934 19.3267 18.9009 19.4141 18.8496C19.5014 18.7983 19.5774 18.7298 19.6376 18.6484C19.6978 18.5669 19.7409 18.4741 19.7643 18.3756L22.1539 8.22086C22.1859 8.08492 22.1793 7.94274 22.1348 7.81035C22.0904 7.67796 22.0098 7.5606 21.9023 7.47151C21.7948 7.38241 21.6645 7.32512 21.5261 7.30607C21.3878 7.28702 21.2468 7.30698 21.1192 7.3637L16.377 9.47136C16.2058 9.54747 16.0122 9.55671 15.8345 9.49728C15.6568 9.43785 15.5078 9.31404 15.4168 9.15023L12.6556 4.18014C12.5907 4.06322 12.4956 3.9658 12.3803 3.89797C12.2651 3.83014 12.1337 3.79437 12 3.79437C11.8662 3.79437 11.7349 3.83014 11.6196 3.89797C11.5044 3.9658 11.4093 4.06322 11.3444 4.18014L8.58321 9.15023C8.49221 9.31404 8.34318 9.43785 8.16547 9.49728C7.98775 9.55671 7.79423 9.54747 7.62299 9.47136L2.88004 7.36338C2.75245 7.30668 2.61156 7.28671 2.47323 7.30574C2.3349 7.32477 2.20464 7.38203 2.09709 7.47109C1.98955 7.56014 1.909 7.67745 1.86452 7.80981C1.82003 7.94216 1.81338 8.0843 1.84531 8.22023L4.2308 18.3766Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconCrown };
