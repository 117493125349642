import { gql } from '@apollo/client';
import { Boolean, String } from './get-real-estates';

export interface CheckPhoneExistData {
  checkPhoneExist?: Boolean;
}
export interface CheckPhoneExistVariables {
  phone?: String;
}

export const CHECK_PHONE_EXIST = gql`
  query checkPhoneExist($phone: String) @api(name: users) {
    checkPhoneExist(phone: $phone)
  }
`;
