import { SVGProps } from 'react';

const IconCalendarTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='16.5703' cy='10.3828' r='0.703125' fill='currentColor' />
    <path
      d='M18.1875 4.40625H17.2734V3.70312C17.2734 3.31479 16.9586 3 16.5703 3C16.182 3 15.8672 3.31479 15.8672 3.70312V4.40625H12.668V3.70312C12.668 3.31479 12.3532 3 11.9648 3C11.5765 3 11.2617 3.31479 11.2617 3.70312V4.40625H8.09766V3.70312C8.09766 3.31479 7.78287 3 7.39453 3C7.0062 3 6.69141 3.31479 6.69141 3.70312V4.40625H5.8125C4.26169 4.40625 3 5.66794 3 7.21875V18.1875C3 19.7383 4.26169 21 5.8125 21H11.1914C11.5797 21 11.8945 20.6852 11.8945 20.2969C11.8945 19.9085 11.5797 19.5938 11.1914 19.5938H5.8125C5.03709 19.5938 4.40625 18.9629 4.40625 18.1875V7.21875C4.40625 6.44334 5.03709 5.8125 5.8125 5.8125H6.69141V6.51562C6.69141 6.90396 7.0062 7.21875 7.39453 7.21875C7.78287 7.21875 8.09766 6.90396 8.09766 6.51562V5.8125H11.2617V6.51562C11.2617 6.90396 11.5765 7.21875 11.9648 7.21875C12.3532 7.21875 12.668 6.90396 12.668 6.51562V5.8125H15.8672V6.51562C15.8672 6.90396 16.182 7.21875 16.5703 7.21875C16.9586 7.21875 17.2734 6.90396 17.2734 6.51562V5.8125H18.1875C18.9629 5.8125 19.5938 6.44334 19.5938 7.21875V11.2266C19.5938 11.6149 19.9085 11.9297 20.2969 11.9297C20.6852 11.9297 21 11.6149 21 11.2266V7.21875C21 5.66794 19.7383 4.40625 18.1875 4.40625Z'
      fill='currentColor'
    />
    <path
      text-rule='evenodd'
      clipRule='evenodd'
      d='M12.4922 16.7461C12.4922 14.4005 14.4005 12.4922 16.7461 12.4922C19.0917 12.4922 21 14.4005 21 16.7461C21 19.0917 19.0917 21 16.7461 21C14.4005 21 12.4922 19.0917 12.4922 16.7461ZM13.8984 16.7461C13.8984 18.3163 15.1759 19.5938 16.7461 19.5938C18.3163 19.5938 19.5938 18.3163 19.5938 16.7461C19.5938 15.1759 18.3163 13.8984 16.7461 13.8984C15.1759 13.8984 13.8984 15.1759 13.8984 16.7461Z'
      fill='currentColor'
    />
    <path
      d='M17.7656 16.043H17.4492V15.3047C17.4492 14.9164 17.1344 14.6016 16.7461 14.6016C16.3578 14.6016 16.043 14.9164 16.043 15.3047V16.7461C16.043 17.1344 16.3578 17.4492 16.7461 17.4492H17.7656C18.154 17.4492 18.4688 17.1344 18.4688 16.7461C18.4688 16.3578 18.154 16.043 17.7656 16.043Z'
      fill='currentColor'
    />
    <circle cx='13.5117' cy='10.3828' r='0.703125' fill='currentColor' />
    <circle cx='10.4531' cy='13.4414' r='0.703125' fill='currentColor' />
    <circle cx='7.39453' cy='10.3828' r='0.703125' fill='currentColor' />
    <circle cx='7.39453' cy='13.4414' r='0.703125' fill='currentColor' />
    <circle cx='7.39453' cy='16.5' r='0.703125' fill='currentColor' />
    <circle cx='10.4531' cy='16.5' r='0.703125' fill='currentColor' />
    <circle cx='10.4531' cy='10.3828' r='0.703125' fill='currentColor' />
  </svg>
);
export { IconCalendarTime };
