import {
  ConstructionQuality,
  LandUsageEnum,
  MinusPoint,
  PlusPoint,
  Utility,
} from 'graphql/main/mutations';
import { gt, isEmpty, isEqual, isUndefined, lte, toNumber } from 'lodash';
import moment from 'moment-timezone';
import * as yup from 'yup';

export interface TownhouseValuationForm {
  nation?: 'vietnam';
  province?: string;
  district?: string;
  ward?: string;
  street?: string;
  houseNumber?: string;
  hasAlley?: 'true' | 'false';
  alleyCount?: string;
  alleyWidth?: string;
  landUsage?: LandUsageEnum;
  width?: string;
  length?: string;
  rear?: string;
  area?: number;
  violatedArea?: string;
  legalArea?: number;
  landAreaForConstruction?: string;
  floorCount?: string;
  utilities?: Utility[];
  floorAreaForConstruction?: number;
  constructionQuality?: ConstructionQuality;
  restQualityPercent?: string;
  plusPoints?: PlusPoint[];
  minusPoints?: MinusPoint[];
  valuationDate?: string;
}

export const townhouseValuationFormDefaultValues: TownhouseValuationForm = {
  nation: 'vietnam',
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  houseNumber: undefined,
  hasAlley: 'false',
  alleyCount: undefined,
  alleyWidth: undefined,
  landUsage: LandUsageEnum.residentialLand,
  width: undefined,
  length: undefined,
  rear: undefined,
  area: 0,
  violatedArea: undefined,
  legalArea: 0,
  landAreaForConstruction: undefined,
  floorCount: '1',
  utilities: undefined,
  floorAreaForConstruction: 0,
  constructionQuality: undefined,
  restQualityPercent: undefined,
  plusPoints: undefined,
  minusPoints: undefined,
  valuationDate: moment('2022-12').format('YYYY-MM'),
};

export const townhouseValuationFormSchema = yup.object().shape({
  nation: yup.string().required('Vui lòng chọn quốc gia'),
  province: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  district: yup.string().required('Vui lòng chọn quận/ huyện'),
  ward: yup.string(),
  street: yup.string().required('Vui lòng chọn đường'),
  houseNumber: yup.string().required('Vui lòng nhập số nhà').max(200, 'Số nhà không quá 200 ký tự'),
  hasAlley: yup.boolean().required('Vui lòng nhập chọn mặt tiền hay hẻm'),
  alleyCount: yup.string().when('hasAlley', {
    is: true,
    then: yup.string().required('Vui lòng chọn số lần quẹo hẻm'),
  }),
  alleyWidth: yup.string().when('hasAlley', {
    is: true,
    then: yup.string().required('Vui lòng nhập độ rộng hẻm'),
  }),
  landUsage: yup.string().required('Vui lòng chọn mục đích sử dụng đất'),
  width: yup
    .string()
    .required('Vui lòng nhập chiều ngang')
    .max(8, 'Chiều ngang không quá 8 chữ số')
    .test('Positive', 'Vui lòng nhập số lớn hơn 0', (value) => gt(toNumber(value), 0)),
  length: yup
    .string()
    .required('Vui lòng nhập chiều dài')
    .max(9, 'Chiểu dài không quá 9 chữ số')
    .test('Positive', 'Vui lòng nhập số lớn hơn 0', (value) => gt(toNumber(value), 0)),
  rear: yup.string().max(10, 'Mặt hậu không quá 10 chữ số'),
  area: yup.number().positive(),
  violatedArea: yup
    .string()
    .max(16, 'Diện tích đất vi phạm lộ giới không quá 16 chữ số')
    .test(
      'Is less than area',
      'Giá trị không được lớn hơn diện tích',
      (value, context) =>
        isUndefined(value) ||
        isEqual(toNumber(context.parent.area), 0) ||
        lte(toNumber(value), toNumber(context.parent.area)),
    )
    .test(
      'Is positive',
      'Vui lòng nhập số lớn hơn 0',
      (value) => isEmpty(value) || lte(0, toNumber(value)),
    ),
  legalArea: yup.number().positive(),
  landAreaForConstruction: yup
    .string()
    .max(16, 'Diện tích đất xây dựng không quá 16 chữ số')
    .test(
      'Is positive',
      'Vui lòng nhập số lớn hơn 0',
      (value) => isEmpty(value) || lte(0, toNumber(value)),
    ),
  floorCount: yup
    .number()
    .typeError('Vui lòng chọn hoặc nhập số tầng')
    .min(0, 'Vui lòng nhập số lớn hơn 0')
    .max(168, 'Giá trị không vượt quá 168'),
  utilities: yup.array().nullable(),
  floorAreaForConstruction: yup.number(),
  constructionQuality: yup.string(),
  restQualityPercent: yup
    .string()
    .test(
      'Is positive',
      'Vui lòng nhập số lớn hơn 0',
      (value) => isEmpty(value) || lte(0, toNumber(value)),
    )
    .test(
      'Is percentage number',
      'Chất lượng còn lại không quá 100%',
      (value) => isEmpty(value) || lte(toNumber(value), 100),
    ),
  plusPoints: yup.array().nullable(),
  minusPoints: yup.array().nullable(),
  valuationDate: yup.string(),
});
