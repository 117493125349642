import { SVGProps } from 'react';

const IconEyeOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12 9.27255C12.7233 9.27255 13.417 9.55989 13.9285 10.0713C14.4399 10.5828 14.7273 11.2765 14.7273 11.9998C14.7273 12.7231 14.4399 13.4168 13.9285 13.9283C13.417 14.4398 12.7233 14.7271 12 14.7271C11.2767 14.7271 10.583 14.4398 10.0715 13.9283C9.56006 13.4168 9.27273 12.7231 9.27273 11.9998C9.27273 11.2765 9.56006 10.5828 10.0715 10.0713C10.583 9.55989 11.2767 9.27255 12 9.27255ZM12 5.18164C16.5455 5.18164 20.4273 8.00891 22 11.9998C20.4273 15.9907 16.5455 18.818 12 18.818C7.45455 18.818 3.57273 15.9907 2 11.9998C3.57273 8.00891 7.45455 5.18164 12 5.18164ZM3.98182 11.9998C5.48182 15.0544 8.58182 16.9998 12 16.9998C15.4182 16.9998 18.5182 15.0544 20.0182 11.9998C18.5182 8.94528 15.4182 6.99982 12 6.99982C8.58182 6.99982 5.48182 8.94528 3.98182 11.9998Z'
      fill='currentColor'
    />
  </svg>
);
export { IconEyeOutline };
