import { SVGProps } from 'react';

const IconMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path d='M19 13H5V11H19V13Z' fill='currentColor' />
  </svg>
);
export { IconMinus };
