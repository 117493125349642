import { SVGProps } from 'react';

const IconPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1776_143987)'>
      <path
        d='M7.26442 9.17322C8.19219 10.1144 9.31477 10.841 10.5533 11.3021L12.2289 9.96877C12.2785 9.93456 12.3374 9.91625 12.3978 9.91625C12.4581 9.91625 12.517 9.93456 12.5666 9.96877L15.6778 11.9732C15.7959 12.0442 15.8958 12.1417 15.9698 12.258C16.0438 12.3743 16.0897 12.5062 16.1039 12.6433C16.1181 12.7803 16.1003 12.9189 16.0518 13.0479C16.0033 13.1768 15.9254 13.2928 15.8244 13.3865L14.3666 14.8266C14.1579 15.0328 13.9013 15.1842 13.6198 15.2672C13.3383 15.3502 13.0406 15.3622 12.7533 15.3021C9.88763 14.7102 7.24626 13.3241 5.13109 11.3021C3.06286 9.25999 1.63165 6.66159 1.01109 3.82211C0.949784 3.53928 0.962579 3.24541 1.04823 2.96898C1.13389 2.69256 1.2895 2.44294 1.49998 2.24433L3.01109 0.786551C3.1046 0.690456 3.21864 0.616762 3.34466 0.570985C3.47069 0.525209 3.60544 0.508537 3.73883 0.522217C3.87221 0.535897 4.00077 0.579576 4.11488 0.649982C4.22899 0.720388 4.3257 0.815697 4.39776 0.928773L6.46887 3.99988C6.50459 4.04811 6.52387 4.10654 6.52387 4.16655C6.52387 4.22657 6.50459 4.28499 6.46887 4.33322L5.10442 5.97322C5.57801 7.18697 6.31586 8.28007 7.26442 9.17322Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1776_143987'>
        <rect width='16' height='16' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconPhone };
