import { SVGProps } from 'react';

const IconStoreOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.21659 2.5C3.85826 2.5 3.51659 2.61667 3.19993 2.85C2.88326 3.08333 2.69993 3.38333 2.61659 3.75L1.75826 7.425C1.54993 8.33333 1.71659 9.125 2.26659 9.80833L2.49993 10.0417V15.8333C2.49993 16.25 2.66659 16.6667 3.00826 16.9917C3.34993 17.3167 3.74993 17.5 4.16659 17.5H15.8333C16.2499 17.5 16.6666 17.3333 16.9916 16.9917C17.3166 16.65 17.4999 16.25 17.4999 15.8333V10.0417L17.7333 9.80833C18.2833 9.125 18.4499 8.33333 18.2416 7.425L17.3833 3.75C17.2749 3.38333 17.0833 3.08333 16.7749 2.85C16.4749 2.61667 16.1499 2.5 15.7833 2.5H4.21659ZM15.7416 4.14167L16.6416 7.81667C16.7166 8.175 16.6416 8.5 16.4083 8.79167C16.1999 9.05 15.9416 9.16667 15.6249 9.16667C15.3666 9.16667 15.1416 9.08333 14.9583 8.88333C14.7749 8.69167 14.6749 8.46667 14.6499 8.2L14.1416 4.16667L15.7416 4.14167ZM4.21659 4.16667H5.85826L5.34993 8.2C5.24993 8.85833 4.92493 9.16667 4.37493 9.16667C4.03326 9.16667 3.77493 9.05 3.59159 8.79167C3.35826 8.5 3.28326 8.175 3.35826 7.81667L4.21659 4.16667ZM7.54159 4.16667H9.16659V8.08333C9.16659 8.375 9.07493 8.625 8.86659 8.85C8.65826 9.06667 8.39992 9.16667 8.08326 9.16667C7.79992 9.16667 7.55826 9.06667 7.36659 8.825C7.17493 8.58333 7.08326 8.33333 7.08326 8.05V7.91667L7.54159 4.16667ZM10.8333 4.16667H12.4583L12.9166 7.91667C12.9833 8.26667 12.9166 8.55833 12.6749 8.80833C12.4583 9.05833 12.1749 9.16667 11.8333 9.16667C11.5749 9.16667 11.3416 9.06667 11.1333 8.85C10.9249 8.625 10.8333 8.375 10.8333 8.08333V4.16667ZM6.20826 10.0417C6.73326 10.5583 7.38326 10.8333 8.16659 10.8333C8.86659 10.8333 9.48326 10.5583 9.99993 10.0417C10.5749 10.5583 11.2083 10.8333 11.9166 10.8333C12.6416 10.8333 13.2666 10.5583 13.7916 10.0417C14.2583 10.5583 14.8833 10.8333 15.6666 10.8333H15.8583V15.8333H4.16659V10.8333H4.37493C5.13326 10.8333 5.74159 10.5583 6.20826 10.0417Z'
      fill='currentColor'
    />
  </svg>
);
export { IconStoreOutline };
