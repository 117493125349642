import * as yup from 'yup';
export interface ECommerceReviewForm {
  rating_value?: number;
  name?: string;
  quick_comment?: string;
  comment?: string;
  images?: File[];
}

export const eCommerceReviewFormDefaultValues: ECommerceReviewForm = {
  rating_value: 5,
  name: undefined,
  quick_comment: undefined,
  comment: undefined,
  images: [],
};

export const eCommerceReviewFormSchema = yup.object().shape({
  quick_comment: yup.string().required('Vui lòng nhập tiêu đề'),
  comment: yup.string().required('Vui lòng nhập nội dung'),
});
