import { SVGProps } from 'react';

const IconCRM = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    {...props}
  >
    <path
      d='M7.5 3.33801C9.01952 2.45891 10.7445 1.99731 12.5 2.00001C18.023 2.00001 22.5 6.47701 22.5 12C22.5 17.523 18.023 22 12.5 22C6.977 22 2.5 17.523 2.5 12C2.5 10.179 2.987 8.47001 3.838 7.00001'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M14.8934 14.415V9.58499H15.6676L17.442 13.5792H17.2296L19.0041 9.58499H19.7714V14.415H18.9698V10.8319H19.2165L17.5928 14.415H17.0652L15.4415 10.8319H15.695V14.415H14.8934Z'
      fill='currentColor'
    />
    <path
      d='M10.0762 14.415V9.58499H12.1863C12.7161 9.58499 13.1249 9.71059 13.4126 9.9618C13.705 10.213 13.8511 10.5624 13.8511 11.01C13.8511 11.3069 13.7849 11.5604 13.6524 11.7705C13.5245 11.9806 13.3396 12.1427 13.0975 12.2569C12.8554 12.3665 12.57 12.4213 12.2411 12.4213L12.337 12.2912H12.57C12.7618 12.2912 12.9331 12.3414 13.0838 12.4419C13.2345 12.5424 13.3578 12.6954 13.4538 12.9009L14.1594 14.415H13.1866L12.474 12.8461C12.4101 12.6862 12.3119 12.5812 12.1795 12.531C12.0516 12.4807 11.8963 12.4556 11.7136 12.4556H10.96V14.415H10.0762ZM10.96 11.8047H12.0424C12.353 11.8047 12.5882 11.7408 12.7481 11.6129C12.9125 11.4805 12.9947 11.2886 12.9947 11.0374C12.9947 10.7862 12.9125 10.5967 12.7481 10.4688C12.5882 10.3409 12.353 10.277 12.0424 10.277H10.96V11.8047Z'
      fill='currentColor'
    />
    <path
      d='M7.63324 14.4835C7.13083 14.4835 6.69921 14.383 6.33839 14.1821C5.98213 13.9765 5.70809 13.6888 5.51626 13.3188C5.32443 12.9443 5.22852 12.5035 5.22852 11.9966C5.22852 11.4896 5.32443 11.0511 5.51626 10.6812C5.70809 10.3112 5.98213 10.0257 6.33839 9.82478C6.69921 9.61925 7.13083 9.51648 7.63324 9.51648C7.97123 9.51648 8.27496 9.56444 8.54444 9.66035C8.81848 9.75627 9.0537 9.89786 9.2501 10.0851L8.96235 10.7839C8.74769 10.6104 8.53987 10.487 8.3389 10.414C8.14251 10.3363 7.91642 10.2975 7.66065 10.2975C7.17194 10.2975 6.79741 10.4459 6.53707 10.7428C6.2813 11.0351 6.15341 11.4531 6.15341 11.9966C6.15341 12.5401 6.2813 12.9603 6.53707 13.2572C6.79741 13.554 7.17194 13.7025 7.66065 13.7025C7.91642 13.7025 8.14251 13.6659 8.3389 13.5929C8.53987 13.5152 8.74769 13.3873 8.96235 13.2092L9.2501 13.908C9.0537 14.0907 8.81848 14.2323 8.54444 14.3328C8.27496 14.4333 7.97123 14.4835 7.63324 14.4835Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCRM };
