import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconFluctuationModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;

  return (
    <svg
      width='56'
      height='55'
      viewBox='0 0 56 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g>
        <rect x='0.5' width='55' height='55' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.6'
          cx='45.1113'
          cy='48.5'
          r={r ?? '36'}
          fill='url(#paint0_radial_14_8816)'
          fillOpacity='0.8'
        />
        <path
          d='M36.1667 18.1666H31.29C31.0511 17.4847 30.6062 16.8938 30.0168 16.4757C29.4274 16.0576 28.7226 15.8331 28 15.8331C27.2774 15.8331 26.5726 16.0576 25.9832 16.4757C25.3938 16.8938 24.9489 17.4847 24.71 18.1666H19.8333C19.2151 18.1685 18.6226 18.4149 18.1855 18.8521C17.7483 19.2893 17.5018 19.8817 17.5 20.5V36.8333C17.5018 37.4516 17.7483 38.044 18.1855 38.4812C18.6226 38.9184 19.2151 39.1648 19.8333 39.1666H36.1667C36.7849 39.1648 37.3774 38.9184 37.8145 38.4812C38.2517 38.044 38.4982 37.4516 38.5 36.8333V20.5C38.4982 19.8817 38.2517 19.2893 37.8145 18.8521C37.3774 18.4149 36.7849 18.1685 36.1667 18.1666ZM28 18.1666C28.2307 18.1666 28.4563 18.2351 28.6482 18.3633C28.84 18.4915 28.9896 18.6737 29.0779 18.8868C29.1662 19.1 29.1893 19.3346 29.1442 19.5609C29.0992 19.7872 28.9881 19.9951 28.825 20.1583C28.6618 20.3214 28.4539 20.4325 28.2276 20.4776C28.0013 20.5226 27.7667 20.4995 27.5535 20.4112C27.3404 20.3229 27.1581 20.1733 27.03 19.9815C26.9018 19.7896 26.8333 19.564 26.8333 19.3333C26.8343 19.0242 26.9575 18.728 27.1761 18.5094C27.3947 18.2908 27.6909 18.1676 28 18.1666ZM25.6667 24L28.9217 27.2596L31.8617 24.3196L30.3333 22.8333H35V27.5L33.5113 25.9705L28.924 30.5613L25.6667 27.3016L22.6497 30.3163L21 28.6666L25.6667 24ZM36.1667 35.6666H19.8333V33.3333H36.1667V35.6666Z'
          fill={fillColor2 ?? '#80888F'}
        />
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_14_8816'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(45.1113 48.5) rotate(90) scale(30)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_14_8816'>
          <rect x='0.5' width='55' height='55' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconFluctuationModule };
