import { SVGProps } from 'react';

const IconPaperPlane = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.59994 13.1996L6.05193 14.7106L15.9999 9.2836L9.99993 16.2836L18.5999 20.1996C18.747 20.264 18.9074 20.2921 19.0676 20.2814C19.2279 20.2707 19.3831 20.2216 19.5204 20.1382C19.6576 20.0549 19.7727 19.9397 19.856 19.8024C19.9393 19.6651 19.9883 19.5098 19.9989 19.3496L20.9989 4.3496C21.0101 4.17599 20.9758 4.00246 20.8995 3.8461C20.8233 3.68975 20.7076 3.55594 20.5639 3.45786C20.4202 3.35978 20.2534 3.3008 20.08 3.28673C19.9066 3.27266 19.7326 3.30398 19.5749 3.3776L2.57494 11.3776C2.40152 11.4599 2.25539 11.5902 2.15392 11.7531C2.05245 11.9161 1.9999 12.1047 2.00254 12.2966C2.00517 12.4885 2.06287 12.6757 2.16877 12.8358C2.27468 12.9958 2.42433 13.1221 2.59994 13.1996ZM7.99993 22.2836L12.7759 19.9676L7.99993 17.7396V22.2836Z'
      fill='currentColor'
    />
  </svg>
);
export { IconPaperPlane };
