import { SVGProps } from 'react';

const IconMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path d='M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z' fill='currentColor' />
  </svg>
);
export { IconMenu };
