import { SVGProps } from 'react';

const IconAddress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M19.799 5.165L17.424 3.335C17.2626 3.23139 17.0871 3.15157 16.903 3.098C16.7196 3.03864 16.5287 3.00565 16.336 3H9.5L10.301 8H16.336C16.5 8 16.705 7.963 16.902 7.902C17.099 7.841 17.289 7.757 17.423 7.666L19.798 5.834C19.933 5.743 20 5.622 20 5.5C20 5.378 19.933 5.257 19.799 5.165ZM8.5 1H7.5C7.36739 1 7.24021 1.05268 7.14645 1.14645C7.05268 1.24021 7 1.36739 7 1.5V5H3.664C3.498 5 3.294 5.037 3.097 5.099C2.899 5.159 2.71 5.242 2.576 5.335L0.201 7.165C0.066 7.256 0 7.378 0 7.5C0 7.621 0.066 7.742 0.201 7.835L2.576 9.667C2.71 9.758 2.899 9.842 3.097 9.902C3.294 9.963 3.498 10 3.664 10H7V18.5C7 18.6326 7.05268 18.7598 7.14645 18.8536C7.24021 18.9473 7.36739 19 7.5 19H8.5C8.63261 19 8.75979 18.9473 8.85355 18.8536C8.94732 18.7598 9 18.6326 9 18.5V1.5C9 1.36739 8.94732 1.24021 8.85355 1.14645C8.75979 1.05268 8.63261 1 8.5 1Z'
      fill='currentColor'
    />
  </svg>
);
export { IconAddress };
