import { gql } from '@apollo/client';
import { ID, String } from './get-real-estates';

export interface FriendStatus {
  status?: String;
  requestId?: ID;
}

export interface FriendStatusData {
  friendStatus?: FriendStatus;
}
export interface FriendStatusVariables {
  userId: ID;
}

export const FRIEND_STATUS = gql`
  query friendStatus($userId: ID!) @api(name: social) {
    friendStatus(userId: $userId) {
      status
      requestId
    }
  }
`;
