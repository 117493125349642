import { Popover } from '@headlessui/react';
import { IconSmile } from 'components/icons';
import { map } from 'lodash';
import Image from 'next/image';

const MessageReaction = () => {
  const messageReactions = [
    {
      iconUrl: '/icons/reactions/thumbs-up-reaction.svg',
      value: 'LIKE',
    },
    {
      iconUrl: '/icons/reactions/red-heart-reaction.svg',
      value: 'LOVE',
    },
    {
      iconUrl: '/icons/reactions/smiley-face-reaction.svg',
      value: 'HAHA',
    },
    {
      iconUrl: '/icons/reactions/surprise-face-reaction.svg',
      value: 'WOW',
    },

    {
      iconUrl: '/icons/reactions/crying-face-reaction.svg',
      value: 'SAD',
    },
    {
      iconUrl: '/icons/reactions/pouting-face-reaction.svg',
      value: 'ANGRY',
    },
  ];

  const clickReaction = (action: string) => {
    switch (action) {
      case 'LIKE':
        break;
      case 'LOVE':
        break;
      case 'HAHA':
        break;
      case 'WOW':
        break;
      case 'SAD':
        break;
      case 'ANGRY':
        break;
      default:
        break;
    }
  };

  return (
    <Popover id='message-reaction' className='relative'>
      <Popover.Button type='button'>
        <IconSmile className='min-h-[18px] min-w-[18px] text-text2' />
      </Popover.Button>
      <Popover.Panel
        className='absolute bottom-[-40px] left-1/2 flex min-h-[40px] -translate-x-1/2 items-center space-x-[16px] rounded-full bg-paper px-[12px]
shadow-9'
      >
        {map(messageReactions, (messageReaction, messageReactionIndex) => (
          <button
            key={messageReactionIndex}
            type='button'
            className='relative min-h-[24px] min-w-[24px] duration-[200ms] hover:origin-bottom hover:scale-[1.5]'
            onClick={() => {
              clickReaction(messageReaction.value);
            }}
          >
            <Image
              src={messageReaction.iconUrl}
              alt={messageReaction.iconUrl}
              objectFit='cover'
              layout='fill'
            />
          </button>
        ))}
      </Popover.Panel>
    </Popover>
  );
};

export default MessageReaction;
