import { SVGProps } from 'react';

const IconCopySolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M16.0417 18.75V20.1562C16.0417 20.6222 15.664 21 15.198 21H5.6355C5.1695 21 4.79175 20.6222 4.79175 20.1562V7.21875C4.79175 6.75275 5.1695 6.375 5.6355 6.375H8.16675V16.7812C8.16675 17.8668 9.04991 18.75 10.1355 18.75H16.0417ZM16.0417 6.65625V3H10.1355C9.6695 3 9.29175 3.37775 9.29175 3.84375V16.7812C9.29175 17.2472 9.6695 17.625 10.1355 17.625H19.698C20.164 17.625 20.5417 17.2472 20.5417 16.7812V7.5H16.8855C16.4214 7.5 16.0417 7.12031 16.0417 6.65625ZM20.2946 5.56539L17.9764 3.24711C17.8181 3.08889 17.6035 3 17.3798 3H17.1667V6.375H20.5417V6.16199C20.5417 5.93822 20.4529 5.72362 20.2946 5.56539Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCopySolid };
