import { SVGProps } from 'react';

const IconNote2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.1875 6.75C6.1875 6.60082 6.24676 6.45774 6.35225 6.35225C6.45774 6.24676 6.60082 6.1875 6.75 6.1875H11.25C11.3992 6.1875 11.5423 6.24676 11.6477 6.35225C11.7532 6.45774 11.8125 6.60082 11.8125 6.75C11.8125 6.89919 11.7532 7.04226 11.6477 7.14775C11.5423 7.25324 11.3992 7.3125 11.25 7.3125H6.75C6.60082 7.3125 6.45774 7.25324 6.35225 7.14775C6.24676 7.04226 6.1875 6.89919 6.1875 6.75ZM6.75 9.5625H11.25C11.3992 9.5625 11.5423 9.50324 11.6477 9.39775C11.7532 9.29226 11.8125 9.14919 11.8125 9C11.8125 8.85082 11.7532 8.70774 11.6477 8.60225C11.5423 8.49676 11.3992 8.4375 11.25 8.4375H6.75C6.60082 8.4375 6.45774 8.49676 6.35225 8.60225C6.24676 8.70774 6.1875 8.85082 6.1875 9C6.1875 9.14919 6.24676 9.29226 6.35225 9.39775C6.45774 9.50324 6.60082 9.5625 6.75 9.5625ZM9 10.6875H6.75C6.60082 10.6875 6.45774 10.7468 6.35225 10.8523C6.24676 10.9577 6.1875 11.1008 6.1875 11.25C6.1875 11.3992 6.24676 11.5423 6.35225 11.6477C6.45774 11.7532 6.60082 11.8125 6.75 11.8125H9C9.14919 11.8125 9.29226 11.7532 9.39775 11.6477C9.50324 11.5423 9.5625 11.3992 9.5625 11.25C9.5625 11.1008 9.50324 10.9577 9.39775 10.8523C9.29226 10.7468 9.14919 10.6875 9 10.6875ZM15.75 3.375V11.0173C15.7505 11.1651 15.7216 11.3115 15.665 11.448C15.6083 11.5845 15.5252 11.7084 15.4202 11.8125L11.8125 15.4202C11.7084 15.5252 11.5845 15.6083 11.448 15.665C11.3115 15.7216 11.1651 15.7505 11.0173 15.75H3.375C3.07663 15.75 2.79048 15.6315 2.5795 15.4205C2.36853 15.2095 2.25 14.9234 2.25 14.625V3.375C2.25 3.07663 2.36853 2.79048 2.5795 2.5795C2.79048 2.36853 3.07663 2.25 3.375 2.25H14.625C14.9234 2.25 15.2095 2.36853 15.4205 2.5795C15.6315 2.79048 15.75 3.07663 15.75 3.375ZM3.375 14.625H10.6875V11.25C10.6875 11.1008 10.7468 10.9577 10.8523 10.8523C10.9577 10.7468 11.1008 10.6875 11.25 10.6875H14.625V3.375H3.375V14.625ZM11.8125 11.8125V13.8305L13.8298 11.8125H11.8125Z'
      fill='currentColor'
    />
  </svg>
);

export { IconNote2 };
