import { SVGProps } from 'react';

const IconTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M20.8438 4.25H18.6875V3.3125C18.6875 3.20937 18.6031 3.125 18.5 3.125H6.5C6.39687 3.125 6.3125 3.20937 6.3125 3.3125V4.25H4.15625C3.88275 4.25 3.62044 4.35865 3.42705 4.55205C3.23365 4.74544 3.125 5.00775 3.125 5.28125V8.75C3.125 10.6648 4.53125 12.2563 6.36406 12.5469C6.72734 15.2703 8.91406 17.4148 11.6562 17.7148V20.1805H7.0625C6.64766 20.1805 6.3125 20.5156 6.3125 20.9305V21.6875C6.3125 21.7906 6.39687 21.875 6.5 21.875H18.5C18.6031 21.875 18.6875 21.7906 18.6875 21.6875V20.9305C18.6875 20.5156 18.3523 20.1805 17.9375 20.1805H13.3438V17.7148C16.0859 17.4148 18.2727 15.2703 18.6359 12.5469C20.4688 12.2563 21.875 10.6648 21.875 8.75V5.28125C21.875 5.00775 21.7663 4.74544 21.573 4.55205C21.3796 4.35865 21.1173 4.25 20.8438 4.25ZM4.8125 8.75V5.9375H6.3125V10.8031C5.87714 10.6641 5.49723 10.3903 5.22762 10.0212C4.95801 9.65219 4.81264 9.20703 4.8125 8.75ZM17 11.75C17 12.9008 16.5523 13.9859 15.7367 14.7992C14.9211 15.6148 13.8383 16.0625 12.6875 16.0625H12.3125C11.1617 16.0625 10.0766 15.6148 9.26328 14.7992C8.44766 13.9836 8 12.9008 8 11.75V4.8125H17V11.75ZM20.1875 8.75C20.1875 9.71094 19.557 10.5266 18.6875 10.8031V5.9375H20.1875V8.75Z'
      fill='currentColor'
    />
  </svg>
);
export { IconTrophy };
