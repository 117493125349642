import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfString, SendVerificationCodeType } from './verify-otp';

export interface SendCodeData {
  sendCode?: MutationOfString;
}
export interface SendCodeVariables {
  phone: String;
  type?: SendVerificationCodeType;
  identifier: String;
}

export const SEND_CODE = gql`
  mutation sendCode($phone: String!, $type: SendVerificationCodeType, $identifier: String!)
  @api(name: users) {
    sendCode(phone: $phone, type: $type, identifier: $identifier) {
      payload
      success
      msg
    }
  }
`;
