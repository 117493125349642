import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationMessage } from './update-list-trading-real-estates';

export interface DeleteListRealEstatesVariables {
  realEstateIds?: String[];
}
export interface DeleteListRealEstatesData {
  deleteListRealEstates?: MutationMessage;
}

export const DELETE_LIST_REAL_ESTATES = gql`
  mutation deleteListRealEstates($realEstateIds: [ObjectID!]) @api(name: realEstate) {
    deleteListRealEstates(realEstateIds: $realEstateIds) {
      success
      msg
    }
  }
`;
