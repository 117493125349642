import { gql } from '@apollo/client';
import { Boolean, CommonAddress, Float, Int, PerM2Valuation, String } from '../queries';

export enum ValuationType {
  townhouse = 'townhouse',
  villa = 'villa',
  building = 'building',
  hotel = 'hotel',
  warehouse = 'warehouse',
  land = 'land',
  apartment = 'apartment',
  shophouse = 'shophouse',
  penthouse = 'penthouse',
  motel = 'motel',
}
export enum ConstructionQuality {
  rough = 'rough',
  basic = 'basic',
  convenient = 'convenient',
  luxurious = 'luxurious',
  extremely_luxurious = 'extremely_luxurious',
}
export enum Utility {
  pool = 'pool',
  mezzanine = 'mezzanine',
  lift = 'lift',
  basement = 'basement',
}
export enum PlusPoint {
  alleyBehind = 'alleyBehind',
  atCornerOrByAlley = 'atCornerOrByAlley',
  nearPark = 'nearPark',
  aheadIsBigAlley = 'aheadIsBigAlley',
  goodForCafeOrHotel = 'goodForCafeOrHotel',
  bestPosition = 'bestPosition',
  twoFrontispieces = 'twoFrontispieces',
  premiumResidentialArea = 'premiumResidentialArea',
  nearSupermarket = 'nearSupermarket',
}
export enum MinusPoint {
  oppositeAlley = 'oppositeAlley',
  sewerFrontOf = 'sewerFrontOf',
  bigTreeFrontOf = 'bigTreeFrontOf',
  pausedPlan = 'pausedPlan',
  underBridgeOrFadeOver = 'underBridgeOrFadeOver',
  oppositePagodaOrChurch = 'oppositePagodaOrChurch',
  polesFrontOf = 'polesFrontOf',
  nearMorgueOrFuneralHouse = 'nearMorgueOrFuneralHouse',
}
export enum WalletSourceType {
  main = 'main',
  reward = 'reward',
  commission = 'commission',
}
export enum LandUsageEnum {
  residentialLand = 'residentialLand',
  productionForests = 'productionForests',
  garden = 'garden',
  aquacultureLand = 'aquacultureLand',
  agriculturalLand = 'agriculturalLand',
  commercialLand = 'commercialLand',
  perennialTreeLand = 'perennialTreeLand',
  educationLand = 'educationLand',
}
export interface RealEstateValuationInput {
  type: ValuationType;
  province: String;
  district: String;
  street: String;
  ward?: String;
  width: Float;
  length: Float;
  houseNumber?: String;
  hasAlley: Boolean;
  alleyWidth?: Float;
  alleyCount?: Int;
  landUsage?: LandUsageEnum;
  rear?: Float;
  area?: Float;
  violatedArea?: Float;
  legalArea?: Float;
  landAreaForConstruction?: Float;
  floorCount?: Int;
  floorAreaForConstruction?: Float;
  constructionQuality?: ConstructionQuality;
  restQualityPercent?: Float;
  utilities?: Utility[];
  plusPoints?: PlusPoint[];
  minusPoints?: MinusPoint[];
  monthOfValuation?: Int;
  yearOfValuation?: Int;
  email?: String;
  source?: WalletSourceType;
}
export interface ValuationOutputType {
  min?: Float;
  avg?: Float;
  max?: Float;
  minBank?: Float;
  maxBank?: Float;
  minSellPerM2?: PerM2Valuation;
  avgSellPerM2?: PerM2Valuation;
  maxSellPerM2?: PerM2Valuation;
}
export interface RealEstateValuation {
  type?: String;
  province?: CommonAddress;
  district?: CommonAddress;
  ward?: CommonAddress;
  street?: CommonAddress;
  houseNumber?: String;
  fullAddress?: String;
  alleyWidth?: Float;
  width?: Float;
  length?: Float;
  rear?: Float;
  area?: Float;
  violatedArea?: Float;
  legalArea?: Float;
  landAreaForConstruction?: Float;
  floorCount?: Int;
  floorAreaForConstruction?: Float;
  constructionQuality?: String;
  restQualityPercent?: Float;
  utilities?: String[];
  plusPoints?: String[];
  minusPoints?: String[];
  monthOfValuation?: Int;
  yearOfValuation?: Int;
  valuation?: ValuationOutputType;
  fullName?: String;
  contactPhone?: String;
  email?: String;
}

export interface CalculateValuationData {
  calculateValuation?: RealEstateValuation;
}
export interface CalculateValuationVariables {
  data?: RealEstateValuationInput;
}

export const CALCULATE_VALUATION = gql`
  mutation calculateValuation($data: RealEstateValuationInput) @api(name: realEstate) {
    calculateValuation(data: $data) {
      type
      province {
        id
        name
        title
      }
      district {
        id
        name
        title
      }
      ward {
        id
        name
        title
      }
      street {
        id
        name
        title
      }
      houseNumber
      fullAddress
      alleyWidth
      width
      length
      rear
      area
      violatedArea
      legalArea
      landAreaForConstruction
      floorCount
      floorAreaForConstruction
      constructionQuality
      restQualityPercent
      utilities
      plusPoints
      minusPoints
      monthOfValuation
      yearOfValuation
      valuation {
        min
        avg
        max
        minBank
        maxBank
        minSellPerM2 {
          usd
          vnd
        }
        avgSellPerM2 {
          usd
          vnd
        }
        maxSellPerM2 {
          usd
          vnd
        }
      }
      fullName
      contactPhone
      email
    }
  }
`;
