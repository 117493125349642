import { SVGProps } from 'react';

const IconNewLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1320_221880)'>
      <path
        d='M19.7208 7.86455L17.4007 5.02131L19.6753 2.23493C19.7997 2.0825 19.8781 1.89776 19.9013 1.70238C19.9244 1.50699 19.8914 1.30905 19.8061 1.13175C19.7197 0.933032 19.5763 0.764347 19.3942 0.646959C19.212 0.529571 18.9992 0.468735 18.7825 0.472121H1.15445C1.01181 0.469863 0.870128 0.495726 0.737489 0.548232C0.60485 0.600739 0.483853 0.67886 0.38141 0.778135C0.278967 0.87741 0.197084 0.995892 0.140438 1.12682C0.083792 1.25774 0.0534923 1.39854 0.0512695 1.54118L0.0512695 8.50712C0.0572464 8.79419 0.176657 9.06722 0.383388 9.26649C0.590119 9.46576 0.867348 9.57506 1.15445 9.57049H18.8451C19.0545 9.57399 19.2606 9.51749 19.4389 9.40766C19.6173 9.29783 19.7605 9.13925 19.8516 8.95066C19.9334 8.77548 19.9646 8.58088 19.9414 8.38891C19.9183 8.19695 19.8419 8.01529 19.7208 7.86455ZM1.18857 8.43319V1.60942H18.6916L15.9393 4.9815L18.7314 8.43319H1.18857Z'
        fill='currentColor'
      />
      <path
        d='M5.3909 5.78899L3.5371 3.31537H2.89453V6.82961H3.5371V4.36168L5.3909 6.82961H6.02778V3.31537H5.3909V5.78899Z'
        fill='currentColor'
      />
      <path
        d='M6.97754 6.82961H9.62744V6.24959H7.62011V5.33407H9.4341V4.74836H7.62011V3.90108H9.62744V3.31537H6.97754V6.82961Z'
        fill='currentColor'
      />
      <path
        d='M13.9945 5.83448L13.1643 3.31537H12.4705L11.6346 5.83448L10.884 3.31537H10.1846L11.2991 6.82961H11.9303L12.8174 4.21952L13.6988 6.82961H14.3414L15.4503 3.31537H14.7508L13.9945 5.83448Z'
        fill='currentColor'
      />
      <path
        d='M0.279192 17.8219L2.59928 14.9787L0.324686 12.1923C0.200275 12.0399 0.121893 11.8552 0.0987282 11.6598C0.0755634 11.4644 0.108576 11.2664 0.193895 11.0891C0.280325 10.8904 0.423651 10.7217 0.605803 10.6044C0.787956 10.487 1.00079 10.4261 1.21746 10.4295H18.8456C18.9882 10.4273 19.1299 10.4531 19.2625 10.5056C19.3952 10.5581 19.5161 10.6363 19.6186 10.7355C19.721 10.8348 19.8029 10.9533 19.8596 11.0842C19.9162 11.2151 19.9465 11.3559 19.9487 11.4986V18.4645C19.9428 18.7516 19.8233 19.0246 19.6166 19.2239C19.4099 19.4232 19.1327 19.5325 18.8456 19.5279H1.15491C0.945486 19.5314 0.739429 19.4749 0.561081 19.3651C0.38273 19.2552 0.239536 19.0967 0.148403 18.9081C0.0665703 18.7329 0.0354462 18.5383 0.0585632 18.3463C0.0816803 18.1543 0.158112 17.9727 0.279192 17.8219ZM18.8114 18.3906V11.5668H1.30845L4.0607 14.9389L1.26864 18.3906H18.8114Z'
        fill='currentColor'
      />
      <path
        d='M7.6557 17.0553C7.2772 17.0553 6.94966 16.9752 6.67307 16.8151C6.39648 16.655 6.18358 16.4257 6.03437 16.1272C5.88515 15.8288 5.81055 15.4758 5.81055 15.0682C5.81055 14.657 5.88515 14.3039 6.03437 14.0091C6.18358 13.7107 6.39648 13.4814 6.67307 13.3213C6.94966 13.1612 7.2772 13.0811 7.6557 13.0811C8.03783 13.0811 8.36719 13.1612 8.64378 13.3213C8.92037 13.4814 9.13327 13.7107 9.28248 14.0091C9.4317 14.3039 9.5063 14.6551 9.5063 15.0627C9.5063 15.474 9.42988 15.8288 9.27703 16.1272C9.12781 16.4257 8.91491 16.655 8.63832 16.8151C8.36537 16.9752 8.03783 17.0553 7.6557 17.0553ZM7.6557 16.4985C8.01235 16.4985 8.29258 16.3765 8.49639 16.1327C8.70019 15.8852 8.80209 15.5286 8.80209 15.0627C8.80209 14.5969 8.70019 14.2439 8.49639 14.0037C8.29258 13.7599 8.01235 13.6379 7.6557 13.6379C7.30268 13.6379 7.02427 13.7599 6.82047 14.0037C6.61666 14.2439 6.51476 14.5969 6.51476 15.0627C6.51476 15.5286 6.61666 15.8852 6.82047 16.1327C7.02427 16.3765 7.30268 16.4985 7.6557 16.4985Z'
        fill='currentColor'
      />
      <path
        d='M10.2275 17.0116V13.1248H10.9317V16.433H12.7441V17.0116H10.2275Z'
        fill='currentColor'
      />
      <path
        d='M13.2822 17.0116V13.1248H14.7125C15.1383 13.1248 15.5004 13.2012 15.7988 13.3541C16.0972 13.5069 16.3247 13.7289 16.4812 14.0201C16.6413 14.3076 16.7214 14.6551 16.7214 15.0627C16.7214 15.474 16.6413 15.8252 16.4812 16.1163C16.3247 16.4075 16.0954 16.6295 15.7933 16.7823C15.4949 16.9352 15.1346 17.0116 14.7125 17.0116H13.2822ZM13.9864 16.4384H14.6688C15.1164 16.4384 15.4513 16.3238 15.6733 16.0945C15.8989 15.8652 16.0117 15.5213 16.0117 15.0627C16.0117 14.1529 15.5641 13.698 14.6688 13.698H13.9864V16.4384Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1320_221880'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconNewLine };
