import { SVGProps } from 'react';

const IconPackage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21 7.27734L12.5 11.9996M12.5 11.9996L3.99997 7.27734M12.5 11.9996L12.5 21.4996M21.5 16.0582V7.94104C21.5 7.5984 21.5 7.42708 21.4495 7.27428C21.4049 7.1391 21.3318 7.01502 21.2354 6.91033C21.1263 6.79199 20.9766 6.70879 20.677 6.54239L13.277 2.43128C12.9934 2.27372 12.8516 2.19494 12.7015 2.16406C12.5685 2.13672 12.4315 2.13672 12.2986 2.16406C12.1484 2.19494 12.0066 2.27372 11.723 2.43128L4.32297 6.54239C4.02345 6.70879 3.87369 6.792 3.76463 6.91033C3.66816 7.01502 3.59515 7.1391 3.55048 7.27428C3.5 7.42708 3.5 7.5984 3.5 7.94104V16.0582C3.5 16.4008 3.5 16.5721 3.55048 16.7249C3.59515 16.8601 3.66816 16.9842 3.76463 17.0889C3.87369 17.2072 4.02345 17.2904 4.32297 17.4568L11.723 21.5679C12.0066 21.7255 12.1484 21.8042 12.2986 21.8351C12.4315 21.8625 12.5685 21.8625 12.7015 21.8351C12.8516 21.8042 12.9934 21.7255 13.277 21.5679L20.677 17.4568C20.9766 17.2904 21.1263 17.2072 21.2354 17.0889C21.3318 16.9842 21.4049 16.8601 21.4495 16.7249C21.5 16.5721 21.5 16.4008 21.5 16.0582Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17 9.5L8 4.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconPackage };
