import { Participant } from 'graphql/main/queries';
import { filter, isEqual, join, map } from 'lodash';

export const getGroupNameFromParticipants = (exceptUserId?: string, participants?: Participant[]) =>
  join(
    map(
      filter(participants, (participant) => !isEqual(participant.userId, exceptUserId)),
      (participant) => participant.nickname || participant.fullName,
    ),
    ', ',
  );
