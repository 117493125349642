import { SVGProps } from 'react';

const IconDollarOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656ZM10.9316 9.54688L10.4355 9.43164V6.80859C11.1777 6.91016 11.6367 7.375 11.7148 7.94531C11.7246 8.02344 11.791 8.08008 11.8691 8.08008H12.7461C12.8379 8.08008 12.9102 8 12.9023 7.9082C12.7832 6.69141 11.7812 5.91016 10.4434 5.77539V5.13672C10.4434 5.05078 10.373 4.98047 10.2871 4.98047H9.73828C9.65234 4.98047 9.58203 5.05078 9.58203 5.13672V5.78125C8.19922 5.91602 7.11719 6.67969 7.11719 8.10547C7.11719 9.42578 8.08984 10.0625 9.11133 10.3066L9.59375 10.4297V13.2168C8.73047 13.1016 8.24609 12.6406 8.14648 12.0195C8.13477 11.9453 8.06836 11.8906 7.99219 11.8906H7.08984C6.99805 11.8906 6.92578 11.9688 6.93359 12.0605C7.02148 13.1348 7.83594 14.123 9.57422 14.25V14.8633C9.57422 14.9492 9.64453 15.0195 9.73047 15.0195H10.2852C10.3711 15.0195 10.4414 14.9492 10.4414 14.8613L10.4375 14.2422C11.9668 14.1074 13.0605 13.2891 13.0605 11.8203C13.0586 10.4648 12.1973 9.85938 10.9316 9.54688ZM9.5918 9.23047C9.48242 9.19922 9.39062 9.16992 9.29883 9.13281C8.63867 8.89453 8.33203 8.50977 8.33203 8.01367C8.33203 7.30469 8.86914 6.90039 9.5918 6.80859V9.23047ZM10.4355 13.2227V10.6113C10.4961 10.6289 10.5508 10.6426 10.6074 10.6543C11.5312 10.9355 11.8418 11.3262 11.8418 11.9258C11.8418 12.6895 11.2676 13.1484 10.4355 13.2227Z'
      fill='currentColor'
    />
  </svg>
);
export { IconDollarOutline };
