import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { ID, String } from '../queries';

export interface Captcha {
  id: ID;
  image: String;
}
export interface MutationOfCaptcha extends MutationOf {
  payload?: Captcha;
}

export interface RequestCaptchaData {
  requestCaptcha?: MutationOfCaptcha;
}

export const REQUEST_CAPTCHA = gql`
  mutation requestCaptcha @api(name: common) {
    requestCaptcha {
      success
      msg
      payload {
        id
        image
      }
    }
  }
`;
