import { useRouter } from 'next/router';
import vi from '../public/lang/vi';

export const useTranslation = () => {
  const { locale } = useRouter();

  switch (locale) {
    case 'en':
      return vi;
    default:
      return vi;
  }
};
