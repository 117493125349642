import { ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { createUploadLink } from 'apollo-upload-client';
import axios from 'axios';
import { AtmnhaAuthenticationModel } from 'components/providers/authentication-provider';
import { getCookie } from 'cookies-next';
import {
  BIHA_API_ENDPOINT,
  BLOG_API_ENDPOINT,
  CHAT_API_ENDPOINT,
  CHAT_SOCKET_API_ENDPOINT,
  COMMON_API_ENDPOINT,
  EWALLET_API_ENDPOINT,
  MAP_API_ENDPOINT,
  MEDIA_API_ENDPOINT,
  REAL_ESTATE_API_ENDPOINT,
  SOCIAL_API_ENDPOINT,
  USERS_API_ENDPOINT,
} from 'globalConstants';
import { createClient } from 'graphql-ws';
import { isEqual, toString } from 'lodash';

const isBrowser = !isEqual(typeof window, 'undefined');

export const baseLink = setContext((_, { headers }) => ({
  headers: {
    ['Accept-Language']: 'vi',
    ...headers,
  },
}));
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      console.error(`[GraphQL error]: Message: ${message} Path: ${path}`),
    );
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});
export const mainLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      users: USERS_API_ENDPOINT,
      media: MEDIA_API_ENDPOINT,
      realEstate: REAL_ESTATE_API_ENDPOINT,
      common: COMMON_API_ENDPOINT,
      chat: CHAT_API_ENDPOINT,
      biha: BIHA_API_ENDPOINT,
      social: SOCIAL_API_ENDPOINT,
      ewallet: EWALLET_API_ENDPOINT,
    },
    createHttpLink,
  }),
]);
export const uploadLink = createUploadLink({
  uri: `${MEDIA_API_ENDPOINT}/graphql`,
  fetch: buildAxiosFetch(axios, (config, _, init: any) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
  })),
});
export const mapLink = createHttpLink({
  uri: `${MAP_API_ENDPOINT}/graphql`,
});
export const chatLink = isBrowser
  ? toString(getCookie('atmnha_authentication_cookie'))
    ? new GraphQLWsLink(
        createClient({
          url: `${CHAT_SOCKET_API_ENDPOINT}/subscriptions`,
          connectionParams: {
            authToken: (
              JSON.parse(
                toString(getCookie('atmnha_authentication_cookie')),
              ) as AtmnhaAuthenticationModel
            ).accessToken,
          },
        }),
      )
    : undefined
  : undefined;
export const blogLink = createHttpLink({
  uri: `${BLOG_API_ENDPOINT}/graphql`,
});

export const createAuthenticationLink = (accessToken?: string) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ['Accept-Language']: 'vi',
      ['Authorization']: `Bearer ${accessToken}`,
    },
  }));
