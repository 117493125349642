import { SVGProps } from 'react';

const IconPanVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='currentColor'
    {...props}
  >
    <path
      d='M5.625 4.5L6.42037 5.29537L8.4375 3.27825L8.4375 14.7217L6.42037 12.7046L5.625 13.5L9 16.875L12.375 13.5L11.5796 12.7046L9.5625 14.7217L9.5625 3.27825L11.5796 5.29537L12.375 4.5L9 1.125L5.625 4.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconPanVertical };
