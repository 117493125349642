import { SVGProps } from 'react';

const IconSettingServices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12.6875 11.2288H11.8125V10.3538H12.25V8.60376H10.5V9.04126H9.625V8.16626C9.625 8.05023 9.67109 7.93895 9.75314 7.8569C9.83519 7.77485 9.94647 7.72876 10.0625 7.72876H12.6875C12.8035 7.72876 12.9148 7.77485 12.9969 7.8569C13.0789 7.93895 13.125 8.05023 13.125 8.16626V10.7913C13.125 10.9073 13.0789 11.0186 12.9969 11.1006C12.9148 11.1827 12.8035 11.2288 12.6875 11.2288Z'
      fill='currentColor'
    />
    <path
      d='M10.5 13.4163H7.875C7.75897 13.4163 7.64769 13.3702 7.56564 13.2881C7.48359 13.2061 7.4375 13.0948 7.4375 12.9788V10.3538C7.4375 10.2377 7.48359 10.1264 7.56564 10.0444C7.64769 9.96235 7.75897 9.91626 7.875 9.91626H10.5C10.616 9.91626 10.7273 9.96235 10.8094 10.0444C10.8914 10.1264 10.9375 10.2377 10.9375 10.3538V12.9788C10.9375 13.0948 10.8914 13.2061 10.8094 13.2881C10.7273 13.3702 10.616 13.4163 10.5 13.4163ZM8.3125 12.5413H10.0625V10.7913H8.3125V12.5413Z'
      fill='currentColor'
    />
    <path
      d='M6.5625 8.97911C6.24638 8.89672 5.95969 8.72735 5.735 8.49022C5.5103 8.25309 5.3566 7.95771 5.29134 7.63762C5.22608 7.31752 5.25188 6.98554 5.36581 6.67938C5.47974 6.37321 5.67724 6.10512 5.93588 5.90556C6.19452 5.706 6.50393 5.58295 6.82898 5.5504C7.15403 5.51784 7.4817 5.57709 7.77478 5.7214C8.06785 5.86571 8.31459 6.0893 8.48698 6.3668C8.65936 6.64429 8.75048 6.96456 8.75 7.29124H9.625C9.62551 6.79065 9.48287 6.30036 9.2139 5.87817C8.94494 5.45598 8.56086 5.1195 8.10697 4.9084C7.65307 4.6973 7.14827 4.62039 6.6521 4.68673C6.15593 4.75308 5.68908 4.95991 5.30659 5.28284C4.92409 5.60577 4.64191 6.03134 4.49332 6.50936C4.34472 6.98739 4.33591 7.49793 4.46792 7.9808C4.59992 8.46366 4.86724 8.89872 5.23836 9.23466C5.60948 9.5706 6.06891 9.79343 6.5625 9.87686V8.97911Z'
      fill='currentColor'
    />
    <path
      d='M12.6394 6.21938L11.6288 7.10751L11.0075 6.48626L12.0619 5.55876L11.0294 3.77376L9.52441 4.28126C9.17187 3.98781 8.77257 3.75563 8.34316 3.59438L8.03254 2.04126H5.96754L5.65691 3.59438C5.22405 3.75112 4.82257 3.98371 4.47129 4.28126L2.97066 3.77376L1.93816 5.55876L3.12816 6.60438C3.04722 7.05725 3.04722 7.52089 3.12816 7.97376L1.93816 9.02376L2.97066 10.8088L4.47566 10.3013C4.8282 10.5947 5.2275 10.8269 5.65691 10.9881L5.96754 12.5413H6.56254V13.4163H5.96754C5.76521 13.4161 5.56919 13.3458 5.41284 13.2174C5.2565 13.089 5.14949 12.9103 5.11004 12.7119L4.88691 11.6094C4.68884 11.5128 4.49738 11.4032 4.31379 11.2813L3.25066 11.64C3.16026 11.6694 3.06573 11.6842 2.97066 11.6838C2.81698 11.6848 2.6658 11.6448 2.53274 11.5679C2.39969 11.491 2.28958 11.38 2.21379 11.2463L1.18129 9.46126C1.07917 9.28583 1.04122 9.08035 1.07395 8.88001C1.10667 8.67968 1.20803 8.49695 1.36066 8.36314L2.20066 7.62813C2.19191 7.51438 2.18754 7.40501 2.18754 7.29126C2.18754 7.17751 2.19629 7.06813 2.20504 6.95876L1.36066 6.21938C1.20803 6.08557 1.10667 5.90284 1.07395 5.70251C1.04122 5.50217 1.07917 5.29669 1.18129 5.12126L2.21379 3.33626C2.28958 3.20256 2.39969 3.09152 2.53274 3.01461C2.6658 2.93769 2.81698 2.8977 2.97066 2.89876C3.06573 2.89832 3.16026 2.91309 3.25066 2.94251L4.30941 3.30126C4.4945 3.17933 4.68742 3.06972 4.88691 2.97313L5.11004 1.87063C5.14949 1.67219 5.2565 1.49355 5.41284 1.36512C5.56919 1.2367 5.76521 1.16642 5.96754 1.16626H8.03254C8.23487 1.16642 8.43089 1.2367 8.58723 1.36512C8.74358 1.49355 8.85058 1.67219 8.89004 1.87063L9.11316 2.97313C9.31124 3.06974 9.5027 3.17936 9.68629 3.30126L10.7494 2.94251C10.8398 2.91309 10.9343 2.89832 11.0294 2.89876C11.1831 2.8977 11.3343 2.93769 11.4673 3.01461C11.6004 3.09152 11.7105 3.20256 11.7863 3.33626L12.8188 5.12126C12.9209 5.29669 12.9589 5.50217 12.9261 5.70251C12.8934 5.90284 12.792 6.08557 12.6394 6.21938Z'
      fill='currentColor'
    />
  </svg>
);
export { IconSettingServices };
