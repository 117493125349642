import { SVGProps } from 'react';

const IconUserCancel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M9.75921 10.7143C8.74948 11.9369 8.14286 13.5048 8.14286 15.2143C8.14286 15.8829 8.23562 16.5297 8.40899 17.1426L8.22024 17.1439C4.97058 17.1439 3.12557 16.0579 3.00618 13.8691L3 13.6388V12.6429C3 11.6404 3.76485 10.8166 4.74284 10.7231L4.92857 10.7143H9.75921ZM8.14286 3C9.91835 3 11.3577 4.43932 11.3577 6.2148C11.3577 7.99029 9.91835 9.42961 8.14286 9.42961C6.36736 9.42961 4.92804 7.99029 4.92804 6.2148C4.92804 4.43932 6.36736 3 8.14286 3ZM14.5714 4.28571C15.9916 4.28571 17.1429 5.43698 17.1429 6.85714C17.1429 7.39164 16.9798 7.88805 16.7007 8.29934C16.2214 8.19682 15.7242 8.14286 15.2143 8.14286C14.3241 8.14286 13.4724 8.30734 12.6877 8.60758C12.261 8.14855 12 7.53333 12 6.85714C12 5.43698 13.1513 4.28571 14.5714 4.28571Z'
      fill='currentColor'
    />
    <path
      d='M15.1671 9.33804C16.692 9.33804 18.1546 9.94384 19.2329 11.0222C20.3113 12.1005 20.9171 13.563 20.9171 15.088C20.9171 16.613 20.3113 18.0756 19.2329 19.1539C18.1546 20.2322 16.692 20.838 15.1671 20.838C13.6421 20.838 12.1795 20.2322 11.1012 19.1539C10.0229 18.0756 9.41705 16.613 9.41705 15.088C9.41705 13.563 10.0229 12.1005 11.1012 11.0222C12.1795 9.94384 13.6421 9.33804 15.1671 9.33804ZM12.6514 14.7287C12.5561 14.7287 12.4647 14.7665 12.3973 14.8339C12.3299 14.9013 12.2921 14.9927 12.2921 15.088C12.2921 15.1834 12.3299 15.2748 12.3973 15.3422C12.4647 15.4096 12.5561 15.4474 12.6514 15.4474H16.8151L15.272 16.9899C15.2045 17.0573 15.1666 17.1489 15.1666 17.2443C15.1666 17.3397 15.2045 17.4312 15.272 17.4987C15.3395 17.5662 15.431 17.6041 15.5264 17.6041C15.6219 17.6041 15.7134 17.5662 15.7809 17.4987L17.9371 15.3425C17.9706 15.3091 17.9971 15.2694 18.0153 15.2258C18.0334 15.1821 18.0427 15.1353 18.0427 15.088C18.0427 15.0408 18.0334 14.994 18.0153 14.9503C17.9971 14.9066 17.9706 14.867 17.9371 14.8336L15.7809 12.6774C15.7134 12.6099 15.6219 12.572 15.5264 12.572C15.431 12.572 15.3395 12.6099 15.272 12.6774C15.2045 12.7448 15.1666 12.8364 15.1666 12.9318C15.1666 13.0272 15.2045 13.1187 15.272 13.1862L16.8151 14.7287H12.6514Z'
      fill='currentColor'
    />
  </svg>
);
export { IconUserCancel };
