import { Popover } from '@headlessui/react';
import { IconDotsHorizontal } from 'components/icons';
import {
  REMOVE_CONVERSATION,
  RemoveConversationData,
  RemoveConversationVariables,
} from 'graphql/main/mutations';
import { useFlexMutation } from 'hooks';
import { map, toString } from 'lodash';

interface Props {
  isGroup?: boolean;
  id?: string;
}

const ConversationAction = ({ isGroup, id }: Props) => {
  const [mutationRemoveConversation] = useFlexMutation<
    RemoveConversationData,
    RemoveConversationVariables
  >('main', REMOVE_CONVERSATION);

  const pinConversation = () => {};
  const changeConversationName = () => {};
  const viewProfile = () => {};
  const createMessage = () => {};
  const viewMediaFiles = () => {};
  const blockUser = () => {};
  const addMember = () => {};
  const removeMember = () => {};
  const clearMessages = () => {};
  const leaveGroup = () => {};
  const disbandGroup = () => {
    mutationRemoveConversation({ variables: { conversationId: toString(id) } });
  };

  const privateMessageActions = [
    {
      label: 'Đổi biệt hiệu',
      onClick: () => changeConversationName(),
    },
    {
      label: 'Xem trang cá nhân',
      onClick: () => viewProfile(),
    },
    {
      label: 'Tạo tin nhắn với',
      onClick: () => createMessage(),
    },
    {
      label: 'Xem lịch sử hình ảnh, video',
      onClick: () => viewMediaFiles(),
    },
    {
      label: 'Chặn người dùng này',
      onClick: () => blockUser(),
    },
  ];
  const groupMessageActions = [
    {
      label: 'Đổi tên nhóm',
      onClick: () => changeConversationName(),
    },
    {
      label: 'Cập nhật thành viên',
      onClick: () => addMember(),
    },
    {
      label: 'Xóa thành viên',
      onClick: () => removeMember(),
    },
    {
      label: 'Xem lịch sử hình ảnh, video',
      onClick: () => viewMediaFiles(),
    },
  ];

  return (
    <Popover id='conversation-action' className='relative'>
      <Popover.Button type='button'>
        <IconDotsHorizontal className='min-h-[24px] min-w-[24px] text-text2 font-[600]' />
      </Popover.Button>
      <Popover.Panel>
        <div className='absolute right-0 z-[1] w-[200px] divide-y divide-stroke rounded-[8px] bg-paper shadow-4'>
          <button
            type='button'
            className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
            onClick={pinConversation}
          >
            <span>Ghim cuộc hội thoại</span>
          </button>
          <div>
            {isGroup
              ? map(groupMessageActions, (action, actionIndex) => (
                  <button
                    key={actionIndex}
                    type='button'
                    className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={action.onClick}
                  >
                    {action.label}
                  </button>
                ))
              : map(privateMessageActions, (action, actionIndex) => (
                  <button
                    key={actionIndex}
                    type='button'
                    className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={action.onClick}
                  >
                    {action.label}
                  </button>
                ))}
          </div>
          <button
            type='button'
            className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
            onClick={clearMessages}
          >
            <span>Xóa tất cả tin nhắn</span>
          </button>
          {isGroup && (
            <div>
              <button
                type='button'
                className='flex h-[38px] w-full items-center px-[10px] text-alert transition duration-[200ms] ease-in-out hover:bg-secondary'
                onClick={leaveGroup}
              >
                Rời khỏi nhóm
              </button>
              <button
                type='button'
                className='flex h-[38px] w-full items-center px-[10px] text-alert transition duration-[200ms] ease-in-out hover:bg-secondary'
                onClick={disbandGroup}
              >
                Giải tán nhóm
              </button>
            </div>
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ConversationAction;
