import { SVGProps } from 'react';

const IconEmailFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M17.1667 6.66634L10.5 10.833L3.83335 6.66634V4.99967L10.5 9.16634L17.1667 4.99967V6.66634ZM17.1667 3.33301H3.83335C2.90835 3.33301 2.16669 4.07467 2.16669 4.99967V14.9997C2.16669 15.4417 2.34228 15.8656 2.65484 16.1782C2.9674 16.4907 3.39133 16.6663 3.83335 16.6663H17.1667C17.6087 16.6663 18.0326 16.4907 18.3452 16.1782C18.6578 15.8656 18.8334 15.4417 18.8334 14.9997V4.99967C18.8334 4.07467 18.0834 3.33301 17.1667 3.33301Z'
      fill='currentColor'
    />
  </svg>
);
export { IconEmailFill };
