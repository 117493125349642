import {
  CommonAddress,
  ProjectEnumType,
  ProjectStatusEnum,
  RangeValueInput,
} from 'graphql/main/queries';

export interface ProjectSearchForm {
  search?: string;
  street?: CommonAddress;
  ward?: CommonAddress;
  district?: CommonAddress;
  province?: CommonAddress;
  projectType?: ProjectEnumType[];
  price?: RangeValueInput;
  projectArea?: RangeValueInput;
  projectStatus?: ProjectStatusEnum[];
  handOverYear?: string;
}

export const projectSearchFormDefaultValues: ProjectSearchForm = {
  search: undefined,
  street: undefined,
  ward: undefined,
  district: undefined,
  province: undefined,
  projectType: undefined,
  price: undefined,
  projectArea: undefined,
  projectStatus: undefined,
  handOverYear: undefined,
};
