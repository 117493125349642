import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfAccessTokenType } from './sign-in';

export interface SignInWithSocialAccountVariables {
  type: String;
  idToken: String;
}
export interface SignInWithSocialAccountData {
  signInWithSocialAccount?: MutationOfAccessTokenType;
}

export const SIGN_IN_WITH_SOCIAL_ACCOUNT = gql`
  mutation signInWithSocialAccount($type: SocialTypeEnumType!, $idToken: String!)
  @api(name: users) {
    signInWithSocialAccount(idToken: $idToken, type: $type) {
      payload {
        accessToken
      }
      msg
      success
    }
  }
`;
