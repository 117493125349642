import { SVGProps } from 'react';

const IconChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.6098 11.3492L3.15975 7.14095C3.05675 7.04362 3 6.91369 3 6.77515C3 6.63662 3.05675 6.50669 3.15975 6.40936L3.48739 6.09945C3.70089 5.89779 4.04788 5.89779 4.26105 6.09945L7.99793 9.63319L11.739 6.09553C11.842 5.9982 11.9793 5.94446 12.1257 5.94446C12.2723 5.94446 12.4096 5.9982 12.5127 6.09553L12.8402 6.40543C12.9433 6.50284 13 6.63269 13 6.77123C13 6.90977 12.9433 7.0397 12.8402 7.13703L8.38614 11.3492C8.2828 11.4467 8.14484 11.5003 7.99817 11.5C7.85094 11.5003 7.71305 11.4467 7.6098 11.3492Z'
      fill='currentColor'
    />
    <mask id='mask0_3488_3916' maskUnits='userSpaceOnUse' x='3' y='5' width='10' height='7'>
      <path
        d='M7.6098 11.3492L3.15975 7.14095C3.05675 7.04362 3 6.91369 3 6.77515C3 6.63662 3.05675 6.50669 3.15975 6.40936L3.48739 6.09945C3.70089 5.89779 4.04788 5.89779 4.26105 6.09945L7.99793 9.63319L11.739 6.09553C11.842 5.9982 11.9793 5.94446 12.1257 5.94446C12.2723 5.94446 12.4096 5.9982 12.5127 6.09553L12.8402 6.40543C12.9433 6.50284 13 6.63269 13 6.77123C13 6.90977 12.9433 7.0397 12.8402 7.13703L8.38614 11.3492C8.2828 11.4467 8.14484 11.5003 7.99817 11.5C7.85094 11.5003 7.71305 11.4467 7.6098 11.3492Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_3488_3916)'>
      <rect
        x='-7'
        y='24'
        width='30'
        height='30'
        transform='rotate(-90 -7 24)'
        fill='currentColor'
      />
    </g>
  </svg>
);
export { IconChevronDown };
