import { useReactiveVar } from '@apollo/client';
import { selectedConversationVar } from '../../../../../../apollo/store';
import MessageList from './message-list';
import MessagePlace from './message-place';
import MessageToolbar from './message-toolbar';

const SectionLeft = () => {
  const selectedConversation = useReactiveVar(selectedConversationVar);

  return selectedConversation ? (
    <div id='section-left' className='w-full'>
      <MessageToolbar />
      <MessageList />
      <MessagePlace />
    </div>
  ) : (
    <div className='flex w-full flex-col items-center justify-center space-y-[16px]'>
      <div className='h-[40px] w-[132px] bg-[url("/logos/atm-nha-colored.svg")] bg-cover bg-center bg-no-repeat opacity-50' />
      <span className='italic text-text2'>Vui lòng chọn tin nhắn</span>
    </div>
  );
};

export default SectionLeft;
