import { SVGProps } from 'react';

const IconFloorPlanSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1786_152651)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H4.96107L8.29547 2.22293L7.70453 3.1104L4.63893 1.06667H1.06667V14.9333H6.4V8.53333H4.26667V7.46667H9.6V8.53333H7.46667V14.9333H14.9333V8.53333H12.8V7.46667H14.9333V1.06667H10.6667V0H16V16H0V0Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1786_152651'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconFloorPlanSolid };
