import { SVGProps } from 'react';

const IconChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' {...props}>
    <path
      d='M7.72642 11.9147L14.0388 5.23963C14.1848 5.08512 14.3796 5 14.5875 5C14.7953 5 14.9902 5.08512 15.1361 5.23963L15.601 5.73109C15.9035 6.05133 15.9035 6.57182 15.601 6.89157L10.3004 12.4969L15.6069 18.1084C15.7529 18.2629 15.8335 18.4689 15.8335 18.6885C15.8335 18.9084 15.7529 19.1144 15.6069 19.269L15.142 19.7604C14.9959 19.9149 14.8011 20 14.5933 20C14.3855 20 14.1906 19.9149 14.0446 19.7604L7.72642 13.0792C7.58008 12.9242 7.4997 12.7173 7.50016 12.4973C7.4997 12.2764 7.58008 12.0696 7.72642 11.9147Z'
      fill='currentColor'
    />
    <mask id='mask0_1320_227179' maskUnits='userSpaceOnUse' x='7' y='5' width='9' height='15'>
      <path
        d='M7.72642 11.9147L14.0388 5.23963C14.1848 5.08512 14.3796 5 14.5875 5C14.7953 5 14.9902 5.08512 15.1361 5.23963L15.601 5.73109C15.9035 6.05133 15.9035 6.57182 15.601 6.89157L10.3004 12.4969L15.6069 18.1084C15.7529 18.2629 15.8335 18.4689 15.8335 18.6885C15.8335 18.9084 15.7529 19.1144 15.6069 19.269L15.142 19.7604C14.9959 19.9149 14.8011 20 14.5933 20C14.3855 20 14.1906 19.9149 14.0446 19.7604L7.72642 13.0792C7.58008 12.9242 7.4997 12.7173 7.50016 12.4973C7.4997 12.2764 7.58008 12.0696 7.72642 11.9147Z'
        fill='white'
      />
    </mask>
  </svg>
);
export { IconChevronLeft };
