import { gql } from '@apollo/client';
import { JSONObject, String } from '../queries';
import { MutationMessage } from './update-list-trading-real-estates';

export type EmailAddress = string;
export enum TemplateEnum {
  signup = 'signup',
  post = 'post',
  bankCard = 'bankCard',
  credit = 'credit',
  forgotpass = 'forgotpass',
  register = 'register',
}

export interface SendEmailV1Data {
  sendMailV1?: MutationMessage;
}
export interface SendEmailV1Variables {
  emails: EmailAddress[];
  subject: String;
  htmlTemplate: TemplateEnum;
  variables?: JSONObject;
}

export const SEND_EMAIL_V1 = gql`
  mutation sendMailV1(
    $emails: [EmailAddress]!
    $subject: String!
    $htmlTemplate: TemplateEnum!
    $variables: JSONObject
  ) @api(name: common) {
    sendMailV1(
      emails: $emails
      subject: $subject
      htmlTemplate: $htmlTemplate
      variables: $variables
    ) {
      success
      msg
    }
  }
`;
