import { SVGProps } from 'react';

const IconQRCodeScan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M15.8787 2.5H13.7793C13.5366 2.5 13.3398 2.69676 13.3398 2.93945C13.3398 3.18215 13.5366 3.37891 13.7793 3.37891H15.8787C16.288 3.37891 16.6211 3.71195 16.6211 4.12132V6.2207C16.6211 6.4634 16.8179 6.66016 17.0605 6.66016C17.3032 6.66016 17.5 6.4634 17.5 6.2207V4.12132C17.5 3.22732 16.7727 2.5 15.8787 2.5V2.5Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
    />
    <path
      d='M2.93945 6.66016C3.18215 6.66016 3.37891 6.4634 3.37891 6.2207V4.12132C3.37891 3.71195 3.71195 3.37891 4.12129 3.37891H6.2207C6.4634 3.37891 6.66016 3.18215 6.66016 2.93945C6.66016 2.69676 6.4634 2.5 6.2207 2.5H4.12129C3.22732 2.5 2.5 3.22732 2.5 4.12132V6.2207C2.5 6.4634 2.69676 6.66016 2.93945 6.66016Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
    />
    <path
      d='M6.2207 16.6211H4.12129C3.71195 16.6211 3.37891 16.288 3.37891 15.8787V13.7793C3.37891 13.5366 3.18215 13.3398 2.93945 13.3398C2.69676 13.3398 2.5 13.5366 2.5 13.7793V15.8787C2.5 16.7727 3.22732 17.5 4.12129 17.5H6.2207C6.4634 17.5 6.66016 17.3032 6.66016 17.0605C6.66016 16.8179 6.4634 16.6211 6.2207 16.6211Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
    />
    <path
      d='M17.0605 13.3398C16.8179 13.3398 16.6211 13.5366 16.6211 13.7793V15.8787C16.6211 16.288 16.288 16.6211 15.8787 16.6211H13.7793C13.5366 16.6211 13.3398 16.8179 13.3398 17.0605C13.3398 17.3032 13.5366 17.5 13.7793 17.5H15.8787C16.7727 17.5 17.5 16.7727 17.5 15.8787V13.7793C17.5 13.5366 17.3032 13.3398 17.0605 13.3398Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
    />
    <path
      d='M2.5293 10C2.5293 10.2427 2.72605 10.4395 2.96875 10.4395H17.0312C17.2739 10.4395 17.4707 10.2427 17.4707 10C17.4707 9.7573 17.2739 9.56055 17.0312 9.56055H2.96875C2.72605 9.56055 2.5293 9.7573 2.5293 10Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
    />
  </svg>
);
export { IconQRCodeScan };
