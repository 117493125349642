import { SVGProps } from 'react';

const IconBaselineSupportAgent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='27'
    height='27'
    viewBox='0 0 27 27'
    fill='currentColor'
    {...props}
  >
    <path
      d='M23.25 13.7383C23.25 7.79083 18.635 3.75 13.5 3.75C8.41913 3.75 3.74996 7.70417 3.74996 13.8033C3.09996 14.1717 2.66663 14.865 2.66663 15.6667V17.8333C2.66663 19.025 3.64163 20 4.83329 20H5.91663V13.3917C5.91663 9.19917 9.30746 5.80833 13.5 5.80833C17.6925 5.80833 21.0833 9.19917 21.0833 13.3917V21.0833H12.4166V23.25H21.0833C22.275 23.25 23.25 22.275 23.25 21.0833V19.7617C23.8891 19.4258 24.3333 18.765 24.3333 17.985V15.4933C24.3333 14.735 23.8891 14.0742 23.25 13.7383Z'
      fill='currentColor'
    />
    <path
      d='M10.25 15.6667C10.8483 15.6667 11.3333 15.1816 11.3333 14.5833C11.3333 13.985 10.8483 13.5 10.25 13.5C9.65165 13.5 9.16663 13.985 9.16663 14.5833C9.16663 15.1816 9.65165 15.6667 10.25 15.6667Z'
      fill='currentColor'
    />
    <path
      d='M16.75 15.6667C17.3483 15.6667 17.8333 15.1816 17.8333 14.5833C17.8333 13.985 17.3483 13.5 16.75 13.5C16.1517 13.5 15.6666 13.985 15.6666 14.5833C15.6666 15.1816 16.1517 15.6667 16.75 15.6667Z'
      fill='currentColor'
    />
    <path
      d='M20 12.4492C19.7417 10.9261 18.9529 9.54363 17.7732 8.54633C16.5935 7.54903 15.0989 7.00127 13.5542 7C10.2717 7 6.74001 9.71917 7.02167 13.9875C8.35757 13.4408 9.53744 12.5721 10.4561 11.4588C11.3747 10.3454 12.0036 9.02206 12.2867 7.60667C13.7058 10.4558 16.62 12.4167 20 12.4492Z'
      fill='currentColor'
    />
  </svg>
);
export { IconBaselineSupportAgent };
