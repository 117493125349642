import { gql } from '@apollo/client';
import { UpgradeRequestMutation } from './checkout-upgrade-rank';

export interface RepaymentWithVNPayData {
  repaymentWithVNPay?: UpgradeRequestMutation;
}
export interface RepaymentWithVNPayVariables {
  requestId: String;
}

export const REPAYMENT_WITH_VNPAY = gql`
  mutation repaymentWithVNPay($requestId: String!) @api(name: users) {
    repaymentWithVNPay(requestId: $requestId) {
      success
      msg
      payload {
        requestId
      }
    }
  }
`;
