import { gql } from '@apollo/client';
import { Boolean, PaymentTypeEnum, String } from '../queries';
import { MutationOfString } from './verify-otp';

export interface CheckoutUpgradeAccountRequestVariables {
  orderId?: String;
  paymentType?: PaymentTypeEnum;
  redirectUrl?: String;
  isMB?: Boolean;
}
export interface CheckoutUpgradeAccountRequestData {
  checkoutUpgradeAccountRequest?: MutationOfString;
}

export const CHECKOUT_UPGRADE_ACCOUNT_REQUEST = gql`
  mutation checkoutUpgradeAccountRequest(
    $orderId: String!
    $paymentType: PaymentTypeEnum!
    $redirectUrl: String
    $isMB: Boolean
  ) @api(name: users) {
    checkoutUpgradeAccountRequest(
      orderId: $orderId
      paymentType: $paymentType
      redirectUrl: $redirectUrl
      isMB: $isMB
    ) {
      msg
      payload
      success
    }
  }
`;
