import { gql } from '@apollo/client';
import { Float, String } from './get-real-estates';

export interface PerM2Valuation {
  usd?: Float;
  vnd?: Float;
}
export interface ApartmentValuationData {
  apartmentValuation?: {
    min?: Float;
    avg?: Float;
    max?: Float;
    minBank?: Float;
    maxBank?: Float;
    minSellPerM2?: PerM2Valuation;
    avgSellPerM2?: PerM2Valuation;
    maxSellPerM2?: PerM2Valuation;
  };
}

export interface ApartmentValuationVariables {
  projectId: String;
  area: Float;
  propertyId: String;
}

export const APARTMENT_VALUATION = gql`
  query apartmentValuation($projectId: String!, $area: Float!, $propertyId: String!)
  @api(name: realEstate) {
    apartmentValuation(projectId: $projectId, area: $area, propertyId: $propertyId) {
      min
      avg
      max
      minBank
      maxBank
      minSellPerM2 {
        usd
        vnd
      }
      avgSellPerM2 {
        usd
        vnd
      }
      maxSellPerM2 {
        usd
        vnd
      }
    }
  }
`;
