import { SVGProps } from 'react';

const IconDiamond2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M5.83333 3.8277H19.1667L22.5 9.38325L13.0556 19.9388C12.9831 20.0127 12.8967 20.0714 12.8013 20.1115C12.7059 20.1516 12.6035 20.1723 12.5 20.1723C12.3965 20.1723 12.2941 20.1516 12.1987 20.1115C12.1033 20.0714 12.0169 20.0127 11.9444 19.9388L2.5 9.38325L5.83333 3.8277Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2779 11.6055L8.05566 9.16102L8.72233 8.04991'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconDiamond2 };
