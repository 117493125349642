import { SVGProps } from 'react';

const IconFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8.93133 13.9981L8.93133 8.53411H10.7747L11.0487 6.39478H8.93133V5.03211C8.93133 4.41478 9.10333 3.99211 9.98933 3.99211H11.112V2.08478C10.5658 2.02624 10.0167 1.99797 9.46733 2.00011C7.838 2.00011 6.71933 2.99478 6.71933 4.82078V6.39078H4.888V8.53011H6.72333L6.72333 13.9981H8.93133Z'
      fill='currentColor'
    />
  </svg>
);
export { IconFacebook };
