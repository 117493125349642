import { gt } from 'lodash';
import moment from 'moment-timezone';

export const convertMessageCreatedAt = (date: string) => {
  const diff = moment().locale('vi').diff(moment(date), 'days');

  return gt(1, diff)
    ? moment(date).format('LT')
    : gt(7, diff)
    ? moment(date).format('ddd')
    : moment(date).format('MMM DD');
};
