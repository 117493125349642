import { SVGProps } from 'react';

const IconPhoto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M18.9841 6.5H5.01589C4.45487 6.5 4 6.95484 4 7.51586V17.9286C4 18.4895 4.45487 18.9444 5.01589 18.9444H18.9841C19.5451 18.9444 20 18.4895 20 17.9285V7.51586C20 6.95484 19.5452 6.5 18.9841 6.5ZM18.9841 7.51586V14.904L16.9821 13.0838C16.6811 12.8103 16.2184 12.8212 15.9309 13.109L13.9047 15.1349L9.90959 10.3628C9.6076 10.0022 9.05446 9.99864 8.74794 10.355L5.01589 14.6922V7.51586H18.9841ZM14.6667 10.1825C14.6667 9.41096 15.2919 8.78569 16.0635 8.78569C16.835 8.78569 17.4603 9.41096 17.4603 10.1825C17.4603 10.9541 16.8351 11.5793 16.0635 11.5793C15.2919 11.5794 14.6667 10.9541 14.6667 10.1825Z'
      fill='currentColor'
    />
  </svg>
);
export { IconPhoto };
