import { SVGProps } from 'react';

const IconBlocksAndArrows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M2 2H6.66667V6.66667H2V2Z'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 9.33301H6.66667V13.9997H2V9.33301Z'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.33334 2H14V6.66667H9.33334V2Z'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.33334 9.33301L14 13.9997M9.33334 9.33301H14H9.33334ZM9.33334 9.33301V13.9997V9.33301Z'
      stroke='currentColor'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconBlocksAndArrows };
