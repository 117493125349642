import { SVGProps } from 'react';

const IconContentPaste = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8.625 0.75C9.05724 0.749912 9.47625 0.899169 9.81109 1.17251C10.1459 1.44585 10.3761 1.82649 10.4625 2.25H12.75C13.3467 2.25 13.919 2.48705 14.341 2.90901C14.7629 3.33097 15 3.90326 15 4.5V14.25C15 14.8467 14.7629 15.419 14.341 15.841C13.919 16.2629 13.3467 16.5 12.75 16.5H4.5C3.90326 16.5 3.33097 16.2629 2.90901 15.841C2.48705 15.419 2.25 14.8467 2.25 14.25V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25H6.7875C6.87395 1.82649 7.10407 1.44585 7.43891 1.17251C7.77375 0.899169 8.19276 0.749912 8.625 0.75ZM9.68625 2.25C9.60895 2.03025 9.46534 1.83992 9.27524 1.70528C9.08515 1.57064 8.85795 1.49833 8.625 1.49833C8.39205 1.49833 8.16485 1.57064 7.97476 1.70528C7.78466 1.83992 7.64105 2.03025 7.56375 2.25H9.68625ZM4.5 3C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10217 3 4.5V14.25C3 14.6478 3.15804 15.0294 3.43934 15.3107C3.72064 15.592 4.10218 15.75 4.5 15.75H12.75C13.1478 15.75 13.5294 15.592 13.8107 15.3107C14.092 15.0294 14.25 14.6478 14.25 14.25V4.5C14.25 4.10217 14.092 3.72064 13.8107 3.43934C13.5294 3.15804 13.1478 3 12.75 3H12V5.25H5.25V3H4.5ZM6 3V4.5H11.25V3H6Z'
      fill='currentColor'
    />
  </svg>
);
export { IconContentPaste };
