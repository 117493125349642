import { SVGProps } from 'react';

const IconWalletCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M14.856 2.595C14.8771 2.56847 14.9034 2.5465 14.9332 2.53043C14.9631 2.51436 14.9959 2.50453 15.0296 2.50154C15.0634 2.49855 15.0974 2.50245 15.1296 2.51302C15.1618 2.52358 15.1916 2.54059 15.217 2.563L16.138 3.375L13.239 7H15.16L17.266 4.368L18.918 5.825C18.9668 5.86817 18.9969 5.92871 19.0018 5.99372C19.0066 6.05872 18.9859 6.12306 18.944 6.173L18.254 7H20.198C20.4388 6.64748 20.5424 6.21909 20.4894 5.79549C20.4363 5.37188 20.2303 4.98227 19.91 4.7L16.21 1.437C16.0315 1.27981 15.8228 1.16065 15.5968 1.0868C15.3707 1.01295 15.1319 0.985961 14.895 1.00749C14.6581 1.02901 14.4282 1.0986 14.2191 1.21199C14.01 1.32538 13.8262 1.48021 13.679 1.667L9.476 7H11.386L14.856 2.595ZM16.75 14C16.5511 14 16.3603 14.079 16.2197 14.2197C16.079 14.3603 16 14.5511 16 14.75C16 14.9489 16.079 15.1397 16.2197 15.2803C16.3603 15.421 16.5511 15.5 16.75 15.5H18.75C18.9489 15.5 19.1397 15.421 19.2803 15.2803C19.421 15.1397 19.5 14.9489 19.5 14.75C19.5 14.5511 19.421 14.3603 19.2803 14.2197C19.1397 14.079 18.9489 14 18.75 14H16.75ZM5 7.25C5 7.05109 5.07902 6.86033 5.21967 6.71967C5.36032 6.57902 5.55109 6.5 5.75 6.5H8.878L10.07 5H5.75C5.15326 5 4.58097 5.23706 4.15901 5.65901C3.73705 6.08097 3.5 6.65327 3.5 7.25V17.75C3.5 18.612 3.84241 19.4386 4.4519 20.0481C5.0614 20.6576 5.88805 21 6.75 21H18.75C19.612 21 20.4386 20.6576 21.0481 20.0481C21.6576 19.4386 22 18.612 22 17.75V11.25C22 10.3881 21.6576 9.5614 21.0481 8.95191C20.4386 8.34241 19.612 8 18.75 8H5.75C5.55109 8 5.36032 7.92099 5.21967 7.78033C5.07902 7.63968 5 7.44892 5 7.25ZM5 17.75V9.372C5.235 9.455 5.487 9.5 5.75 9.5H18.75C19.716 9.5 20.5 10.284 20.5 11.25V17.75C20.5 18.2141 20.3156 18.6593 19.9874 18.9874C19.6592 19.3156 19.2141 19.5 18.75 19.5H6.75C6.28587 19.5 5.84075 19.3156 5.51256 18.9874C5.18437 18.6593 5 18.2141 5 17.75Z'
      fill='currentColor'
    />
  </svg>
);
export { IconWalletCreditCard };
