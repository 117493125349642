import { SVGProps } from 'react';

const IconDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1955_55868)'>
      <path
        d='M2.77791 9.53642L9.46986 16.6795L6.24741 9.53642H2.77791ZM11.5 17.8288L15.2488 9.53642H7.75122L11.5 17.8288ZM6.27964 8.16151L8.4709 4.03678H5.65663L2.56308 8.16151H6.27964ZM13.5301 16.6795L20.2221 9.53642H16.7526L13.5301 16.6795ZM7.83715 8.16151H15.1628L12.9716 4.03678H10.0284L7.83715 8.16151ZM16.7204 8.16151H20.4369L17.3434 4.03678H14.5291L16.7204 8.16151ZM18.2349 2.94114L22.3596 8.44079C22.4599 8.56968 22.5064 8.71827 22.4993 8.88656C22.4921 9.05484 22.4313 9.19985 22.3167 9.32159L12.0048 20.3209C11.876 20.4641 11.7077 20.5357 11.5 20.5357C11.2923 20.5357 11.124 20.4641 10.9951 20.3209L0.683321 9.32159C0.568746 9.19985 0.507877 9.05484 0.500716 8.88656C0.493555 8.71827 0.540102 8.56968 0.640355 8.44079L4.76509 2.94114C4.89398 2.75496 5.07659 2.66187 5.3129 2.66187H17.6871C17.9234 2.66187 18.106 2.75496 18.2349 2.94114Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1955_55868'>
        <rect width='22' height='22' fill='currentColor' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconDiamond };
