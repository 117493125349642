import { gql } from '@apollo/client';
import { ObjectID, String } from './get-real-estates';
import { RefUserType } from './get-representative-brokers';

export interface GetDeveloperInfoData {
  getDeveloperInfo?: RefUserType;
}
export interface GetDeveloperInfoVariables {
  search: String;
  id?: ObjectID;
}

export const GET_DEVELOPER_INFO = gql`
  query getDeveloperInfo($search: String!, $id: ObjectID) @api(name: users) {
    getDeveloperInfo(search: $search, id: $id) {
      id
      type
      fullName
      phone
      memberId
      avatar {
        ... on FileWithFullUrls {
          fileId
          originalUrl
        }
      }
      phones
    }
  }
`;
