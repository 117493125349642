import { SVGProps } from 'react';

const IconTunnel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M13.3332 14.1559L2.6665 10.8226M13.3332 14.1559H2.6665V10.8226L13.3332 14.1559ZM13.3332 14.1559V8.82259C13.3332 7.4081 12.7713 6.05155 11.7711 5.05136C10.7709 4.05116 9.41433 3.48926 7.99984 3.48926C6.58535 3.48926 5.2288 4.05116 4.2286 5.05136C3.22841 6.05155 2.6665 7.4081 2.6665 8.82259V10.8226L13.3332 14.1559Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.33301 5.48926V6.15592M10.6663 6.82259V7.48926V6.82259ZM7.99967 6.15592V6.82259V6.15592Z'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </svg>
);
export { IconTunnel };
