import { SVGProps } from 'react';

const IconTypeHierarchySub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M13.5 11H11.771L8.438 6H9.5L10 5.5V1.5L9.5 1H5.5L5 1.5V5.5L5.5 6H6.562L3.229 11H1.5L1 11.5V14.5L1.5 15H4.5L5 14.5V11.5L4.5 11H4.432L7.5 6.4L10.568 11H10.5L10 11.5V14.5L10.5 15H13.5L14 14.5V11.5L13.5 11ZM6 5V2H9V5H6ZM4 12V14H2V12H4ZM13 14H11V12H13V14Z'
      fill='currentColor'
    />
  </svg>
);
export { IconTypeHierarchySub };
