import { SVGProps } from 'react';

const IconCurrentLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.99 3.00008C11.6358 3.00561 11.3529 3.29687 11.3578 3.65107V4.32589C7.62126 4.63662 4.63529 7.62146 4.32491 11.3576H3.65135C3.41975 11.3543 3.20432 11.476 3.08757 11.6761C2.97081 11.8761 2.97081 12.1235 3.08757 12.3236C3.20432 12.5236 3.41975 12.6453 3.65135 12.642H4.32617C4.63686 16.3783 7.62161 19.3631 11.3578 19.6737V20.3486C11.3545 20.5802 11.4762 20.7956 11.6762 20.9124C11.8763 21.0291 12.1237 21.0291 12.3238 20.9124C12.5238 20.7956 12.6455 20.5802 12.6422 20.3486V19.6737C16.3784 19.3631 19.3631 16.3783 19.6738 12.642H20.3486C20.5803 12.6453 20.7957 12.5236 20.9124 12.3236C21.0292 12.1235 21.0292 11.8761 20.9124 11.6761C20.7957 11.476 20.5803 11.3543 20.3486 11.3576H19.6751C19.3647 7.62146 16.3787 4.63662 12.6422 4.32589V3.65107C12.6446 3.47748 12.5766 3.31032 12.4537 3.18768C12.3308 3.06504 12.1635 2.99737 11.99 3.00008ZM11.3579 5.60905V6.2199C11.3546 6.45151 11.4763 6.66693 11.6763 6.78369C11.8764 6.90045 12.1238 6.90045 12.3238 6.78369C12.5239 6.66693 12.6455 6.45151 12.6423 6.2199V5.60905C15.682 5.90939 18.0893 8.31776 18.3895 11.3576H17.7799C17.5483 11.3543 17.3329 11.476 17.2161 11.6761C17.0994 11.8761 17.0994 12.1235 17.2161 12.3236C17.3329 12.5236 17.5483 12.6453 17.7799 12.642H18.3908C18.0904 15.6816 15.6818 18.0902 12.6423 18.3906V17.7797C12.6447 17.6061 12.5767 17.439 12.4538 17.3163C12.3309 17.1937 12.1636 17.126 11.99 17.1287C11.6358 17.1343 11.353 17.4255 11.3579 17.7797V18.3906C8.31835 18.0902 5.90971 15.6816 5.60939 12.642H6.22024C6.45184 12.6453 6.66726 12.5236 6.78402 12.3236C6.90078 12.1235 6.90078 11.8761 6.78402 11.6761C6.66726 11.476 6.45184 11.3543 6.22024 11.3576H5.61064C5.91083 8.31776 8.31817 5.90939 11.3579 5.60905ZM9.43098 11.9998C9.43098 10.581 10.5811 9.43092 11.9998 9.43092C13.4185 9.43092 14.5686 10.581 14.5686 11.9998C14.5686 13.4185 13.4185 14.5686 11.9998 14.5686C10.5811 14.5686 9.43098 13.4185 9.43098 11.9998Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCurrentLocation };
