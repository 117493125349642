import { SVGProps } from 'react';

const IconCrownFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='18'
    viewBox='0 0 19 18'
    fill='currentColor'
    {...props}
  >
    <path
      d='M17.6433 6.20068C17.5165 6.09005 17.3595 6.01961 17.1925 5.99833C17.0255 5.97705 16.856 6.00588 16.7054 6.08115L13.175 7.83897L10.2275 2.94311C10.1517 2.81748 10.0448 2.71355 9.91703 2.64142C9.78928 2.56928 9.64506 2.53137 9.49835 2.53137C9.35164 2.53137 9.20742 2.56928 9.07967 2.64142C8.95192 2.71355 8.84498 2.81748 8.76921 2.94311L5.82171 7.84107L2.29272 6.08326C2.1425 6.00865 1.97358 5.98008 1.80717 6.00112C1.64076 6.02216 1.48427 6.09189 1.35734 6.20154C1.23041 6.3112 1.13869 6.4559 1.0937 6.61749C1.04871 6.77907 1.05245 6.95035 1.10444 7.10983L3.70601 15.0804C3.73216 15.1605 3.77597 15.2337 3.83419 15.2946C3.89242 15.3555 3.96357 15.4025 4.0424 15.4322C4.12123 15.4619 4.20573 15.4735 4.28965 15.4662C4.37357 15.4588 4.45477 15.4327 4.52726 15.3898C4.54483 15.3793 6.34272 14.3436 9.49835 14.3436C12.654 14.3436 14.4519 15.3793 14.468 15.3891C14.5406 15.4324 14.6219 15.4589 14.706 15.4665C14.7902 15.4741 14.8749 15.4626 14.954 15.433C15.0331 15.4033 15.1045 15.3562 15.1629 15.2952C15.2213 15.2342 15.2652 15.1608 15.2914 15.0804L17.893 7.11193C17.9464 6.95242 17.9513 6.78059 17.9068 6.61832C17.8624 6.45606 17.7707 6.31068 17.6433 6.20068ZM12.8621 11.8686C12.8391 11.9987 12.7711 12.1166 12.6699 12.2016C12.5687 12.2866 12.4409 12.3332 12.3087 12.3333C12.2757 12.3333 12.2428 12.3305 12.2103 12.3249C10.4136 12.0165 8.57747 12.0165 6.78077 12.3249C6.70801 12.3377 6.63343 12.3361 6.5613 12.3201C6.48917 12.3041 6.42089 12.2741 6.36036 12.2317C6.23813 12.1462 6.1549 12.0155 6.12897 11.8686C6.10305 11.7216 6.13657 11.5704 6.22215 11.4482C6.30772 11.3259 6.43836 11.2427 6.5853 11.2168C8.51135 10.8858 10.4797 10.8858 12.4058 11.2168C12.4788 11.2293 12.5486 11.2561 12.6113 11.2956C12.6739 11.3352 12.7281 11.3867 12.7708 11.4473C12.8134 11.5078 12.8437 11.5762 12.8599 11.6485C12.876 11.7208 12.8777 11.7956 12.8649 11.8686H12.8621Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCrownFill };
