import { SVGProps } from 'react';

const IconPriceChange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8 17.2813H10V16.2813H11C11.2833 16.2813 11.521 16.1853 11.713 15.9933C11.905 15.8013 12.0007 15.5639 12 15.2813V12.2813C12 11.9979 11.904 11.7603 11.712 11.5683C11.52 11.3763 11.2827 11.2806 11 11.2813H8V10.2813H12V8.28125H10V7.28125H8V8.28125H7C6.71667 8.28125 6.479 8.37725 6.287 8.56925C6.095 8.76125 5.99934 8.99859 6 9.28125V12.2813C6 12.5646 6.096 12.8023 6.288 12.9943C6.48 13.1863 6.71734 13.2819 7 13.2813H10V14.2813H6V16.2813H8V17.2813ZM16 16.5313L18 14.5313H14L16 16.5313ZM14 10.2813H18L16 8.28125L14 10.2813ZM4 20.2813C3.45 20.2813 2.979 20.0853 2.587 19.6933C2.195 19.3013 1.99934 18.8306 2 18.2813V6.28125C2 5.73125 2.196 5.26025 2.588 4.86825C2.98 4.47625 3.45067 4.28059 4 4.28125H20C20.55 4.28125 21.021 4.47725 21.413 4.86925C21.805 5.26125 22.0007 5.73192 22 6.28125V18.2813C22 18.8313 21.804 19.3023 21.412 19.6943C21.02 20.0863 20.5493 20.2819 20 20.2813H4ZM4 18.2813H20V6.28125H4V18.2813Z'
      fill='currentColor'
      stroke='#F4F4F4'
      strokeWidth='0.4'
    />
  </svg>
);
export { IconPriceChange };
