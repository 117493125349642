import { SVGProps } from 'react';

const IconRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='17'
    viewBox='0 0 19 17'
    fill='currentColor'
    {...props}
  >
    <g id='Group'>
      <path
        id='Vector'
        d='M10.0802 7.51511C10.248 7.16209 10.4532 6.71461 10.6153 6.30998C10.7621 5.94516 10.5947 5.44536 10.2672 5.22739C8.16015 3.82203 1.01293 -0.2263 0.204242 8.87167C0.169332 9.26354 0.0998404 9.26819 0.061001 8.87665C-0.0757554 7.50692 -0.139483 4.48001 1.66737 2.8502C1.95948 2.58697 2.47422 2.20827 2.8034 1.99311C3.97474 1.22615 6.91441 -0.0934072 11.1423 2.16301C11.4896 2.34823 11.8284 2.20218 11.9103 1.81745L12.2046 0.432725C12.2862 0.0479995 12.5174 0.00916012 12.7212 0.345812L16.1871 6.08087C16.3905 6.41752 16.2437 6.75771 15.859 6.84109L10.4637 8.00457C10.0785 8.08762 9.91139 7.8703 10.0802 7.51511ZM15.4733 14.6281C14.302 15.3941 11.3627 16.7111 7.13448 14.4564C6.78715 14.2712 6.41094 14.4069 6.28342 14.7792L5.92542 15.8244C5.79789 16.1967 5.546 16.2159 5.36293 15.8675L2.59532 10.5987C2.41219 10.2503 2.56899 9.87481 2.94553 9.76007L7.51927 8.3629C7.89548 8.24821 8.11529 8.46225 8.01049 8.84207C7.89293 9.26707 7.74084 9.82104 7.61221 10.2945C7.50925 10.6742 7.68668 11.169 8.01868 11.381C10.1508 12.7411 17.3678 16.6416 18.1537 7.6704C18.1878 7.27854 18.2516 7.27533 18.2851 7.66719C18.4034 9.05264 18.429 12.1312 16.6097 13.7713C16.3173 14.0343 15.8029 14.413 15.4733 14.6281Z'
        fill='currentColor'
      />
    </g>
  </svg>
);
export { IconRefresh };
