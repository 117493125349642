import { SVGProps } from 'react';

const IconTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_6286_1995)'>
      <path
        d='M19.388 0.405023C19.33 0.260535 19.2185 0.143976 19.0767 0.0795951C18.935 0.015214 18.7738 0.00796739 18.6269 0.059363C18.4799 0.110759 18.3584 0.216839 18.2876 0.355536C18.2169 0.494233 18.2023 0.654868 18.247 0.804023C19.176 3.47402 17.332 5.46802 15.926 6.53602L15.358 5.72202C15.167 5.44902 14.74 5.22202 14.408 5.21802L11.22 5.23202C10.8306 5.24378 10.4515 5.36057 10.123 5.57002L0.729002 12.157C0.510497 12.3108 0.361821 12.5449 0.315527 12.808C0.269234 13.0712 0.329094 13.3419 0.482002 13.561L4.751 19.669C5.071 20.124 5.582 20.069 6.038 19.751L15.432 13.163C15.702 12.972 16.014 12.56 16.124 12.245L17.122 9.10002C17.232 8.78602 17.165 8.30702 16.974 8.03402L16.628 7.53802C18.516 6.09102 20.476 3.53402 19.388 0.405023ZM15.017 9.76302C14.8439 9.88416 14.6487 9.97002 14.4425 10.0157C14.2362 10.0613 14.023 10.0659 13.815 10.0291C13.6069 9.99236 13.4082 9.91496 13.2301 9.80135C13.052 9.68774 12.898 9.54016 12.777 9.36702C12.5319 9.01668 12.4359 8.58341 12.5099 8.16232C12.584 7.74123 12.8221 7.36673 13.172 7.12102C13.4463 6.92884 13.7738 6.82717 14.1087 6.83021C14.4436 6.83326 14.7692 6.94088 15.04 7.13802C14.768 7.30202 14.581 7.39802 14.546 7.41302C14.422 7.47213 14.3218 7.57159 14.2618 7.6951C14.2018 7.81861 14.1855 7.95885 14.2155 8.09284C14.2456 8.22683 14.3204 8.34663 14.4275 8.4326C14.5346 8.51857 14.6677 8.56561 14.805 8.56602C14.891 8.56602 14.979 8.54602 15.062 8.50702C15.256 8.41502 15.464 8.30602 15.681 8.17702C15.7309 8.47709 15.6951 8.78514 15.5776 9.06573C15.4601 9.34632 15.2658 9.58803 15.017 9.76302Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6286_1995'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconTag };
