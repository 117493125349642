import { gql } from '@apollo/client/core';
import { MutationOf } from 'graphql/upload/mutations';
import { UserBiha } from '../queries';
import { Boolean, ID, ObjectID, String } from './../queries/get-real-estates';

export interface RegisterGetPriceList {
  publishedProject: ObjectID;
  fullName: String;
  phone: String;
  email?: String;
  priceList?: Boolean;
}
export interface RegisterInProjectOutput {
  id?: ID;
  fullName?: String;
  phone?: String;
  email?: String;
  priceList?: Boolean;
  note?: String;
  appointmentTime?: Date;
  registerType?: String;
  publishedProject?: ObjectID;
  product?: ObjectID;
  creator?: UserBiha;
  project?: ObjectID;
  demand?: String;
  status?: String;
  reasonCancel?: String;
}
export interface MutationOfRegisterInProject extends MutationOf {
  payload?: RegisterInProjectOutput;
}

export interface CreateRegisterGetPriceListData {
  createRegisterGetPriceList?: MutationOfRegisterInProject;
}
export interface CreateRegisterGetPriceListVariables {
  input?: RegisterGetPriceList;
}

export const CREATE_REGISTER_GET_PRICE_LIST = gql`
  mutation createRegisterGetPriceList($input: RegisterGetPriceList) @api(name: biha) {
    createRegisterGetPriceList(input: $input) {
      success
      msg
    }
  }
`;
