import { SVGProps } from 'react';

const IconVideo360 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M16.6667 9.65302V7.31781C16.6666 7.28606 16.6645 7.25435 16.6603 7.22289C16.659 7.21233 16.6563 7.20229 16.6547 7.1919C16.6512 7.17147 16.6475 7.15105 16.6423 7.13115C16.6407 7.12481 16.6385 7.11864 16.6365 7.1123L12.3333 9.63823V14.9739L16.2982 12.8786C16.524 12.7592 16.6667 12.5153 16.6667 12.2485V11.1984C17.9297 11.8676 18.6667 12.7694 18.6667 13.6788C18.6667 14.828 17.4947 15.9548 15.6078 16.6196L16.0295 17.9561C18.5167 17.0801 20 15.4813 20 13.6788C20 12.0386 18.7852 10.5744 16.6667 9.65302Z'
      fill='currentColor'
    />
    <path
      d='M11.1381 15.2937L10.1951 16.2901L11.0191 17.1605C7.84994 16.9013 5.33327 15.4123 5.33327 13.6788C5.33327 12.7645 6.06927 11.8673 7.33327 11.1991V12.2485C7.33325 12.5153 7.47591 12.7592 7.70177 12.8786L11.6666 14.9731V9.63823L7.36344 7.1123C7.36144 7.11864 7.35927 7.12481 7.35761 7.13115C7.35244 7.15105 7.34877 7.17147 7.34527 7.1919C7.34361 7.20229 7.34094 7.21233 7.33961 7.22289C7.33548 7.25435 7.33337 7.28606 7.33327 7.31781V9.65461C5.24294 10.5654 3.99994 12.0571 3.99994 13.6788C3.99994 16.2665 7.07811 18.3291 11.1499 18.5829L10.3033 19.5363L11.2756 20.5002L13.1524 18.387C13.3988 18.1097 13.3922 17.6758 13.1376 17.4069L11.1381 15.2937Z'
      fill='currentColor'
    />
    <path
      d='M16.1342 6.60107L12.2981 4.57438C12.1104 4.47521 11.8894 4.47521 11.7017 4.57438L7.86557 6.60107L11.9999 9.02785L16.1342 6.60107Z'
      fill='currentColor'
    />
  </svg>
);
export { IconVideo360 };
