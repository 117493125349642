import { SVGProps } from 'react';

const IconMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M2.66683 2.66663H13.3335C14.0668 2.66663 14.6668 3.26663 14.6668 3.99996V12C14.6668 12.7333 14.0668 13.3333 13.3335 13.3333H2.66683C1.9335 13.3333 1.3335 12.7333 1.3335 12V3.99996C1.3335 3.26663 1.9335 2.66663 2.66683 2.66663Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6668 4L8.00016 8.66667L1.3335 4'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconMail };
