import { gql } from '@apollo/client';
import { PaginationOf } from 'graphql/main/queries';
import { Int, ObjectID, String } from '../../main/queries/get-real-estates';
import { Ward } from './get-list-districts-of-province';

export interface ListWards extends PaginationOf {
  wards?: Ward[];
}

export interface GetListWardsOfDistrictData {
  getListWardsOfDistrict?: ListWards;
}
export interface GetListOldWardsOfDistrictData {
  getListOldWardsOfDistrict?: ListWards;
}
export interface GetListWardsOfDistrictVariables {
  districtId?: ObjectID;
  streetId?: ObjectID;
  name?: String;
  limit?: Int;
  page?: Int;
}

export const GET_LIST_WARDS_OF_DISTRICT = gql`
  query getListWardsOfDistrict(
    $districtId: ObjectID!
    $streetId: ObjectID
    $name: String
    $limit: Int
    $page: Int
  ) {
    getListWardsOfDistrict(
      districtId: $districtId
      streetId: $streetId
      name: $name
      limit: $limit
      page: $page
    ) {
      wards {
        id
        name
      }
    }
  }
`;

export const GET_LIST_OLD_WARDS_OF_DISTRICT = gql`
  query getListOldWardsOfDistrict(
    $districtId: ObjectID!
    $streetId: ObjectID
    $name: String
    $limit: Int
    $page: Int
  ) {
    getListOldWardsOfDistrict(
      districtId: $districtId
      streetId: $streetId
      name: $name
      limit: $limit
      page: $page
    ) {
      wards {
        id
        name
      }
    }
  }
`;
