import { SVGProps } from 'react';

const IconHomeVerify1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_3028_16130)'>
      <path
        d='M16.5434 22.5C18.1241 22.5 19.64 21.872 20.7577 20.7543C21.8754 19.6366 22.5034 18.1206 22.5034 16.54C22.5034 14.9593 21.8754 13.4433 20.7577 12.3256C19.64 11.2079 18.1241 10.58 16.5434 10.58C14.9627 10.58 13.4467 11.2079 12.329 12.3256C11.2113 13.4433 10.5834 14.9593 10.5834 16.54C10.5834 18.1206 11.2113 19.6366 12.329 20.7543C13.4467 21.872 14.9627 22.5 16.5434 22.5ZM17.1462 12.5314L19.7005 15.0857C19.8603 15.2456 19.9502 15.4624 19.9502 15.6885C19.9502 15.9146 19.8603 16.1315 19.7005 16.2913C19.5406 16.4512 19.3238 16.541 19.0977 16.541C18.8716 16.541 18.6547 16.4512 18.4948 16.2913L17.3948 15.1896V19.9457C17.3948 20.1715 17.3051 20.388 17.1454 20.5477C16.9858 20.7074 16.7692 20.7971 16.5434 20.7971C16.3176 20.7971 16.101 20.7074 15.9413 20.5477C15.7816 20.388 15.6919 20.1715 15.6919 19.9457V15.1896L14.5919 16.2913C14.4323 16.4512 14.2156 16.5411 13.9897 16.5413C13.7638 16.5414 13.547 16.4518 13.3871 16.2922C13.2273 16.1325 13.1373 15.9159 13.1372 15.69C13.137 15.464 13.2266 15.2473 13.3863 15.0874L15.9406 12.5331C16.0197 12.4538 16.1136 12.3909 16.2171 12.348C16.3205 12.3051 16.4314 12.283 16.5434 12.283C16.6554 12.283 16.7663 12.3051 16.8697 12.348C16.9731 12.3909 17.0671 12.4538 17.1462 12.5331V12.5314Z'
        fill='currentColor'
      />
      <path
        d='M16.0833 5.9614V2.33323C16.0833 1.82723 15.6727 1.41657 15.1667 1.41657C14.6607 1.41657 14.25 1.82723 14.25 2.33323V4.1684L10.7657 0.76115C10.4092 0.412817 9.83992 0.413734 9.48425 0.76115L0.906083 9.14682C0.6485 9.4044 0.5 9.7619 0.5 10.1249C0.5 10.883 1.11692 11.4999 1.875 11.4999H3.25V16.5416C3.25 17.8047 4.2785 18.8332 5.54167 18.8332H7.83333C8.33933 18.8332 8.75 18.4226 8.75 17.9166C8.75 17.4106 8.33933 16.9999 7.83333 16.9999H5.54167C5.28867 16.9999 5.08333 16.7946 5.08333 16.5416V10.5832C5.08333 10.0772 4.67267 9.66656 4.16667 9.66656H2.997L10.125 2.69807L16.1301 8.57023C16.4931 8.92498 17.0724 8.91765 17.4262 8.55557C17.7801 8.19348 17.7737 7.61323 17.4116 7.2594L16.0833 5.9614Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_3028_16130'>
        <rect width='22' height='22' fill='currentColor' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconHomeVerify1 };
