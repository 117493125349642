import { gql } from '@apollo/client';
import { String } from '../queries';
import { MessageType } from './forgot-password';

export interface ResetPasswordData {
  resetPassword?: MessageType;
}
export interface ResetPasswordVariables {
  authString: String;
  newPassword: String;
  confirmNewPassword: String;
  secretCode: String;
}

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $authString: String!
    $newPassword: String!
    $confirmNewPassword: String!
    $secretCode: String!
  ) @api(name: users) {
    resetPassword(
      authString: $authString
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
      secretCode: $secretCode
    ) {
      message
    }
  }
`;
