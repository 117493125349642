import { gql } from '@apollo/client';
import { Boolean, DateTime, Int, ObjectID, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';
import { Message } from './messages';

export interface Avatar {
  fileId?: String;
  originalUrl?: String;
}
export enum Role {
  admin = 'admin',
  member = 'member',
}
export interface Participant {
  id?: ObjectID;
  nickname?: String;
  fullName?: String;
  avatar?: Avatar;
  pinnedAt?: DateTime;
  userId?: ObjectID;
  role?: Role;
}
export interface Conversation {
  blockedBy?: ObjectID[];
  createdAt?: DateTime;
  id?: ObjectID;
  isGroup?: Boolean;
  lastMessage?: Message;
  name?: String;
  participants?: Participant[];
  typing?: Boolean;
  updatedAt?: DateTime;
}
export interface PaginationOfConversation extends PaginationOf {
  items?: Conversation[];
}

export interface ConversationsData {
  conversations?: PaginationOfConversation;
  conversationUpdated?: Conversation;
}
export interface ConversationsVariables {
  search?: String;
  offset?: Int;
  limit?: Int;
}

export const CONVERSATIONS = gql`
  query conversations($search: String, $offset: Int, $limit: Int) @api(name: chat) {
    conversations(search: $search, offset: $offset, limit: $limit) {
      limit
      nextPage
      offset
      total
      items {
        id
        name
        lastMessage {
          id
          from
          to
          text
          type
          createdAt
          callPayload
          attachments {
            type
            attachmentId
            url
          }
        }
        participants {
          id
          nickname
          fullName
          avatar {
            originalUrl
            fileId
          }
          userId
          pinnedAt
        }
      }
    }
  }
`;
