import { SVGProps } from 'react';

const IconDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='currentColor'
    {...props}
  >
    <path
      d='M4.58334 18.6146H17.4167V16.7812H4.58334V18.6146ZM17.4167 8.53125H13.75V3.03125H8.25001V8.53125H4.58334L11 14.9479L17.4167 8.53125Z'
      fill='currentColor'
    />
  </svg>
);
export { IconDownload };
