import { SVGProps } from 'react';

const IconCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.5 2H3.80616C4.05218 2 4.17519 2 4.27418 2.04524C4.36142 2.08511 4.43535 2.14922 4.48715 2.22995C4.54593 2.32154 4.56333 2.44332 4.59812 2.68686L5.07143 6M5.07143 6L6.12332 13.7314C6.25681 14.7125 6.32355 15.2031 6.5581 15.5723C6.76478 15.8977 7.06108 16.1564 7.41135 16.3174C7.80886 16.5 8.30394 16.5 9.29411 16.5H17.852C18.7945 16.5 19.2658 16.5 19.651 16.3304C19.9905 16.1809 20.2818 15.9398 20.4923 15.6342C20.7309 15.2876 20.8191 14.8247 20.9955 13.8988L22.3191 6.94969C22.3812 6.62381 22.4122 6.46087 22.3672 6.3335C22.3278 6.22177 22.2499 6.12768 22.1475 6.06802C22.0308 6 21.865 6 21.5332 6H5.07143ZM10.5 21C10.5 21.5523 10.0523 22 9.5 22C8.94772 22 8.5 21.5523 8.5 21C8.5 20.4477 8.94772 20 9.5 20C10.0523 20 10.5 20.4477 10.5 21ZM18.5 21C18.5 21.5523 18.0523 22 17.5 22C16.9477 22 16.5 21.5523 16.5 21C16.5 20.4477 16.9477 20 17.5 20C18.0523 20 18.5 20.4477 18.5 21Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { IconCart };
