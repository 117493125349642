import { SVGProps } from 'react';

const IconProjectOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8.00001 4.66634C8.3682 4.66634 8.66668 4.36786 8.66668 3.99967C8.66668 3.63148 8.3682 3.33301 8.00001 3.33301C7.63182 3.33301 7.33334 3.63148 7.33334 3.99967C7.33334 4.36786 7.63182 4.66634 8.00001 4.66634Z'
      fill='currentColor'
    />
    <path d='M4 11.333H12V12.6663H4V11.333Z' fill='currentColor' />
    <path
      d='M6.66667 7.88634L8.528 9.74901L11.1493 7.12567L12 7.99967V5.33301H9.33333L10.2067 6.18301L8.52733 7.86234L6.66667 5.99967L4 8.66634L4.94267 9.60901L6.66667 7.88634Z'
      fill='currentColor'
    />
    <path
      d='M12.6667 1.99967H10.468C10.4026 1.9011 10.3313 1.80649 10.2547 1.71633L10.248 1.70833C9.75819 1.13783 9.06905 0.775857 8.32134 0.696334C8.10903 0.655909 7.89099 0.655909 7.67868 0.696334C6.93097 0.775857 6.24183 1.13783 5.75201 1.70833L5.74534 1.71633C5.66873 1.80627 5.59749 1.90066 5.53201 1.999V1.99967H3.33334C2.80307 2.0002 2.29467 2.21108 1.91971 2.58604C1.54476 2.961 1.33387 3.4694 1.33334 3.99967V13.333C1.33387 13.8633 1.54476 14.3717 1.91971 14.7466C2.29467 15.1216 2.80307 15.3325 3.33334 15.333H12.6667C13.1969 15.3325 13.7053 15.1216 14.0803 14.7466C14.4553 14.3717 14.6661 13.8633 14.6667 13.333V3.99967C14.6661 3.4694 14.4553 2.961 14.0803 2.58604C13.7053 2.21108 13.1969 2.0002 12.6667 1.99967ZM13.3333 13.333C13.3333 13.5098 13.2631 13.6794 13.1381 13.8044C13.0131 13.9294 12.8435 13.9997 12.6667 13.9997H3.33334C3.15659 13.9995 2.98712 13.9292 2.86213 13.8042C2.73715 13.6792 2.66685 13.5098 2.66668 13.333V3.99967C2.66685 3.82291 2.73715 3.65344 2.86213 3.52846C2.98712 3.40347 3.15659 3.33318 3.33334 3.333H6.36668C6.44318 2.95624 6.64759 2.61751 6.94526 2.37421C7.24293 2.13091 7.61556 1.998 8.00001 1.998C8.38446 1.998 8.75709 2.13091 9.05476 2.37421C9.35243 2.61751 9.55684 2.95624 9.63334 3.333H12.6667C12.8434 3.33318 13.0129 3.40347 13.1379 3.52846C13.2629 3.65344 13.3332 3.82291 13.3333 3.99967V13.333Z'
      fill='currentColor'
    />
  </svg>
);
export { IconProjectOutline };
