import { InternalFromToDateStringInputType } from 'graphql/main/queries';

export interface BookingSearchForm {
  projectName?: string;
  createdAt?: InternalFromToDateStringInputType;
  sort?: string;
}

export const BookingSearchFormDefaultValues: BookingSearchForm = {
  projectName: undefined,
  createdAt: undefined,
  sort: undefined,
};
