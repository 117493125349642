import {
  OperationVariables,
  SubscriptionHookOptions,
  SubscriptionResult,
  TypedDocumentNode,
  useSubscription,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { isEqual } from 'lodash';
import { blogClient, chatClient, mainClient, mapClient, uploadClient } from '../apollo/client';

export const useFlexSubscription = <TData = any, TVariables = OperationVariables>(
  service: 'main' | 'upload' | 'map' | 'chat' | 'blog',
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>,
): SubscriptionResult<TData, any> =>
  useSubscription(subscription, {
    ...options,
    client: isEqual(service, 'upload')
      ? uploadClient
      : isEqual(service, 'map')
      ? mapClient
      : isEqual(service, 'chat')
      ? chatClient
      : isEqual(service, 'blog')
      ? blogClient
      : mainClient,
  });
