import { SVGProps } from 'react';

const IconStarHaft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.66677 14.1333L2.80131 10.9334C2.07173 10.35 2.39172 9.17668 3.31755 9.04442L8.80002 8.80016L11.1413 2.93051C11.3045 2.60518 11.6362 2.40039 11.9999 2.40039C12.3636 2.40039 12.6953 2.60625 12.8585 2.93051L15.1998 8.80016L20.6822 9.04442C21.6081 9.17668 21.9281 10.35 21.1985 10.9334L17.333 14.1333L18.3879 20.3806C18.5095 21.2328 17.6242 21.8706 16.8552 21.4856L11.9999 18.3998L7.14461 21.4845C6.37451 21.8696 5.49028 21.2317 5.61187 20.3795L6.66677 14.1333Z'
      fill='#E5E5E5'
    />
    <path
      d='M12.0433 2.39453C11.6159 2.39453 11.3139 2.58449 11.0927 3.03067L8.79457 8.79197L3.35911 9.03466C2.37676 9.17714 2.0796 10.3212 2.79427 10.9359L6.66267 14.1414L5.61771 20.3565C5.44898 21.3351 6.52345 21.8792 7.22232 21.4434L11.9881 18.3996L12.0433 2.39453Z'
      fill='currentColor'
    />
  </svg>
);
export { IconStarHaft };
