import { gql } from '@apollo/client';
import { Boolean, Int, ObjectID, String } from './get-real-estates';
import { KindRealEstateEnumType } from './near-real-estates';

export interface StatisticRealEstateByWard {
  slug?: String;
  total?: Int;
  wardId?: String;
  wardName?: String;
}

export interface NearRealEstatesByWardData {
  nearRealEstatesByWard?: StatisticRealEstateByWard[];
}
export interface NearRealEstatesByWardVariables {
  isForSell?: Boolean;
  realEstateType?: String;
  kindOfRealEstate?: KindRealEstateEnumType;
  province?: ObjectID;
  district?: ObjectID;
  ward?: ObjectID;
}

export const NEAR_REAL_ESTATES_BY_WARD = gql`
  query nearRealEstatesByWard(
    $isForSell: Boolean
    $realEstateType: String
    $kindOfRealEstate: KindRealEstateEnumType
    $province: ObjectID
    $district: ObjectID
    $ward: ObjectID
  ) @api(name: realEstate) {
    nearRealEstatesByWard(
      isForSell: $isForSell
      realEstateType: $realEstateType
      kindOfRealEstate: $kindOfRealEstate
      province: $province
      district: $district
      ward: $ward
    ) {
      slug
      total
      wardId
      wardName
    }
  }
`;
