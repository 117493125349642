import { PHONE_NUMBER_REGEX } from 'globalConstants';
import * as yup from 'yup';

export interface RegisterAdviseForm {
  fullName?: string;
  phone?: string;
  email?: string;
  note?: string;
}

export const registerAdviseFormDefaultValues: RegisterAdviseForm = {
  fullName: undefined,
  phone: undefined,
  email: undefined,
  note: undefined,
};

export const registerAdviseFormSchema = yup.object().shape({
  fullName: yup.string().required('Vui lòng nhập họ và tên'),
  phone: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  email: yup.string().nullable().email('Vui lòng nhập đúng định dạng email@xxx.com'),
});
