import { gte, round } from 'lodash';

export const convertNumberToVietnameseCurrencyShortString = (number: number) => {
  return gte(number, 1e9)
    ? `${round(number / 1e9, 2).toLocaleString()} tỷ`
    : gte(number, 1e6)
    ? `${round(number / 1e6, 2)} triệu`
    : gte(number, 1e3)
    ? `${round(number / 1e3, 2)} nghìn`
    : gte(number, 1)
    ? `${round(number, 2)} đồng`
    : '0 đồng';
};
