import { gql } from '@apollo/client';
import {
  Boolean,
  BuildingType,
  DirectionEnumType,
  EquipmentsAndServicesEnumType,
  FarmLandUseEnumType,
  FixedUtilitiesEnumType,
  Float,
  Int,
  LocalUtilitiesEnumType,
  ObjectID,
  RealEstate,
  RealEstateTypeEnumType,
  String,
  UtilitiesEnumType,
  WarehouseEnumType,
} from '../queries';
import { WalletSourceType } from './calculate-valuation';
import { Date } from './update-user-v2';

export enum ViewEnumType {
  sea = 'sea',
  city = 'city',
  mountain = 'mountain',
  inside = 'inside',
}
export interface CoordinateDataInput {
  type?: String;
  coordinates?: Float[];
}
export interface RealEstateInput {
  postTitle: String;
  postContent: String;
  privateNote?: String;
  type: RealEstateTypeEnumType;
  isForSell: Boolean;
  country: String;
  province: String;
  district: String;
  ward?: String;
  street?: String;
  houseNumber?: String;
  hasAlley: Boolean;
  alleyTurnCount?: Int;
  frontHomeAlleyWidth?: Float;
  location?: CoordinateDataInput;
  width?: Float;
  length?: Float;
  rear?: Float;
  area?: Float;
  price?: Float;
  constructionArea?: Float;
  floorArea?: Float;
  floorCount?: Int;
  bedroomCount?: Int;
  toiletCount?: Int;
  direction?: DirectionEnumType;
  isNewConstruction?: Boolean;
  industryTrade?: String;
  clearanceArea?: Float;
  onFloor?: Int;
  hotelStar?: Int;
  villaView?: ViewEnumType[];
  buildingType?: BuildingType | null;
  warehouseType?: WarehouseEnumType;
  hasMezzanine?: Boolean;
  block?: String;
  investor?: String;
  project?: String;
  mediaUrls?: ObjectID[];
  panoramicImages?: ObjectID[];
  vr360Urls?: String[];
  legals?: String[];
  contactName: String;
  contactPhones: String[];
  contactEmail?: String;
  postType: Int;
  startShowTime: Date;
  endShowTime: Date;
  source?: WalletSourceType;
  fixedUtilities?: FixedUtilitiesEnumType[];
  utilities?: UtilitiesEnumType[];
  localUtilities?: LocalUtilitiesEnumType[];
  equipmentsAndServices?: EquipmentsAndServicesEnumType[];
  farmLandUse?: FarmLandUseEnumType;
}

export interface CreateRealEstateData {
  createRealEstate?: RealEstate;
}
export interface CreateRealEstateVariables {
  data: RealEstateInput;
  captchaId: String;
  captchaCode: String;
}

export const CREATE_REAL_ESTATE = gql`
  mutation createRealEstate($data: RealEstateInput!, $captchaId: String!, $captchaCode: String!)
  @api(name: realEstate) {
    createRealEstate(data: $data, captchaId: $captchaId, captchaCode: $captchaCode) {
      id
    }
  }
`;
