import { gql } from '@apollo/client';
import { UserSocial } from '../mutations/temp/create-post';
import { Boolean, DateTime, Float, JSONObject, String } from './get-real-estates';
import { B2CUser, UserConfigType } from './get-representative-brokers';
import { AddressType, AnotherServiceFileType } from './list-companies-by-type';

export interface ApproveStatusOutputType {
  reason?: String;
  reasonType?: String;
  status?: String;
}
export interface PermissionOutputType {
  configs?: JSONObject;
  id?: String;
  name?: String;
}
export interface EmployeeDepartmentType {
  createdAt?: DateTime;
  creator?: B2BUserType;
  deletedAt?: DateTime;
  deletedBy?: B2BUserType;
  department?: DepartmentOutputType;
  editor?: B2BUserType;
  id?: String;
  manager?: EmployeeDepartmentType;
  positionName?: String;
  positionType?: String;
  secretaries?: EmployeeDepartmentType[];
  updatedAt?: DateTime;
  user?: B2BUserType;
}
export interface DepartmentOutputType {
  code?: String;
  company?: String;
  createdAt?: DateTime;
  creator?: B2BUserType;
  deletedAt?: DateTime;
  editor?: B2BUserType;
  id?: String;
  leader?: EmployeeDepartmentType;
  name?: String;
  note?: String;
  parentDepartment?: DepartmentOutputType;
  province?: AddressType;
  region?: AddressType;
  updatedAt?: DateTime;
}
export interface B2BUserType {
  amount?: Float;
  approvedAt?: DateTime;
  approvedBy?: B2BUserType;
  approveStatus?: ApproveStatusOutputType;
  attachments?: AnotherServiceFileType[];
  avatar?: AnotherServiceFileType;
  b2cPermissions?: PermissionOutputType[];
  company?: String;
  configs?: UserConfigType;
  createdAt?: DateTime;
  creator?: B2BUserType;
  dateOfBirth?: DateTime;
  deletedAt?: DateTime;
  departments?: EmployeeDepartmentType[];
  deviceToken?: String;
  district?: AddressType;
  editor?: B2BUserType;
  email?: String;
  employeeCode?: String;
  employeeDepartments?: String[];
  fullName?: String;
  gender?: String;
  houseNumber?: String;
  id?: String;
  idImages?: AnotherServiceFileType[];
  idNumber?: String;
  isLocked?: Boolean;
  isPersonal?: Boolean;
  lastOnline?: DateTime;
  lockByRtAt?: DateTime;
  lockedByBo?: String;
  permissionGroup?: String;
  phone?: String;
  position?: String;
  productEmployees?: String[];
  productPermissions?: PermissionOutputType[];
  projectEmployees?: String[];
  projectPermissions?: PermissionOutputType[];
  province?: AddressType;
  role?: String;
  secondPhone?: String;
  street?: AddressType;
  system?: String[];
  updatedAt?: DateTime;
  username?: String;
  userType?: String;
  ward?: AddressType;
}
export type UserUnion = B2BUserType | B2CUser | UserSocial;

export interface UserInfoData {
  userInfo?: UserUnion;
}
export interface UserInfoVariables {
  id: String;
}

export const USER_INFO = gql`
  query userInfo($id: String!) @api(name: users) {
    userInfo(id: $id) {
      ... on B2CUser {
        id
        memberId
        fullName
        phones
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
        type
        gender
        province {
          id
          name
        }
      }
    }
  }
`;
