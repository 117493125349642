import { gql } from '@apollo/client';
import { String } from '../queries';
import { FriendRequestMutation } from './un-friend';

export interface DenyFriendRequestVariables {
  requestId: String;
}
export interface DenyFriendRequestData {
  denyFriendRequest?: FriendRequestMutation;
}

export const DENY_FRIEND_REQUEST = gql`
  mutation denyFriendRequest($requestId: ID!) @api(name: social) {
    denyFriendRequest(requestId: $requestId) {
      success
      msg
      data {
        _id
      }
    }
  }
`;
