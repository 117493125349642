import { ApolloClient, ApolloLink } from '@apollo/client';
import { baseClientOption } from 'apollo/client';
import { createAuthenticationLink, errorLink, mainLink } from 'apollo/link';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { BASE_PATH } from 'globalConstants';
import { B2CUser, USER, UserData } from 'graphql/main/queries';
import { toString } from 'lodash';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface AtmnhaAuthenticationModel {
  accessToken?: string;
  refreshToken?: string;
}
export interface ECommerceAuthenticationModel {
  token?: string;
}

interface Props {
  atmnhaAuthentication?: AtmnhaAuthenticationModel;
  saveAtmnhaAuthentication?: (authentication?: AtmnhaAuthenticationModel) => void;
  eCommerceAuthentication?: ECommerceAuthenticationModel;
  saveECommerceAuthentication?: (authentication?: ECommerceAuthenticationModel) => void;
  currentUser?: B2CUser;
  setCurrentUser?: Dispatch<SetStateAction<B2CUser | undefined>>;
  clearAuthentication?: () => void;
}

const AuthenticationContext = createContext<Props>({});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const [atmnhaAuthentication, setAtmnhaAuthentication] = useState<
    AtmnhaAuthenticationModel | undefined
  >(() => {
    const atmnhaAuthenticationString = toString(getCookie('atmnha_authentication_cookie')) || '{}';
    return JSON.parse(atmnhaAuthenticationString) as AtmnhaAuthenticationModel;
  });
  const [eCommerceAuthentication, setECommerceAuthentication] = useState<
    ECommerceAuthenticationModel | undefined
  >(() => {
    const eCommerceAuthenticationString =
      toString(getCookie('e_commerce_authentication_cookie')) || '{}';
    return JSON.parse(eCommerceAuthenticationString) as ECommerceAuthenticationModel;
  });
  const { accessToken } = atmnhaAuthentication ?? {};
  const [currentUser, setCurrentUser] = useState<B2CUser | undefined>();
  const didRequest = useRef(false);

  const saveAtmnhaAuthentication = (authentication?: AtmnhaAuthenticationModel) => {
    setAtmnhaAuthentication(authentication);
    if (authentication) {
      setCookie('atmnha_authentication_cookie', JSON.stringify(authentication), {
        path: '/',
        domain: BASE_PATH,
      });
    } else {
      deleteCookie('atmnha_authentication_cookie', {
        path: '/',
        domain: BASE_PATH,
      });
    }
  };
  const saveECommerceAuthentication = (authentication?: ECommerceAuthenticationModel) => {
    setECommerceAuthentication(authentication);
    if (authentication) {
      setCookie('e_commerce_authentication_cookie', JSON.stringify(authentication), {
        path: '/',
        domain: BASE_PATH,
      });
    } else {
      deleteCookie('e_commerce_authentication_cookie', {
        path: '/',
        domain: BASE_PATH,
      });
    }
  };
  const clearAuthentication = () => {
    saveAtmnhaAuthentication(undefined);
    saveECommerceAuthentication(undefined);
    setCurrentUser(undefined);
  };

  useEffect(() => {
    const requestUser = async (accessToken: string) => {
      if (!didRequest.current) {
        const authLink = createAuthenticationLink(accessToken);
        const mainClient = new ApolloClient({
          ...baseClientOption,
          link: ApolloLink.from([errorLink, authLink, mainLink]),
        });
        const { data: userData, error: userError } = await mainClient.query<UserData>({
          query: USER,
        });
        const { user } = userData;
        const { ecommerceData } = user ?? {};
        if (userError || !user) {
          clearAuthentication?.();
        } else {
          saveECommerceAuthentication(ecommerceData);
          setCurrentUser?.(user);
        }
      }

      return () => (didRequest.current = true);
    };

    if (accessToken) {
      requestUser(accessToken);
    } else {
      clearAuthentication?.();
    }
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        atmnhaAuthentication,
        saveAtmnhaAuthentication,
        eCommerceAuthentication,
        saveECommerceAuthentication,
        currentUser,
        setCurrentUser,
        clearAuthentication,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
