import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { Rank } from '../queries';
import { UpgradeRequest } from './checkout-upgrade-rank';

export interface RequestUpgradeAccountMutation extends MutationOf {
  payload?: UpgradeRequest;
}
export interface RequestUpgradeAccountData {
  requestUpgradeAccount?: RequestUpgradeAccountMutation;
}
export interface RequestUpgradeAccountVariables {
  rank: Rank;
}

export const REQUEST_UPGRADE_ACCOUNT = gql`
  mutation requestUpgradeAccount($rank: Rank!) @api(name: users) {
    requestUpgradeAccount(rank: $rank) {
      success
      msg
      payload {
        requestId
        rankName
        amount
      }
    }
  }
`;
