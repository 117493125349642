import { gql } from '@apollo/client';
import { Boolean, String } from '../queries';

export interface MutationOfAccessTokenType {
  payload?: AccessTokenType;
  msg?: String;
  success?: Boolean;
}
export interface AccessTokenType {
  accessToken?: String;
  refreshToken?: String;
  memberId?: String;
  fullName?: String;
  system?: String[];
  developer?: String;
  id?: String;
}

export interface SignInData {
  signIn?: MutationOfAccessTokenType;
}
export interface SignInVariables {
  emailOrPhone: String;
  password: String;
  savedLogin?: Boolean;
}

export const SIGN_IN = gql`
  mutation signIn($emailOrPhone: String!, $password: String!, $savedLogin: Boolean)
  @api(name: users) {
    signIn(emailOrPhone: $emailOrPhone, password: $password, savedLogin: $savedLogin) {
      success
      msg
      payload {
        accessToken
        refreshToken
      }
    }
  }
`;
