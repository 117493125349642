import { gql } from '@apollo/client';
import { Boolean, GenderEnumType, ObjectID, String, UserTypeEnum } from '../queries';
import { MessageType } from './forgot-password';

export type Date = string;

export interface UpdateUserV2Data {
  updateUserV2?: MessageType;
}
export interface UpdateUserV2Variables {
  avatar?: ObjectID;
  phones?: String[];
  companyName?: String;
  fullName?: String;
  isShareInfo?: Boolean;
  type?: UserTypeEnum;
  province?: ObjectID;
  dateOfBirth?: Date;
  gender?: GenderEnumType;
  idNumber?: String;
  company?: String;
}

export const UPDATE_USER_V2 = gql`
  mutation updateUserV2(
    $avatar: ObjectID
    $phones: [String]
    $companyName: String
    $fullName: String
    $isShareInfo: Boolean
    $type: UserTypeEnum
    $province: ObjectID
    $dateOfBirth: Date
    $gender: GenderEnumType
    $idNumber: String
    $company: String
  ) @api(name: users) {
    updateUserV2(
      avatar: $avatar
      phones: $phones
      companyName: $companyName
      fullName: $fullName
      isShareInfo: $isShareInfo
      type: $type
      province: $province
      dateOfBirth: $dateOfBirth
      gender: $gender
      idNumber: $idNumber
      company: $company
    ) {
      msg
      success
    }
  }
`;
