import { gql } from '@apollo/client';
import { PaginationOf } from 'graphql/main/queries';
import { Int, ObjectID, String } from '../../main/queries/get-real-estates';
import { Street } from './get-list-districts-of-province';

export interface ListStreets extends PaginationOf {
  streets?: Street[];
}

export interface GetListStreetsOfDistrictData {
  getListStreetsOfDistrict?: ListStreets;
}
export interface GetListOldStreetsOfDistrictData {
  getListOldStreetsOfDistrict?: ListStreets;
}
export interface GetListStreetsOfDistrictVariables {
  districtId: ObjectID;
  name?: String;
  limit?: Int;
  page?: Int;
}

export const GET_LIST_STREETS_OF_DISTRICT = gql`
  query getListStreetsOfDistrict($districtId: ObjectID!, $name: String, $limit: Int, $page: Int) {
    getListStreetsOfDistrict(districtId: $districtId, name: $name, limit: $limit, page: $page) {
      streets {
        id
        name
        location {
          type
          coordinates
        }
      }
    }
  }
`;

export const GET_LIST_OLD_STREETS_OF_DISTRICT = gql`
  query getListOldStreetsOfDistrict(
    $districtId: ObjectID!
    $name: String
    $limit: Int
    $page: Int
  ) {
    getListOldStreetsOfDistrict(districtId: $districtId, name: $name, limit: $limit, page: $page) {
      streets {
        id
        name
      }
    }
  }
`;
