import '@photo-sphere-viewer/compass-plugin/index.css';
import '@photo-sphere-viewer/markers-plugin/index.css';
import { setupApollo } from 'apollo/client';
import { setupAxios } from 'axios/client';
import ApolloConfigurationProvider from 'components/providers/apollo-configuration-provider';
import AuthenticationProvider, {
  useAuthenticationContext,
} from 'components/providers/authentication-provider';
import AxiosConfigurationProvider from 'components/providers/axios-configuration-provider';
import ChatProvider from 'components/providers/chat-provider';
import ECommerceCartProvider from 'components/providers/e-commerce-cart-provider';
import { SITE_MAP } from 'globalConstants';
import 'leaflet/dist/leaflet.css';
import { isEqual, split } from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/vi';
import type { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Fragment } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-range-slider-input/dist/style.css';
import 'react-rangeslider/lib/index.css';
import 'styles/blog.css';
import 'styles/chat.css';
import 'styles/globals.css';
import 'styles/map.css';
import 'styles/react-dates.css';
import 'styles/react-range-slider-input.css';
import 'styles/react-rangeslider.css';
import 'styles/swiper.css';
import 'styles/tocbot.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Modules from '../components/desktop/modules';
moment.tz.setDefault('Asia/Ho_Chi_Minh');
moment.updateLocale('vi', {
  weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
});
NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
});

const App = (props: AppProps) => {
  const router = useRouter();
  const { asPath } = router;
  const { Component, pageProps } = props;
  const { currentUser } = useAuthenticationContext();
  setupApollo();
  setupAxios();

  const render = () => {
    const splittedAsPath = split(asPath, '/');
    if (isEqual(splittedAsPath[1], SITE_MAP.E_COMMERCE.INDEX)) {
      return (
        <ECommerceCartProvider>
          <Component {...pageProps} />
        </ECommerceCartProvider>
      );
    }
    return (
      <Fragment>
        {currentUser ? (
          <ChatProvider>
            <Modules />
          </ChatProvider>
        ) : null}
        <Component {...pageProps} />
      </Fragment>
    );
  };

  return render();
};

const AppWithProviders = (props: AppProps) => (
  <AuthenticationProvider>
    <ApolloConfigurationProvider>
      <AxiosConfigurationProvider>
        <App {...props} />
      </AxiosConfigurationProvider>
    </ApolloConfigurationProvider>
  </AuthenticationProvider>
);

export default AppWithProviders;
