import { gql } from '@apollo/client';
import { Int, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export interface Property {
  rawId?: String;
  name?: String;
  floorRawId?: String;
  buildingRawId?: String;
  projectRawId?: String;
  increment_number?: Int;
}
export interface ListProperty extends PaginationOf {
  items?: Property[];
}

export interface ListPropertyByFloorData {
  listPropertyByFloor?: ListProperty;
}
export interface ListPropertyByFloorVariables {
  search?: String;
  floorId: String;
  limit?: Int;
  page?: Int;
}

export const LIST_PROPERTY_BY_FLOOR = gql`
  query listPropertyByFloor($search: String, $floorId: String!, $limit: Int, $page: Int)
  @api(name: realEstate) {
    listPropertyByFloor(search: $search, floorId: $floorId, limit: $limit, page: $page) {
      total
      items {
        rawId
        name
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
