import { gql } from '@apollo/client';
import { UpgradeRequestOutputType } from './get-detail-request-by-token';
import { ObjectID, String } from './get-real-estates';

export interface GetDetailsRequestUpgradeByUserData {
  getDetailsRequestUpgradeByUser?: UpgradeRequestOutputType;
}
export interface GetDetailsRequestUpgradeByUserVariables {
  id?: ObjectID;
  requestId?: String;
}

export const GET_DETAILS_REQUEST_UPGRADE_BY_USER = gql`
  query getDetailsRequestUpgradeByUser($id: ObjectID, $requestId: String) @api(name: users) {
    getDetailsRequestUpgradeByUser(id: $id, requestId: $requestId) {
      id
      requestId
      rank
      owner {
        phones
        memberId
        fullName
        email
      }
      paymentInfo {
        amount
        status
        paymentMethod
        body
      }
      reason
      status
    }
  }
`;
