import { SVGProps } from 'react';

const IconOrderInProcessing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.5 2.5H9.5C8.94772 2.5 8.5 2.94772 8.5 3.5V4.5C8.5 5.05228 8.94772 5.5 9.5 5.5H12.5C13.0523 5.5 13.5 5.05228 13.5 4.5V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5Z'
      fill='currentColor'
    />
    <path
      d='M17 3.5H14.5V4.5C14.5 5.03043 14.2893 5.53914 13.9142 5.91421C13.5391 6.28929 13.0304 6.5 12.5 6.5H9.5C8.96957 6.5 8.46086 6.28929 8.08579 5.91421C7.71071 5.53914 7.5 5.03043 7.5 4.5V3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H12.5C11.8388 20.6167 11.4884 19.5395 11.5033 18.4363C11.5182 17.333 11.8976 16.2657 12.5824 15.4006C13.2672 14.5355 14.2189 13.9211 15.2893 13.6533C16.3596 13.3855 17.4885 13.4793 18.5 13.92V5C18.5 4.60218 18.342 4.22064 18.0607 3.93934C17.7794 3.65804 17.3978 3.5 17 3.5ZM10.5 17.5H6.5C6.36739 17.5 6.24021 17.4473 6.14645 17.3536C6.05268 17.2598 6 17.1326 6 17C6 16.8674 6.05268 16.7402 6.14645 16.6464C6.24021 16.5527 6.36739 16.5 6.5 16.5H10.5C10.6326 16.5 10.7598 16.5527 10.8536 16.6464C10.9473 16.7402 11 16.8674 11 17C11 17.1326 10.9473 17.2598 10.8536 17.3536C10.7598 17.4473 10.6326 17.5 10.5 17.5ZM15.5 13.5H6.5C6.36739 13.5 6.24021 13.4473 6.14645 13.3536C6.05268 13.2598 6 13.1326 6 13C6 12.8674 6.05268 12.7402 6.14645 12.6464C6.24021 12.5527 6.36739 12.5 6.5 12.5H15.5C15.6326 12.5 15.7598 12.5527 15.8536 12.6464C15.9473 12.7402 16 12.8674 16 13C16 13.1326 15.9473 13.2598 15.8536 13.3536C15.7598 13.4473 15.6326 13.5 15.5 13.5ZM15.5 9.5H6.5C6.36739 9.5 6.24021 9.44732 6.14645 9.35355C6.05268 9.25979 6 9.13261 6 9C6 8.86739 6.05268 8.74021 6.14645 8.64645C6.24021 8.55268 6.36739 8.5 6.5 8.5H15.5C15.6326 8.5 15.7598 8.55268 15.8536 8.64645C15.9473 8.74021 16 8.86739 16 9C16 9.13261 15.9473 9.25979 15.8536 9.35355C15.7598 9.44732 15.6326 9.5 15.5 9.5Z'
      fill='currentColor'
    />
    <path
      d='M16.5 14.5C14.2943 14.5 12.5 16.2943 12.5 18.5C12.5 20.7057 14.2943 22.5 16.5 22.5C18.7057 22.5 20.5 20.7057 20.5 18.5C20.5 16.2943 18.7057 14.5 16.5 14.5ZM18.4023 20.569C18.3373 20.634 18.252 20.6667 18.1667 20.6667C18.0813 20.6667 17.996 20.634 17.931 20.569L16.2643 18.9023C16.2017 18.84 16.1667 18.7553 16.1667 18.6667V16.5C16.1667 16.3157 16.316 16.1667 16.5 16.1667C16.684 16.1667 16.8333 16.3157 16.8333 16.5V18.5287L18.4023 20.0977C18.5327 20.228 18.5327 20.4387 18.4023 20.569Z'
      fill='currentColor'
    />
  </svg>
);

export { IconOrderInProcessing };
