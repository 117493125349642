import { gql } from '@apollo/client';
import { Float, Int, ObjectID, PerM2Valuation } from '../queries';

export enum BuildingUtility {
  pool = 'pool',
  lift = 'lift',
}
export type PricePerM2Unit = PerM2Valuation;
export interface BuildingValuation {
  minRent?: Float;
  avgRent?: Float;
  maxRent?: Float;
  minSell?: Float;
  avgSell?: Float;
  maxSell?: Float;
  minBank?: Float;
  maxBank?: Float;
  minPriceRentPerM2VND?: PricePerM2Unit;
  avgPriceRentPerM2VND?: PricePerM2Unit;
  maxPriceRentPerM2VND?: PricePerM2Unit;
  minPriceSellPerM2VND?: PricePerM2Unit;
  avgPriceSellPerM2VND?: PricePerM2Unit;
  maxPriceSellPerM2VND?: PricePerM2Unit;
}

export interface CalculateBuildingValueData {
  calculateBuildingValue?: BuildingValuation;
}
export interface CalculateBuildingValueVariables {
  buildingId: ObjectID;
  year: Float;
  month: Float;
  area: Float;
  floorCount: Int;
  tunnelCount?: Int;
  utilities?: BuildingUtility[];
}

export const CALCULATE_BUILDING_VALUE = gql`
  mutation calculateBuildingValue(
    $buildingId: ObjectID!
    $year: Float!
    $month: Float!
    $area: Float!
    $floorCount: Int!
    $tunnelCount: Int
    $utilities: [BuildingUtility]
  ) @api(name: realEstate) {
    calculateBuildingValue(
      buildingId: $buildingId
      year: $year
      month: $month
      area: $area
      floorCount: $floorCount
      tunnelCount: $tunnelCount
      utilities: $utilities
    ) {
      minRent
      avgRent
      maxRent
      minSell
      avgSell
      maxSell
      minBank
      maxBank
      minPriceRentPerM2VND {
        usd
        vnd
      }
      avgPriceRentPerM2VND {
        usd
        vnd
      }
      maxPriceRentPerM2VND {
        usd
        vnd
      }
      minPriceSellPerM2VND {
        usd
        vnd
      }
      avgPriceSellPerM2VND {
        usd
        vnd
      }
      maxPriceSellPerM2VND {
        usd
        vnd
      }
    }
  }
`;
