import { isEqual } from 'lodash';

export type MotionDirection = 'left' | 'right' | 'up' | 'down';
export type MotionTransitionType = 'tween' | 'spring' | 'inertia';
export type MotionDelay = number;
export type MotionDuration = number;

export const motionSlideIn = (
  direction: MotionDirection,
  type: MotionTransitionType,
  delay: MotionDelay,
  duration: MotionDuration,
) => ({
  hidden: {
    x: isEqual(direction, 'left') ? '-150%' : isEqual(direction, 'right') ? '150%' : 0,
    y: isEqual(direction, 'up') ? '150%' : isEqual(direction, 'down') ? '-150%' : 0,
  },
  show: {
    x: 0,
    y: 0,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});

export const motionFadeIn = (
  direction: MotionDirection,
  type: MotionTransitionType,
  delay: MotionDelay,
  duration: MotionDuration,
) => ({
  hidden: {
    x: isEqual(direction, 'left') ? 100 : isEqual(direction, 'right') ? -100 : 0,
    y: isEqual(direction, 'up') ? 100 : isEqual(direction, 'down') ? -100 : 0,
    opacity: 0,
  },
  show: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});
