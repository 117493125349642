import { SVGProps } from 'react';

const IconBuildingTwo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M11 7.28125L6 10.7812V22.7812'
      stroke='currentColor'
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 2.78125L16 6.28125V12.7812L19.5 15.2812V22.7812H11V2.78125Z'
      stroke='currentColor'
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.5 22.7812H22.5'
      stroke='currentColor'
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconBuildingTwo };
