import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import {
  AreaOutput,
  Block,
  CompanyBiha,
  DateTime,
  Float,
  Floor,
  ID,
  Int,
  MediaUnionWithFullUrls,
  ObjectID,
  ProductInProjectBiha,
  RealEstateProjectBiha,
  String,
  UserBiha,
} from '../queries';

export enum PaymentTypeEnum {
  reservation = 'reservation',
  deposit = 'deposit',
  paymentInStages = 'paymentInStages',
  ninetyEightPercentFinalization = 'ninetyEightPercentFinalization',
  handingOverCertificate = 'handingOverCertificate',
  transferred = 'transferred',
}
export enum RefundStatus {
  waitingRefund = 'waitingRefund',
  refundComplete = 'refundComplete',
}
export enum TradingStatusEnumType {
  transferred = 'transferred',
  confirmed = 'confirmed',
  other = 'other',
}
export enum ClassifyEnumType {
  inCompany = 'inCompany',
  outCompany = 'outCompany',
}
export enum TypeFEnumType {
  collaborators = 'collaborators',
  employee = 'employee',
  f1 = 'f1',
  f2 = 'f2',
  f3 = 'f3',
  f4 = 'f4',
  f5 = 'f5',
  f6 = 'f6',
  f7 = 'f7',
  f8 = 'f8',
  f9 = 'f9',
  f10 = 'f10',
}
export interface StagesInputATM {
  paymentAmount?: Float;
  attachments?: String[];
}
export interface RefundHistory {
  id?: ID;
  attachments?: MediaUnionWithFullUrls[];
  refundTimes?: Int;
  createdAt?: DateTime;
  issuedDate?: DateTime;
  amount?: Float;
  note?: String;
  lastRefundAmount?: Int;
  currentAmount?: Float;
  remainAmount?: Float;
  creator?: UserBiha;
}
export interface Refund {
  id?: ID;
  attachments?: MediaUnionWithFullUrls[];
  createdAt?: DateTime;
  updatedAt?: DateTime;
  issuedDate?: DateTime;
  note?: String;
  currentAmount?: Float;
  creator?: UserBiha;
  editor?: UserBiha;
  deleteAt?: DateTime;
  reservationId?: String;
  reservation?: ProductReservation;
  departments?: ReservationDepartment[];
}
export interface StageOutput {
  id?: ObjectID;
  stageName?: String;
  period?: DateTime;
  paymentAmount?: Float;
  attachments?: MediaUnionWithFullUrls[];
}
export interface GroupedStagesType {
  groupPaymentType?: String;
  totalAmountCollected?: Float;
  stages?: StageOutput[];
}
export interface ReservationDepartment {
  departmentName?: String;
  classify?: ClassifyEnumType;
  typeF?: TypeFEnumType;
}
export interface ProductReservation {
  projectId?: String;
  block?: Block;
  floor?: Floor;
  project?: RealEstateProjectBiha;
  paymentType?: String;
  productArea?: AreaOutput;
  customerName?: String;
  customerPhone?: String;
  idNumber?: String;
  productId?: ObjectID;
  attachments?: ObjectID[];
  note?: String;
  refundNote?: String;
  id?: ID;
  reservationCode?: String;
  creator?: UserBiha;
  amount?: Float;
  remainRefundAmount?: Float;
  refundedAmount?: Float;
  price?: Float;
  presenterName?: String;
  presenterPhone?: String;
  reservationTime?: DateTime;
  refundHistories?: Refund[];
  createdAt?: DateTime;
  updatedAt?: DateTime;
  editor?: UserBiha;
  deletedBy?: UserBiha;
  deletedAt?: DateTime;
  companyId?: ObjectID;
  reservationStatus?: String;
  refundStatus?: RefundStatus;
  displayReservationStatus?: String;
  approvalReason?: String;
  tradingStatus?: TradingStatusEnumType;
  investorId?: ObjectID;
  stages?: StageOutput[];
  groupedStages?: GroupedStagesType[];
  totalStagePrice?: Float;
  product?: ProductInProjectBiha;
  transferedProduct?: String;
  newProduct?: ProductInProjectBiha;
  oldProduct?: ProductInProjectBiha;
  order?: Int;
  sellerName?: String;
  sellerPhone?: String;
  approvedBy?: UserBiha;
  approvedAt?: DateTime;
  departments?: ReservationDepartment[];
  oldBooking?: ObjectID;
  newBooking?: ObjectID;
  oldBookingCode?: String;
  newBookingCode?: String;
  company?: CompanyBiha;
  isShared?: Boolean;
  fInfo?: CompanyBiha[];
  editable?: Boolean;
  isInvestor?: Boolean;
  roomNumber?: String;
  successBy?: UserBiha;
  successAt?: DateTime;
  transferedBy?: UserBiha;
  transferedAt?: DateTime;
  lockedBy?: UserBiha;
  lockedAt?: DateTime;
  unlockBy?: UserBiha;
  unlockedAt?: DateTime;
  transferHistory?: ProductReservation[];
}
export interface MutationOfReservation extends MutationOf {
  payload?: ProductReservation;
}

export interface CreateBookingATMNhaData {
  createBookingATMNha?: MutationOfReservation;
}
export interface CreateBookingATMNhaVariables {
  productId: ObjectID;
  paymentType: PaymentTypeEnum;
  customerName: String;
  customerPhone: String;
  idNumber: String;
  stages?: StagesInputATM[];
}

export const CREATE_BOOKING_ATMNHA = gql`
  mutation createBookingATMNha(
    $productId: ObjectID!
    $paymentType: PaymentTypeEnum!
    $customerName: String!
    $customerPhone: String!
    $idNumber: String!
    $stages: [StagesInputATM]
  ) @api(name: biha) {
    createBookingATMNha(
      productId: $productId
      paymentType: $paymentType
      customerName: $customerName
      customerPhone: $customerPhone
      idNumber: $idNumber
      stages: $stages
    ) {
      success
      msg
    }
  }
`;
