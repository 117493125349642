import { gql } from '@apollo/client';
import { Boolean, ObjectID } from './get-real-estates';
import { ProductInProjectShared } from './list-projects-atmnha';

export interface ProductInProjectToPublishATMNhaData {
  productInProjectToPublishATMnha?: ProductInProjectShared[];
}
export interface ProductInProjectToPublishATMNhaVariables {
  isForSell?: Boolean;
  projectId?: ObjectID;
}

export const PRODUCT_IN_PROJECT_TO_PUBLISH_ATMNHA = gql`
  query productInProjectToPublishATMnha($projectId: ObjectID, $isForSell: Boolean)
  @api(name: biha) {
    productInProjectToPublishATMnha(projectId: $projectId, isForSell: $isForSell) {
      id
      name
      floors {
        name
        id
        products {
          name
          id
          status
        }
      }
      products {
        name
        id
        status
      }
    }
  }
`;
