import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { Boolean, Float, PaymentMethodEnum, Rank, String } from '../queries';

export interface UpgradeRequest {
  amount?: Float;
  exist?: Boolean;
  paymentMethod?: PaymentMethodEnum;
  paymentUrl?: String;
  rankName?: String;
  requestId?: String;
}
export interface UpgradeRequestMutation extends MutationOf {
  payload?: UpgradeRequest;
}
export interface CheckoutUpgradeRankData {
  checkoutUpgradeRank?: UpgradeRequestMutation;
}
export interface CheckoutUpgradeRankVariables {
  rank?: Rank;
  isMB?: Boolean;
}

export const CHECKOUT_UPGRADE_RANK = gql`
  mutation checkoutUpgradeRank($rank: Rank!, $isMB: Boolean) @api(name: users) {
    checkoutUpgradeRank(rank: $rank, isMB: $isMB) {
      success
      msg
      payload {
        exist
        requestId
        amount
        rankName
        paymentUrl
      }
    }
  }
`;
