import { SVGProps } from 'react';

const IconEarthMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.5 2.4578C14.553 2.16035 13.5452 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 10.2847 22.0681 8.67022 21.3071 7.25945M17.5 5.75H17.505M11.0001 21.8883L11.0002 19.6849C11.0002 19.5656 11.0429 19.4502 11.1205 19.3596L13.6063 16.4594C13.8106 16.2211 13.7473 15.8556 13.4748 15.6999L10.6185 14.0677C10.5409 14.0234 10.4766 13.9591 10.4323 13.8814L8.57046 10.6186C8.47356 10.4488 8.28657 10.3511 8.09183 10.3684L2.56418 10.8607M21.5 6C21.5 8.20914 19.5 10 17.5 12C15.5 10 13.5 8.20914 13.5 6C13.5 3.79086 15.2909 2 17.5 2C19.7091 2 21.5 3.79086 21.5 6ZM17.75 5.75C17.75 5.88807 17.6381 6 17.5 6C17.3619 6 17.25 5.88807 17.25 5.75C17.25 5.61193 17.3619 5.5 17.5 5.5C17.6381 5.5 17.75 5.61193 17.75 5.75Z'
      stroke='currentColor'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconEarthMarker };
