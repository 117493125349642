import { SVGProps } from 'react';

const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M15.9999 5.34166L14.8249 4.16666L10.1666 8.82499L5.50825 4.16666L4.33325 5.34166L8.99159 9.99999L4.33325 14.6583L5.50825 15.8333L10.1666 11.175L14.8249 15.8333L15.9999 14.6583L11.3416 9.99999L15.9999 5.34166Z'
      fill='currentColor'
    />
  </svg>
);
export { IconClose };
