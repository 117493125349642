import { notarizationClient } from 'axios/client';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { NotarizationConfigResponse } from 'restful/notarization';

const AxiosConfigurationContext = createContext<NotarizationConfigResponse>({});

export const useAxiosConfigurationContext = () => useContext(AxiosConfigurationContext);

const AxiosConfigurationProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<NotarizationConfigResponse>();

  useEffect(() => {
    (async () => {
      try {
        const notarizationConfigurationResponse =
          await notarizationClient.get<NotarizationConfigResponse>('/v1/config');
        setData(notarizationConfigurationResponse?.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <AxiosConfigurationContext.Provider value={{ ...data }}>
      {children}
    </AxiosConfigurationContext.Provider>
  );
};

export default AxiosConfigurationProvider;
