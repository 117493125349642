import { gql } from '@apollo/client';
import { AttachmentType, ObjectID, String } from '../queries';
import { MutationOfConversation } from './create-conversation';

export type URL = string;
export enum RecipientType {
  conversation = 'conversation',
  users = 'users',
}
export enum SenderAction {
  typingOn = 'typingOn',
  typingOff = 'typingOff',
  markSeen = 'markSeen',
}
export interface AttachmentInput {
  attachmentId?: ObjectID;
  type?: AttachmentType;
  url?: URL;
}
export interface MessageInput {
  attachments?: AttachmentInput[];
  text?: String;
}
export interface RecipientInput {
  conversationId?: ObjectID;
  recipientType: RecipientType;
  userIds?: ObjectID[];
}

export interface SendMessageData {
  sendMessage?: MutationOfConversation;
}
export interface SendMessageVariables {
  recipient: RecipientInput;
  message?: MessageInput;
  senderAction?: SenderAction;
  name?: String;
}

export const SEND_MESSAGE = gql`
  mutation sendMessage(
    $recipient: RecipientInput!
    $message: MessageInput
    $senderAction: SenderAction
    $name: String
  ) @api(name: chat) {
    sendMessage(
      recipient: $recipient
      message: $message
      senderAction: $senderAction
      name: $name
    ) {
      success
      msg
      payload {
        name
        lastMessage {
          id
          text
          from
          to
        }
      }
    }
  }
`;
