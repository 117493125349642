import { SVGProps } from 'react';

const IconNewspaperMultiple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='currentColor'
    {...props}
  >
    <path
      d='M3.66683 6.41667V17.4167H17.4168V19.25H3.66683C1.8335 19.25 1.8335 17.4167 1.8335 17.4167V6.41667H3.66683ZM19.5252 2.75H7.0585C6.19683 2.75 5.50016 3.39167 5.50016 4.17083V14.1625C5.50016 14.9508 6.19683 15.5833 7.0585 15.5833H19.5252C20.3868 15.5833 21.0835 14.9508 21.0835 14.1625V4.17083C21.0835 3.39167 20.3868 2.75 19.5252 2.75ZM7.3335 4.58333H11.9168V10.0833H7.3335V4.58333ZM19.2502 13.75H7.3335V11.9167H19.2502V13.75ZM19.2502 10.0833H13.7502V8.25H19.2502V10.0833ZM19.2502 6.41667H13.7502V4.58333H19.2502V6.41667Z'
      fill='currentColor'
    />
  </svg>
);
export { IconNewspaperMultiple };
