import { SVGProps } from 'react';

const IconPhotoGallery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='currentColor'
    {...props}
  >
    <path
      d='M15.6116 3.21912L5.00303 2.01451C4.59131 1.95598 4.17415 2.07617 3.85671 2.34483C3.53929 2.59095 3.33583 2.95578 3.29325 3.35515L3.09897 4.94839H2.49662C1.64171 4.94839 1.00053 5.70612 1.00053 6.56103V14.5077C0.978992 15.3229 1.62241 16.0013 2.43767 16.0228C2.45731 16.0234 2.47698 16.0235 2.49662 16.0232H13.1634C14.0183 16.0232 14.7955 15.3626 14.7955 14.5077V14.1968C15.0606 14.1456 15.312 14.0397 15.5338 13.886C15.8486 13.6209 16.0502 13.2456 16.0973 12.8368L16.9911 4.94839C17.0822 4.09151 16.4672 3.32068 15.6116 3.21912ZM14.0183 14.5077C14.0183 14.9352 13.5909 15.246 13.1634 15.246H2.49662C2.11048 15.2574 1.78827 14.9535 1.77693 14.5674C1.77633 14.5475 1.77659 14.5276 1.77771 14.5077V13.0699L4.7893 10.855C5.15108 10.5772 5.66065 10.6019 5.99394 10.9132L8.11176 12.7785C8.43338 13.0485 8.83822 13.1995 9.25813 13.2059C9.58643 13.2099 9.90935 13.1225 10.1908 12.9533L14.0184 10.7384V14.5077H14.0183ZM14.0183 9.82518L9.78268 12.2927C9.41897 12.5082 8.9586 12.4692 8.63632 12.1956L6.49908 10.3109C5.88654 9.78457 4.99124 9.75229 4.3424 10.2332L1.77771 12.0984V6.56103C1.77771 6.13357 2.06916 5.72557 2.49662 5.72557H13.1634C13.6201 5.7445 13.9889 6.10494 14.0183 6.56103V9.82518ZM16.2146 4.84347C16.2144 4.84604 16.2141 4.84864 16.2138 4.85121L15.3007 12.7396C15.3022 12.9441 15.2089 13.1379 15.0481 13.2642C14.9703 13.3419 14.7955 13.3808 14.7955 13.4197V6.56103C14.7648 5.67588 14.0488 4.96843 13.1634 4.94839H3.87612L4.05098 3.43288C4.08892 3.23663 4.19152 3.05879 4.34244 2.9277C4.51284 2.80988 4.71887 2.75496 4.92534 2.77225L15.5145 3.9963C15.9417 4.03687 16.2552 4.41617 16.2146 4.84347Z'
      fill='currentColor'
    />
  </svg>
);
export { IconPhotoGallery };
