import { IconAccountGroup, IconClockFive, IconEdit, IconSearch } from 'components/icons';

const ConversationToolbar = () => (
  <div
    id='conversation-toolbar'
    className='flex min-h-[56px] items-center space-x-[16px] border-b border-stroke bg-paper p-[12px]'
  >
    <div className='flex h-[32px] flex-1 items-center space-x-[8px] overflow-hidden rounded-[6px] border border-stroke'>
      <input
        placeholder='Tìm kiếm'
        className='group w-full bg-transparent p-[8px] text-[12px] leading-[15px] placeholder-text2'
      />
      <div className='border-l border-stroke px-[8px]'>
        <IconSearch className='min-h-[24px] min-w-[24px] text-text2' />
      </div>
    </div>
    <button
      type='button'
      className='flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#22c55e]'
    >
      <IconEdit className='min-h-[12px] min-w-[12px] text-paper' />
    </button>
    <button
      type='button'
      className='flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#0ea5e9]'
    >
      <IconClockFive className='min-h-[12px] min-w-[12px] text-paper' />
    </button>
    <button
      type='button'
      className='flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#10b981]'
    >
      <IconAccountGroup className='min-h-[12px] min-w-[12px] text-paper' />
    </button>
  </div>
);

export default ConversationToolbar;
