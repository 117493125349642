import { gql } from '@apollo/client';
import {
  Boolean,
  DateTime,
  FileWithFullUrls,
  Float,
  Int,
  ObjectID,
  String,
  YoutubeFile,
} from './get-real-estates';

export enum CompanyEnum {
  estate = 'estate',
  construction = 'construction',
  law = 'law',
  supplier = 'supplier',
  cj = 'cj',
  car = 'car',
  bank = 'bank',
  notarization = 'notarization',
  register = 'register',
}
export interface LocationOutputType {
  coordinates?: Float[];
  type?: String;
}
export interface AddressType {
  id?: ObjectID;
  name?: String;
}
export type AnotherServiceFileType = YoutubeFile | FileWithFullUrls;
export interface Branch {
  address?: String;
  company?: CompanyUsers;
  description?: String;
  email?: String;
  id?: String;
  interestRate?: String;
  location?: LocationOutputType;
  name?: String;
  phone?: String[];
  saveInterestRate?: String;
  type?: String;
  website?: String;
  workTimes?: String;
}
export interface CompanyUsers {
  address?: String;
  branches?: Branch[];
  companyCode?: String;
  companyFields?: String[];
  createdAt?: DateTime;
  creator?: String;
  description?: String;
  district?: AddressType;
  email?: String;
  establishedDate?: DateTime;
  facebook?: String;
  hotline?: String;
  houseNumber?: String;
  id?: String;
  idNumber?: String;
  isIncognito?: Boolean;
  isPersonal?: Boolean;
  isSaved?: Boolean;
  isVerifiedCompany?: Boolean;
  logo?: AnotherServiceFileType;
  name?: String;
  phone?: String[];
  province?: AddressType;
  service?: String;
  shortName?: String;
  skype?: String;
  speciality?: String[];
  street?: AddressType;
  taxId?: String;
  type?: String;
  updatedAt?: DateTime;
  viber?: String;
  ward?: AddressType;
  website?: String;
  zalo?: String;
}
export interface ListCompany {
  companies?: CompanyUsers[];
  hasNextPage?: Boolean;
  hasPrevPage?: Boolean;
  limit?: Int;
  nextPage?: Int;
  page?: Int;
  pagingCounter?: Int;
  prevPage?: Int;
  total?: Int;
  totalPages?: Int;
}

export interface ListCompaniesByTypeData {
  listCompaniesByType?: ListCompany;
}
export interface ListCompaniesByTypeVariables {
  searchString?: String;
  province?: String;
  district?: String;
  type: CompanyEnum;
  companyFields?: String[];
  isPromoted?: Boolean;
  sort?: String;
  limit?: Int;
  page?: Int;
  service?: String;
  bankCode?: String;
}

export const LIST_COMPANIES_BY_TYPE = gql`
  query listCompaniesByType(
    $searchString: String
    $province: String
    $district: String
    $type: CompanyEnum!
    $companyFields: [String]
    $isPromoted: Boolean
    $sort: String
    $limit: Int
    $page: Int
    $service: String
    $bankCode: String
  ) @api(name: users) {
    listCompaniesByType(
      searchString: $searchString
      province: $province
      district: $district
      type: $type
      companyFields: $companyFields
      isPromoted: $isPromoted
      sort: $sort
      limit: $limit
      page: $page
      service: $service
      bankCode: $bankCode
    ) {
      companies {
        id
        name
        logo {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
