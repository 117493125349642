import axios from 'axios';
import {
  AtmnhaAuthenticationModel,
  ECommerceAuthenticationModel,
} from 'components/providers/authentication-provider';
import { getCookie } from 'cookies-next';
import { E_COMMERCE_API_ENDPOINT, NOTARIZATION_API_ENDPOINT } from 'globalConstants';
import { toString } from 'lodash';

export const notarizationClient = axios.create({
  baseURL: `${NOTARIZATION_API_ENDPOINT}`,
});

export const eCommerceClient = axios.create({
  baseURL: `${E_COMMERCE_API_ENDPOINT}`,
});

export const setupAxios = () => {
  const atmnhaAuthenticationString = toString(getCookie('atmnha_authentication_cookie'));
  if (atmnhaAuthenticationString) {
    const atmnhaAuthentication = JSON.parse(
      atmnhaAuthenticationString,
    ) as AtmnhaAuthenticationModel;
    const { accessToken } = atmnhaAuthentication;
    notarizationClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
  const eCommerceAuthenticationString = toString(getCookie('e_commerce_authentication_cookie'));
  if (eCommerceAuthenticationString) {
    const eCommerceAuthentication = JSON.parse(
      eCommerceAuthenticationString,
    ) as ECommerceAuthenticationModel;
    const { token } = eCommerceAuthentication;
    eCommerceClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};
