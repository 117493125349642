import { gql } from '@apollo/client';
import { KeyValueType } from './get-internal-real-estate-configuration';
import { ID, String } from './get-real-estates';

export interface TransactionTypeEwallet {
  id?: String;
  content?: String;
}
export interface Bank {
  id: ID;
  bankCode: String;
  bankName: String;
  logo?: String;
  bankAccount?: String;
  bankOwnerName?: String;
}

export interface EwalletConfiguration {
  banks?: Bank[];
  transactionTypes?: TransactionTypeEwallet[];
  zmsBankInfo?: Bank;
  bankCash?: KeyValueType[];
  paymentStatusType?: KeyValueType[];
  depositMethodType?: KeyValueType[];
  paymentMethodType?: KeyValueType[];
  withdrawalMethodType?: KeyValueType[];
  sourceTransactionType?: KeyValueType[];
  typeOfTransactionType?: KeyValueType[];
}
export interface GetEwalletConfigurationsData {
  getEwalletConfigurations?: EwalletConfiguration;
}

export const GET_EWALLET_CONFIGURATIONS = gql`
  query getEwalletConfigurations($isUsedBank: Boolean) @api(name: ewallet) {
    getEwalletConfigurations(isUsedBank: $isUsedBank) {
      depositMethodType {
        key
        value
      }
      paymentMethodType {
        key
        value
      }
      paymentStatusType {
        key
        value
      }
      sourceTransactionType {
        key
        value
      }
      transactionTypes {
        content
        id
      }
      typeOfTransactionType {
        key
        value
      }
      withdrawalMethodType {
        key
        value
      }
    }
  }
`;
