import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { Boolean, DateTime, Float, ID, ObjectID, String } from '../queries';

export enum DepositMethodEnum {
  CashInVNPay = 'CashInVNPay',
  CashInTransfer = 'CashInTransfer',
  CashInMomo = 'CashInMomo',
  CashInDirect = 'CashInDirect',
  CashInOffice = 'CashInOffice',
  CashInCommission = 'CashInCommission',
}
export interface TransferInfoInput {
  bankOwnerName?: String;
  phone?: String;
  email?: String;
  images?: String[];
  transactionNote?: String;
  province?: ObjectID;
  district?: ObjectID;
  ward?: ObjectID;
  street?: ObjectID;
  houseNumber?: String;
  recharger?: String;
  rechargerPhone?: String;
}
export interface DepositTransactionInputType {
  paymentMethod: DepositMethodEnum;
  amount: Float;
  content?: String;
  bankTransfer?: String;
  reasonOfFailure?: String;
  transactionInfo?: TransferInfoInput;
  redirectUrl?: String;
  isMB?: Boolean;
}
export interface CreateDepositTransactionMutationPayload extends MutationOf {
  payload?: TransactionForUser;
}
export interface TransactionForUser {
  id: ID;
  ownerId?: ObjectID;
  type?: String;
  status?: String;
  amount?: Float;
  basicAmount?: Float;
  vatAmount?: Float;
  paymentMethod?: String;
  content?: String;
  orderId?: String;
  reasonOfFailure?: String;
  paymentPayload?: TransactionPayload;
  commission?: CommissionData;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  rank?: RankEnum;
  bankCode?: String;
  cardType?: String;
  invoiceDate?: DateTime;
  reasonReject?: String;
}
export enum RankEnum {
  gold = 'gold',
  diamond = 'diamond',
}
export interface TransactionPayload {
  paymentUrl?: String;
  transactionId?: String;
}
export interface CommissionData {
  commission?: Float;
  grandCommission?: Float;
  developer?: String;
  grand?: String;
  isRead?: Boolean;
  balance?: Float;
  totalDeposit?: Float;
}

export interface CreateDepositTransactionVariables {
  inputData: DepositTransactionInputType;
}
export interface CreateDepositTransactionData {
  createDepositTransaction: CreateDepositTransactionMutationPayload;
}

export const CREATE_DEPOSIT_TRANSACTION = gql`
  mutation createDepositTransaction($inputData: DepositTransactionInputType!) @api(name: ewallet) {
    createDepositTransaction(inputData: $inputData) {
      success
      msg
      payload {
        id
        orderId
        paymentPayload {
          paymentUrl
        }
      }
    }
  }
`;
