import { SVGProps } from 'react';

const IconFloorPlan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M8.33333 4.16667V8.33333H7.5V4.16667H4.16667V10.8333H7.5V10H8.33333V14.1667H7.5V11.6667H4.16667V15.8333H10V14.1667H10.8333V15.8333H15.8333V14.1667H17.5V17.5H2.5V2.5H17.5V12.5H15.8333V8.33333H10.8333V12.5H10V7.5H15.8333V4.16667H8.33333Z'
      fill='currentColor'
    />
  </svg>
);
export { IconFloorPlan };
