import { SVGProps } from 'react';

const IconLeaf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12.8334 19C6.13337 19 4.83337 16.4 4.83337 12C4.83337 5.49999 11.9334 0.399988 12.2334 0.199988C12.5334 -1.22041e-05 13.0334 -1.22041e-05 13.4334 0.199988C13.7334 0.399988 20.8334 5.49999 20.8334 12C20.8334 13.7 20.8334 19 12.8334 19ZM12.8334 2.29999C11.2334 3.49999 6.83337 7.49999 6.83337 12C6.83337 15.6 7.33337 17 12.8334 17C18.3334 17 18.8334 14.4 18.8334 12C18.8334 7.49999 14.4334 3.49999 12.8334 2.29999Z'
      fill='currentColor'
    />
    <path
      d='M14.8333 24C14.5333 24 14.2333 23.8 14.0333 23.6C10.4333 18.1 11.4333 10.6 11.7333 7.8C11.8333 7.4 11.8333 7.1 11.8333 7C11.8333 6.5 12.3333 6 12.8333 6C13.3333 6 13.8333 6.4 13.8333 7C13.8333 7.2 13.8333 7.5 13.7333 8C13.3333 10.6 12.4333 17.6 15.6333 22.4C15.9333 22.9 15.8333 23.5 15.3333 23.8C15.2333 23.9 15.0333 24 14.8333 24Z'
      fill='currentColor'
    />
  </svg>
);
export { IconLeaf };
