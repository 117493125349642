import { gql } from '@apollo/client';
import {
  DateTime,
  FileWithFullUrls,
  Float,
  ID,
  Int,
  ObjectID,
  SavedItem,
  String,
} from '../../main/queries/get-real-estates';
import {
  CompanyBiha,
  ProductInProjectShared,
  RangeValueType,
  RealEstateProjectBiha,
  SharingProjectStatus,
  UserBiha,
  salesType,
} from './list-projects-atmnha';

export interface DistributedProject {
  id?: ID;
  commission?: String;
  attachments?: FileWithFullUrls;
  distributor?: CompanyBiha;
  distributorId?: ObjectID;
  allowToShare?: Boolean;
  creator?: UserBiha;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  deletedAt?: DateTime;
  salePercent?: salesType;
  products?: ProductInProjectShared[];
  stopSharingAt?: DateTime;
  project_refs?: RealEstateProjectBiha;
  activeAt?: DateTime;
  lockedAt?: DateTime;
  draftAt?: DateTime;
  generation?: Int;
  projectId?: String;
  content?: String;
  ownerCompany?: String;
  projectSharedCode?: String;
  originalCompany?: String;
  status?: SharingProjectStatus;
  savedItem?: SavedItem;
  distributedCompany?: String;
  dummyProducts?: String[];
  permissions?: JSON;
  price?: RangeValueType;
  initialAmount?: Float;
  recipientApprovalStatus?: String;
  approvalStatus?: String;
  consignStatus?: String;
  representative?: String;
  hotline?: String[];
  expectedPrice?: RangeValueType;
  email?: String;
  taxId?: String;
  project?: RealEstateProjectBiha;
  isOwner?: Boolean;
  approvedBy?: UserBiha;
  approvedAt?: DateTime;
  editor?: UserBiha;
  expectedCompletionYear?: Int;
  supporterATMNha?: UserBiha[];
  totalBlock?: Int;
  totalFloor?: Int;
  totalProduct?: Int;
}

export interface GetDetailProjectsATMNhaData {
  getDetailProjectsATMNha?: DistributedProject;
}
export interface GetDetailProjectsATMNhaVariables {
  id: String;
}

export const GET_DETAIL_PROJECTS_ATMNHA = gql`
  query getDetailProjectsATMNha($id: ObjectID!) @api(name: biha) {
    getDetailProjectsATMNha(id: $id) {
      id
      commission
      expectedPrice {
        from
        to
      }
      savedItem {
        id
        belongsToCollection {
          id
          name
        }
      }
      supporterATMNha {
        id
        fullName
        employeeCode
        phones
        avatar {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
      }
      project {
        id
        projectName
        fullAddress
        projectStatus
        projectType
        blocks {
          id
          name
          floors {
            id
            name
            products {
              id
              isForSell
              roomNumber
              status
            }
          }
        }
        totalBlock
        apartmentCount
        projectArea {
          area
        }
        investorName
        mediaUrls {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        panoramicImages {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        expectedPrice {
          from
          to
        }
        country {
          name
        }
        map {
          coordinates
        }
        projectArea {
          area
        }
        constructionProjectArea {
          area
        }
        projectScale
        complexProjectType
        totalBlock
        totalBasements
        floorCountPerBlockRange {
          from
          to
        }
        localProjectUtilities
        projectUtilities
        ownershipTime
        startYear
        expectedCompletionYear
        investorName
        constructionGeneralContractor
        projectOverview
        investment {
          from
          to
        }
        standardStar
        typeAreas {
          area
        }
        totalZone
        totalBackground
        floorCanBuild
        directions
        constructionForm
        totalWarehouse
        totalWorkshops
        floorRange {
          from
          to
        }
        apartmentCount
        independentVillaCount
        townhouseCount
        doubleVillaCount
        shopHouseCount
        condotelCount
        offictelCount
        penthouseCount
        commercialAreaCount
        apartment {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        townhouse {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        independentVilla {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        doubleVilla {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        condotel {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        penthouse {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        shophouse {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        offictel {
          title
          width
          length
          area
          price
          bedroomCount {
            from
            to
          }
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          bedroomArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        commercialArea {
          title
          width
          length
          area
          price
          toiletCount {
            from
            to
          }
          internalRoadArea {
            from
            to
          }
          toiletArea {
            from
            to
          }
          floorCount {
            from
            to
          }
          kioskCount {
            from
            to
          }
          views
          imageAndVideos {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          priceByStages {
            name
            priceByStage {
              from
              to
            }
          }
        }
        legalLicense
        legals
        projectLocation
        projectLocationImages {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        projectUtilities
        projectUtilityImages {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        fixedUtilities
        utilities
        localUtilities
        projectViews
        equipmentsAndServices
        planAndModelApartment
        planAndModelApartmentImages {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        paymentRoute
        paymentRouteImages {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        vr360Urls
      }
      totalBlock
      totalFloor
      totalProduct
    }
  }
`;
