import { SVGProps } from 'react';

const IconArrowCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M19.5001 3.09009L20.9101 4.50009L16.4101 9.00009H20.0001V11.0001H13.0001V4.00009H15.0001V7.59009L19.5001 3.09009ZM20.9101 19.5001L19.5001 20.9101L15.0001 16.4101V20.0001H13.0001V13.0001H20.0001V15.0001H16.4101L20.9101 19.5001ZM4.50009 3.09009L9.00009 7.59009V4.00009H11.0001V11.0001H4.00009V9.00009H7.59009L3.09009 4.50009L4.50009 3.09009ZM3.09009 19.5001L7.59009 15.0001H4.00009V13.0001H11.0001V20.0001H9.00009V16.4101L4.50009 20.9101L3.09009 19.5001Z'
      fill='currentColor'
    />
  </svg>
);
export { IconArrowCollapse };
