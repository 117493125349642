import { SVGProps } from 'react';

const IconUserCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M18.482 18.725C17.7833 17.7999 16.8793 17.0496 15.8412 16.5334C14.8031 16.0171 13.6594 15.749 12.5 15.75C11.3407 15.749 10.1969 16.0171 9.15883 16.5334C8.12077 17.0496 7.21675 17.7999 6.51801 18.725M18.481 18.725C19.8445 17.5122 20.8071 15.9136 21.2412 14.1411C21.6753 12.3686 21.5603 10.5061 20.9115 8.80049C20.2627 7.09488 19.1107 5.62679 17.6084 4.59091C16.1061 3.55503 14.3244 3.00031 12.4995 3.00031C10.6747 3.00031 8.89295 3.55503 7.39062 4.59091C5.88829 5.62679 4.73634 7.09488 4.08755 8.80049C3.43875 10.5061 3.32376 12.3686 3.75783 14.1411C4.1919 15.9136 5.15451 17.5122 6.51801 18.725M18.481 18.725C16.835 20.1932 14.7056 21.0032 12.5 21C10.294 21.0034 8.16425 20.1934 6.51801 18.725M15.5 9.75001C15.5 10.5457 15.1839 11.3087 14.6213 11.8713C14.0587 12.4339 13.2957 12.75 12.5 12.75C11.7044 12.75 10.9413 12.4339 10.3787 11.8713C9.81608 11.3087 9.50001 10.5457 9.50001 9.75001C9.50001 8.95436 9.81608 8.1913 10.3787 7.62869C10.9413 7.06608 11.7044 6.75001 12.5 6.75001C13.2957 6.75001 14.0587 7.06608 14.6213 7.62869C15.1839 8.1913 15.5 8.95436 15.5 9.75001V9.75001Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconUserCircle };
