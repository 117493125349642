import { gql } from '@apollo/client';
import { Boolean, DateTime, Float, Int, String } from './get-real-estates';
import {
  B2CUser,
  CompanyRequestOutputType,
  PremiumRequestType,
  RefUserType,
  UserConfigType,
} from './get-representative-brokers';
import { PaginationOf } from './get-user-saved-real-estate';
import { AddressType, AnotherServiceFileType, CompanyUsers } from './list-companies-by-type';

export interface UserUsers {
  id?: String;
  type?: String;
  userType?: String;
  firstName?: String;
  lastName?: String;
  memberId?: String;
  isPremiumAccount?: Boolean;
  seenPremiumNoti?: Boolean;
  isVerifiedPhone?: Boolean;
  isVerifiedEmail?: Boolean;
  deviceToken?: String;
  avatar?: AnotherServiceFileType;
  gender?: String;
  phones?: String[];
  phone?: String;
  secondPhone?: String;
  province?: AddressType;
  company?: String;
  signupType?: String;
  bankAccountNumber?: String;
  description?: String;
  companyName?: String;
  companyFields?: String[];
  taxId?: String;
  bank?: String;
  isShareInfo?: Boolean;
  phoneChanged?: Boolean;
  secondPhoneChanged?: Boolean;
  shareOwnedPosts?: Boolean;
  refMembers?: B2CUser;
  developMember?: RefUserType;
  developer?: String;
  upgradePremiumDate?: DateTime;
  upgradePremiumRequest?: PremiumRequestType;
  establishedDate?: DateTime;
  isCompanyApproved?: Boolean;
  companyRegisterRequest?: CompanyRequestOutputType;
  role?: String;
  idNumber?: String;
  dateOfBirth?: DateTime;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  isLocked?: Boolean;
  fullName?: String;
  email?: String;
  frequentREActivities?: String[];
  frequentREProvinces?: String[];
  zalo?: String;
  facebook?: String;
  skype?: String;
  viber?: String;
  madeCommissions?: Float;
  lastOnline?: DateTime;
  frontIdImages?: AnotherServiceFileType[];
  backIdImages?: AnotherServiceFileType[];
  attachments?: AnotherServiceFileType[];
  developerRef?: B2CUser;
  companyRef?: CompanyUsers;
  statusProfile?: String;
  rejectedReason?: String;
  sendProfileAt?: DateTime;
  statusProfileCompany?: String;
  rejectedReasonCompany?: String;
  companyLicense?: AnotherServiceFileType[];
  mediaOfCompany?: AnotherServiceFileType[];
  configs?: UserConfigType;
  step?: Int;
  totalStep?: Int;
  stepTitle?: String;
  refB2CUser?: B2CUser;
  numberOfRemaining?: Int;
}
export interface ListBrokerType extends PaginationOf {
  users?: UserUsers[];
}

export interface GetListBrokerVariables {
  search?: String;
  province?: String;
  district?: String;
  sort?: String;
  demandType?: String;
  realEstateType?: String;
  limit?: Int;
  page?: Int;
}
export interface GetListBrokerData {
  getListBroker?: ListBrokerType;
}

export const GET_LIST_BROKER = gql`
  query getListBroker(
    $search: String
    $province: String
    $district: String
    $sort: String
    $demandType: String
    $realEstateType: String
    $limit: Int
    $page: Int
  ) @api(name: users) {
    getListBroker(
      search: $search
      province: $province
      district: $district
      sort: $sort
      demandType: $demandType
      realEstateType: $realEstateType
      limit: $limit
      page: $page
    ) {
      users {
        id
        fullName
        phones
        memberId
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
        type
        province {
          id
          name
        }
      }
      total
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
