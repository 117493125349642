import { SVGProps } from 'react';

const IconStairs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_1320_221746)'>
      <path
        d='M15.8554 9.94641H13.1841C12.7957 9.94641 12.481 10.2612 12.481 10.6495C12.481 11.0379 12.7957 11.3527 13.1841 11.3527H14.1584L10.7855 14.7255C10.5109 15.0001 10.5109 15.4453 10.7855 15.7198C11.0601 15.9944 11.5053 15.9945 11.7799 15.7199L15.1528 12.347V13.3214C15.1528 13.7097 15.4676 14.0245 15.8559 14.0245C16.2443 14.0245 16.5591 13.7097 16.5591 13.3214V10.6495C16.5591 10.6494 16.5591 10.6493 16.5591 10.6492C16.5589 10.2671 16.2484 9.94613 15.8554 9.94641Z'
        fill='currentColor'
      />
      <path
        d='M17.2974 2.07422H13.1489C12.7606 2.07422 12.4458 2.38901 12.4458 2.77734V6.22266H9.00051C8.61221 6.22266 8.29738 6.53745 8.29738 6.92578V10.3711H4.85208C4.46374 10.3711 4.14895 10.6859 4.14895 11.0742V14.5195H0.703612C0.315277 14.5195 0.000488281 14.8343 0.000488281 15.2227C0.000488281 15.611 0.315277 15.9258 0.703612 15.9258H4.85208C5.03855 15.9258 5.21742 15.8517 5.34926 15.7198C5.48113 15.588 5.5552 15.4091 5.5552 15.2227L5.55517 11.7773H9.00051C9.18698 11.7773 9.36585 11.7033 9.49769 11.5714C9.62956 11.4396 9.70363 11.2607 9.70363 11.0742L9.7036 7.62891H13.1489C13.3354 7.62891 13.5142 7.55483 13.6461 7.42296C13.778 7.29113 13.852 7.11225 13.852 6.92578L13.852 3.48047H17.2973C17.6857 3.48047 18.0005 3.16568 18.0005 2.77734C18.0005 2.38901 17.6857 2.07422 17.2974 2.07422Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1320_221746'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconStairs };
