import { gql } from '@apollo/client';
import { String, YoutubeFile } from 'graphql/main/queries';
import { MutationOf } from 'graphql/upload/mutations';

export interface MutationOfYoutubeType extends MutationOf {
  payload?: YoutubeFile;
}

export interface AddYoutubeVideoVariables {
  youtubeUrl: String;
}
export interface AddYoutubeVideoData {
  addYoutubeVideo?: MutationOfYoutubeType;
}

export const ADD_YOUTUBE_VIDEO = gql`
  mutation addYoutubeVideo($youtubeUrl: String!) @api(name: media) {
    addYoutubeVideo(youtubeUrl: $youtubeUrl) {
      success
      msg
      payload {
        fileId
        type
        youtubeMetadata {
          videoId
          title
          description
          thumbnailUrl
        }
      }
    }
  }
`;
