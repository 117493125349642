import { BuildingUtility } from 'graphql/main/mutations';
import { gt, toNumber } from 'lodash';
import moment from 'moment-timezone';
import * as yup from 'yup';

export interface OfficeBuildingValuationForm {
  nation?: 'vietnam';
  province?: string;
  building?: string;
  width?: string;
  length?: string;
  area?: number;
  floorAreaForConstruction?: number;
  floorCount?: string;
  tunnelCount?: string;
  utilities?: BuildingUtility[];
  valuationDate?: string;
}

export const officeBuildingValuationFormDefaultValues: OfficeBuildingValuationForm = {
  nation: 'vietnam',
  province: undefined,
  building: undefined,
  width: undefined,
  length: undefined,
  area: 0,
  floorCount: '0',
  tunnelCount: '0',
  floorAreaForConstruction: 0,
  utilities: undefined,
  valuationDate: moment('2022-12').format('YYYY-MM'),
};

export const officeBuildingValuationFormSchema = yup.object().shape({
  nation: yup.string().required('Vui lòng chọn quốc gia'),
  province: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  building: yup.string().required('Vui lòng chọn tên tòa nhà'),
  width: yup
    .string()
    .required('Vui lòng nhập chiều ngang')
    .max(8, 'Chiểu ngang không quá 8 chữ số')
    .test('Positive', 'Vui lòng nhập số lớn hơn 0', (value) => gt(toNumber(value), 0)),
  length: yup
    .string()
    .required('Vui lòng nhập chiều dài')
    .max(9, 'Chiểu dài không quá 9 chữ số')
    .test('Positive', 'Vui lòng nhập số lớn hơn 0', (value) => gt(toNumber(value), 0)),
  area: yup.number().positive(),
  floorAreaForConstruction: yup.number(),
  floorCount: yup
    .number()
    .typeError('Vui lòng chọn hoặc nhập số tầng')
    .min(0, 'Vui lòng nhập số lớn hơn 0')
    .max(168, 'Giá trị không vượt quá 168'),
  tunnelCount: yup
    .number()
    .typeError('Vui lòng chọn hoặc nhập số tầng hầm')
    .min(0, 'Vui lòng nhập số lớn hơn 0')
    .max(6, 'Giá trị không vượt quá 6'),
  utilities: yup.array().nullable(),
  valuationDate: yup.string(),
});
