import { SVGProps } from 'react';

const IconHouseHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M13.5604 2.74997C13.2791 2.46876 12.8977 2.31079 12.4999 2.31079C12.1022 2.31079 11.7207 2.46876 11.4394 2.74997L1.46893 12.719C1.3283 12.8598 1.24937 13.0507 1.24951 13.2498C1.24965 13.4488 1.32885 13.6396 1.46968 13.7802C1.61051 13.9209 1.80144 13.9998 2.00046 13.9996C2.19948 13.9995 2.3903 13.9203 2.53093 13.7795L3.49993 12.8105V20.75C3.49993 21.3467 3.73698 21.919 4.15894 22.341C4.5809 22.7629 5.15319 23 5.74993 23H19.2499C19.8467 23 20.419 22.7629 20.8409 22.341C21.2629 21.919 21.4999 21.3467 21.4999 20.75V12.8105L22.4689 13.7795C22.6096 13.9203 22.8004 13.9995 22.9994 13.9996C23.1984 13.9998 23.3894 13.9209 23.5302 13.7802C23.671 13.6396 23.7502 13.4488 23.7503 13.2498C23.7505 13.0507 23.6716 12.8598 23.5309 12.719L19.9999 9.18947V4.24997C19.9999 4.05106 19.9209 3.86029 19.7803 3.71964C19.6396 3.57899 19.4488 3.49997 19.2499 3.49997H17.7499C17.551 3.49997 17.3603 3.57899 17.2196 3.71964C17.0789 3.86029 16.9999 4.05106 16.9999 4.24997V6.18947L13.5604 2.74997ZM19.9999 11.3105V20.75C19.9999 20.9489 19.9209 21.1396 19.7803 21.2803C19.6396 21.421 19.4488 21.5 19.2499 21.5H5.74993C5.55102 21.5 5.36025 21.421 5.2196 21.2803C5.07895 21.1396 4.99993 20.9489 4.99993 20.75V11.3105L12.4999 3.81047L19.9999 11.3105Z'
      fill='currentColor'
      stroke='white'
      strokeWidth='0.1'
    />
    <path
      d='M12.5 10.6273C9.84238 10.6273 7.6875 12.7822 7.6875 15.4398C7.6875 18.0974 9.84238 20.2523 12.5 20.2523C15.1576 20.2523 17.3125 18.0974 17.3125 15.4398C17.3125 12.7822 15.1576 10.6273 12.5 10.6273ZM12.7396 17.773L12.7417 18.1136C12.7417 18.1608 12.703 18.2006 12.6558 18.2006H12.3507C12.3034 18.2006 12.2647 18.1619 12.2647 18.1146V17.7773C11.3108 17.7064 10.8618 17.1629 10.8135 16.572C10.8092 16.5216 10.8489 16.4786 10.8994 16.4786H11.3957C11.4376 16.4786 11.4741 16.5087 11.4806 16.5495C11.5354 16.89 11.8007 17.1446 12.2766 17.208V15.6751L12.0112 15.6074C11.4494 15.4731 10.9145 15.1229 10.9145 14.3968C10.9145 13.6136 11.5096 13.1926 12.2701 13.1184V12.7639C12.2701 12.7167 12.3088 12.678 12.3561 12.678H12.6579C12.7052 12.678 12.7438 12.7167 12.7438 12.7639V13.1152C13.4797 13.1893 14.0318 13.619 14.0963 14.2883C14.1017 14.3387 14.0619 14.3828 14.0104 14.3828H13.528C13.4851 14.3828 13.4485 14.3506 13.4432 14.3087C13.4002 13.995 13.1488 13.7393 12.7396 13.6835V15.1261L13.0124 15.1895C13.7085 15.3614 14.1822 15.6944 14.1822 16.4399C14.1822 17.2488 13.5807 17.7 12.7396 17.773ZM11.5826 14.3473C11.5826 14.6202 11.7513 14.8318 12.1144 14.9629C12.1648 14.9833 12.2153 14.9994 12.2755 15.0166V13.6845C11.8791 13.735 11.5826 13.9574 11.5826 14.3473ZM12.8341 15.7997C12.804 15.7932 12.7739 15.7857 12.7396 15.776V17.2123C13.1972 17.1715 13.513 16.9201 13.513 16.499C13.513 16.1692 13.3422 15.9544 12.8341 15.7997Z'
      fill='currentColor'
    />
  </svg>
);
export { IconHouseHeart };
