import { SVGProps } from 'react';

const IconGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M5.2381 0H0.476191C0.21319 0 0 0.21319 0 0.476191V5.2381C0 5.5011 0.21319 5.71429 0.476191 5.71429H5.2381C5.5011 5.71429 5.71429 5.5011 5.71429 5.2381V0.476191C5.71429 0.21319 5.5011 0 5.2381 0Z'
      fill='currentColor'
    />
    <path
      d='M5.2381 7.14282H0.476191C0.21319 7.14282 0 7.35601 0 7.61901V12.3809C0 12.6439 0.21319 12.8571 0.476191 12.8571H5.2381C5.5011 12.8571 5.71429 12.6439 5.71429 12.3809V7.61901C5.71429 7.35601 5.5011 7.14282 5.2381 7.14282Z'
      fill='currentColor'
    />
    <path
      d='M5.2381 14.2856H0.476191C0.21319 14.2856 0 14.4988 0 14.7618V19.5237C0 19.7867 0.21319 19.9999 0.476191 19.9999H5.2381C5.5011 19.9999 5.71429 19.7867 5.71429 19.5237V14.7618C5.71429 14.4988 5.5011 14.2856 5.2381 14.2856Z'
      fill='currentColor'
    />
    <path
      d='M12.3803 0H7.6184C7.3554 0 7.14221 0.21319 7.14221 0.476191V5.2381C7.14221 5.5011 7.3554 5.71429 7.6184 5.71429H12.3803C12.6433 5.71429 12.8565 5.5011 12.8565 5.2381V0.476191C12.8565 0.21319 12.6433 0 12.3803 0Z'
      fill='currentColor'
    />
    <path
      d='M12.3803 7.14282H7.6184C7.3554 7.14282 7.14221 7.35601 7.14221 7.61901V12.3809C7.14221 12.6439 7.3554 12.8571 7.6184 12.8571H12.3803C12.6433 12.8571 12.8565 12.6439 12.8565 12.3809V7.61901C12.8565 7.35601 12.6433 7.14282 12.3803 7.14282Z'
      fill='currentColor'
    />
    <path
      d='M12.3803 14.2856H7.6184C7.3554 14.2856 7.14221 14.4988 7.14221 14.7618V19.5237C7.14221 19.7867 7.3554 19.9999 7.6184 19.9999H12.3803C12.6433 19.9999 12.8565 19.7867 12.8565 19.5237V14.7618C12.8565 14.4988 12.6433 14.2856 12.3803 14.2856Z'
      fill='currentColor'
    />
    <path
      d='M19.5248 0H14.7629C14.4999 0 14.2867 0.21319 14.2867 0.476191V5.2381C14.2867 5.5011 14.4999 5.71429 14.7629 5.71429H19.5248C19.7878 5.71429 20.001 5.5011 20.001 5.2381V0.476191C20.001 0.21319 19.7878 0 19.5248 0Z'
      fill='currentColor'
    />
    <path
      d='M19.5248 7.14282H14.7629C14.4999 7.14282 14.2867 7.35601 14.2867 7.61901V12.3809C14.2867 12.6439 14.4999 12.8571 14.7629 12.8571H19.5248C19.7878 12.8571 20.001 12.6439 20.001 12.3809V7.61901C20.001 7.35601 19.7878 7.14282 19.5248 7.14282Z'
      fill='currentColor'
    />
    <path
      d='M19.5248 14.2856H14.7629C14.4999 14.2856 14.2867 14.4988 14.2867 14.7618V19.5237C14.2867 19.7867 14.4999 19.9999 14.7629 19.9999H19.5248C19.7878 19.9999 20.001 19.7867 20.001 19.5237V14.7618C20.001 14.4988 19.7878 14.2856 19.5248 14.2856Z'
      fill='currentColor'
    />
  </svg>
);
export { IconGrid };
