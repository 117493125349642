import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { Boolean, Conversation, ObjectID } from '../queries';

export interface MutationOfConversation extends MutationOf {
  payload?: Conversation;
}

export interface CreateConversationData {
  createConversation?: MutationOfConversation;
}
export interface CreateConversationVariables {
  userIds: ObjectID[];
  isGroup?: Boolean;
}

export const CREATE_CONVERSATION = gql`
  mutation createConversation($userIds: [ObjectID!]!, $isGroup: Boolean) @api(name: chat) {
    createConversation(userIds: $userIds, isGroup: $isGroup) {
      success
      msg
      payload {
        name
        lastMessage {
          id
          text
          from
          to
        }
      }
    }
  }
`;
