import { gql } from '@apollo/client';
import { Collection, FromTo, Int } from './get-real-estates';
import { ListSavedItem, PaginationOf } from './get-user-saved-real-estate';
import { ProjectEnumType, ProjectStatusEnum } from './list-projects-atmnha';

export type ListCollection = { items?: Collection[] } & PaginationOf;

export interface GetSavedProjectData {
  getSavedProject?: ListSavedItem;
}
export interface GetSavedProjectVariables {
  province?: String;
  district?: String;
  ward?: String;
  street?: String;
  projectType?: ProjectEnumType[];
  expectedPrice?: FromTo;
  projectArea?: FromTo;
  projectStatus?: ProjectStatusEnum[];
  collection?: String[];
  searchString?: String;
  page?: Int;
  limit?: Int;
  sort?: String;
}

export const GET_SAVED_PROJECT = gql`
  query getSavedProject(
    $province: String
    $district: String
    $ward: String
    $street: String
    $projectType: [ProjectEnumType]
    $expectedPrice: FromTo
    $projectArea: FromTo
    $projectStatus: [ProjectStatusEnum]
    $collection: [String]
    $searchString: String
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: realEstate) {
    getSavedProject(
      province: $province
      district: $district
      ward: $ward
      street: $street
      projectType: $projectType
      expectedPrice: $expectedPrice
      projectArea: $projectArea
      projectStatus: $projectStatus
      collection: $collection
      searchString: $searchString
      page: $page
      limit: $limit
      sort: $sort
    ) {
      hasPrevPage
      hasNextPage
      items {
        belongsToCollection {
          id
          name
          owner
          totalItems
        }
        createdAt
        createdItemDate
        id
        note
        type
        updatedAt
        item {
          ... on BaseProject {
            id
            expectedPrice {
              from
              to
            }
            project {
              id
              projectStatus
              projectType
              projectName
              fullAddress
              projectArea {
                area
              }
              mediaUrls {
                ... on FileWithFullUrls {
                  fileId
                  type
                  originalUrl
                }
                ... on YoutubeFile {
                  fileId
                  type
                  youtubeMetadata {
                    videoId
                  }
                }
              }
              totalBlock
              blocks {
                id
                name
              }
              projectOverview
              vr360Urls
              createdAt
            }
            savedItem {
              id
              belongsToCollection {
                id
                name
              }
            }
            totalBlock
            totalFloor
            totalProduct
          }
        }
      }
      limit
      nextPage
      prevPage
      total
      totalPages
      page
      pagingCounter
    }
  }
`;
