import { gql } from '@apollo/client';
import { Boolean, ID, SavedItem, String } from '../queries';

export enum SavedItemType {
  estate = 'estate',
  premium = 'premium',
  need_estate = 'need_estate',
  lead_estate = 'lead_estate',
  law = 'law',
  news = 'news',
  project = 'project',
  product = 'product',
}

export interface CreateSavedItemData {
  success?: Boolean;
  msg?: String;
  payload?: SavedItem;
}

export interface CreateSavedItemVariables {
  type: SavedItemType;
  belongsToCollection: ID;
  item: ID;
  note?: String;
}

export const CREATE_SAVED_ITEM = gql`
  mutation createSavedItem(
    $type: SavedItemType!
    $belongsToCollection: ID!
    $item: ID!
    $note: String
  ) @api(name: realEstate) {
    createSavedItem(
      type: $type
      belongsToCollection: $belongsToCollection
      item: $item
      note: $note
    ) {
      success
      msg
      payload {
        id
        belongsToCollection {
          id
          name
        }
      }
    }
  }
`;
