import { gql } from '@apollo/client';
import { ObjectID } from '../queries';
import { MutationMessage } from './update-list-trading-real-estates';

export interface RemoveConversationData {
  removeConversation?: MutationMessage;
}
export interface RemoveConversationVariables {
  conversationId: ObjectID;
}

export const REMOVE_CONVERSATION = gql`
  mutation removeConversation($conversationId: ObjectID!) @api(name: chat) {
    removeConversation(conversationId: $conversationId) {
      success
      msg
    }
  }
`;
