import { SVGProps } from 'react';

const IconArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='28'
    height='29'
    viewBox='0 0 28 29'
    fill='currentColor'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21 14.5C21 14.2679 20.9078 14.0454 20.7437 13.8813C20.5796 13.7172 20.3571 13.625 20.125 13.625H9.98725L13.7445 9.86951C13.9088 9.70521 14.0011 9.48236 14.0011 9.25001C14.0011 9.01765 13.9088 8.79481 13.7445 8.63051C13.5802 8.46621 13.3574 8.3739 13.125 8.3739C12.8926 8.3739 12.6698 8.46621 12.5055 8.63051L7.2555 13.8805C7.17401 13.9618 7.10936 14.0583 7.06525 14.1646C7.02114 14.271 6.99844 14.3849 6.99844 14.5C6.99844 14.6151 7.02114 14.7291 7.06525 14.8354C7.10936 14.9417 7.17401 15.0382 7.2555 15.1195L12.5055 20.3695C12.6698 20.5338 12.8926 20.6261 13.125 20.6261C13.3574 20.6261 13.5802 20.5338 13.7445 20.3695C13.9088 20.2052 14.0011 19.9824 14.0011 19.75C14.0011 19.5176 13.9088 19.2948 13.7445 19.1305L9.98725 15.375H20.125C20.3571 15.375 20.5796 15.2828 20.7437 15.1187C20.9078 14.9546 21 14.7321 21 14.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconArrowLeft };
