import { gql } from '@apollo/client';
import { Date } from '../mutations';
import { Boolean, DateTime, Float, Int, ObjectID } from './get-real-estates';

export enum RealEstateActionEnumType {
  createPost = 'createPost',
  renewPost = 'renewPost',
  updatePost = 'updatePost',
}
export interface RealEstateFees {
  startDate?: DateTime;
  endDate?: DateTime;
  basicFees?: Float;
  vat?: Float;
  totalFees?: Float;
  totalDays?: Int;
  noChanges?: Boolean;
  update?: Boolean;
}

export interface GetRealEstateFeesVariables {
  startDate?: Date;
  endDate?: Date;
  postType?: Int;
  id?: ObjectID;
  type?: RealEstateActionEnumType;
  actionType?: RealEstateActionEnumType;
}
export interface GetRealEstateFeesData {
  getRealEstateFees?: RealEstateFees;
}

export const GET_REAL_ESTATE_FEES = gql`
  query getRealEstateFees(
    $startDate: Date!
    $endDate: Date!
    $postType: Int!
    $id: ObjectID
    $type: RealEstateEnumType
    $actionType: RealEstateActionType
  ) @api(name: realEstate) {
    getRealEstateFees(
      startDate: $startDate
      endDate: $endDate
      postType: $postType
      id: $id
      type: $type
      actionType: $actionType
    ) {
      basicFees
      vat
      totalFees
      totalDays
      noChanges
      update
    }
  }
`;
