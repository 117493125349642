import { SVGProps } from 'react';

const IconFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M18.4725 0.10625C18.5578 0.163297 18.6277 0.240462 18.6761 0.330933C18.7245 0.421403 18.7499 0.522398 18.75 0.625V10C18.75 10.1248 18.7126 10.2468 18.6426 10.3501C18.5727 10.4535 18.4734 10.5336 18.3575 10.58L18.125 10L18.3575 10.58L18.3537 10.5813L18.3463 10.585L18.3175 10.5963C18.1532 10.6616 17.9877 10.7241 17.8212 10.7837C17.4913 10.9025 17.0325 11.0625 16.5125 11.2213C15.4925 11.5363 14.1637 11.875 13.125 11.875C12.0662 11.875 11.19 11.525 10.4275 11.2188L10.3925 11.2063C9.6 10.8875 8.925 10.625 8.125 10.625C7.25 10.625 6.0775 10.9125 5.07875 11.2213C4.63157 11.3606 4.18847 11.5128 3.75 11.6775V19.375C3.75 19.5408 3.68415 19.6997 3.56694 19.8169C3.44973 19.9342 3.29076 20 3.125 20C2.95924 20 2.80027 19.9342 2.68306 19.8169C2.56585 19.6997 2.5 19.5408 2.5 19.375V0.625C2.5 0.45924 2.56585 0.300269 2.68306 0.183058C2.80027 0.065848 2.95924 0 3.125 0C3.29076 0 3.44973 0.065848 3.56694 0.183058C3.68415 0.300269 3.75 0.45924 3.75 0.625V0.9775C4.0325 0.87875 4.37 0.765 4.7375 0.6525C5.7575 0.34 7.0875 0 8.125 0C9.175 0 10.03 0.34625 10.7763 0.64875L10.83 0.67125C11.6075 0.985 12.285 1.25 13.125 1.25C14 1.25 15.1725 0.9625 16.1713 0.65375C16.7404 0.476015 17.3028 0.27755 17.8575 0.05875L17.8813 0.05L17.8862 0.0475H17.8875L18.4725 0.10625ZM17.5 1.52625C17.225 1.62375 16.9 1.735 16.5425 1.845C15.53 2.16 14.2025 2.49875 13.125 2.49875C12.0175 2.49875 11.135 2.14125 10.3713 1.83125L10.3612 1.8275C9.5775 1.5125 8.92375 1.25 8.125 1.25C7.28875 1.25 6.1175 1.53625 5.10625 1.8475C4.65014 1.98823 4.19789 2.1412 3.75 2.30625V10.3475C4.025 10.25 4.35 10.1388 4.7075 10.0287C5.72 9.7125 7.0475 9.375 8.125 9.375C9.18375 9.375 10.06 9.725 10.8225 10.0312L10.8575 10.0437C11.65 10.3625 12.325 10.625 13.125 10.625C13.96 10.625 15.1325 10.3388 16.1438 10.0275C16.5999 9.88676 17.0521 9.73379 17.5 9.56875V1.5275V1.52625Z'
      fill='currentColor'
    />
  </svg>
);
export { IconFlag };
