import { SVGProps } from 'react';

const IconReject = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 3.69177C7.58182 3.69177 4 7.27359 4 11.6918C4 16.11 7.58182 19.6918 12 19.6918C16.4182 19.6918 20 16.11 20 11.6918C20 7.27359 16.4182 3.69177 12 3.69177ZM14.696 10.0241C14.8285 9.88697 14.9018 9.70326 14.9001 9.51257C14.8985 9.32188 14.822 9.13947 14.6871 9.00463C14.5523 8.86979 14.3699 8.7933 14.1792 8.79165C13.9885 8.78999 13.8048 8.86329 13.6676 8.99577L12 10.6634L10.3324 8.99577C10.2653 8.92631 10.185 8.8709 10.0963 8.83279C10.0076 8.79467 9.91213 8.77461 9.81556 8.77377C9.719 8.77293 9.62323 8.79133 9.53385 8.8279C9.44447 8.86447 9.36327 8.91847 9.29499 8.98676C9.2267 9.05504 9.1727 9.13625 9.13613 9.22562C9.09956 9.315 9.08116 9.41077 9.082 9.50734C9.08284 9.6039 9.1029 9.69934 9.14102 9.78807C9.17913 9.8768 9.23454 9.95705 9.304 10.0241L10.9716 11.6918L9.304 13.3594C9.23454 13.4265 9.17913 13.5067 9.14102 13.5955C9.1029 13.6842 9.08284 13.7796 9.082 13.8762C9.08116 13.9728 9.09956 14.0685 9.13613 14.1579C9.1727 14.2473 9.2267 14.3285 9.29499 14.3968C9.36327 14.4651 9.44447 14.5191 9.53385 14.5556C9.62323 14.5922 9.719 14.6106 9.81556 14.6098C9.91213 14.6089 10.0076 14.5889 10.0963 14.5508C10.185 14.5126 10.2653 14.4572 10.3324 14.3878L12 12.7201L13.6676 14.3878C13.8048 14.5203 13.9885 14.5936 14.1792 14.5919C14.3699 14.5902 14.5523 14.5138 14.6871 14.3789C14.822 14.2441 14.8985 14.0617 14.9001 13.871C14.9018 13.6803 14.8285 13.4966 14.696 13.3594L13.0284 11.6918L14.696 10.0241Z'
      fill='currentColor'
    />
  </svg>
);
export { IconReject };
