import { SVGProps } from 'react';

const IconBookmarkCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='currentColor'
    {...props}
  >
    <path
      d='M11.3333 2C11.6869 2 12.0261 2.14048 12.2761 2.39052C12.5262 2.64057 12.6666 2.97971 12.6666 3.33333V14L7.99998 12L3.33331 14V3.33333C3.33331 2.59333 3.93331 2 4.66665 2H11.3333ZM7.33331 9.33333L11.5 5.17333L10.56 4.22667L7.33331 7.45333L5.60665 5.72667L4.66665 6.66667L7.33331 9.33333Z'
      fill='currentColor'
    />
  </svg>
);
export { IconBookmarkCheck };
