import { gql } from '@apollo/client';
import { String } from '../queries';
import { FriendRequestMutation } from './un-friend';

export interface AcceptFriendRequestData {
  acceptFriendRequest?: FriendRequestMutation;
}
export interface AcceptFriendRequestVariables {
  requestId: String;
}

export const ACCEPT_FRIEND_REQUEST = gql`
  mutation acceptFriendRequest($requestId: ID!) @api(name: social) {
    acceptFriendRequest(requestId: $requestId) {
      success
      msg
      data {
        _id
      }
    }
  }
`;
