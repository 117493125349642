import { SVGProps } from 'react';

const IconPeopleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='currentColor'
    {...props}
  >
    <path
      d='M6.125 12.75C6.125 12.75 5.25 12.75 5.25 11.875C5.25 11 6.125 8.375 9.625 8.375C13.125 8.375 14 11 14 11.875C14 12.75 13.125 12.75 13.125 12.75H6.125ZM9.625 7.5C10.3212 7.5 10.9889 7.22344 11.4812 6.73116C11.9734 6.23887 12.25 5.57119 12.25 4.875C12.25 4.17881 11.9734 3.51113 11.4812 3.01884C10.9889 2.52656 10.3212 2.25 9.625 2.25C8.92881 2.25 8.26113 2.52656 7.76884 3.01884C7.27656 3.51113 7 4.17881 7 4.875C7 5.57119 7.27656 6.23887 7.76884 6.73116C8.26113 7.22344 8.92881 7.5 9.625 7.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.564 12.75C4.43429 12.4768 4.3696 12.1774 4.375 11.875C4.375 10.6894 4.97 9.46876 6.069 8.62001C5.52045 8.45099 4.94895 8.36833 4.375 8.37501C0.875 8.37501 0 11 0 11.875C0 12.75 0.875 12.75 0.875 12.75H4.564Z'
      fill='currentColor'
    />
    <path
      d='M3.9375 7.5C4.51766 7.5 5.07406 7.26953 5.4843 6.8593C5.89453 6.44906 6.125 5.89266 6.125 5.3125C6.125 4.73234 5.89453 4.17594 5.4843 3.7657C5.07406 3.35547 4.51766 3.125 3.9375 3.125C3.35734 3.125 2.80094 3.35547 2.3907 3.7657C1.98047 4.17594 1.75 4.73234 1.75 5.3125C1.75 5.89266 1.98047 6.44906 2.3907 6.8593C2.80094 7.26953 3.35734 7.5 3.9375 7.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconPeopleFill };
