import { NAME_REGEX, PHONE_NUMBER_REGEX } from 'globalConstants';
import * as yup from 'yup';

export interface ContactForeclosureForm {
  fullName: string;
  phone: string;
  email?: string;
  note?: string;
}

export const contactForeclosureFormDefaultValues: ContactForeclosureForm = {
  fullName: '',
  phone: '',
  email: '',
  note: '',
};

export const contactForeclosureFormSchema = yup
  .object()
  .shape({
    fullName: yup
      .string()
      .required('Vui lòng nhập họ và tên')
      .matches(NAME_REGEX, 'Vui lòng nhập họ và tên hợp lệ'),
    phone: yup
      .string()
      .required('Vui lòng nhập số điện thoại')
      .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
    email: yup.string().nullable().email('Vui lòng nhập đúng định dạng email@xxx.com'),
    note: yup.string(),
  })
  .required();
