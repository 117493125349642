import { SVGProps } from 'react';

const IconShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.5 10.5C2.5 11.8783 3.62167 13 5 13C5.66167 13 6.26 12.7375 6.7075 12.3167L11.7 15.17C11.685 15.2783 11.6667 15.3867 11.6667 15.5C11.6667 16.8783 12.7883 18 14.1667 18C15.545 18 16.6667 16.8783 16.6667 15.5C16.6667 14.1217 15.545 13 14.1667 13C13.505 13 12.9067 13.2625 12.4592 13.6833L7.46667 10.8308C7.48167 10.7217 7.5 10.6133 7.5 10.5C7.5 10.3867 7.48167 10.2783 7.46667 10.1692L12.4592 7.31667C12.9067 7.7375 13.505 8 14.1667 8C15.545 8 16.6667 6.87833 16.6667 5.5C16.6667 4.12167 15.545 3 14.1667 3C12.7883 3 11.6667 4.12167 11.6667 5.5C11.6667 5.61333 11.685 5.72167 11.7 5.83083L6.7075 8.68333C6.24667 8.24557 5.63561 8.00102 5 8C3.62167 8 2.5 9.12167 2.5 10.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconShare };
