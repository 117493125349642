import { SVGProps } from 'react';

const IconCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='37'
    height='36'
    viewBox='0 0 37 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M34.6458 7.40953C34.2052 6.88777 33.5883 6.56907 32.9085 6.51218L8.96538 4.50908C8.28551 4.45219 7.62451 4.66397 7.10369 5.1053C6.58499 5.5449 6.26737 6.15988 6.2088 6.83767L5.72709 11.3658H3.80364C2.39557 11.3658 1.25 12.5136 1.25 13.9244V28.9414C1.25 30.3522 2.39557 31.5 3.80364 31.5H27.8302C29.2383 31.5 30.3839 30.3522 30.3839 28.9414V26.5054L31.2365 26.5767C31.3084 26.5827 31.3799 26.5857 31.4508 26.5857C32.7635 26.5857 33.8826 25.5737 33.9938 24.2399L35.241 9.2749C35.2977 8.59379 35.0863 7.93137 34.6458 7.40953ZM3.80364 12.6965H27.8302C28.5059 12.6965 29.0558 13.2473 29.0558 13.9244V15.2927H2.57812V13.9244C2.57812 13.2473 3.12789 12.6965 3.80364 12.6965ZM2.57812 16.6234H29.0558V19.3839H2.57812V16.6234ZM27.8302 30.1693H3.80364C3.12789 30.1693 2.57812 29.6185 2.57812 28.9414V20.7146H29.0558V28.9414C29.0558 29.6185 28.5059 30.1693 27.8302 30.1693ZM33.9174 9.16412L32.6702 24.1291C32.614 24.8038 32.0203 25.3071 31.347 25.2506L30.3839 25.17V13.9244C30.3839 12.5136 29.2383 11.3658 27.8302 11.3658H7.06278L7.53021 6.9718C7.53074 6.96674 7.53121 6.96175 7.53161 6.95663C7.58785 6.2819 8.18126 5.77863 8.85488 5.83519L32.7981 7.83828C33.1243 7.86556 33.4204 8.01853 33.6318 8.26896C33.8432 8.5194 33.9446 8.83724 33.9174 9.16412Z'
      fill='currentColor'
    />
    <path
      d='M26.2771 22.693H19.886C19.5192 22.693 19.2219 22.9909 19.2219 23.3583V27.536C19.2219 27.9035 19.5192 28.2014 19.886 28.2014H26.2771C26.6438 28.2014 26.9411 27.9035 26.9411 27.536V23.3583C26.9411 22.9909 26.6438 22.693 26.2771 22.693ZM25.613 26.8707H20.55V24.0237H25.613V26.8707Z'
      fill='currentColor'
    />
  </svg>
);
export { IconCreditCard };
