import { SVGProps } from 'react';

const IconVipCrownLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.5 19.5H22.5V21.5H2.5V19.5ZM2.5 5.5L7.5 9L12.5 2.5L17.5 9L22.5 5.5V17.5H2.5V5.5ZM4.5 9.341V15.5H20.5V9.341L17.08 11.735L12.5 5.78L7.92 11.735L4.5 9.34V9.341Z'
      fill='currentColor'
    />
  </svg>
);
export { IconVipCrownLine };
