import { gql } from '@apollo/client';
import { Boolean, Collection, CollectionEnum, ObjectID, String } from '../queries';

export interface CreateUserCollectionData {
  createUserCollection?: {
    success?: Boolean;
    msg?: String;
    payload?: Collection;
  };
}

export interface CreateUserCollectionVariables {
  name: String;
  type: CollectionEnum;
  itemId?: ObjectID;
  note?: String;
}

export const CREATE_USER_COLLECTION = gql`
  mutation createUserCollection(
    $name: String!
    $type: CollectionEnum!
    $itemId: ObjectID
    $note: String
  ) @api(name: realEstate) {
    createUserCollection(name: $name, type: $type, itemId: $itemId, note: $note) {
      success
      msg
      payload {
        id
        type
        name
        owner
        totalItems
      }
    }
  }
`;
