import { ColumnObject, CellObject } from 'components/desktop/shared/table';
import { forEach, map } from 'lodash';

export const combineTableRawDataWithColumnObjects = (
  rawData?: any[],
  columnObjects?: ColumnObject[],
  limit?: number,
  page?: number,
): CellObject[][] => {
  const tableBodyDisplayData: CellObject[][] = [];
  forEach(rawData, (rawDataItem, rawDataItemIndex) => {
    const tableRowDisplayData: CellObject[] = map(columnObjects, (columnObject) => {
      return {
        id: columnObject.id,
        rowId: rawDataItem?.id,
        align: columnObject.align,
        content: columnObject.formatter
          ? columnObject.formatter(rawDataItem, rawDataItemIndex, limit, page)
          : null,
        className: columnObject.className,
      };
    });
    tableBodyDisplayData.push(tableRowDisplayData);
  });
  return tableBodyDisplayData;
};
