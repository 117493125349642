import { SVGProps } from 'react';

const IconPercentage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_6286_1999)'>
      <path
        d='M16.7 7.25835V3.33335H12.7833L10 0.575012L7.25833 3.33335H3.33333V7.25835L0.575001 10L3.33333 12.7833V16.7H7.25833L10 19.4583L12.7833 16.7H16.7V12.7833L19.4583 10L16.7 7.25835ZM7.35833 5.87501C8.175 5.87501 8.83333 6.53335 8.83333 7.35835C8.83333 7.74954 8.67793 8.12471 8.40132 8.40133C8.1247 8.67794 7.74953 8.83335 7.35833 8.83335C6.53333 8.83335 5.875 8.17501 5.875 7.35835C5.875 6.53335 6.53333 5.87501 7.35833 5.87501ZM12.6833 14.1667C11.8667 14.1667 11.2083 13.5 11.2083 12.6833C11.2083 12.2922 11.3637 11.917 11.6404 11.6404C11.917 11.3637 12.2921 11.2083 12.6833 11.2083C13.5 11.2083 14.1667 11.8667 14.1667 12.6833C14.1667 13.0767 14.0104 13.454 13.7322 13.7322C13.454 14.0104 13.0767 14.1667 12.6833 14.1667ZM7.08333 14.1917L5.83333 12.9417L12.9417 5.83335L14.1917 7.08335L7.08333 14.1917Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6286_1999'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export { IconPercentage };
