import { SVGProps } from 'react';

const IconSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2 20.1429L22 11.5714L2 3V9.66667L16.2857 11.5714L2 13.4762V20.1429Z'
      fill='currentColor'
    />
  </svg>
);
export { IconSend };
