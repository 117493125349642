import { gql } from '@apollo/client';
import { DateTime, Int, ObjectID, JSONObject, String, Boolean } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export enum AttachmentType {
  media = 'media',
  image = 'image',
  video = 'video',
}
export enum MessageType {
  response = 'response',
  update = 'update',
  system = 'system',
}
export interface Attachment {
  attachmentId?: ObjectID;
  type?: AttachmentType;
  url?: String;
}
export interface Message {
  attachments?: Attachment[];
  callPayload?: JSONObject;
  createdAt?: DateTime;
  deletedAt?: DateTime;
  from?: ObjectID;
  hiddenBy?: ObjectID[];
  id?: ObjectID;
  seenBy?: ObjectID[];
  text?: String;
  to?: ObjectID;
  type?: MessageType;
  unreadBy?: ObjectID[];
  updatedAt?: DateTime;
}
export interface PaginationOfMessage extends PaginationOf {
  items?: Message[];
}

export interface MessagesData {
  messages?: PaginationOfMessage;
  messageCreated?: Message;
}
export interface MessagesVariables {
  conversationId: ObjectID;
  fromDate?: DateTime;
  toDate?: DateTime;
  offset?: Int;
  limit?: Int;
}

export const MESSAGES = gql`
  query messages(
    $conversationId: ObjectID!
    $fromDate: DateTime
    $toDate: DateTime
    $offset: Int
    $limit: Int
  ) @api(name: chat) {
    messages(
      conversationId: $conversationId
      fromDate: $fromDate
      toDate: $toDate
      offset: $offset
      limit: $limit
    ) {
      limit
      offset
      nextPage
      prevPage
      items {
        id
        text
        from
        to
        createdAt
      }
    }
  }
`;
