import { SVGProps } from 'react';

const IconEarth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.4083 15.9408C16.17 15.2075 15.4825 14.6666 14.6667 14.6666H13.75V11.9166C13.75 11.6735 13.6534 11.4404 13.4815 11.2685C13.3096 11.0966 13.0764 11 12.8333 11H7.33333V9.16665H9.16667C9.40978 9.16665 9.64294 9.07007 9.81485 8.89816C9.98676 8.72625 10.0833 8.49309 10.0833 8.24998V6.41665H11.9167C12.4029 6.41665 12.8692 6.22349 13.213 5.87968C13.5568 5.53586 13.75 5.06954 13.75 4.58331V4.20748C16.4358 5.28915 18.3333 7.91998 18.3333 11C18.3333 12.9066 17.6 14.6391 16.4083 15.9408ZM10.0833 18.2691C6.4625 17.82 3.66667 14.74 3.66667 11C3.66667 10.4316 3.74 9.88165 3.85917 9.35915L8.25 13.75V14.6666C8.25 15.1529 8.44315 15.6192 8.78697 15.963C9.13079 16.3068 9.5971 16.5 10.0833 16.5V18.2691ZM11 1.83331C9.79622 1.83331 8.60422 2.07042 7.49207 2.53108C6.37992 2.99175 5.36939 3.66696 4.51819 4.51817C2.7991 6.23725 1.83333 8.56883 1.83333 11C1.83333 13.4311 2.7991 15.7627 4.51819 17.4818C5.36939 18.333 6.37992 19.0082 7.49207 19.4689C8.60422 19.9295 9.79622 20.1666 11 20.1666C13.4312 20.1666 15.7627 19.2009 17.4818 17.4818C19.2009 15.7627 20.1667 13.4311 20.1667 11C20.1667 9.7962 19.9296 8.6042 19.4689 7.49205C19.0082 6.3799 18.333 5.36937 17.4818 4.51817C16.6306 3.66696 15.6201 2.99175 14.5079 2.53108C13.3958 2.07042 12.2038 1.83331 11 1.83331Z'
      fill='currentColor'
    />
  </svg>
);
export { IconEarth };
