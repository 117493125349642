import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfRegisterInProject } from './create-register-get-price-list';

export interface CancelRegisterSeeHouseVariables {
  id: String;
  reasonCancel: String;
}
export interface CancelRegisterSeeHouseData {
  cancelRegisterSeeHouse?: MutationOfRegisterInProject;
}

export const CANCEL_REGISTER_SEE_HOUSE = gql`
  mutation cancelRegisterSeeHouse($id: ObjectID!, $reasonCancel: String!) @api(name: biha) {
    cancelRegisterSeeHouse(id: $id, reasonCancel: $reasonCancel) {
      success
      msg
    }
  }
`;
