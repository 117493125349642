import { SVGProps } from 'react';

const IconPower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.3125 4.0625C15.3125 4.0625 17.8125 5.685 17.8125 10C17.8125 12.072 16.9894 14.0591 15.5243 15.5243C14.0591 16.9894 12.072 17.8125 10 17.8125C7.928 17.8125 5.94086 16.9894 4.47573 15.5243C3.0106 14.0591 2.1875 12.072 2.1875 10C2.1875 5.685 4.6875 4.0625 4.6875 4.0625M10 2.1875V10.3125V2.1875Z'
      stroke='currentColor'
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconPower };
