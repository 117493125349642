import { SVGProps } from 'react';

const IconTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='14'
    viewBox='0 0 16 14'
    fill='currentColor'
    {...props}
  >
    <path
      d='M15.5 2.34743C14.9483 2.59208 14.3548 2.75755 13.7324 2.83145C14.3679 2.4507 14.8555 1.84825 15.0857 1.12921C14.4911 1.48196 13.8322 1.73786 13.1315 1.87579C12.5701 1.27793 11.7703 0.904297 10.8847 0.904297C9.18539 0.904297 7.80722 2.28248 7.80722 3.98173C7.80722 4.22294 7.83453 4.45773 7.88731 4.6831C5.32971 4.55481 3.06197 3.32971 1.54402 1.46727C1.27917 1.92169 1.12724 2.4507 1.12724 3.0146C1.12724 4.08203 1.67094 5.02437 2.49624 5.5761C1.99201 5.56027 1.51717 5.42188 1.10245 5.19076C1.10222 5.20385 1.10222 5.21693 1.10222 5.22978C1.10222 6.72088 2.16345 7.96456 3.57101 8.24709C3.31305 8.31777 3.04062 8.35518 2.7604 8.35518C2.56164 8.35518 2.36909 8.33613 2.18135 8.30033C2.57289 9.52267 3.70917 10.4125 5.05614 10.4375C4.00271 11.263 2.67594 11.7548 1.23373 11.7548C0.985862 11.7548 0.740292 11.7404 0.5 11.7117C1.8612 12.5852 3.47921 13.0945 5.21702 13.0945C10.8775 13.0945 13.9731 8.40521 13.9731 4.33815C13.9731 4.20481 13.9701 4.07193 13.9642 3.94019C14.5659 3.50643 15.0876 2.96457 15.5 2.34743Z'
      fill='currentColor'
    />
  </svg>
);
export { IconTwitter };
