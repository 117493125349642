import { gql } from '@apollo/client';
import { Boolean, Int, ObjectID, String } from './get-real-estates';

export enum KindRealEstateEnumType {
  RealEstate = 'RealEstate',
  RealEstateProject = 'RealEstateProject',
}
export interface StatisticRealEstate {
  slug?: String;
  districtId?: String;
  districtName?: String;
  total?: Int;
}

export interface NearRealEstatesData {
  nearRealEstates?: StatisticRealEstate[];
}
export interface NearRealEstatesVariables {
  isForSell?: Boolean;
  realEstateType?: String;
  kindOfRealEstate?: KindRealEstateEnumType;
  province?: ObjectID;
  district?: ObjectID;
}

export const NEAR_REAL_ESTATES = gql`
  query nearRealEstates(
    $isForSell: Boolean
    $realEstateType: String
    $kindOfRealEstate: KindRealEstateEnumType
    $province: ObjectID
    $district: ObjectID
  ) @api(name: realEstate) {
    nearRealEstates(
      isForSell: $isForSell
      realEstateType: $realEstateType
      kindOfRealEstate: $kindOfRealEstate
      province: $province
      district: $district
    ) {
      slug
      districtId
      districtName
      total
    }
  }
`;
