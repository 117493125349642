import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfAccessTokenType } from './sign-in';

export interface NewGoogleSignInVariables {
  accessToken: String;
}
export interface NewGoogleSignInData {
  newGoogleSignIn?: MutationOfAccessTokenType;
}

export const NEW_GOOGLE_SIGN_IN = gql`
  mutation newGoogleSignIn($accessToken: String!) @api(name: users) {
    newGoogleSignIn(accessToken: $accessToken) {
      msg
      success
      payload {
        accessToken
        refreshToken
      }
    }
  }
`;
