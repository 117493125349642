import { SVGProps } from 'react';

const IconLogOutCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path d='M2.5 12L7.5 16V13H16.5V11H7.5V8L2.5 12Z' fill='currentColor' />
    <path
      d='M13.501 2.999C12.3185 2.99572 11.1471 3.22711 10.0547 3.67976C8.9623 4.13241 7.97057 4.79733 7.13696 5.636L8.55096 7.05C9.87296 5.728 11.631 4.999 13.501 4.999C15.371 4.999 17.129 5.728 18.451 7.05C19.773 8.372 20.502 10.13 20.502 12C20.502 13.87 19.773 15.628 18.451 16.95C17.129 18.272 15.371 19.001 13.501 19.001C11.631 19.001 9.87296 18.272 8.55096 16.95L7.13696 18.364C8.83596 20.064 11.096 21.001 13.501 21.001C15.906 21.001 18.166 20.064 19.865 18.364C21.565 16.665 22.502 14.405 22.502 12C22.502 9.595 21.565 7.335 19.865 5.636C19.0314 4.79733 18.0396 4.13241 16.9472 3.67976C15.8548 3.22711 14.6834 2.99572 13.501 2.999Z'
      fill='currentColor'
    />
  </svg>
);
export { IconLogOutCircle };
