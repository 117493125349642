import { SVGProps } from 'react';

const IconReturn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M15.9999 19.1105H2.51192C1.86214 19.1105 1.33325 18.5816 1.33325 17.9318V7.11046C1.33325 6.86513 1.53236 6.66602 1.7777 6.66602H18.6666C18.9119 6.66602 19.111 6.86513 19.111 7.11046V10.666C19.111 10.9113 18.9119 11.1105 18.6666 11.1105C18.4213 11.1105 18.2221 10.9113 18.2221 10.666V7.5549H2.22214V17.9318C2.22214 18.0918 2.35192 18.2216 2.51192 18.2216H15.9999C16.2453 18.2216 16.4444 18.4207 16.4444 18.666C16.4444 18.9113 16.2453 19.1105 15.9999 19.1105Z'
      fill='currentColor'
    />
    <path
      d='M18.6666 7.55523C18.5101 7.55523 18.3581 7.47256 18.2772 7.32501L15.5928 2.45212C15.5155 2.3099 15.3661 2.2219 15.2035 2.2219H5.2408C5.07814 2.2219 4.9288 2.3099 4.85147 2.45212L2.16702 7.32501C2.0488 7.54101 1.7768 7.61656 1.56347 7.50012C1.34836 7.3819 1.27014 7.11079 1.38836 6.89656L4.0728 2.02367C4.30658 1.59701 4.75458 1.33301 5.2408 1.33301H15.2044C15.6906 1.33301 16.1386 1.59701 16.3724 2.02279L19.0559 6.89567C19.175 7.11079 19.0968 7.38101 18.8817 7.49923C18.8132 7.53745 18.7395 7.55523 18.6666 7.55523Z'
      fill='currentColor'
    />
    <path
      d='M10.2228 7.55523C9.97743 7.55523 9.77832 7.35612 9.77832 7.11079V1.77745C9.77832 1.53212 9.97743 1.33301 10.2228 1.33301C10.4681 1.33301 10.6672 1.53212 10.6672 1.77745V7.11079C10.6672 7.35612 10.4681 7.55523 10.2228 7.55523Z'
      fill='currentColor'
    />
    <path
      d='M22.2221 22.666C21.9767 22.666 21.7776 22.4669 21.7776 22.2216V20.4438C21.7776 17.7478 19.5838 15.5549 16.8887 15.5549H15.1109C14.8656 15.5549 14.6665 15.3558 14.6665 15.1105C14.6665 14.8651 14.8656 14.666 15.1109 14.666H16.8887C20.0745 14.666 22.6665 17.258 22.6665 20.4438V22.2216C22.6665 22.4669 22.4674 22.666 22.2221 22.666Z'
      fill='currentColor'
    />
    <path
      d='M18.2221 18.2216C18.1198 18.2216 18.0167 18.186 17.9332 18.114L14.8221 15.4474C14.7225 15.3638 14.6665 15.2403 14.6665 15.1105C14.6665 14.9807 14.7225 14.8572 14.8221 14.7736L17.9332 12.1069C18.1189 11.9478 18.3981 11.9683 18.5598 12.1549C18.7198 12.3407 18.6976 12.6216 18.5109 12.7816L15.7945 15.1105L18.5118 17.4403C18.6985 17.6003 18.7198 17.8803 18.5607 18.0669C18.4709 18.1692 18.3474 18.2216 18.2221 18.2216Z'
      fill='currentColor'
    />
  </svg>
);
export { IconReturn };
