export interface BaseData {
  status?: string;
  message?: string;
}
export enum CartType {
  cart = 'cart',
}
export enum ProductOptionType {
  S = 'S',
  R = 'R',
  C = 'C',
  I = 'I',
  T = 'T',
}
export enum RequiredType {
  Y = 'Y',
  N = 'N',
}
export interface MessageType {
  message?: string;
}
export interface ListPrice {
  lower_limit?: number;
  percentage_discount?: number;
  price?: number;
  product_id?: string;
  usergroup_id?: string;
}
export interface SelectedOptions {
  [key: string]: number;
}
export interface ProductSerialization {
  product_id?: number;
  company_id?: string;
  company_name?: string;
  product?: string;
  amount?: number;
  parent_product_id?: number;
  average_rating?: string;
  base_price?: string;
  price?: number;
  discount?: number;
  main_pair?: {
    detailed?: {
      image_path?: string;
    };
  };
  image_pairs?: ImagesPairs;
  price_qty_discount?: {
    base_price?: string;
    wholesale?: string;
    lowest_price?: string;
    list_price?: ListPrice[];
  };
  product_reviews_count?: string;
  meta_description?: string;
  full_description?: string;
  product_reviews_rating_stats?: ProductReviewsRatingStats;
  variation_features_variants?: VariationFeaturesVariants;
  product_options?: ProductOptions;
  category_ids?: number[];
  selected_options?: SelectedOptions;
  list_price?: number;
  seo_name?: ProductReviewsRatingStats;
  other_main_pair?: {
    detailed?: {
      image_path?: string;
    };
  };
  other_image_pairs: ImagesPairs;
  punch_line: string;
  specification?: string;
  special_note?: string;
  purchasing_notice?: string;
  main_category_name?: string;
  is_wishlist?: boolean;
}
export interface ProductsParamsSerialization {
  page?: number;
  items_per_page?: number;
  total_items?: string;
}
export interface ProductsSerialization {
  products?: ProductSerialization[];
  params?: ProductsParamsSerialization;
}
export interface ProductsData extends BaseData {
  data?: ProductsSerialization;
}
export interface ProductsResponse {
  data?: ProductsData;
}
export interface ProductsParams {
  q?: string | null;
  variant_id?: number | null;
  cid?: string | null;
  order_ids?: string | null;
  features_hash?: string | null;
  page?: number | null;
  items_per_page?: number | null;
  limit?: number | null;
  event_id?: number | null;
  get_sold?: string | null;
  subcats?: string | null;
  sort_by?: string | null;
  sort_order?: string | null;
}
export interface ProductDetailParams {
  id: string;
}
export interface UpdateCartProductSerialization {
  message?: string;
}
export interface UpdateCartProductData extends BaseData {
  data?: UpdateCartProductSerialization;
}
export interface UpdateCartProductParams {
  cart_id: number;
  product_id?: number | null;
  amount: number;
}
export interface UpdateCartProductResponse {
  data?: UpdateCartProductData;
}
export interface DeleteCartProductData extends BaseData {
  data?: CartSerialization;
}
export interface DeleteCartProductParams {
  cart_ids: number[] | null;
}
export interface DeleteCartProductResponse {
  data?: DeleteCartProductData;
}
export interface CheckoutParams {
  cart_ids: number[] | null;
}
export interface CheckoutData extends BaseData {
  data?: CartSerialization;
}
export interface CheckoutResponse {
  data?: CheckoutData;
}
export interface ProductDetailData extends BaseData {
  data?: ProductSerialization;
}
export interface ProductDetailResponse {
  data?: ProductDetailData;
}
export interface ProductReviewsRatingStats {
  ratings?: { [key: string]: { count?: number; percentage?: string } };
  total?: number;
  average_rating?: number;
}
export interface ImagesPairs {
  [key: number]: {
    detailed?: {
      image_path?: string;
    };
  };
}
export interface VariationFeaturesVariants {
  [key: string]: VariationFeaturesVariant;
}
export interface VariationFeaturesVariant {
  feature_id?: string;
  internal_name?: string;
  variant_id?: number;
  variant?: string;
  variants?: Variants;
  description?: string;
}
export interface Variants {
  [key: string]: Variant;
}
export interface Variant {
  variant_id?: number;
  variant_name?: string;
  variant?: string;
  modifier?: number;
  product_id?: number;
  product?: ProductSerialization;
}
export interface ProductOptions {
  [key: string]: ProductOption;
}
export interface ProductOption {
  option_id?: number;
  option_name?: string;
  option_type?: ProductOptionType;
  required?: RequiredType;
  variants?: Variants;
  inner_hint?: string;
  variant_name?: string;
  value?: number;
}
export interface ReviewParams {
  id: number;
  sort_by?: string;
  sort_order?: string;
  page?: number;
  limit?: number;
}
export interface ReviewsData extends BaseData {
  data?: ReviewListWithPagination;
}
export interface ReviewListWithPagination {
  data?: ReviewSerialization[];
  current_page?: number | null;
  next_page_url?: string | null;
  path?: string | null;
  per_page?: number | null;
  prev_page_url?: string | null;
  to?: number | null;
  total?: number | null;
}
export interface ReviewSerialization {
  product_review_id?: string;
  user_id?: string;
  name?: string;
  quick_comment?: string;
  comment?: string;
  rating_value?: number;
  product_review_timestamp?: string;
  is_buyer?: string;
  images?: string[];
  avatar?: string;
}
export interface CreateReviewParams {
  id: string;
  name?: string;
  advantages?: string;
  disadvantages?: string;
  quick_comment?: string;
  comment?: string;
  rating_value?: number;
  ip_address?: string;
  images?: File[];
}
export interface CreateReviewData extends BaseData {
  data?: ReviewSerialization;
}
export interface Seo {
  name?: string;
  path?: string;
}
export interface CategorySerialization {
  category_id?: number;
  seo_name?: string;
  category?: string;
  position?: number;
  id_path?: string;
  parent_id?: number;
  seo?: Seo;
  main_pair?: {
    [key: string]: {
      object_id?: number;
      image_id?: number;
      image_path?: string;
      images_image_id?: number;
    };
  };
}
export interface CategoriesData extends BaseData {
  data?: CategorySerialization[];
}
export interface CategoriesResponse {
  data?: CategoriesData;
}
export interface CategoriesParams {
  parent_id?: string | null;
  brand_id?: string | null;
}
export interface CategoriesMainData extends BaseData {
  data?: CategorySerialization[];
}
export interface CategoriesMainResponse {
  data?: CategoriesMainData;
}
export interface CategoriesMainParams {
  parent_id?: string | null;
  brand_id?: string | null;
}
export interface CategoriesResponse {
  data?: CategoriesData;
}
export interface CategoriesParams {
  parent_id?: string | null;
  brand_id?: string | null;
}
export interface CategoryData extends BaseData {
  data?: CategorySerialization;
}
export interface CategoryResponse {
  data?: CategoryData;
}
export interface CategoryParams {
  seo_name?: string | null;
}
export interface FilterSerialization {
  filter_id?: string;
  filter?: string;
  filter_style?: string;
  slider?: boolean;
  min?: number;
  max?: number;
  disable?: boolean;
  extra?: string;
  variants?: {
    [key: string]: {
      variant_id?: string;
      variant?: string;
    };
  };
}
export interface FiltersData extends BaseData {
  data?: { [key: string]: FilterSerialization }[];
}
export interface FiltersResponse {
  data?: FiltersData;
}
export interface FiltersParams {
  category_id?: string | null;
  dispatch?: string | null;
  items_per_page?: number | null;
  page?: number | null;
}
export interface Promotion {
  promotion_id?: string;
  bonuses?: { promotion_id?: string }[];
  conditions?: {
    conditions?: {
      [key: number]: {
        condition: string;
      };
    };
  };
  short_description?: string;
}
export interface Promotions {
  [key: number]: Promotion;
}
export interface AddProductData extends BaseData {
  data?: CartSerialization | MessageType;
}
export interface AddProductParams {
  product_id: number;
  amount: number;
  product_options?: {
    [key: string]: number | undefined;
  };
}
export interface AddProductResponse {
  data?: AddProductData;
}
export interface BuyNowData extends BaseData {
  data?: CartSerialization | MessageType;
}
export interface BuyNowParams {
  cart_id?: number | null;
  product_id: number;
  amount: number;
  product_options?: {
    [key: string]: number | undefined;
  } | null;
}
export interface BuyNowResponse {
  data?: BuyNowData;
}
export interface CartCoupons {
  [key: string]: number[];
}
export interface AppliedVendorPromotions {
  [key: number]: Promotion[];
}
export interface CartSerialization {
  user_id?: number;
  shipping_cost?: number;
  subtotal?: number;
  discounted_subtotal?: number;
  total?: number;
  amount?: number;
  products?: CartProducts;
  product_groups?: CartProductGroup[];
  user_data?: ProfileSerialization;
  promotions?: Promotions;
  applied_promotions?: Promotions;
  applied_vendor_promotions?: AppliedVendorPromotions;
  payment_id?: number;
  coupons?: CartCoupons;
  subtotal_discount?: number;
}
export interface CartProducts {
  [key: string]: CartProduct;
}
export interface CartProduct {
  product_id?: number;
  product_code?: string;
  product?: string;
  amount?: number;
  product_options?: [];
  price?: number;
  main_pair?: {
    detailed?: {
      image_path?: string;
    };
  };
  product_option_data: ProductOption[];
  product_variant_data: VariationFeaturesVariant;
  seo_name?: string;
}
export interface Origination {
  company_id?: number;
  address?: string;
}
export interface PackageInfo {
  C?: number;
  origination?: Origination;
}
export interface Shipping {
  shipping_id?: number;
  shipping?: number;
  delivery_time?: string;
  image?: {
    icon?: {
      image_path?: string;
    };
  };
  rate?: number;
}
export interface Shippings {
  [key: number]: Shipping;
}
export interface CartProductGroup {
  name?: string;
  company_id?: number;
  products?: CartProducts[];
  package_info_full?: PackageInfo;
  shippings?: Shippings;
  chosen_shippings?: Shipping[];
}
export interface CartParams {
  type?: CartType | null;
}
export interface CartData extends BaseData {
  data?: CartSerialization;
}
export interface CartResponse {
  data?: CartData;
}
export interface PromotionSerialization {
  promotion_id?: number;
  name?: string;
  short_description?: string;
  detailed_description?: string;
  coupon_code?: string;
  from_date?: number;
  to_date?: number;
}
export interface PromotionsData extends BaseData {
  data?: PromotionSerialization[];
}
export interface PromotionsParams {
  company_id?: number | null;
}
export interface PromotionsResponse {
  data?: PromotionsData;
}
export interface CalculateData extends BaseData {
  data?: CartSerialization;
}
export interface CalculateParams {
  shipping_ids?: number[] | null;
  payment_id?: number | null;
  profile_id?: number | null;
  user_id?: number | null;
}
export interface CalculateResponse {
  data?: CalculateData;
}
export interface ApplyCouponData extends BaseData {
  data?: CartSerialization | MessageType;
}
export interface ApplyCouponParams {
  coupon_code?: string | null;
  company_id?: number | null;
}
export interface ApplyCouponResponse {
  data?: ApplyCouponData;
}
export interface CompanySerialization {
  company_id?: number;
  company?: string;
  logo_url?: string;
  banner_url?: string;
  average_rating?: number;
  phone?: string;
  email?: string;
  description?: string;
  address?: string;
  products_count?: number;
  timestamp?: number;
}
export interface CompaniesParamsSerialization {
  current_page?: number;
  per_page?: number;
  total?: number;
}
export interface CompaniesSerialization extends CompaniesParamsSerialization {
  data?: CompanySerialization[];
}
export interface CompaniesData extends BaseData {
  data?: CompaniesSerialization | CompanySerialization[];
}
export interface CompaniesResponse {
  data?: CompaniesData;
}
export interface CompaniesParams {
  top_ten?: string | null;
}

export interface CompanyData extends BaseData {
  data?: CompanySerialization;
}
export interface CompanyResponse extends BaseData {
  data?: CompanyData;
}
export interface CompanyParams {
  id?: number | null;
}
export interface PaymentSerialization {
  payment_id?: number;
  payment?: string;
  description?: {
    payment?: string;
  };
  instructions?: string;
  surcharge_title?: string;
  lang_code?: string;
  image_path?: string;
}
export interface ProfileDefaultData extends BaseData {
  data?: ProfileSerialization;
}
export interface OrderDetailSeralization {
  seo_name?: string;
  amount?: number;
  price?: string;
  main_pair?: string;
  extra?: {
    product?: string;
    product_options_value?: {
      variant_name?: string;
    }[];
  };
}
export interface OrderSerialization {
  order_id?: number;
  status?: string;
  timestamp?: string;
  details?: OrderDetailSeralization[];
  full_address?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  payment?: PaymentSerialization;
  subtotal?: string;
  shipping_cost?: string;
  subtotal_discount?: string;
  total?: string;
  company?: string;
}
export interface OrdersDataData {
  current_page?: number;
  per_page?: number;
  total?: number;
  last_page?: number;
  data?: OrderSerialization[];
}
export interface OrdersData extends BaseData {
  data?: OrdersDataData;
}
export interface OrdersResponse {
  data?: OrdersData;
}
export interface OrdersParams {
  status?: string | null;
  item_per_page?: string | null;
  page?: string | null;
}
export interface OrderData extends BaseData {
  data?: OrderSerialization;
}
export interface OrderResponse {
  data?: OrderData;
}
export interface OrderParams {
  id?: number | null;
}
export interface ProfileSerialization {
  profile_name?: string;
  s_ward?: string;
  s_district?: string;
  s_city?: string;
  s_phone?: string;
  s_address?: string;
  s_address_2?: string;
  is_default?: string;
  profile_type?: string;
  user_id?: number;
  s_country?: string;
  s_state?: string;
  s_firstname?: string;
  profile_update_timestamp?: number;
  profile_id?: number;
  full_address?: number;
}
export interface ProfilesDataData {
  data?: ProfileSerialization[];
}
export interface ProfilesData extends BaseData {
  data?: ProfilesDataData;
}
export interface ProfilesResponse {
  data?: ProfilesData;
}
export interface ProfilesParams {
  limit?: number | null;
  page?: number | null;
}
export interface StateSerialization {
  code?: string;
  state?: string;
}
export interface StatesData extends BaseData {
  data?: StateSerialization[];
}
export interface StatesResponse {
  data?: StatesData;
}
export interface StatesParams {
  country_code?: string | null;
}
export interface DistrictSerialization {
  district_id?: string;
  name?: string;
  type?: string;
  city_id?: string;
}
export interface DistrictsData extends BaseData {
  data?: DistrictSerialization[];
}
export interface DistrictsResponse {
  data?: DistrictsData;
}
export interface DistrictsParams {
  city_id?: string | null;
}
export interface WardSerialization {
  ward_id?: string;
  name?: string;
  type?: string;
  district_id?: string;
}
export interface WardsData extends BaseData {
  data?: WardSerialization[];
}
export interface WardsResponse {
  data?: WardsData;
}
export interface WardsParams {
  district_id?: string | null;
}
export interface CreateProfileData extends BaseData {
  data?: ProfileSerialization;
}
export interface CreateProfileResponse {
  data?: CreateProfileData;
}
export interface CreateProfileParams {
  profile_name?: string | null;
  s_ward?: string | null;
  s_district?: string | null;
  s_city?: string | null;
  s_phone?: string | null;
  s_address?: string | null;
  s_address_2?: string | null;
  is_default?: string | null;
}
export interface UpdateProfileData extends BaseData {
  data?: ProfileSerialization;
}
export interface UpdateProfileResponse {
  data?: UpdateProfileData;
}
export interface UpdateProfileParams {
  profile_name?: string | null;
  s_ward?: string | null;
  s_district?: string | null;
  s_city?: string | null;
  s_phone?: string | null;
  s_address?: string | null;
  s_address_2?: string | null;
  is_default?: string | null;
}
export interface DeleteProfileData extends BaseData {
  data?: null;
}
export interface DeleteProfileResponse {
  data?: DeleteProfileData;
}
export interface DeleteProfileParams {
  id?: number | null;
}
export interface BannerSerialization {
  banner_page_code?: string;
  banner_position_code?: string;
  banner_id?: number;
  image_path?: string;
  url?: string;
}
export interface BannersData extends BaseData {
  data?: BannerSerialization[];
}
export interface BannersResponse {
  data?: BannersData;
}
export interface BannersParams {
  banner_position_code?: string | null;
  banner_ids?: string | null;
}
export interface WishListSerialization extends ProductSerialization {}
export interface WishListData extends BaseData {
  data?: WishListSerialization[];
}
export interface WishListResponse {
  data?: WishListData;
}
export interface WishListParams {}
export interface AddWishListData extends BaseData {
  data?: WishListSerialization[];
}
export interface AddWishListParams {
  product_id: number;
}
export interface AddWishListResponse {
  data?: AddWishListData;
}
export interface DeleteWishListData extends BaseData {
  data?: WishListSerialization[];
}
export interface DeleteWishListParams {
  product_id: number;
}
export interface DeleteWishListResponse {
  data?: DeleteWishListData;
}
export interface ReviewStatsData extends BaseData {
  data?: ProductReviewsRatingStats;
}
export interface ReviewStatsParams {
  id: number;
}
export interface ReviewStatsResponse {
  data?: ReviewStatsData;
}
