import * as yup from 'yup';

export interface LoanEstimateForm {
  projectAmount?: string;
  loanPercentage?: string;
  loanAmount?: string;
  loanTerm?: string;
  bank?: string;
  interestRate?: string;
  interestType?: 'fixed' | 'adjustable';
  totalPaymentAmount?: number;
  prePaymentAmount?: number;
  paymentRemainingAmount?: number;
  principalAmount?: number;
  paymentAmountFirstMonth?: number;
}

export const defaultLoanEstimateForm: LoanEstimateForm = {
  projectAmount: '3000000000',
  loanPercentage: '50',
  loanAmount: undefined,
  loanTerm: '5',
  bank: 'optional',
  interestRate: '6',
  interestType: 'adjustable',
  totalPaymentAmount: 0,
  prePaymentAmount: 0,
  paymentRemainingAmount: 0,
  principalAmount: 0,
  paymentAmountFirstMonth: 0,
};

export const defaultLoanEstimateFormSchema = yup.object().shape({});
