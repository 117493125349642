import { PHONE_NUMBER_REGEX } from 'globalConstants';
import * as yup from 'yup';

export interface ChangePhoneNumberStep1Form {
  verifyPassword: string;
}
export interface ChangePhoneNumberStep2Form {
  oldPhone: string;
  newPhone: string;
  identifier: string;
}
export interface ChangePhoneNumberStep3Form {
  phoneVerifyToken: string;
}
export interface ChangePhoneNumberForm {
  step1?: ChangePhoneNumberStep1Form;
  step2?: ChangePhoneNumberStep2Form;
  step3?: ChangePhoneNumberStep3Form;
}
export const changePhoneNumberStep1DefaultValue: ChangePhoneNumberStep1Form = {
  verifyPassword: '',
};
export const changePhoneNumberStep2DefaultValue: ChangePhoneNumberStep2Form = {
  oldPhone: '',
  newPhone: '',
  identifier: 'identifier',
};
export const changePhoneNumberStep3DefaultValue: ChangePhoneNumberStep3Form = {
  phoneVerifyToken: '',
};

export const changePhoneNumberStep1Schema = yup.object().shape({
  verifyPassword: yup
    .string()
    .required('Vui lòng nhập mật khẩu')
    .min(8, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự')
    .max(32, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự'),
});
export const changePhoneNumberStep2Schema = yup.object().shape({
  newPhone: yup
    .string()
    .required('Vui lòng nhập số điện thoại mới')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  oldPhone: yup
    .string()
    .required('Vui lòng nhập số điện thoại cũ')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
});
