import { SVGProps } from 'react';

const IconDirectionDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M17.3653 9.30865C17.3309 9.21729 17.2726 9.1392 17.1979 9.08426C17.1231 9.02932 17.0353 9 16.9454 9H7.85445C7.76455 9 7.67666 9.02932 7.60191 9.08427C7.52716 9.13921 7.4689 9.2173 7.4345 9.30866C7.4001 9.40003 7.3911 9.50056 7.40864 9.59755C7.42618 9.69455 7.46947 9.78364 7.53304 9.85356L12.0785 14.8535C12.1207 14.9 12.1708 14.9368 12.226 14.9619C12.2811 14.9871 12.3402 15 12.3999 15C12.4596 15 12.5187 14.9871 12.5738 14.9619C12.629 14.9368 12.6791 14.9 12.7213 14.8535L17.2668 9.85356C17.3303 9.78364 17.3736 9.69454 17.3912 9.59755C17.4087 9.50056 17.3997 9.40002 17.3653 9.30865Z'
      fill='currentColor'
    />
  </svg>
);
export { IconDirectionDown };
