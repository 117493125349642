import { SVGProps } from 'react';

const IconUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='26'
    viewBox='0 0 24 26'
    fill='currentColor'
    {...props}
  >
    <path
      d='M18.204 6.6736L12.604 0.2736C12.452 0.1008 12.2328 0 12.0024 0C11.772 0 11.5528 0.1008 11.4008 0.2736L5.80079 6.6736C5.59439 6.9104 5.54319 7.2448 5.67439 7.5312C5.80399 7.816 6.08879 8 6.40239 8H9.60239V18.4C9.60239 18.8416 9.96079 19.2 10.4024 19.2H13.6024C14.044 19.2 14.4024 18.8416 14.4024 18.4V8H17.6024C17.916 8 18.2008 7.8176 18.3304 7.5312C18.46 7.2448 18.412 6.9088 18.204 6.6736Z'
      fill='currentColor'
    />
    <path
      d='M20.8024 17.6V22.4H3.20244V17.6H0.00244141V24C0.00244141 24.8848 0.719241 25.6 1.60244 25.6H22.4024C23.2872 25.6 24.0024 24.8848 24.0024 24V17.6H20.8024Z'
      fill='currentColor'
    />
  </svg>
);
export { IconUpload };
