import { gql } from '@apollo/client';

export interface IsValidPasswordVariables {
  password: string;
}

export const IS_VALID_PASSWORD = gql`
  mutation isValidPassword($password: String!) @api(name: users) {
    isValidPassword(password: $password)
  }
`;
