import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconOrderDeliveredModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;
  return (
    <svg
      width='46'
      height='45'
      viewBox='0 0 46 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g clipPath='url(#clip0_369_18450)'>
        <rect x='0.5' width='45' height='45' rx='8' fill='white' />
        <rect x='0.5' width='45' height='45' rx='8' fill='url(#paint0_linear_369_18450)' />
        <rect x='0.5' width='45' height='45' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.6'
          cx='35.1111'
          cy='38.5'
          r={r ?? '30'}
          fill='url(#paint1_radial_369_18450)'
          fillOpacity='0.8'
        />
        <path
          d='M31.9099 25.4915L27.1359 26.9875C27.1799 26.8775 27.2019 26.7675 27.2019 26.6355C27.2239 26.3275 27.1139 26.0195 26.8939 25.7775C26.6519 25.5135 26.3219 25.3815 25.9699 25.3815H22.9119L20.2719 24.4135C19.6339 24.1715 18.9519 24.0615 18.2479 24.0615H16.9939V30.0895H17.4999C17.6979 30.0895 17.8959 30.1335 18.0939 30.1995L23.7479 32.2015C23.9239 32.2675 24.0999 32.2895 24.2759 32.2895C24.5399 32.2895 24.8039 32.2235 25.0459 32.0915L32.8779 27.6255C33.1419 27.4935 33.3399 27.2735 33.4719 27.0095C33.4939 26.9875 33.4939 26.9655 33.4939 26.9655C33.6039 26.6795 33.5819 26.3495 33.4499 26.0635C33.1859 25.4695 32.4819 25.2275 31.9099 25.4915ZM15.6519 22.3235H13.3199C12.8359 22.3235 12.4399 22.7195 12.4399 23.2035V30.8375C12.4399 31.3215 12.8359 31.7175 13.3199 31.7175H15.6519C16.1359 31.7175 16.5319 31.3215 16.5319 30.8375V23.2255C16.5539 22.7195 16.1579 22.3235 15.6519 22.3235ZM26.4759 15.4595L27.4659 16.3615V12.6875H25.1779V16.3395L26.1679 15.4375C26.2119 15.3935 26.2559 15.3715 26.3219 15.3715C26.3879 15.3935 26.4319 15.4155 26.4759 15.4595Z'
          fill={fillColor2 ?? '#80888F'}
        />
        <path
          d='M21.878 12.6875C21.152 12.6875 20.536 13.2815 20.536 14.0295V22.9395C20.536 23.6655 21.13 24.2815 21.878 24.2815H30.92C31.602 24.2155 32.13 23.6435 32.13 22.9615V14.0295C32.13 13.3035 31.536 12.6875 30.788 12.6875H27.928V16.8455C27.928 16.9335 27.884 17.0215 27.796 17.0435C27.774 17.0655 27.73 17.0655 27.708 17.0655C27.664 17.0655 27.598 17.0435 27.554 16.9995L26.344 15.8995L25.134 16.9995C25.068 17.0655 24.98 17.0655 24.892 17.0435C24.804 16.9995 24.76 16.9335 24.76 16.8455V12.6875H21.878ZM25.442 21.8175H22.34C22.208 21.8175 22.12 21.7075 22.12 21.5975C22.12 21.4875 22.208 21.3775 22.34 21.3775H25.442C25.574 21.3775 25.662 21.4875 25.662 21.5975C25.662 21.7075 25.574 21.8175 25.442 21.8175ZM25.442 19.5955C25.574 19.5955 25.662 19.6835 25.662 19.8155C25.662 19.9475 25.574 20.0355 25.442 20.0355H22.34C22.208 20.0355 22.12 19.9475 22.12 19.8155C22.12 19.6835 22.208 19.5955 22.34 19.5955H25.442Z'
          fill={fillColor2 ?? '#80888F'}
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_369_18450'
          x1='41.5'
          y1='42.5'
          x2='4.5'
          y2='3.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9DBFE8' />
          <stop offset='1' stopColor='#97A9B0' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_369_18450'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(35.1111 38.5) rotate(90) scale(30)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_369_18450'>
          <rect x='0.5' width='45' height='45' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconOrderDeliveredModule };
