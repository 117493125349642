import { SVGProps } from 'react';

const IconImage2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M9.25 8.1875C9.25 8.73451 9.0327 9.25911 8.64591 9.64591C8.25911 10.0327 7.73451 10.25 7.1875 10.25C6.64049 10.25 6.11589 10.0327 5.72909 9.64591C5.3423 9.25911 5.125 8.73451 5.125 8.1875C5.125 7.64049 5.3423 7.11589 5.72909 6.72909C6.11589 6.3423 6.64049 6.125 7.1875 6.125C7.73451 6.125 8.25911 6.3423 8.64591 6.72909C9.0327 7.11589 9.25 7.64049 9.25 8.1875Z'
      fill='currentColor'
    />
    <path
      d='M3.75 2C3.02065 2 2.32118 2.28973 1.80546 2.80546C1.28973 3.32118 1 4.02065 1 4.75V18.5C1 19.2293 1.28973 19.9288 1.80546 20.4445C2.32118 20.9603 3.02065 21.25 3.75 21.25H20.25C20.9793 21.25 21.6788 20.9603 22.1945 20.4445C22.7103 19.9288 23 19.2293 23 18.5V4.75C23 4.02065 22.7103 3.32118 22.1945 2.80546C21.6788 2.28973 20.9793 2 20.25 2H3.75ZM20.25 3.375C20.6147 3.375 20.9644 3.51987 21.2223 3.77773C21.4801 4.03559 21.625 4.38533 21.625 4.75V13.6875L16.4316 11.0104C16.3027 10.9458 16.1567 10.9234 16.0143 10.9463C15.8719 10.9693 15.7404 11.0364 15.6383 11.1383L10.537 16.2395L6.8795 13.803C6.74745 13.7151 6.58905 13.6755 6.43117 13.6911C6.27328 13.7066 6.12563 13.7763 6.01325 13.8883L2.375 17.125V4.75C2.375 4.38533 2.51987 4.03559 2.77773 3.77773C3.03559 3.51987 3.38533 3.375 3.75 3.375H20.25Z'
      fill='currentColor'
    />
  </svg>
);
export { IconImage2 };
