import { PHONE_NUMBER_REGEX } from 'globalConstants';
import * as yup from 'yup';

export interface RecoveryStep1Form {
  phone: string;
  identifier: string;
}
export interface RecoveryStep2Form {
  secretCode: string;
}
export interface RecoveryStep3Form {
  newPassword: string;
  confirmNewPassword: string;
}
export interface RecoveryForm {
  step1?: RecoveryStep1Form;
  step2?: RecoveryStep2Form;
  step3?: RecoveryStep3Form;
}

export const recoveryStep1FormDefaultValues: RecoveryStep1Form = {
  phone: '',
  identifier: '',
};
export const recoveryStep2FormDefaultValues: RecoveryStep2Form = {
  secretCode: '',
};
export const recoveryStep3FormDefaultValues: RecoveryStep3Form = {
  newPassword: '',
  confirmNewPassword: '',
};

export const recoveryStep1FormSchema = yup
  .object()
  .shape({
    phone: yup
      .string()
      .required('Vui lòng nhập số điện thoại')
      .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  })
  .required();
export const recoveryStep3FormSchema = yup
  .object()
  .shape({
    newPassword: yup
      .string()
      .required('Vui lòng nhập mật khẩu mới')
      .min(8, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự')
      .max(32, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự'),
    confirmNewPassword: yup
      .string()
      .required('Vui lòng nhập lại mật khẩu mới')
      .oneOf([yup.ref('newPassword')], 'Nhập lại mật khẩu mới không trùng khớp'),
  })
  .required();
