import { mainClient, mapClient } from 'apollo/client';
import {
  EwalletConfiguration,
  GET_EWALLET_CONFIGURATIONS,
  GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS,
  GET_LIST_BANK,
  GET_REAL_ESTATE_CONFIGURATIONS,
  GetEwalletConfigurationsData,
  GetInternalRealEstateConfigurationsData,
  GetListBankData,
  GetRealEstateConfigurationsData,
  InternalRealEstateConfigurationType,
  RealEstateConfiguration,
} from 'graphql/main/queries';
import {
  GET_LIST_OLD_PROVINCES,
  GET_LIST_PROVINCES,
  GetListOldProvincesData,
  GetListProvincesData,
  Province,
} from 'graphql/map/queries';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

interface Props {
  major?: RealEstateConfiguration;
  provinces?: Province[];
  oldProvinces?: Province[];
  projectConfiguration?: InternalRealEstateConfigurationType;
  banks?: any[];
  ewallet?: EwalletConfiguration;
}

const ApolloConfigurationContext = createContext<Props>({});

export const useApolloConfigurationContext = () => useContext(ApolloConfigurationContext);

const ApolloConfigurationProvider = ({ children }: PropsWithChildren) => {
  const [major, setMajor] = useState<RealEstateConfiguration>();
  const [provinces, setProvinces] = useState<Province[]>();
  const [oldProvinces, setOldProvinces] = useState<Province[]>();
  const [projectConfiguration, setProjectConfiguration] =
    useState<InternalRealEstateConfigurationType>();
  const [banks, setBanks] = useState<any[]>();
  const [ewallet, setEwallet] = useState<EwalletConfiguration>();

  useEffect(() => {
    (async () => {
      const [
        { data: getRealEstateConfigurationsData },
        { data: getListProvincesData },
        { data: getListOldProvincesData },
        { data: getProjectConfigurationsData },
        { data: getListBankData },
        { data: getEwalletConfigurationsData },
      ] = await Promise.all([
        mainClient.query<GetRealEstateConfigurationsData>({
          query: GET_REAL_ESTATE_CONFIGURATIONS,
        }),
        mapClient.query<GetListProvincesData>({
          query: GET_LIST_PROVINCES,
        }),
        mapClient.query<GetListOldProvincesData>({
          query: GET_LIST_OLD_PROVINCES,
        }),
        mainClient.query<GetInternalRealEstateConfigurationsData>({
          query: GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS,
        }),
        mainClient.query<GetListBankData>({
          query: GET_LIST_BANK,
        }),
        mainClient.query<GetEwalletConfigurationsData>({
          query: GET_EWALLET_CONFIGURATIONS,
        }),
      ]);
      const { getRealEstateConfigurations: major } = getRealEstateConfigurationsData;
      const { getListProvinces: provinces } = getListProvincesData;
      const { getListOldProvinces: oldProvinces } = getListOldProvincesData;
      const { getInternalRealEstateConfigurations: projectConfiguration } =
        getProjectConfigurationsData;
      const { getListBank: banks } = getListBankData;
      const { getEwalletConfigurations: _ewallet } = getEwalletConfigurationsData ?? {};
      setMajor(major);
      setProvinces(provinces);
      setOldProvinces(oldProvinces);
      setProjectConfiguration(projectConfiguration);
      setBanks(banks);
      setEwallet(_ewallet);
    })();
  }, []);

  return (
    <ApolloConfigurationContext.Provider
      value={{
        major,
        provinces,
        projectConfiguration,
        oldProvinces,
        banks,
        ewallet,
      }}
    >
      {children}
    </ApolloConfigurationContext.Provider>
  );
};

export default ApolloConfigurationProvider;
