import { SVGProps } from 'react';

const IconInformationOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M12.5 2.25C10.5716 2.25 8.68657 2.82183 7.08319 3.89317C5.47982 4.96451 4.23013 6.48726 3.49218 8.26884C2.75422 10.0504 2.56114 12.0108 2.93735 13.9021C3.31355 15.7934 4.24215 17.5307 5.60571 18.8943C6.96928 20.2579 8.70656 21.1865 10.5979 21.5627C12.4892 21.9389 14.4496 21.7458 16.2312 21.0078C18.0127 20.2699 19.5355 19.0202 20.6068 17.4168C21.6782 15.8134 22.25 13.9284 22.25 12C22.2475 9.4149 21.2195 6.93639 19.3916 5.10845C17.5636 3.28051 15.0851 2.25248 12.5 2.25ZM12.5 20.25C10.8683 20.25 9.27326 19.7661 7.91655 18.8596C6.55984 17.9531 5.50242 16.6646 4.878 15.1571C4.25358 13.6496 4.0902 11.9908 4.40853 10.3905C4.72685 8.79016 5.51259 7.32015 6.66637 6.16637C7.82016 5.01259 9.29017 4.22685 10.8905 3.90852C12.4909 3.59019 14.1497 3.75357 15.6571 4.37799C17.1646 5.00242 18.4531 6.05984 19.3596 7.41655C20.2661 8.77325 20.75 10.3683 20.75 12C20.7475 14.1873 19.8775 16.2843 18.3309 17.8309C16.7843 19.3775 14.6873 20.2475 12.5 20.25ZM14 16.5C14 16.6989 13.921 16.8897 13.7803 17.0303C13.6397 17.171 13.4489 17.25 13.25 17.25H12.5C12.3011 17.25 12.1103 17.171 11.9697 17.0303C11.829 16.8897 11.75 16.6989 11.75 16.5V12C11.5511 12 11.3603 11.921 11.2197 11.7803C11.079 11.6397 11 11.4489 11 11.25C11 11.0511 11.079 10.8603 11.2197 10.7197C11.3603 10.579 11.5511 10.5 11.75 10.5H12.5C12.6989 10.5 12.8897 10.579 13.0303 10.7197C13.171 10.8603 13.25 11.0511 13.25 11.25V15.75C13.4489 15.75 13.6397 15.829 13.7803 15.9697C13.921 16.1103 14 16.3011 14 16.5ZM11.1875 7.875C11.1875 7.6525 11.2535 7.43499 11.3771 7.24998C11.5007 7.06498 11.6764 6.92078 11.882 6.83564C12.0876 6.75049 12.3138 6.72821 12.532 6.77162C12.7502 6.81502 12.9507 6.92217 13.108 7.0795C13.2653 7.23684 13.3725 7.43729 13.4159 7.65552C13.4593 7.87375 13.437 8.09995 13.3519 8.30552C13.2667 8.51109 13.1225 8.68679 12.9375 8.8104C12.7525 8.93402 12.535 9 12.3125 9C12.0141 9 11.728 8.88147 11.517 8.6705C11.306 8.45952 11.1875 8.17337 11.1875 7.875Z'
      fill='currentColor'
    />
  </svg>
);
export { IconInformationOutline };
