import { SVGProps } from 'react';

const IconComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='currentColor'
    {...props}
  >
    <path
      d='M17.1667 2.16699H3.83335C2.91669 2.16699 2.16669 2.91699 2.16669 3.83366V18.8337L5.50002 15.5003H17.1667C18.0834 15.5003 18.8334 14.7503 18.8334 13.8337V3.83366C18.8334 2.91699 18.0834 2.16699 17.1667 2.16699ZM5.50002 12.167V10.1087L11.2334 4.37533C11.4 4.20866 11.6584 4.20866 11.825 4.37533L13.3 5.85033C13.4667 6.01699 13.4667 6.27533 13.3 6.44199L7.55835 12.167H5.50002ZM14.6667 12.167H9.25002L10.9167 10.5003H14.6667C15.125 10.5003 15.5 10.8753 15.5 11.3337C15.5 11.792 15.125 12.167 14.6667 12.167Z'
      fill='currentColor'
    />
  </svg>
);
export { IconComment };
