import { SVGProps } from 'react';

const IconHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.6631 3.4713C15.0178 3.4627 13.4492 4.14465 12.3345 5.34292L12.1673 5.53053C11.1042 4.36397 9.59039 3.70258 8.0042 3.7213V3.4713C8.00371 3.4713 8.00321 3.4713 8.00272 3.4713C4.73339 3.4721 2.08334 6.12264 2.08334 9.39216C2.08334 10.806 2.6992 12.1903 3.59882 13.4614C4.49983 14.7345 5.69998 15.9147 6.90358 16.9254C9.27551 18.9172 11.6919 20.2766 11.9524 20.4174C12.1844 20.5663 12.4821 20.5663 12.7142 20.4175C12.973 20.2781 15.39 18.9372 17.7627 16.9542C18.9665 15.9481 20.1669 14.7702 21.0681 13.4932C21.9681 12.2179 22.5833 10.8243 22.5833 9.39216C22.5833 6.12237 19.9328 3.47167 16.6631 3.4713Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
    />
  </svg>
);
export { IconHeart };
