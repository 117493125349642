import { SVGProps } from 'react';

const IconLandRows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15 1.5H3C2.175 1.5 1.5 2.175 1.5 3V15C1.5 15.825 2.175 16.5 3 16.5H15C15.825 16.5 16.5 15.825 16.5 15V3C16.5 2.175 15.825 1.5 15 1.5ZM4.875 15H3V3H4.875V15ZM8.25 15H6.375V3H8.25V15ZM11.625 15H9.75V3H11.625V15ZM15 15H13.125V3H15V15Z'
      fill='currentColor'
    />
  </svg>
);
export { IconLandRows };
