import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import {
  Boolean,
  CommonAddress,
  Foreclosure,
  MediaUnionWithFullUrls,
  ObjectID,
  String,
} from '../queries';

export interface UserForeclosureType {
  id?: ObjectID;
  type?: String;
  fullName?: String;
  gender?: String;
  phone?: String;
  province?: CommonAddress;
  memberId?: String;
  isPremiumAccount?: Boolean;
  avatar?: MediaUnionWithFullUrls;
  email?: String;
}
export interface ForeclosurePayload extends MutationOf {
  payload?: Foreclosure;
}

export interface SendForeclosureRequestData {
  sendForeclosureRequest?: ForeclosurePayload;
}
export interface SendForeclosureRequestVariables {
  fullName: String;
  phone: String;
  email?: String;
  note?: String;
  realEstateId: ObjectID;
}

export const SEND_FORECLOSURE_REQUEST = gql`
  mutation sendForeclosureRequest(
    $fullName: String!
    $phone: String!
    $email: String
    $note: String
    $realEstateId: ObjectID!
  ) @api(name: realEstate) {
    sendForeclosureRequest(
      fullName: $fullName
      phone: $phone
      email: $email
      note: $note
      realEstateId: $realEstateId
    ) {
      payload {
        id
      }
      success
      msg
    }
  }
`;
