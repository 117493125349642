import { SVGProps } from 'react';

const IconOfficeBuildingOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='currentColor'
    {...props}
  >
    <g clipPath='url(#clip0_3534_38400)'>
      <path
        d='M11.0833 2.04124V12.5412H7.58333V10.4996H6.41667V12.5412H2.91667V2.04124H11.0833ZM8.75 4.37457H9.91667V3.20791H8.75V4.37457ZM6.41667 4.37457H7.58333V3.20791H6.41667V4.37457ZM4.08333 4.37457H5.25V3.20791H4.08333V4.37457ZM8.75 6.70791H9.91667V5.54124H8.75V6.70791ZM6.41667 6.70791H7.58333V5.54124H6.41667V6.70791ZM4.08333 6.70791H5.25V5.54124H4.08333V6.70791ZM8.75 9.04124H9.91667V7.87457H8.75V9.04124ZM6.41667 9.04124H7.58333V7.87457H6.41667V9.04124ZM4.08333 9.04124H5.25V7.87457H4.08333V9.04124ZM8.75 11.3746H9.91667V10.2079H8.75V11.3746ZM4.08333 11.3746H5.25V10.2079H4.08333V11.3746ZM12.25 0.874573H1.75V13.7079H12.25V0.874573Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_3534_38400'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.29126)' />
      </clipPath>
    </defs>
  </svg>
);
export { IconOfficeBuildingOutline };
