export interface SignInForm {
  phone: string;
  password: string;
  savedLogin?: string;
}

export const signInFormDefaultValues: SignInForm = {
  phone: '',
  password: '',
  savedLogin: undefined,
};
