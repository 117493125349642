import { SVGProps } from 'react';

const IconRuler = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M4.13772 19.1995L19.1999 4.13733C19.491 3.84714 20 4.04708 20 4.4687V8.74986H18.5938C18.3347 8.74986 18.125 8.95952 18.125 9.21861C18.125 9.4777 18.3347 9.68736 18.5938 9.68736H20V10.6248H18.5938C18.3347 10.6248 18.125 10.8345 18.125 11.0936C18.125 11.3527 18.3347 11.5623 18.5938 11.5623H20V12.4998H18.5938C18.3347 12.4998 18.125 12.7095 18.125 12.9685C18.125 13.2276 18.3347 13.4373 18.5938 13.4373H20V14.3748H18.5938C18.3347 14.3748 18.125 14.5844 18.125 14.8435C18.125 15.1026 18.3347 15.3123 18.5938 15.3123H20V16.2497H18.5938C18.3347 16.2497 18.125 16.4594 18.125 16.7185C18.125 16.9776 18.3347 17.1872 18.5938 17.1872H20V19.5309C20 19.79 19.7903 19.9997 19.5312 19.9997H17.1876V18.5935C17.1876 18.3344 16.9779 18.1247 16.7188 18.1247C16.4597 18.1247 16.2501 18.3344 16.2501 18.5935V19.9997H15.3126V18.5935C15.3126 18.3344 15.1029 18.1247 14.8438 18.1247C14.5847 18.1247 14.3751 18.3344 14.3751 18.5935V19.9997H13.4376V18.5935C13.4376 18.3344 13.228 18.1247 12.9689 18.1247C12.7098 18.1247 12.5001 18.3344 12.5001 18.5935V19.9997H11.5627V18.5935C11.5627 18.3344 11.353 18.1247 11.0939 18.1247C10.8348 18.1247 10.6252 18.3344 10.6252 18.5935V19.9997H9.68769V18.5935C9.68769 18.3344 9.47803 18.1247 9.21894 18.1247C8.95984 18.1247 8.75019 18.3344 8.75019 18.5935V19.9997H4.46903C4.052 19.9997 3.84341 19.4927 4.13772 19.1995ZM11.3214 17.1871H16.7188C16.9779 17.1871 17.1876 16.9775 17.1876 16.7184V11.3206C17.1876 10.9006 16.6792 10.6973 16.3874 10.9892L10.9901 16.387C10.6958 16.6802 10.9043 17.1871 11.3214 17.1871Z'
      fill='currentColor'
    />
  </svg>
);
export { IconRuler };
