import { gql } from '@apollo/client';
import { Address, Boolean, Float, ID, Int, String } from 'graphql/main/queries';
import { File } from '../cancel-friend-request';

export interface fromTo {
  from?: Float;
  to?: Float;
}
export enum TYPE {
  realEstate = 'realEstate',
  project = 'project',
  demand = 'demand',
}
export enum DEMAND {
  sell = 'sell',
  rent = 'rent',
  needRent = 'needRent',
  needBuy = 'needBuy',
}
export enum REAL_ESTATE_TYPE {
  land = 'land',
  townhouse = 'townhouse',
  villa = 'villa',
  building = 'building',
  hotel = 'hotel',
  warehouse = 'warehouse',
  apartment = 'apartment',
  shophouse = 'shophouse',
  penthouse = 'penthouse',
  condotel = 'condotel',
  motel = 'motel',
  house = 'house',
  farm = 'farm',
  other = 'other',
}
export enum PROJECT_TYPE {
  complex = 'complex',
  land = 'land',
  officeBuilding = 'officeBuilding',
  industrialPark = 'industrialPark',
  commercialCenter = 'commercialCenter',
}
export enum LEGAL {
  underInvestorContract = 'underInvestorContract',
  houseCertificate = 'houseCertificate',
  loans = 'loans',
  capitalContributionContract = 'capitalContributionContract',
}
export enum INTERIOR_STATUS {
  full = 'full',
  basic = 'basic',
  raw = 'raw',
}
export enum DIRECTION {
  Dong = 'Dong',
  DongBac = 'DongBac',
  DongNam = 'DongNam',
  DongTuTrach = 'DongTuTrach',
  Nam = 'Nam',
  Tay = 'Tay',
  Bac = 'Bac',
  TayBac = 'TayBac',
  TayNam = 'TayNam',
  TayTuTrach = 'TayTuTrach',
}
export interface priceDetail {
  name?: String;
  price?: fromTo;
  area?: fromTo;
}
export interface PostInput {
  type: TYPE;
  demand: DEMAND;
  realEstateType?: REAL_ESTATE_TYPE;
  projectType?: PROJECT_TYPE;
  title: String;
  body: String;
  province: String;
  district: String;
  ward: String;
  street?: String;
  houseNumber?: String;
  price?: fromTo;
  commission?: String;
  contactPhone?: String;
  images?: String[];
  legals?: LEGAL[];
  bedroomCount?: fromTo;
  toiletCount?: fromTo;
  floorCount?: fromTo;
  blockCode?: String;
  interiorStatus?: INTERIOR_STATUS;
  direction?: DIRECTION[];
  width?: fromTo;
  length?: fromTo;
  area?: fromTo;
  hasAlley?: Boolean;
  turnCount?: Int;
  alleyWidth?: Float;
  projectName?: String;
  ownerName?: String;
  priceDetail?: priceDetail[];
}
export interface UserSocial {
  id?: ID;
  fullName?: String;
  avatar?: File;
  phone?: String;
  province?: Address;
  type?: String;
  totalPost?: Int;
}
export type address = Address;
export interface FromTo {
  from?: Float;
  to?: Float;
}
export interface PriceDetail {
  name?: String;
  price?: FromTo;
  area?: FromTo;
}
export enum POST_MODE {
  public = 'public',
  private = 'private',
  friend = 'friend',
}
export interface Post {
  id: ID;
  title?: String;
  user?: UserSocial;
  body?: String;
  type?: TYPE;
  demand?: DEMAND;
  realEstateType?: REAL_ESTATE_TYPE;
  projectType?: PROJECT_TYPE;
  province?: address;
  district?: address;
  ward?: address;
  street?: address;
  houseNumber?: String;
  price?: FromTo;
  commission?: String;
  contactPhone?: String;
  images?: File[];
  legals?: LEGAL[];
  bedroomCount?: FromTo;
  toiletCount?: FromTo;
  floorCount?: FromTo;
  blockCode?: String;
  interiorStatus?: INTERIOR_STATUS;
  direction?: DIRECTION[];
  width?: FromTo;
  length?: FromTo;
  area?: FromTo;
  hasAlley?: Boolean;
  turnCount?: Int;
  alleyWidth?: Float;
  createdAt?: Date;
  slug?: String;
  projectName?: String;
  ownerName?: String;
  hideUser?: String[];
  hidden?: Boolean;
  likeCount?: Int;
  isLiked?: Boolean;
  commentCount?: Int;
  priceDetail?: PriceDetail[];
  mode?: POST_MODE;
  lat?: Float;
  lng?: Float;
  shareLink?: String;
}

export interface CreatePostData {
  createPost?: Post;
}
export interface CreatePostVariables {
  data: PostInput;
}

export const CREATE_POST = gql`
  mutation createPost($data: PostInput!) @api(name: social) {
    createPost(data: $data) {
      alleyWidth
      area {
        from
        to
      }
      bedroomCount {
        from
        to
      }
      blockCode
      body
      commentCount
      commission
      contactPhone
      createdAt
      demand
      hidden
      district {
        id
        name
      }
      floorCount {
        from
        to
      }
      hasAlley
      houseNumber
      id
      images {
        fileId
        type
        originalUrl
      }
      interiorStatus
      isLiked
      legals
      length {
        from
        to
      }
      likeCount
      mode
      ownerName
      price {
        from
        to
      }
      priceDetail {
        area {
          from
          to
        }
        name
        price {
          from
          to
        }
      }
      projectName
      projectType
      province {
        id
        name
      }
      realEstateType
      slug
      street {
        id
        name
      }
      title
      toiletCount {
        from
        to
      }
      turnCount
      type
      user {
        avatar {
          fileId
          originalKey
          originalUrl
          type
        }
        fullName
        id
        phone
      }
      ward {
        id
        name
      }
      width {
        from
        to
      }
    }
  }
`;
