import { SVGProps } from 'react';

const IconDirection = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      d='M7.3559 4.1128L4.05326 8.81053C3.99379 8.89378 3.98604 9.00333 4.03319 9.09412C4.08034 9.18491 4.17443 9.2416 4.27675 9.24086H5.93221V10.0684C5.93175 12.1232 6.81887 14.078 8.36573 15.4309L9.04447 16.024C9.87214 16.7489 10.3468 17.7955 10.3468 18.8956V19.7232C10.3468 19.8755 10.4703 19.999 10.6227 19.999H13.3818C13.5342 19.999 13.6577 19.8755 13.6577 19.7232V18.8956C13.6526 16.8421 12.7664 14.8894 11.2242 13.5331L10.5454 12.94C9.71775 12.2151 9.24309 11.1685 9.24312 10.0684V9.24086H10.8986C11.0009 9.2416 11.095 9.18491 11.1421 9.09412C11.1893 9.00333 11.1815 8.89378 11.1221 8.81053L7.80288 4.1128C7.75087 4.04131 7.6678 3.99902 7.57939 3.99902C7.49098 3.99902 7.40791 4.04131 7.3559 4.1128Z'
      fill='currentColor'
    />
    <path
      d='M12.8299 8.96776C12.8302 9.04058 12.8595 9.11029 12.9112 9.16152C12.963 9.21275 13.033 9.24129 13.1059 9.24085H14.7613V10.0684C14.7613 11.1685 14.2867 12.2151 13.459 12.94L12.7803 13.5331C12.6396 13.6572 12.5044 13.7869 12.3747 13.9193C12.3912 13.9413 12.4106 13.9606 12.4271 13.9827C13.2174 14.9281 13.7674 16.0505 14.0302 17.2543C14.252 16.7846 14.5686 16.3657 14.96 16.024L15.6387 15.4309C17.1856 14.078 18.0727 12.1232 18.0722 10.0684V9.24085H19.7277C19.83 9.24159 19.9241 9.18491 19.9713 9.09412C20.0184 9.00333 20.0107 8.89377 19.9512 8.81052L16.632 4.1128C16.58 4.04131 16.4969 3.99902 16.4085 3.99902C16.3201 3.99902 16.237 4.04131 16.185 4.1128L12.8824 8.81052C12.8488 8.85611 12.8304 8.91114 12.8299 8.96776Z'
      fill='currentColor'
    />
  </svg>
);
export { IconDirection };
