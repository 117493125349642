import { gql } from '@apollo/client';
import { Boolean, ObjectID, String, TradingStatus } from '../queries';

export interface MutationMessage {
  success?: Boolean;
  msg?: String;
}

export interface UpdateListTradingRealEstatesQueryData {
  updateListTradingRealEstates: MutationMessage;
}
export interface UpdateListTradingRealEstatesQueryVariables {
  realEstateIds: ObjectID[];
  tradingStatus?: TradingStatus;
}

export const UPDATE_LIST_TRADING_REAL_ESTATE = gql`
  mutation updateListTradingRealEstates($realEstateIds: [ObjectID!], $tradingStatus: TradingStatus)
  @api(name: realEstate) {
    updateListTradingRealEstates(realEstateIds: $realEstateIds, tradingStatus: $tradingStatus) {
      success
      msg
    }
  }
`;
