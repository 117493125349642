export interface SortForm {
  sort?: string;
}

export const sortFormDefaultValues: SortForm = {
  sort: undefined,
};

export const eCommerceProductSort = [
  { label: 'Giá: Thấp đến cao', value: 'price:asc' },
  { label: 'Giá: Cao đến thấp', value: 'price:desc' },
];

export const realEstateSortOptions = [
  { label: 'Mới nhất', value: '-createdAt' },
  { label: 'Cũ nhất', value: 'createdAt' },
  { label: 'Giá thấp nhất', value: 'price' },
  { label: 'Giá cao nhất', value: '-price' },
];

export const projectSortOptions = [
  { label: 'Mới nhất', value: '-createdAt' },
  { label: 'Cũ nhất', value: 'createdAt' },
  { label: 'Giá thấp nhất', value: 'item.expectedPrice' },
  { label: 'Giá cao nhất', value: '-item.expectedPrice' },
];

export const productSortOptions = [
  { label: 'Mới nhất', value: '-createdAt' },
  { label: 'Cũ nhất', value: 'createdAt' },
];
