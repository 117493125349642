import { gql } from '@apollo/client';
import { String } from './get-real-estates';

export interface ListBuilding {
  items?: Building[];
}
export interface Building {
  id?: String;
  name?: String;
}

export interface GetListBuildingData {
  getListBuilding?: ListBuilding;
}
export interface GetListBuildingVariables {
  province: String;
}

export const GET_LIST_BUILDING = gql`
  query getListBuilding($province: String!) @api(name: realEstate) {
    getListBuilding(province: $province) {
      items {
        id
        name
      }
    }
  }
`;
