import { gql } from '@apollo/client';
import { Boolean, ID, Int, RealEstateProjectRealEstate, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';
import {
  InternalFromToDateStringInputType,
  ProductInProjectBiha,
  UserBiha,
} from './list-projects-atmnha';

export enum statusRegisterInProject {
  activeSeeHouse = 'activeSeeHouse',
  pendingAppointment = 'pendingAppointment',
  seenHouse = 'seenHouse',
  cancelSeeHouse = 'cancelSeeHouse',
  skipSeeHouse = 'skipSeeHouse',
}

export interface RegisterInProject {
  id?: ID;
  fullName?: String;
  phone?: String;
  email?: String;
  priceList?: Boolean;
  note?: String;
  appointmentTime?: Date;
  registerType?: String;
  project?: RealEstateProjectRealEstate;
  product?: ProductInProjectBiha;
  creator?: UserBiha;
  editor?: UserBiha;
  status?: String;
  source?: String;
  demand?: String;
  createdAt?: Date;
  updatedAt?: Date;
  bookingCode?: String;
  reasonCancel?: String;
}
export interface RegisterInProjectPagination extends PaginationOf {
  items?: RegisterInProject[];
}

export interface ListRegisterSeeHouseListInProjectUserData {
  listRegisterSeeHouseListInProjectUser?: RegisterInProjectPagination;
}

export interface ListRegisterSeeHouseListInProjectUserVariables {
  fullName?: String;
  phone?: String;
  email?: String;
  projectName?: String;
  note?: String;
  status?: statusRegisterInProject[];
  appointmentTime?: InternalFromToDateStringInputType;
  createdAt?: InternalFromToDateStringInputType;
  limit?: Int;
  page?: Int;
  sort?: String;
}

export const LIST_REGISTER_SEE_HOUSE_LIST_IN_PROJECT_USER = gql`
  query listRegisterSeeHouseListInProjectUser(
    $fullName: String
    $phone: String
    $email: String
    $projectName: String
    $note: String
    $status: [statusRegisterInProject]
    $appointmentTime: InternalFromToDateStringInputType
    $createdAt: InternalFromToDateStringInputType
    $limit: Int
    $page: Int
    $sort: String
  ) @api(name: biha) {
    listRegisterSeeHouseListInProjectUser(
      fullName: $fullName
      phone: $phone
      email: $email
      projectName: $projectName
      note: $note
      status: $status
      appointmentTime: $appointmentTime
      createdAt: $createdAt
      limit: $limit
      page: $page
      sort: $sort
    ) {
      items {
        id
        createdAt
        demand
        appointmentTime
        fullName
        product {
          id
          isForSell
          shortAddress
          productCode
          mediaUrls {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          block {
            id
            name
          }
          floor {
            id
            name
          }
          roomNumber
          projectName
        }
        registerType
        status
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
