import { gql } from '@apollo/client';
import {
  Boolean,
  DateTime,
  Direction,
  FileWithFullUrls,
  Float,
  ID,
  Int,
  Map,
  MediaUnionWithFullUrls,
  ObjectID,
  RangeValue,
  SavedItem,
  String,
} from '../../main/queries/get-real-estates';
import { AddressType } from '../../main/queries/list-companies-by-type';
import { DistributedProject } from './get-detail-projects-atmnha';
import { PaginationOf } from './get-user-saved-real-estate';

export type RangeValueType = RangeValue;
export interface RangeValueInput {
  from?: Float | null;
  to?: Float | null;
  key?: String;
  unit?: String;
  value?: String;
}
export type Int32Array = number;
export type Address = AddressType;
export type DirectionEnumType = Direction;
export enum SharingProjectStatus {
  pending = 'pending',
  active = 'active',
  rejected = 'rejected',
  stopped = 'stopped',
  locked = 'locked',
  approved = 'approved',
}
export enum ConstructionFormEnumType {
  freedom = 'freedom',
  rules = 'rules',
}
export enum ViewEnumType {
  sea = 'sea',
  city = 'city',
  mountain = 'mountain',
  inside = 'inside',
}
export enum ProjectStatusEnum {
  onSale = 'onSale',
  constructingFoundation = 'constructingFoundation',
  completedFiftyPercent = 'completedFiftyPercent',
  roofed = 'roofed',
}
export enum PublicProjectStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}
export enum ProjectEnumType {
  complex = 'complex',
  landProject = 'landProject',
  industrialArea = 'industrialArea',
  buildingProject = 'buildingProject',
  shoppingMall = 'shoppingMall',
  socialHouse = 'socialHouse',
}
export interface CompanyBiha {
  id?: String;
  name?: String;
  logo?: FileWithFullUrls;
  isPersonal?: Boolean;
  taxId?: String;
  email?: String;
  hotline?: String;
}
export interface UserBiha {
  id?: ObjectID;
  fullName?: String;
  phones?: String[];
  avatar?: FileWithFullUrls;
  email?: String;
  employeeCode?: String;
  role?: String;
}
export interface salesType {
  sold?: Int;
  assigned?: Int;
}
export interface ProductSharedOutput {
  name?: String;
  id?: ObjectID;
  status?: String;
}
export interface FloorSharedOutput {
  name?: String;
  id?: ObjectID;
  products?: ProductSharedOutput[];
}
export interface ProductInProjectShared {
  id?: ObjectID;
  name?: String;
  products?: ProductSharedOutput[];
  floors?: FloorSharedOutput[];
}
export interface PolygonOutput {
  coordinates: Float[][][];
}
export interface ApproveStatusType {
  status?: String;
  reasonType?: String;
  reason?: String;
}
export interface AreaOutput {
  area?: Float;
  unit?: String;
}
export interface PriceByStagesOutput {
  typeArea?: AreaOutput;
  name?: String;
  priceByStage?: RangeValueType;
}
export interface AreaLandOutput {
  length?: Float;
  width?: Float;
  rear?: Float;
  unit?: String;
}
export interface ApartmentOutput {
  width?: Float;
  length?: Float;
  area?: Float;
  price?: Float;
  priceByStages?: PriceByStagesOutput[];
  bedroomCount?: RangeValueType;
  bedroomArea?: RangeValueType;
  toiletCount?: RangeValueType;
  toiletArea?: RangeValueType;
  internalRoadArea?: RangeValueType;
  views?: String[];
  imageAndVideos?: MediaUnionWithFullUrls[];
  title?: String;
}
export interface TownhouseOutput {
  width?: Float;
  length?: Float;
  area?: Float;
  price?: Float;
  priceByStages?: [PriceByStagesOutput];
  floorCount?: RangeValueType;
  bedroomCount?: RangeValueType;
  bedroomArea?: RangeValueType;
  internalRoadArea?: RangeValueType;
  toiletCount?: RangeValueType;
  toiletArea?: RangeValueType;
  areaList?: AreaLandOutput[];
  views?: String[];
  imageAndVideos?: MediaUnionWithFullUrls[];
  title?: String;
}
export interface CommercialAreaOutput {
  width?: Float;
  length?: Float;
  area?: Float;
  price?: Float;
  priceByStages?: PriceByStagesOutput[];
  floorCount?: RangeValueType;
  kioskCount?: RangeValueType;
  internalRoadArea?: RangeValueType;
  toiletCount?: RangeValueType;
  toiletArea?: RangeValueType;
  views?: String[];
  imageAndVideos?: MediaUnionWithFullUrls[];
  title?: String;
}
export interface PriceByStageOfOfficeProjectOutput {
  stageName?: String;
  price?: RangeValueType;
  unitPrice?: String;
}
export interface ServicesOfShoppingMallProjectOutput {
  serviceName?: String;
  serviceBusinessAreaCount?: RangeValueType;
  from?: Int;
  to?: Int;
}
export interface saleRequestStatus {
  id?: ObjectID;
  status?: String;
  rejectReason?: String;
}
export interface Floor {
  id?: ID;
  name?: String;
  project?: RealEstateProjectBiha;
  block?: Block;
  products?: ProductInProjectBiha[];
}
export interface Block {
  id?: ID;
  name?: String;
  otherName?: String;
  projectId?: ObjectID;
  creator?: UserBiha;
  editor?: UserBiha;
  floors?: Floor[];
  products?: ProductInProjectBiha[];
}
export interface PolygonTypes {
  type?: String;
  coordinates?: Float[][][];
}
export interface ApproveStatus {
  status?: String;
  reasonType?: String;
  reason?: String;
}
export interface ProductInProjectBiha {
  id?: ID;
  isSharing?: Boolean;
  project?: RealEstateProjectBiha;
  projectId?: ObjectID;
  projectName?: String;
  blockId?: ObjectID;
  blockName?: String;
  floorId?: ObjectID;
  floorName?: String;
  productCode?: String;
  type?: String;
  isForSell?: Boolean;
  status?: String;
  fullAddress?: String;
  timeForRent?: Int;
  timeForRentUnit?: String;
  alleyWidth?: Float;
  isNewConstruction?: Boolean;
  width?: Float;
  length?: Float;
  rear?: Float;
  area?: Float;
  clearanceArea?: Float;
  constructionArea?: Float;
  floorCount?: Int;
  bedRoomArea?: Float;
  villaView?: String[];
  views?: ViewEnumType[];
  price?: Float;
  tnccPrice?: Float;
  priceUnit?: String;
  bedroomCount?: Int;
  bathroomCount?: Int;
  roomCount?: Int;
  hasSeaView?: Boolean;
  hotelStar?: Int;
  block?: Block;
  floor?: Floor;
  industryTrade?: String;
  buildingType?: String;
  roomNumber?: String;
  toiletCount?: Int;
  direction?: String;
  companyNote?: String;
  legals?: String[];
  legalMediaUrls?: ObjectID[];
  mediaUrls?: MediaUnionWithFullUrls[];
  vr360Urls?: String[];
  attachments?: FileWithFullUrls[];
  creator?: UserBiha;
  editor?: UserBiha;
  map?: Map;
  squareMap?: PolygonTypes;
  savedItem?: SavedItem;
  fixedUtilities?: String[];
  utilities?: String[];
  localUtilities?: String[];
  equipmentsAndServices?: String[];
  warehouseType?: String;
  warehouseRoomCount?: Int;
  hasMezzanine?: Boolean;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  deletedAt?: DateTime;
  approveStatus?: ApproveStatus;
  approvedBy?: UserBiha;
  approvedAt?: DateTime;
  commission?: String;
  contactPhones?: String[];
  contactName?: String;
  onFloor?: Int;
  isRead?: Boolean;
  reservationStatus?: String;
  formatName?: String;
  shortAddress?: String;
  canBook?: Boolean;
  supporterATMNha?: UserBiha[];
}
export interface RealEstateProjectBiha {
  id?: ID;
  creator?: UserBiha;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  deletedAt?: DateTime;
  projectType?: String;
  isSharing?: Boolean;
  investorName?: String;
  country?: Address;
  contactName?: String;
  contactPhones?: String[];
  contactEmail?: String;
  taxId?: String;
  projectName?: String;
  projectStatus?: String;
  projectCode?: String;
  province?: Address;
  district?: Address;
  ward?: Address;
  street?: Address;
  houseNumber?: String;
  fullAddress?: String;
  map?: Map;
  internalMap?: PolygonOutput;
  exclusiveSupplier?: String[];
  exclusiveSupplierSub?: String[];
  companyNote?: String;
  projectScale?: String;
  attachments?: MediaUnionWithFullUrls[];
  fixedUtilities?: String[];
  utilities?: String[];
  localUtilities?: String[];
  equipmentsAndServices?: String[];
  legals?: String[];
  legalMediaUrls?: [ObjectID];
  mediaUrls?: MediaUnionWithFullUrls[];
  mapPositionImages?: MediaUnionWithFullUrls[];
  mapPositionDescription?: String;
  landImages?: MediaUnionWithFullUrls[];
  landDescription?: String;
  projectLocationImages?: MediaUnionWithFullUrls[];
  projectLocation?: String;
  projectUtilityImages?: MediaUnionWithFullUrls[];
  projectUtilities?: String;
  planAndModelApartmentImages?: MediaUnionWithFullUrls[];
  planAndModelApartment?: String;
  paymentRouteImages?: MediaUnionWithFullUrls[];
  paymentRoute?: String;
  legalLicense?: String;
  localProjectUtilities?: String;
  approveStatus?: ApproveStatusType;
  projectArea?: AreaOutput;
  constructionProjectArea?: AreaOutput;
  projectViews?: String[];
  floorCountPerBlockRange?: RangeValueType;
  standardStar?: Int;
  apartmentCount?: Int;
  townhouseCount?: Int;
  independentVillaCount?: Int;
  doubleVillaCount?: Int;
  shopHouseCount?: Int;
  penthouseCount?: Int;
  condotelCount?: Int;
  offictelCount?: Int;
  commercialAreaCount?: Int;
  constructionGeneralContractor?: String;
  projectOverview?: String;
  startYear?: Int;
  expectedCompletionYear?: Int;
  investment?: RangeValueType;
  expectedPrice?: RangeValueType;
  ownershipTime?: String;
  complexProjectType?: String[];
  complexProjectTypeV2?: String;
  apartment?: ApartmentOutput[];
  townhouse?: TownhouseOutput[];
  independentVilla?: TownhouseOutput[];
  doubleVilla?: TownhouseOutput[];
  condotel?: ApartmentOutput[];
  penthouse?: TownhouseOutput[];
  shophouse?: ApartmentOutput[];
  offictel?: ApartmentOutput[];
  commercialArea?: CommercialAreaOutput[];
  isAlley?: Boolean;
  typeAreas?: AreaOutput[];
  totalLandArea?: AreaLandOutput;
  totalZone?: Int;
  totalBackground?: Int;
  floorCanBuild?: Int;
  directions?: DirectionEnumType[];
  priceRange?: RangeValueType;
  constructionForm?: ConstructionFormEnumType;
  totalWarehouse?: Int;
  totalWorkshops?: Int;
  floorRange?: RangeValueType;
  totalAreaOfLand?: AreaOutput;
  totalAreaBuild?: AreaOutput;
  priceByGap?: PriceByStageOfOfficeProjectOutput[];
  totalBlock?: Int;
  totalFloorShopping?: Int;
  totalAreaFloor?: RangeValueType;
  totalBasements?: Int;
  services?: ServicesOfShoppingMallProjectOutput[];
  totalCinema?: RangeValueType;
  totalFoodTown?: RangeValueType;
  editor?: UserBiha;
  approvedBy?: UserBiha;
  approvedAt?: DateTime;
  deletedBy?: UserBiha;
  pinnedAt?: DateTime;
  tags?: String[];
  hasVideo?: Boolean;
  hasImage?: Boolean;
  has360Image?: Boolean;
  vr360Urls?: String[];
  panoramicImages?: MediaUnionWithFullUrls[];
  company?: String;
  saleRequestStatus?: saleRequestStatus;
  blocks?: Block[];
  totalProductApproved?: Int;
  isReadStatus?: Boolean;
}

export interface InternalFromToDateStringInputType {
  from?: String;
  to?: String;
}
export interface DistributedProjectPagination extends PaginationOf {
  items?: DistributedProject[];
}

export interface ListProjectsATMNhaData {
  listProjectsATMNha?: DistributedProjectPagination;
}
export interface ListProjectsATMNhaVariables {
  projectName?: String;
  projectStatus?: ProjectStatusEnum[];
  status?: PublicProjectStatus[];
  priceRange?: RangeValueInput;
  projectType?: ProjectEnumType[];
  street?: ObjectID;
  ward?: ObjectID;
  district?: ObjectID;
  province?: ObjectID;
  projectCode?: String;
  createdAt?: InternalFromToDateStringInputType;
  expectedCompletionYear?: Int;
  projectArea?: RangeValueInput;
  expectedPrice?: RangeValueInput;
  page?: Int;
  limit?: Int;
  sort?: String;
}

export const LIST_PROJECTS_ATMNHA = gql`
  query listProjectsATMNha(
    $projectName: String
    $projectStatus: [ProjectStatusEnum]
    $status: [PublicProjectStatus]
    $priceRange: RangeValueInput
    $projectType: [ProjectEnumType]
    $street: ObjectID
    $ward: ObjectID
    $district: ObjectID
    $province: ObjectID
    $projectCode: String
    $createdAt: InternalFromToDateStringInputType
    $expectedCompletionYear: Int
    $projectArea: RangeValueInput
    $expectedPrice: RangeValueInput
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: biha) {
    listProjectsATMNha(
      projectName: $projectName
      projectStatus: $projectStatus
      status: $status
      priceRange: $priceRange
      projectType: $projectType
      street: $street
      ward: $ward
      district: $district
      province: $province
      projectCode: $projectCode
      createdAt: $createdAt
      expectedCompletionYear: $expectedCompletionYear
      projectArea: $projectArea
      expectedPrice: $expectedPrice
      page: $page
      limit: $limit
      sort: $sort
    ) {
      items {
        id
        expectedPrice {
          from
          to
        }
        project {
          id
          projectStatus
          projectType
          projectName
          fullAddress
          projectArea {
            area
          }
          mediaUrls {
            ... on FileWithFullUrls {
              fileId
              type
              originalUrl
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                videoId
              }
            }
          }
          totalBlock
          blocks {
            id
            name
          }
          projectOverview
          vr360Urls
          createdAt
        }
        savedItem {
          id
          belongsToCollection {
            id
            name
          }
        }
        totalBlock
        totalFloor
        totalProduct
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
