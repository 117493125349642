import { SVGProps } from 'react';

const IconSettingsCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      d='M10.0016 13.6V13.6H10C9.28799 13.6 8.59197 13.3889 7.99995 12.9933C7.40793 12.5977 6.94651 12.0355 6.67404 11.3777C6.40156 10.7198 6.33027 9.99601 6.46917 9.29768C6.60808 8.59934 6.95095 7.95789 7.45442 7.45442C7.95789 6.95095 8.59934 6.60808 9.29768 6.46917C9.99601 6.33027 10.7198 6.40156 11.3777 6.67404C12.0355 6.94651 12.5977 7.40793 12.9933 7.99995C13.3889 8.59197 13.6 9.28799 13.6 10L13.6 10.0016C13.6051 10.4756 13.5156 10.9458 13.3366 11.3847C13.1576 11.8236 12.8927 12.2223 12.5575 12.5575C12.2223 12.8927 11.8236 13.1576 11.3847 13.3366C10.9458 13.5156 10.4756 13.6051 10.0016 13.6ZM10 7.34996C9.65052 7.34247 9.30312 7.40566 8.97865 7.53576C8.65312 7.66628 8.35742 7.86143 8.10943 8.10943C7.86143 8.35742 7.66628 8.65312 7.53576 8.97865C7.40566 9.30312 7.34247 9.65052 7.34996 10C7.34247 10.3495 7.40566 10.6969 7.53576 11.0214C7.66628 11.3469 7.86143 11.6426 8.10943 11.8906C8.35742 12.1386 8.65312 12.3337 8.97865 12.4642C9.30312 12.5943 9.65052 12.6575 10 12.65C10.3495 12.6575 10.6969 12.5943 11.0214 12.4642C11.3469 12.3337 11.6426 12.1386 11.8906 11.8906C12.1386 11.6426 12.3337 11.3469 12.4642 11.0214C12.5943 10.6969 12.6575 10.3495 12.65 10C12.6575 9.65052 12.5943 9.30312 12.4642 8.97865C12.3337 8.65312 12.1386 8.35742 11.8906 8.10943C11.6426 7.86143 11.3469 7.66628 11.0214 7.53576C10.6969 7.40566 10.3495 7.34247 10 7.34996Z'
      fill='currentColor'
      stroke='#F4F4F4'
      strokeWidth='0.3'
    />
    <path
      d='M18.3156 6.9025L16.8406 4.3475C16.6962 4.09699 16.4694 3.90434 16.1988 3.80245C15.9282 3.70055 15.6306 3.69571 15.3569 3.78875L13.8356 4.30313C13.5735 4.12662 13.2995 3.96833 13.0156 3.82937L12.7013 2.255C12.6446 1.97153 12.4914 1.71645 12.2679 1.53321C12.0443 1.34997 11.7641 1.24989 11.475 1.25H8.52501C8.23593 1.24989 7.95575 1.34997 7.73216 1.53321C7.50858 1.71645 7.35542 1.97153 7.29876 2.255L6.98439 3.82937C6.69734 3.96681 6.42021 4.12406 6.15501 4.3L4.64251 3.78875C4.36891 3.69603 4.07163 3.70104 3.80131 3.80292C3.53099 3.90481 3.30435 4.09726 3.16001 4.3475L1.68501 6.9025C1.54052 7.15268 1.48715 7.44519 1.53398 7.73028C1.58081 8.01537 1.72495 8.27544 1.94189 8.46625L3.14876 9.52687C3.13814 9.68437 3.12501 9.84062 3.12501 10C3.12501 10.1613 3.13126 10.3206 3.14251 10.4788L1.94251 11.5338C1.72537 11.7244 1.58099 11.9844 1.53393 12.2695C1.48687 12.5546 1.54004 12.8472 1.68439 13.0975L3.15939 15.6525C3.30368 15.9031 3.53048 16.0959 3.80108 16.1979C4.07168 16.2999 4.3693 16.3049 4.64314 16.2119L6.16439 15.6969C6.42646 15.8737 6.70045 16.0322 6.98439 16.1713L7.29876 17.745C7.35542 18.0285 7.50858 18.2835 7.73216 18.4668C7.95575 18.65 8.23593 18.7501 8.52501 18.75H11.25V17.5H8.52501L8.08126 15.2812C7.4674 15.0532 6.89756 14.7208 6.39688 14.2987L4.24251 15.0275L2.76751 12.4725L4.47064 10.9756C4.35411 10.3293 4.35263 9.66747 4.46626 9.02062L2.76751 7.5275L4.24251 4.9725L6.38439 5.6975C6.88863 5.27483 7.46291 4.94359 8.08126 4.71875L8.52501 2.5H11.475L11.9188 4.71875C12.5326 4.94682 13.1024 5.2792 13.6031 5.70125L15.7575 4.9725L17.2325 7.5275L15.4838 9.06L16.3075 10L18.0575 8.46625C18.2746 8.2756 18.419 8.01561 18.4661 7.73051C18.5131 7.44542 18.46 7.15282 18.3156 6.9025Z'
      fill='currentColor'
    />
    <path
      d='M14.2689 16.469L14.375 16.575L14.4811 16.469L17.8688 13.0813L18.5379 13.7504L14.375 17.9133L12.0871 15.6254L12.7562 14.9563L14.2689 16.469Z'
      fill='currentColor'
      stroke='#F4F4F4'
      strokeWidth='0.3'
    />
  </svg>
);
export { IconSettingsCheck };
