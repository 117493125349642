import { SVGProps } from 'react';

const IconStickerText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.4167 1.66663H4.58341C3.00008 1.66663 1.66675 2.99996 1.66675 4.58329V15.4166C1.66675 17 3.00008 18.3333 4.58341 18.3333H13.3334L18.3334 13.3333V4.58329C18.3334 2.99996 17.0001 1.66663 15.4167 1.66663ZM16.7501 12.5H15.5001C13.9167 12.5 12.5834 13.8333 12.5834 15.4166V16.6666H4.83342C4.00008 16.6666 3.33341 16 3.33341 15.1666V4.83329C3.33341 3.99996 4.00008 3.33329 4.83342 3.33329H15.2501C16.0834 3.33329 16.7501 3.99996 16.7501 4.83329V12.5ZM5.83342 5.83329H14.1667V7.49996H5.83342V5.83329ZM5.83342 9.16663H14.1667V10.8333H5.83342V9.16663ZM5.83342 12.5H10.8334V14.1666H5.83342V12.5Z'
      fill='currentColor'
    />
  </svg>
);
export { IconStickerText };
