import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { ID } from '../queries';
import { FriendRequest } from './cancel-friend-request';

export interface FriendRequestMutation extends MutationOf {
  data: FriendRequest;
}

export interface UnFriendData {
  unFriend?: FriendRequestMutation;
}
export interface UnFriendVariables {
  friendId: ID;
}

export const UN_FRIEND = gql`
  mutation unFriend($friendId: ID!) @api(name: social) {
    unFriend(friendId: $friendId) {
      success
      msg
    }
  }
`;
