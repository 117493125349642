import { Variants } from 'restful/e-commerce';

export interface ECommerceAddProductToCart {
  amount?: number;
  product_option?: Variants;
  variation_features_variant?: Variants;
}

export const eCommerceAddProductToCartDefaultValues: ECommerceAddProductToCart = {
  amount: 1,
  product_option: undefined,
  variation_features_variant: undefined,
};
