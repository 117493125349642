import { gql } from '@apollo/client';
import { Point } from './get-list-districts-of-province';
import { String } from '../../main/queries/get-real-estates';

export interface SuggestPlace {
  id?: String;
  fullText?: String;
  location?: Point;
  mainText?: String;
}
export interface IotAutoSuggestPlaceData {
  iotAutoSuggestPlaces?: SuggestPlace[];
}
export interface IotAutoSuggestPlacesPayload {
  data?: IotAutoSuggestPlaceData;
}
export interface IotAutoSuggestPlacesVariables {
  searchString: String;
}
export interface PlanInfo {
  area?: String;
  buildingDensity?: String;
  color?: String;
  floorAreaRatio?: String;
  functionalLot?: String;
  height?: String;
  landUse?: String;
  population?: String;
  polygon?: Point;
}
export interface Plan {
  planningId?: String;
  area?: Number;
  type?: String;
  landUse?: String;
  color?: String;
  gid?: String;
  polygon?: Point;
  planningInfo?: PlanInfo;
}
export interface LandDetail {
  fullAddress?: String;
  id?: String;
  location?: Point;
  mapId?: String;
  parcelId?: String;
  totalArea?: Number;
  plans?: Plan[];
  polygon?: Point;
}
export interface LandDetailData {
  getLandDetailsByCoordinateV2?: LandDetail;
}
export interface LandDetailVariables {
  district?: String;
  ward?: String;
  parcelId?: String;
  mapId?: String;
  lat?: Number;
  long?: Number;
}
interface FloatArray {
  [index: number]: number;
}
export interface VN2000ToLatLong {
  centroid?: Number[];
  latLong?: FloatArray[];
}
export interface VN2000ToLatLongData {
  getLatLongFromVN2000?: VN2000ToLatLong;
}
export const IOT_AUTO_SUGGEST_PLACES = gql`
  query ($searchString: String!) {
    iotAutoSuggestPlaces(searchString: $searchString) {
      id
      placeId
      location {
        type
        coordinates
      }
      mainText
      fullText
    }
  }
`;

export const GET_LAND_DETAIL = gql`
  query getLandDetailsByCoordinateV2(
    $district: ObjectID
    $ward: ObjectID
    $mapId: String
    $parcelId: String
    $lat: Float
    $long: Float
  ) {
    getLandDetailsByCoordinateV2(
      district: $district
      ward: $ward
      mapId: $mapId
      parcelId: $parcelId
      lat: $lat
      long: $long
    ) {
      id
      location {
        type
        coordinates
      }
      mapId
      parcelId
      polygon {
        type
        coordinates
      }
      fullAddress
      totalArea
      plans {
        planningInfo {
          functionalLot
          landUse
          area
          population
          height
          buildingDensity
          floorAreaRatio
          gid
          color
          polygon {
            type
            coordinates
          }
        }
        planningId
        area
        type
        functionalLot
        landUse
        color
        gid
        polygon {
          type
          coordinates
        }
      }
    }
  }
`;

export const GET_LATLONG_FROMVN2000 = gql`
  query getLatLongFromVN2000($arrayXY: [[Float!]!], $provinceId: String!) {
    getLatLongFromVN2000(arrayXY: $arrayXY, provinceId: $provinceId) {
      latLong
      centroid
    }
  }
`;
