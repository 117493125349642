import { SVGProps } from 'react';

const IconVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M19.8994 12.089C19.7185 11.7674 19.2964 11.6468 18.9547 11.8478L16.6632 13.1744L16.0803 13.5161V16.6919L16.623 17.0135C16.6431 17.0135 16.6431 17.0336 16.6632 17.0336L18.9145 18.3402C19.015 18.4206 19.1557 18.4608 19.2964 18.4608C19.6783 18.4608 19.9798 18.1592 19.9798 17.7773V15.0839L19.9999 12.4307C19.9999 12.3101 19.9798 12.1895 19.8994 12.089Z'
      fill='currentColor'
    />
    <path
      d='M6.51445 11.0672C7.80231 11.0672 8.84418 10.0253 8.84418 8.73745C8.84418 7.44958 7.80231 6.40771 6.51445 6.40771C5.22658 6.40771 4.18472 7.44958 4.18472 8.73745C4.17025 10.0253 5.22658 11.0672 6.51445 11.0672ZM6.51445 7.7824C7.04985 7.7824 7.46949 8.21651 7.46949 8.73745C7.46949 9.27285 7.03538 9.69249 6.51445 9.69249C5.99351 9.69249 5.5594 9.25838 5.5594 8.73745C5.54493 8.20204 5.97904 7.7824 6.51445 7.7824Z'
      fill='currentColor'
    />
    <path
      d='M14.7135 11.2449C14.1909 11.5062 13.608 11.6671 12.9849 11.6671C12.2211 11.6671 11.4975 11.4459 10.8945 11.0439H8.60307C8.00006 11.4459 7.27645 11.6671 6.51265 11.6671C5.88954 11.6671 5.30663 11.5263 4.78403 11.2449C4.32173 11.5062 4.00012 12.0289 4.00012 12.5917V17.4961C4.00012 18.4006 4.74383 19.1242 5.62824 19.1242H13.8894C14.7939 19.1242 15.5175 18.3805 15.5175 17.4961V12.5917C15.4974 12.0289 15.1758 11.5062 14.7135 11.2449Z'
      fill='currentColor'
    />
    <path
      d='M12.9903 11.0673C14.5292 11.0673 15.7742 9.82229 15.7742 8.28338C15.7742 6.74447 14.5292 5.49951 12.9903 5.49951C11.4514 5.49951 10.2065 6.74447 10.2065 8.28338C10.2065 9.82229 11.4514 11.0673 12.9903 11.0673ZM12.9903 7.14217C13.6301 7.14217 14.1315 7.6609 14.1315 8.28338C14.1315 8.92315 13.6128 9.4246 12.9903 9.4246C12.3678 9.4246 11.8491 8.90586 11.8491 8.28338C11.8491 7.64361 12.3506 7.14217 12.9903 7.14217Z'
      fill='currentColor'
    />
  </svg>
);
export { IconVideo };
