import { SVGProps } from 'react';

const IconVR = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='currentColor'
    {...props}
  >
    <path
      d='M14.0911 9.91602V12.3385H15.3823C16.2073 12.3385 16.7173 11.9435 16.7173 11.151C16.7173 10.351 16.1898 9.91602 15.4048 9.91602H14.0923H14.0911Z'
      fill='currentColor'
    />
    <path
      d='M2 7.5C2 6.83696 2.26339 6.20107 2.73223 5.73223C3.20107 5.26339 3.83696 5 4.5 5H19.5C20.163 5 20.7989 5.26339 21.2678 5.73223C21.7366 6.20107 22 6.83696 22 7.5V17.5C22 18.163 21.7366 18.7989 21.2678 19.2678C20.7989 19.7366 20.163 20 19.5 20H4.5C3.83696 20 3.20107 19.7366 2.73223 19.2678C2.26339 18.7989 2 18.163 2 17.5V7.5ZM9.42125 16.25L11.9088 8.75125H10.2625L8.59625 14.635H8.525L6.84375 8.75125H5.125L7.635 16.25H9.42125ZM12.625 8.75125V16.25H14.0913V13.4537H15.4213L16.7337 16.25H18.3925L16.8875 13.2288C17.5687 12.9663 18.25 12.2238 18.25 11.125C18.25 9.69125 17.2838 8.75 15.6188 8.75H12.625V8.75125Z'
      fill='currentColor'
    />
  </svg>
);
export { IconVR };
