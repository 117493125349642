import { gql } from '@apollo/client';
import { String } from '../queries';
import { FriendRequestMutation } from './un-friend';

export interface SendFriendRequestVariables {
  recipientId: String;
}
export interface SendFriendRequestData {
  sendFriendRequest?: FriendRequestMutation;
}

export const SEND_FRIEND_REQUEST = gql`
  mutation sendFriendRequest($recipientId: ID!) @api(name: social) {
    sendFriendRequest(recipientId: $recipientId) {
      success
      msg
    }
  }
`;
